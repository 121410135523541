import React, { useState, useEffect, useMemo } from "react";
import ClaimService from "../../../../services/ClaimService";
import Notifications from "../../../../services/Notifications";

const FailureTypeSelector = ({
  localized,
  productType,
  failureType,
  setFailureType,
  locale,
  needsValidation,
}) => {
  const [failureTypes, setFailureTypes] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);

  const filteredFailureTypes = useMemo(() => {
    if (!filterOptions || filterOptions.length === 0) {
      return failureTypes;
    }

    const productFilterOption = filterOptions.find(
      (option) => option.productType === productType
    );

    if (!productFilterOption || !productFilterOption.filterGroup) {
      return failureTypes;
    }

    return failureTypes.filter((failureType) =>
      productFilterOption.filterGroup.includes(failureType.productType)
    );
  }, [failureTypes, filterOptions, productType]);

  useEffect(() => {
    const fetchFailureTypes = async () => {
      try {
        const [failureTypeResponse, failureTypeFilterOptionsResponse] =
          await Promise.all([
            ClaimService.fetchFailureTypes(),
            ClaimService.fetchFailureTypeFilterOptions(),
          ]);

        if (
          !failureTypeResponse &&
          failureTypeResponse.length === 0 &&
          !failureTypeFilterOptionsResponse &&
          failureTypeFilterOptionsResponse.length === 0
        ) {
          Notifications.error(localized.FailedFetchingFailureTypes);
          return;
        }

        setFailureTypes(failureTypeResponse);
        setFilterOptions(failureTypeFilterOptionsResponse);
      } catch (error) {
        Notifications.error(localized.FailedFetchingFailureTypes);
      }
    };

    fetchFailureTypes();
  }, [locale]);

  const disabled = !productType || productType.trim() === "";
  const needsValidationStyle = needsValidation
    ? failureType.selected === ""
      ? "is-invalid"
      : "is-valid"
    : "";

  const onSelectedFailureType = (value) => {
    const failureType = failureTypes.find((ft) => ft.value === value);

    setFailureType(() => ({
      selected: failureType.value,
      isCommentsRequired: failureType.isCommentsRequired,
    }));
  };

  return (
    <div className="claim-replacement-option">
      <div className="label">{localized.FailureType}*</div>

      <div className="fele-content">
        <select
          style={{ lineHeight: 2.2, fontSize: "small" }}
          className={`form-select ${needsValidationStyle}`}
          onChange={(e) => onSelectedFailureType(e.target.value)}
          value={failureType.selected}
          required
          disabled={disabled}
          tabIndex={disabled ? -1 : 0}
        >
          <option disabled hidden value="">
            {localized.Select}
          </option>
          {filteredFailureTypes.map((failureType) => (
            <option key={failureType.id} value={failureType.value}>
              {failureType.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FailureTypeSelector;
