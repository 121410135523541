import React from 'react';
import PricingHelper from '../helpers/PricingHelper';

export default class Money extends React.Component {

    render() {
        return (
            <React.Fragment>
                {
                    this.props.currency && this.props.country &&
                    <span className="money" >
                        {PricingHelper.formatValue(this.props.country, this.props.currency, this.props.value)}
                    </span>
                }
            </React.Fragment>
        );
    }
}