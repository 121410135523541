import React from 'react';


export default class MessageWarning extends React.Component {

    getHeading = () => {
        if (this.props.title.length > 0) {
            return `${this.props.title}:`;
        }
        else {
            return "";
        }
    }

    render() {
        return (
            <div className="message warning">
                <div className="heading4">{this.getHeading()}</div>
                <div className="text">{this.props.message}</div>
            </div>
        );
    }
}