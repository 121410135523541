import React from 'react';
import Notifications from '../../services/Notifications';
import Authorize from '../Authorize';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import {withUserConfig} from '../../hooks/UserConfigContext'; 
import Loading from '../Loading';
import UserService from '../../services/UserService';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Checkbox from '@mui/material/Checkbox';
import ImportCSV from './ImportCSV';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

export class ImportCustomers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectIsOpen: false,
            userList: [],
            updateUsersToImport: props.updateUsersToImport,
            customers: [],
            addOnly: props.addOnly
        }

        this.handleImportedUsersChange = this.handleImportedUsersChange.bind(this);
        this.setOpenFromList = this.setOpenFromList.bind(this);
    }

    updateStorefrontPersona = (storefront, persona) => {
        this.setState({ selectedStorefront: storefront, selectedPersona: persona });
    }

    setOpenFromList = (openState) => {
        this.setState({ selectIsOpen: openState });
    }

    async handleImportedUsersChange(e, values) {
        await this.setState({ updateUsersToImport: values.map(x => x.id) });
        this.props.setUsersToImport(this.state.updateUsersToImport);

    }
    render() {
        const localized = this.props.UserConfig.labels;
        if (this.props.StorefrontConfig.storefrontSettingsLoaded !== true) {
            return (<Loading type="brand" />)
        } else {
            return (
                <>
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Typography variant="h3" sx={{ fontWeight: "bold" }}>{localized.ImportCustomers}</Typography>
                        <Tooltip sx={{ marginBottom: "10px" }} title={<h3>{localized.CSVImportTooltip}</h3>}>
                            <IconButton >
                                <InfoIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box style={{ paddingTop: "2rem", display: "flex", flexDirection: "row", justifyContent:"space-between" }}>
                        <ImportCSV   localized={this.props.localized} multiple={false} accept=".csv" currentUser={this.props.currentUser} parsedCustomers={this.props.parsedCustomers} setValidFileName={this.props.setValidFileName} setParsedCustomers={this.props.setParsedCustomers} />                        
                        <div>{this.props.validFileName}</div>
                    </Box>
                    
                        
                    <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop:"2rem" }}>{localized.SelectUsersForImport}</Typography>
                    <br /> <br />
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        size="lg"
                        disablePortal
                        PopperComponent={(props) => (
                            <Popper {...props}
                                placement="bottom-start"
                            >
                                {props.children}
                            </Popper>
                        )}
                        options={this.props.usersToImport}
                        getOptionLabel={(option) => option.email}

                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.email}
                                    {...getTagProps({ index })}
                                    sx={{ background: 'white', color: 'black', fontSize: "15px", height: "100%", width: '100%', padding: "10px" }}
                                />
                            ))
                        }
                        onChange={this.handleImportedUsersChange}
                        defaultValue={this.state.updateUsersToImport}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={localized.SelectUsersForImport}
                            />


                        )}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: 'fit-content' }}>

                        <Box>
                            <Typography variant="h6" sx={{ marginTop: "5px", fontWeight: "bold" }}>{localized.DeleteExistingCustomers}</Typography>
                        </Box>
                        <Box>
                            <Checkbox
                                onChange={this.props.setAddOnly}
                                value={this.props.addOnly}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Box>
                    </Box>

                </>




            )
        }
    }
}
function notValid(message) {
    Notifications.error(message);
    return false;
}

export default withStorefrontConfig(withUserConfig(ImportCustomers));