import React, { useState, useEffect } from 'react';
import DoubleDownArrow from '../icons/DoubleDownArrow';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import Strings from './OrdersReport.Strings';
import OrderStrings from '../../translations/Orders.Strings';
import UserService from '../../services/UserService';
import FilterSelect from '../form-controls/FilterSelect';
import { useCustomers } from '../../hooks/CustomerContext';


const addCustomerDropdownDescription = customers => {
    return customers.map(c => { return { ...c, dropdownDescription: `${c.externalId} - ${c.name}` } });
}

function OrdersReportFilter(props) {
    const { customerList } = useCustomers();
    const [flatCustomerList, setFlatCustomerList] = useState(customerList);
    const store = props.store ? props.store : useSelector((state) => state);
    const [customers, setCustomers] = useState(addCustomerDropdownDescription(flatCustomerList));
    const localized = Strings.localize();
    const localizedOrders = OrderStrings.localize();
    const [animateHeight, setAnimateHeight] = useState("auto");
    const [territories, setTerritories] = useState([]);

    useEffect(() => {
        let mounted = true;
        if (mounted) UserService.getUserSalesTerritories(store.user.currentUser.id, loadTerritories);
        flattenCustomers();
        return () => { mounted = false; return null; }
    }, [])

    const loadTerritories = (data) => {
        if (data) {
            data = data.map(territory => { return { ...territory, dropdownDescription: `${territory.code} - ${territory.description}` } })
            setTerritories(data);
            if (data.length === 1) changeTerritory(data[0].code);
        }
    }

    const flattenCustomers = () => {
        let flat = [];
        flat = customerList.flatMap(c => {
            return (c.children.length > 0) ? [].concat(...c.children, c) : c;
        });
        setFlatCustomerList(flat.sort((a, b) => (a.id > b.id) ? 1 : -1));
    }

    const loadCustomers = (newTerritory) => {
        //when territory changes, filter customers by the new territory
        if (newTerritory) {
            setCustomers(flatCustomerList.flatMap(c => {
                return c.salesTerritoryCode === newTerritory ? { ...c, dropdownDescription: `${c.externalId} - ${c.name}` } : [];
            }));
        }
        else setCustomers(addCustomerDropdownDescription(flatCustomerList));
    }
    const handleEnter = (e) => {
        if (e.key === "Enter") {
            props.fetchOrders();
        }
    }
    const changeTerritory = (newTerritory) => {
        props.setTerritory(newTerritory);
        if (props.filterCustomer !== 0) props.setCustomer({ target: { value: 0 } });
        loadCustomers(newTerritory);
    }
    const territoryChange = (e) => {
        changeTerritory(JSON.parse(JSON.stringify(e.target.value)));
    }

    const { dateRange, setDateRange, numberFilter, setNumber, statusFilter, setStatus, fetchOrders, filterCustomer, setCustomer, territory, setCreditHoldFilter, creditHoldFilter } = props;
    const orderStatuses = [
        { value: '', description: `- ${localizedOrders.SelectStatus} -` }, { value: '0', description: localizedOrders.BeingProcessed },
        { value: '1', description: localizedOrders.Shipped }];
    const dateRanges = [
        { value: '30', description: localizedOrders.Last30Days }, { value: '90', description: localizedOrders.Last90Days },
        { value: '365', description: localizedOrders.LastYear }, { value: '730', description: localizedOrders.Last2Years }
    ];
    return (
        <div className="fele-order-history-filter">
            <div className="button-wrapper filter-trigger">
                <button data-cy="filter-trigger" className="button" onClick={() => setAnimateHeight(animateHeight === 0 ? "auto" : 0)}>
                    {localizedOrders.SearchForOrders}
                    <DoubleDownArrow />
                </button>
            </div>
            <AnimateHeight duration={500} height={animateHeight}>
                <div className="flex">
                    <FilterSelect wrapperClass={'report-status'} label={localized.SelectTerritoryLabel} onChange={territoryChange} value={territory}
                        placeholder={localized.SelectTerritoryPlaceholder} options={territories} valueField={'code'} descriptionField={'dropdownDescription'} />
                    <FilterSelect wrapperClass={'report-status'} label={localized.SelectCustomerLabel} onChange={setCustomer} value={filterCustomer}
                        placeholder={localized.SelectCustomerPlaceholder} options={customers} valueField={'externalId'} descriptionField={'dropdownDescription'} />
                    <FilterSelect wrapperClass={'report-status'} label={localizedOrders.OrderStatus} onChange={setStatus}
                        value={statusFilter} options={orderStatuses} valueField={'value'} descriptionField={'description'} />
                    <FilterSelect wrapperClass={'claim-date-range'} label={localizedOrders.DateRange} onChange={setDateRange}
                        value={dateRange} options={dateRanges} valueField={'value'} descriptionField={'description'} />
                    <div className="claim-submission-text">
                        <div className="label">{localized.CreditHoldLabel}</div>
                        <div className="fele-content">
                            <div className="input-wrapper">
                                <input data-cy="credithold-search" onChange={setCreditHoldFilter} value={creditHoldFilter} type="text" placeholder={localized.CreditHoldLabel} />
                            </div>
                        </div>
                    </div>
                    <div className="report-search">
                        <div className="label">{localizedOrders.SearchInputLabel}</div>
                        <div className="fele-content">
                            <div className="input-wrapper">
                                <input data-cy="order-report-search" type="text" placeholder={localizedOrders.SearchInputLabelPlaceholder}
                                    onChange={setNumber}
                                    onKeyPress={handleEnter}
                                    value={numberFilter} />
                            </div>
                            <div className="button-wrapper small">
                                <button data-cy="order-submit-report-search" className="button search-button button-alt" onClick={fetchOrders}>
                                    {localizedOrders.SearchButtonText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimateHeight>
            <div className="seperator"></div>
        </div>

    );
}
export default OrdersReportFilter;