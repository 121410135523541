import React from 'react';
import Notifications from '../../services/Notifications';
import Authorize from '../Authorize';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import Loading from '../Loading';
import UserService from '../../services/UserService';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Checkbox from '@mui/material/Checkbox';

export class Clone extends React.Component {
    constructor(props) {
        super(props);
        const defaultStorefront = props.defaultStorefront || this.props.StorefrontConfig.getStorefrontByNumber(props.user.storefrontNumber);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            selectedPersona: { id: '', value: '' },
            isActive: true, // should create users as active
            selectIsOpen: false,
            selectedCustomer: null,
            selectedStorefront: defaultStorefront,
            userList: [],
            primaryUserToCloneFrom: props.user.id,
            primaryUserCustomers: 0,
            updateUsersToClone: props.updateUsersToClone,
            customers: [],
            infoBoxOpen: false,
            isRoleClone: this.props.isRoleClone,
            isCustomerAdd: false
        }

        this.getCustomers = this.getCustomers.bind(this);
        this.handleClonedUsersChange = this.handleClonedUsersChange.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.updateSelectedCustomer = this.updateSelectedCustomer.bind(this);
        this.setOpenFromList = this.setOpenFromList.bind(this);
        this.getCustomers();
    }

    clearForm() {
        const defaultStorefront = this.props.StorefrontConfig.getStorefrontByNumber(props.currentUser.storefrontNumber);
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            selectedPersona: null,
            selectedStorefront: { value: defaultStorefront.id, label: defaultStorefront.displayName },
        })
    }

    updateStorefrontPersona = (storefront, persona) => {
        this.setState({ selectedStorefront: storefront, selectedPersona: persona });
    }

    updateSelectedCustomer = (customer) => {
        this.setState({ selectedCustomer: customer });
    }

    loadBusinessUnits = (data) => {
        if (data) {
            this.setState({customers : data})
        
        }
    }

    setOpenFromList = (openState) => {
        this.setState({ selectIsOpen: openState });
    }

    async handleClonedUsersChange(e, values) {
        await this.setState({ updateUsersToClone: values.map(x => x.id) });
        this.props.setUsersToClone(this.state.updateUsersToClone);
       
    }

    async getCustomers() {
        await UserService.getCustomers(this.props.user.id, this.loadBusinessUnits); 
    }


    render() {
        const localized = this.props.StorefrontConfig.labels;
        if (this.props.StorefrontConfig.storefrontSettingsLoaded !== true) {
            return (<Loading type="brand" />)
        } else {
            return (
               <>
                 <Typography variant="h4" sx={{fontWeight: "bold"} }>User To Clone</Typography>
                   <Box sx={{display: 'flex', justifyContent: 'center', width: 'fit-content'} }>
                        <Box>
                            <Typography variant="h4" style={{ marginLeft: "10px" } }>{this.props.user.email}</Typography>
                            <Typography variant="h5" style={{ marginLeft: "10px" }}>{"Customers: " + this.state.customers.length}</Typography>
                        </Box>
                        <Box>                     
                            <Typography variant="h5" style={{ marginLeft: "10px", marginTop: "15%" }}>{"Secondary Email: "} {this.props.user.secondaryEmail == null ? "N/A" : this.props.user.secondaryEmail}</Typography>
                        </Box>
                    </Box>
               <Box sx={{ display: 'flex', justifyContent: 'center', width: 'fit-content' }}>
         
                <Box>
                     <Typography variant="h6" sx={{ marginTop: "5px",fontWeight: "bold" }}>Clone Permissions</Typography>
                </Box>
                   <Box>
                    <Checkbox
                        onChange={this.props.setIsRoleClone}
                        value={this.state.isRoleClone}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                   </Box>
                </Box>

                        <br/> <br/>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        size="lg"
                        disablePortal
                        PopperComponent={(props) => (
                            <Popper {...props}
                                placement="bottom-start"
                                >
                                    {props.children}
                                </Popper>
                        )}
                        options={this.props.usersToClone}
                            getOptionLabel={(option) => option.email}
                       
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.email}
                                        {...getTagProps({ index })}
                                        sx={{ background: 'white', color: 'black', fontSize: "15px", height: "100%", width: '100%', padding: "10px"}}
                                    />
                                ))
                            }
                        onChange={this.handleClonedUsersChange }
                            defaultValue={this.state.updateUsersToClone}
                        
                        renderInput={(params) => (
                            <TextField
                                        {...params}
                                placeholder="Clone To"
                                />
                                 
                           
                        )}
                        />
            
                </> 
             
               
             
              
            )
        }
    }
}
function notValid(message) {
    Notifications.error(message);
    return false;
}

export default withStorefrontConfig(Clone);