import LocalizedStrings from 'react-localization';
import Localization from '../services/Localization';


class StatusHelperStrings {
    localized = new LocalizedStrings({

        en: {
            BeingProcessed: "Being Processed",
            Cancelled: "Cancelled",
            Complete: "Complete",
            Completed: "Completed",
            Converted: "Converted",
            Invoiced: "Invoiced",
            OpenQuote: "Open Quote",
            OrderReceived: "Order Received",
            PartiallyShipped: "Partially Shipped",
            PreparingForShipment: "Preparing for Shipment",
            ReadyToInvoice: "Ready to Invoice",
            Rejected: "Rejected",
            Shipped: "Shipped",
            StagedForShipment: "Staged for Shipment",
            Unknown: "Unknown"
        },

        pt: {
            BeingProcessed: "Sendo Processado",
            Cancelled: "Cancelado",
            Complete: "Concluído",
            Completed: "Concluído",
            Converted: "Converted",
            Invoiced: "Invoiced",
            OpenQuote: "Orçamento Aberto",
            OrderReceived: "Pedido Recebido",
            PartiallyShipped: "Enviado Parcialmente",
            PreparingForShipment: "Preparando para embarque",
            ReadyToInvoice: "Ready to Invoice",
            Rejected: "Rejected",
            Shipped: "Enviado",
            StagedForShipment: "Staged for Shipment",
            Unknown: "Unknown"
        },

        es: {
            BeingProcessed: "En proceso",
            Cancelled: "Cancelled",
            Complete: "Completo",
            Completed: "Completo",
            Converted: "Confirmada",
            Invoiced: "Invoiced",
            OpenQuote: "Cotización abierta",
            OrderReceived: "Order Received",
            PartiallyShipped: "Parcialmente enviado",
            PreparingForShipment: "Preparing for Shipment",
            ReadyToInvoice: "Ready to Invoice",
            Rejected: "Rejected",
            Shipped: "Enviado",
            StagedForShipment: "Staged for Shipment",
            Unknown: "Unknown"
        },

        it: {
            BeingProcessed: "In lavorazione",
            Cancelled: "Cancellato",
            Complete: "Fatturato",
            Completed: "Completed",
            Converted: "Converted",
            Invoiced: "Invoiced",
            OpenQuote: "Non confermata",
            OrderReceived: "Ordine ricevuto",
            PartiallyShipped: "Partially Shipped",
            PreparingForShipment: "In Spedizione",
            ReadyToInvoice: "Ready to Invoice",
            Rejected: "Rejected",
            Shipped: "Shipped",
            StagedForShipment: "Staged for Shipment",
            Unknown: "Unknown"
        },

        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new StatusHelperStrings();