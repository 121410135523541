import React from 'react';

export default class OrderAckIcon extends React.Component {
    render() {
        return (
            <div className="icon order-ack-icon">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 65">
                    <g>
	                    <path fill="#F40000" d="M61.3,34.8H24c-0.5,0-0.9,0.4-0.9,0.9v12.1c0,0.5,0.4,0.9,0.9,0.9h37.3c0.5,0,0.9-0.4,0.9-0.9V35.7
		                    C62.1,35.2,61.7,34.8,61.3,34.8z M34.7,43.3h-1.3v2.5h-2.6v-7.9h3.8c2.3,0,3.4,1,3.4,2.7C38,42.2,36.9,43.3,34.7,43.3z M42.6,45.8
		                    h-3.4v-7.9h3.5c2.6,0,4.1,1.2,4.1,3.8C46.8,44.6,45.2,45.8,42.6,45.8z M54.5,39.7h-3.6v1.4h2.8V43h-2.8v2.8h-2.6v-7.9h6.2V39.7z"/>
	                    <path fill="#F40000" d="M34.1,39.7h-0.7v1.8h1.1c0.6,0,1-0.2,1-0.9C35.5,39.8,34.6,39.7,34.1,39.7z"/>
	                    <path fill="#F40000" d="M42.3,39.7h-0.5v4.2h0.5c1.2,0,1.9-0.6,1.9-2.1C44.2,40.1,43.7,39.7,42.3,39.7z"/>
                    </g>
                    <path d="M61.3,49.6H24c-1,0-1.9-0.8-1.9-1.9V35.7c0-1,0.8-1.9,1.9-1.9h37.3c1,0,1.9,0.8,1.9,1.9v12.1C63.1,48.8,62.3,49.6,61.3,49.6
	                    z M24.1,47.6h37V35.8h-37V47.6z"/>
                    <g>
	                    <path fill="none" d="M22.1,47.7V35.7c0-1,0.8-1.9,1.9-1.9h30.1V4H11.9v57h42.2V49.6H24C23,49.6,22.1,48.8,22.1,47.7z"/>
	                    <path d="M54.1,61H11.9V4h42.2v29.8h4V2.4C58.1,1.1,57,0,55.7,0H10.3C9,0,7.9,1.1,7.9,2.4v60.1c0,1.3,1.1,2.4,2.4,2.4h45.4
		                    c1.3,0,2.4-1.1,2.4-2.4V49.6h-4V61z"/>
                    </g>
                </svg>
            </div>
        );
    }
}
