import * as React from 'react';
import { useState, useEffect } from 'react';
import storefrontService from '../services/Storefront';
import Localization from '../services/Localization';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import * as Components from '../resources/Components';

const AccountSummaryConfigContext = React.createContext();

function AccountSummaryConfigProvider({ children, ...props }) {

    // have all the config settings here.
    const [accountSummaryConfigLoaded, setAccountSummaryConfigLoaded] = useState(false);
    const [settings, setSettings] = useState(props.settings || []);
    const [childComponents, setChildComponents] = useState(props.childComponents || {});
    const [labels, setLabels] = React.useState({});

    // might have to just make this a parameter that gets passed in
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);
    const { locale } = useStorefrontSettings();
    // useEffect?
    useEffect(() => {
        getCartConfigSettings();
    }, []);

    useEffect(() => {
        Localization.getComponentLabels(Components.ACCOUNT_SUMMARY, locale, storefrontNumber)
            .then(res => setLabels(res))
    }, [locale]);

    // fetchSettings
    const getCartConfigSettings = () => {
        storefrontService.getAccountSummarySettings(storefrontNumber).then((res) => {
            setSettings(res.settings || []);
            setChildComponents(res.childComponents || []);
            setAccountSummaryConfigLoaded(true);
        });
    }

    return (
        <AccountSummaryConfigContext.Provider
            value={{
                settings,
                childComponents,
                accountSummaryConfigLoaded,
                labels
            }}
        >
            {children}
        </AccountSummaryConfigContext.Provider >
    );
}

function useAccountSummaryConfig() {
    const context = React.useContext(AccountSummaryConfigContext);
    if (context === undefined) {
        throw new Error('useAccountSummaryConfig must be used within a AccountSummaryConfigContext');
    }

    return context;
}

function withAccountSummaryConfig(Component) {
    return function WrappedComponent(props) {
        const AccountSummaryConfig = useAccountSummaryConfig();
        return <Component {...props}
            AccountSummaryConfig={AccountSummaryConfig}
        />;
    }
}

export { AccountSummaryConfigProvider, useAccountSummaryConfig, withAccountSummaryConfig }