import React from "react";
import PropTypes from "prop-types";
import { withWarrantyConfig } from "../../hooks/WarrantyConfigContext";
import NotificationBanner from "../banner/NotificationBanner";
import FileDownloadButton from "../shared/FileDownloadButton";
import WarrantyClaimDialog from "./WarrantyClaimDialog";
import ClaimsSidebar from "./ClaimsSidebar";
import ActionButton from "../shared/button/ActionButton";
import { Row } from "react-bootstrap";
import ClaimForm from "./form/ClaimForm";
import AddOrUpdateButton from "./button/AddOrUpdateButton";

export class ClaimsSubmission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateInstalledSubmit: props.dateInstalledSubmit || null,
      dateFailedSubmit: props.dateFailedSubmit || null,
      minDate: new Date("Jan 1 1970"),
      needsValidation: false,
      itemNeedsValidation: false,
      acceptableObsolete: false,
      fetchingAsync: false,
      itemIsValid: false,
      canAdd: true,
      comment: props.commentsSubmit || null,
      showDialog: false,
    };
    this.stringLimit = 30;
  }

  toggleDialog = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }

    this.setState({
      showDialog: !this.state.showDialog,
      canAdd: false,
      needsValidation: false,
    });

    this.cancel();
  };

  updateDateInstalled = (e) => {
    this.setState(
      { dateInstalledSubmit: new Date(e) },
      this.props.setDateInstalledSubmit(e)
    );
  };

  updateDateFailed = (e) => {
    this.setState(
      { dateFailedSubmit: new Date(e) },
      this.props.setDateFailedSubmit(e)
    );
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props &&
      prevProps.dateInstalledSubmit !== "" &&
      this.props.dateInstalledSubmit === ""
    ) {
      this.setState({ dateInstalledSubmit: null });
      this.setState({ dateFailedSubmit: null });
    }

    if (
      this.props.isEditMode &&
      (this.props.dateInstalledSubmit !== this.state.dateInstalledSubmit ||
        this.props.dateFailedSubmit !== this.state.dateFailedSubmit)
    ) {
      this.setState({
        dateInstalledSubmit: this.props.dateInstalledSubmit,
        dateFailedSubmit: this.props.dateFailedSubmit,
      });
    }
  };

  addLocalClaimItem = () => {
    this.setState({
      needsValidation: true,
    });

    this.props.addClaimItem().then(async (valid) => {
      if (valid) {
        this.setState({
          dateInstalledSubmit: null,
          dateFailedSubmit: null,
          needsValidation: false,
        });
      }
    });
  };

  editLocalClaimItem = () => {
    this.setState({
      needsValidation: true,
    });

    this.props.editClaimItem().then(async (valid) => {
      if (valid) {
        this.setState({
          dateInstalledSubmit: null,
          dateFailedSubmit: null,
          needsValidation: false,
        });
      }
    });
  };

  validateItemNumber = async (e) => {
    const itemNumber = e.target.value.trim();

    this.setState({ fetchingAsync: true });
    if (itemNumber.length > 0) {
      await this.props.validateFastTrack(itemNumber);

      this.setState({ itemNeedsValidation: true });
      this.setState({ acceptableObsolete: false });

      if (this.props.obsolete) {
        if (this.props.returnPolicy == "F") {
          this.props.setClaimCredit(true);
          this.setState({ acceptableObsolete: true });
          this.setState({ itemIsValid: true });
          this.setState({ itemNeedsValidation: false });
          this.setState({ canAdd: true });
        } else {
          this.setState({ acceptableObsolete: false });
          this.setState({ canAdd: false });
          this.props.setClaimCredit(false);
          this.setState({ itemIsValid: false });
        }
      } else {
        this.setState({ canAdd: true });
        this.setState({ itemIsValid: true });
        this.setState({ itemNeedsValidation: false });

        if (this.props.pioneerBrand && this.props.pioneerBrand.shown === true) {
          this.props.setAnalysisOnlyClaimType();
          this.props.setPioneerItem(true);
        } else {
          this.props.setClaimCredit(false);
        }
      }
    }

    // clear pioneer item selected values
    if (itemNumber.length == 0) {
      this.props.setPioneerItem(false);
    }

    await this.validateSerialNumber(itemNumber, this.props.serialNumberSubmit);
  };

  validateSerialNumber = async (itemNumberIn, e) => {
    this.setState({ fetchingAsync: true });
    this.setState({ needsValidation: false });
    this.props.setSerialNumberWarning(false);
    if (
      this.props.claimLines.find(
        (item) =>
          item.serialNumber === e && e.length >= 1 && !this.props.isPioneerItem
      )
    ) {
      this.props.setDuplicateSerial(true);
      this.setState({ canAdd: false });
    }
    if (
      itemNumberIn === "5870205313C" ||
      itemNumberIn === "5870205403C" ||
      itemNumberIn === "5870205503C"
    ) {
      const serialYear = e.slice(0, 2);
      const serialMonth = e.slice(2, 3);
      const prior = ["A", "B", "C"];
      if (
        (serialYear == 24 && !prior.includes(serialMonth.toUpperCase())) ||
        serialYear > 24
      ) {
        await this.props.bypassFastTrackValidation(" ", itemNumberIn);
      } else {
        await this.props.bypassFastTrackValidation("F", itemNumberIn);
      }
    } else {
      this.props.setDuplicateSerial(false);
      if (this.state.itemIsValid) {
        this.setState({ canAdd: true });
      }
    }
    this.setState({ fetchingAsync: false });
  };

  commentHandleChange = (e) => {
    let comments = e.target.value;
    if (comments.length < this.stringLimit + 1) {
      this.props.setCommentsSubmit(e);
      this.setState({ comment: comments });
    }
  };

  submitClaim = () => {
    this.toggleDialog();
    this.props.submitClaim();
  };

  cancel = () => {
    this.props.clearForm();
    this.setState({ dateInstalledSubmit: null, dateFailedSubmit: null });
  };

  render() {
    const {
      itemNumberSubmit,
      setItemNumberSubmit,
      serialNumberSubmit,
      setSerialNumberSubmit,
      referenceNumberSubmit,
      setReferenceNumberSubmit,
      loyaltyIDSubmit,
      setLoyaltyIDSubmit,
      commentsSubmit,
      claimLines,
      dateFormat,
      itemNumberError,
      commentsError,
      installDateError,
      failureDateError,
      loyalityIDWarning,
      serialNumberWarning,
      referenceNumberError,
      setClaimType,
      claimType,
      claimTypeOptionError,
      returnPolicy,
      obsolete,
      duplicateSerial,
      serialNumberErrors,
      fetching,
      isValidClaim,
      isPioneerItem,
      pioneerBrand,
      itemDispositions,
      uploadClaimAttachments,
      handleEnter,
      removeClaimItem,
      uploadClaimAttachmentsToLocalClaimItem,
      loadClaimLineItemForEdit,
      detailsCommentSubmit,
      setDetailsCommentSubmit,
      uploadFiles,
      productType,
      setPumpManufacturer,
      pumpManufacturer,
      failureType,
      setFailureType,
      addingItem,
      productDescription
    } = this.props;

    const localized = this.props.WarrantyConfig.labels;
    this.stringLimit = 25;
    const stringLimittext = commentsSubmit
      ? this.stringLimit - commentsSubmit.length
      : this.stringLimit;
    return (
      <div>
        <div className="fele-order-history-filter">
          <NotificationBanner
            page="ClaimsSubmission"
            locale={this.props.locale}
            classOverride="mb-4"
          />

          <div className="claim-header d-flex justify-content-between">
            {/* Page Title */}
            <span className="text-nowrap">{localized.SubmitClaim}</span>

            <WarrantyClaimDialog
              localized={localized}
              open={this.state.showDialog}
              toggleDialog={this.toggleDialog}
              count={claimLines.length}
              fetching={fetching}
            >
              <Row>
                <ClaimForm
                  localized={localized}
                  itemNumberSubmit={itemNumberSubmit}
                  itemNumberError={itemNumberError}
                  returnPolicy={returnPolicy}
                  obsolete={obsolete}
                  needsValidation={this.state.needsValidation}
                  itemNeedsValidation={this.state.itemNeedsValidation}
                  setItemNumberSubmit={setItemNumberSubmit}
                  handleEnter={handleEnter}
                  validateItemNumber={this.validateItemNumber}
                  serialNumberSubmit={serialNumberSubmit}
                  serialNumberWarning={serialNumberWarning}
                  serialNumberErrors={serialNumberErrors}
                  pioneerBrand={pioneerBrand}
                  setSerialNumberSubmit={setSerialNumberSubmit}
                  validateSerialNumber={this.validateSerialNumber}
                  loyaltyIDSubmit={loyaltyIDSubmit}
                  loyalityIDWarning={loyalityIDWarning}
                  setLoyaltyIDSubmit={setLoyaltyIDSubmit}
                  referenceNumberSubmit={referenceNumberSubmit}
                  referenceNumberError={referenceNumberError}
                  setReferenceNumberSubmit={setReferenceNumberSubmit}
                  commentsSubmit={commentsSubmit}
                  commentsError={commentsError}
                  stringLimittext={stringLimittext}
                  commentHandleChange={this.commentHandleChange}
                  dateInstalledSubmit={this.state.dateInstalledSubmit}
                  installDateError={installDateError}
                  minDate={this.state.minDate}
                  updateDateInstalled={this.updateDateInstalled}
                  dateFailedSubmit={this.state.dateFailedSubmit}
                  failureDateError={failureDateError}
                  updateDateFailed={this.updateDateFailed}
                  claimType={claimType}
                  setClaimType={setClaimType}
                  fetching={fetching}
                  canAdd={this.state.canAdd}
                  fetchingAsync={this.state.fetchingAsync}
                  claimTypeOptionError={claimTypeOptionError}
                  itemDispositions={itemDispositions}
                  isPioneerItem={isPioneerItem}
                  claimLines={claimLines}
                  uploadFiles={uploadFiles}
                  uploadClaimAttachmentsToLocalClaimItem={
                    uploadClaimAttachmentsToLocalClaimItem
                  }
                  duplicateSerial={duplicateSerial}
                  isValidClaim={isValidClaim}
                  dateFormat={dateFormat}
                  setDetailsCommentSubmit={setDetailsCommentSubmit}
                  detailComment={detailsCommentSubmit}
                  isEditMode={this.props.isEditMode}
                  productType={productType}
                  pumpManufacturer={pumpManufacturer}
                  setPumpManufacturer={setPumpManufacturer}
                  failureType={failureType}
                  setFailureType={setFailureType}
                  productDescription={productDescription}
                  locale={this.props.locale}
                />

                {/* Sidebar for Items Added */}
                <ClaimsSidebar
                  claimLines={claimLines}
                  uploadClaimAttachments={uploadClaimAttachments}
                  removeClaimItem={removeClaimItem}
                  loadClaimLineItemForEdit={loadClaimLineItemForEdit}
                  isEditMode={this.props.isEditMode}
                  localized={localized}
                />
              </Row>

              {/* Footer with Buttons */}
              <div className="d-flex flex-column flex-lg-row justify-content-between mt-5 gap-4">
                {/* Pioneer Download RMA PDF  */}
                <FileDownloadButton
                  downloadUrl={localized.DownloadPioneerWarrantyURL}
                  text={localized.DownloadPioneerWarrantyText}
                  visible={isPioneerItem}
                />

                <div className="d-flex flex-column flex-md-row gap-4 mb-md-0">
                  <ActionButton
                    text={localized.Cancel}
                    type={
                      serialNumberSubmit === "" && itemNumberSubmit === ""
                        ? "gray-outline"
                        : "blue-outline"
                    }
                    disabled={
                      serialNumberSubmit === "" && itemNumberSubmit === ""
                    }
                    tabIndex={
                      serialNumberSubmit === "" && itemNumberSubmit === ""
                        ? -1
                        : 0
                    }
                    onClick={() => this.cancel()}
                  />

                  <AddOrUpdateButton
                    isEditMode={this.props.isEditMode}
                    editLocalClaimItem={this.editLocalClaimItem}
                    addLocalClaimItem={this.addLocalClaimItem}
                    localized={localized}
                    canAdd={this.state.canAdd && !addingItem}
                  />
                </div>

                <ActionButton
                  dataCy="claim-submit"
                  text={localized.SubmitClaim}
                  type={
                    claimLines.length === 0 ? "gray-outline" : "green-outline"
                  }
                  onClick={this.submitClaim}
                  disabled={claimLines.length === 0}
                  tabIndex={claimLines.length === 0 ? -1 : 0}
                />
              </div>
            </WarrantyClaimDialog>
          </div>
        </div>
      </div>
    );
  }
}

ClaimsSubmission.propTypes = {
  claimLines: PropTypes.arrayOf(PropTypes.object),
  dateInstalled: PropTypes.object,
  dateFailed: PropTypes.object,
  dateFormat: PropTypes.string,
};

export default withWarrantyConfig(ClaimsSubmission);
