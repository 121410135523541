import React from 'react';
import { connect } from 'react-redux';
import MessageSystem from './MessageSystem';
import PropTypes from 'prop-types';
import UserHelper from '../helpers/UserHelper';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';

export class Authorize extends React.Component {
    constructor(props) {
        super(props);

    }

    userHasRole = (roles) => {
        return UserHelper.hasRoles(roles, this.props.user);
    }

    isAuthorized = () => {
        let authorized = false;
        if (this.props.as && this.props.as.indexOf(this.props.user.personaName) > -1) {
            authorized = true;
        } else if (this.props.hasRoles && this.userHasRole(this.props.hasRoles)) {
            authorized = true;
        } else if (this.props.allExcept && this.props.allExcept.indexOf(this.props.user.personaName) === -1) {
            authorized = true;
        }
        return authorized;
    }

    showUnauthorized = () => {
        return (!this.isAuthorized() && this.props.displayMessage);
    }

    render() {
        const localized = this.props.StorefrontConfig.labels;
        return (
            <React.Fragment>
                {
                    this.isAuthorized() &&
                    this.props.children
                }
                {
                    this.showUnauthorized() &&
                    <MessageSystem title={localized.NotAuthorizedTitle}
                        message={localized.NotAuthorizedMessage} />
                }
            </React.Fragment>
        );
    }
}

Authorize.propTypes = {
    user: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        user: state.user.currentUser,
    };
}

export default connect(
    mapStateToProps,
    null
)(withStorefrontConfig(Authorize));