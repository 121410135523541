import Api from "./Api";
import Notifications from "./Notifications";

class UserService {
    filteredUsers = [];
    selected = null;
    current = null;
    callbacks = [];
    filters = this.defaultFilters();

    fetchFilteredUsers = () => {
        return Api.fetch(this.uri());
    }

    defaultFilters() {
        return {
            search: "",
            active: false,
            admin: false,
            custAdmin: false,
            standard: false,
            standardNoOrdering: false,
            salesRep: false,
            sort: "lastName",
            persona: "*",
            storefrontNumber:""
        }
    }


    resetFilters() {
        this.filters = this.defaultFilters();
    }

    load = res => {
        if (res) {
            this.filteredUsers = res;
            this.updateSelectedUser();
            this.updateSubscribers();
        }
    }

    add = (user, customer = null, callback = null) => {
        Api.fetch("api/user", { body: JSON.stringify({ user: user, customer: customer }), method: 'POST' })
            .then(this.handleAddResponse)
            .then(res => {
                if (callback) {
                    callback(res);
                }
            });
    }

    setCurrent = (user) => {
        this.current = user;
    }

    setUserLanguage = (id, language) => {
        return Api.fetch(`/api/user/setLanguage/${id}/${language}`, { method: 'POST' });
    }

    handleAddResponse = (res) => {
        if (res) {
            return this.fetchFilteredUsers();
        }
        Notifications.error("User already exists");
    }

    confirm(callback) {
        Notifications.confirmation("Would you like to continue?", callback);
    }

    getPersonas = () => {
        return Api.fetch("api/user/personas");
    }

    updateFilter(property, value) {
        this.filters[property] = value;
    }

    updateSelectedUser = () => {
        if (this.selected)
            for (let i = 0; i < this.filteredUsers.length; i++) {
                if (this.filteredUsers[i].id === this.selected.id) {
                    return this.setSelected(this.filteredUsers[i]);
                }
            }
    }

    getUserByid(id) {
        return Api.fetch(`/api/User/${id}`);
    }

    updateActive(id, callback) {
        this.confirm(() => this.updateActiveApi(id, callback));
    }
    getAvailableSalesTerritories(userId, salesTerritoryInput, callback) {
        Api.fetch(`/api/Customer/GetSalesTerritory/${userId}/${salesTerritoryInput}`).then(callback);
    }
    getUserSalesTerritories(userId, callback) {
        Api.fetch(`/api/user/${userId}/GetUserSalesTerritories`).then(callback);
    }
    getUserBusinessUnits(userId, callback) {
        Api.fetch(`/api/user/${userId}/GetUserBusinessUnits`).then(callback);
    }
    updateSalesTerritory(salesTerritoryCode, userId, callback) {
        Api.fetch(`/api/user/${userId}/UpdateSalesTerritory/${salesTerritoryCode}`, { method: 'POST' }).then(callback);
    }
    removeSalesTerritory(salesTerritoryCode, userId, callback) {
        Api.fetch(`/api/user/${userId}/RemoveSalesTerritoryForUser/${salesTerritoryCode}`, { method: 'POST' }).then(callback);
    }
    updateActiveApi(id, callback) {
        Api.fetch(`api/user/toggleActive/${id}`, { method: 'PUT' })
            .then(res => (res === null) && this.fetchFilteredUsers()).then(callback);
    }

    updateWarrantyAccess(id, roles, callback) {
        this.confirm(() => {
            this.updateWarrantyAccessApi(id, roles).then((res) => {
                if (callback)
                    callback();
            });
        });
    }

    updateWarrantyAccessApi(id, roles) {
        return Api.fetch(`api/user/updateWarrantyAccess/${id}/${roles}`, { method: 'PUT' });
    }

    mergeCustomersFromCSV = (customerIds, userIds, addOnly, callback) => {
        Api.fetch(`api/user/mergeFromCSV`, { body: JSON.stringify({ customerIds: customerIds, userIds: userIds, addOnly: addOnly }), method: 'POST' }).then((res) => { });
    }

    cloneUser = (userId, updateUsersToClone, isRoleClone, callback) => {
        Api.fetch(`api/user/clone`, { body: JSON.stringify({ userId: userId, updateUsersToClone: updateUsersToClone, isRoleClone: isRoleClone }), method: 'POST' }).then((res) => { });
    }

    updateStorefront(email, value) {
        this.confirm(() => this.updateStorefrontApi(email, value));
    }

    updateStorefrontApi(email, value) {
        email = email.replace('+', '%20');
        Api.fetch(`/api/user/${email}/storefront/${value}`, { method: 'PUT' });
    }

    updatePersonaApi(userId, personaId) {
        Api.fetch(`/api/user/${userId}/setPersona/${personaId}`, { method: 'POST' }).then((res) => this.fetchFilteredUsers());
    }

    setSecondaryEmailApi(userId, secondaryEmail) {
        Api.fetch(`/api/user/setUserSecondaryEmail/${userId}/${secondaryEmail}`, { method: 'POST' }).then((res) => {
        });
    }

    getReports(callback) {
        Api.fetch(`/api/user/Reports`).then(callback);
    }

    getCustomers(userId, callback) {
        Api.fetch(`api/user/${userId}/customers`).then(callback);
    }

    setSelected(user) {
        this.selected = user;
        this.updateSubscribers();
    }

    subscribe(callback) {
        this.callbacks.push(callback);
    }

    updateSubscribers() {
        this.callbacks.forEach(cb => cb());
    }

    uri() {
        let clauses = [this.top(), this.skip(), this.orderBy()];
        clauses.push(this.filtersQuery(this.filters.active, this.filters.persona, this.filters.search, this.filters.storefrontNumber));
        return [`/api/user/list`, clauses.filter(c => !!c).join("&")].join("?");
    }

    top() { return "top=5000" }

    skip() { return "skip=0" }

    orderBy() { return `orderBy=${this.filters.sort}`; }

    filtersQuery(active, personas, search, storefrontNumber) {
        let filters = [];

        if (active)
            filters.push(`isActive=true`);

        if (personas && personas.length > 0) {
            let personaFilters = []

            for (var i = 0; i < personas.length; i++)
                personaFilters.push(`${personas[i]}`)

            filters.push(`personas=${personaFilters.join(",")}`);
        }

        if (storefrontNumber)
            filters.push(`storefrontNumber=${storefrontNumber}`)

        if (search)
            filters.push(`search=${search.trim()}`)

        if (filters.length) {
            return filters.join("&");
        }
        return "";
    }

    updateSelectedCustomer = async (customerId) => {
        return Api.fetch(`/api/user/updateSelectedCustomer/${customerId}`, { method: "PUT" });
    }
}

export default new UserService();
