import React from 'react';
import DateHelper from '../helpers/DateHelper';

export default class DateDisplay extends React.Component {
    render() {

        if (!DateHelper.isValidDate(this.props.date))
            return null;
        return (
            <React.Fragment>
                {
                    this.props.date ?
                    <span className="money" >
                            {DateHelper.format(this.props.date, this.props.dateFormat || 'yyy-MMM-dd')}
                    </span>                        
                    : ''            
                }
            </React.Fragment>
        );
    }
}
