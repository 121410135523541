import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../Loading';

export default class PlacingOrder extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { localized } = this.props;
        return (
            <div className="cart-placing-order center">
                <Loading type="brand" />
                <div className="heading1">
                    {
                        (!this.props.IsQuote && localized.OrderBeingProcessedMessage)
                        ||
                        (this.props.IsQuote && localized.QuoteBeingProcessedMessage)
                    }
                </div>
                <p>{localized.DoNotLeavePageMessage}</p>
            </div>
        )
    }
}

PlacingOrder.propTypes = {
    IsQuote: PropTypes.bool,
};