import React from "react";
import { Typography, CircularProgress } from "@mui/material";
import FormSection from "../FormSection";
import { Col } from "react-bootstrap";

const ProductDescription = ({ productDescription, label, fetching }) => {
  if (fetching) {
    return (
      <Col>
        <FormSection label="">
          <CircularProgress size="30px" />
        </FormSection>
      </Col>
    );
  }

  if (!productDescription) {
    return null;
  }

  return (
    <Col>
      <FormSection label={label}>
        <Typography
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 2,
            lineClamp: 2,
          }}
          style={{
            fontFamily: '"Gotham XNarrow SSm A", "Gotham XNarrow SSm B"',
            fontSize: "1.4rem",
          }}
        >
          {productDescription}
        </Typography>
      </FormSection>
    </Col>
  );
};

export default ProductDescription;
