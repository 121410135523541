import React, { useState, useEffect } from 'react';
import Loading from '../Loading';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import { useHistory } from 'react-router-dom';
import MessageSystem from '../MessageSystem';
import UserService from '../../services/UserService';

export const ReportsIndex = props => {

    const [usersReports, setUsersReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = props.history || useHistory();
    const storefrontConfig = useStorefrontSettings();
    const salesHistoryConfig = useSalesHistoryConfig(); // this will be needed for report name labels

    useEffect(() => {
        //setUsersReports(getUsersReports());
        UserService.getReports(getUsersReports);
    }, []);

    const getUsersReports = (data) => {
        if (data) {
            setUsersReports(data);
        }
        setLoading(false);
    }

    const navigateToReport = (url) => {
        history.push({ pathname: url, state: {} });
    }

    return (
        <div className="fele-reporting">
            {loading && <Loading type="brand" />}
            {
                !loading && usersReports.length < 1 &&
                <MessageSystem title={storefrontConfig.labels.NotAuthorizedTitle}
                    message={storefrontConfig.labels.NotAuthorizedMessage} />
            }
            {
                !loading && usersReports.length > 0 &&
                <div className="row row-cols-1 row-cols-md-2 g-4" style={{ margin: '50px' }}>
                    {
                        usersReports.map((r, key) =>
                            <div className="col align-self-center" role="button" key={key} onClick={() => navigateToReport(r.path)} style={{ width: 400, marginRight: '50px' }}>
                                <div className="card bg-button-color" style={{ height: 100 }}>
                                    <div className="card-body text-center" style={{ margin: 0, position: 'absolute', top: '50%', left: '50%', msTransform: 'translate(-50%, -50%)', transform: 'translate(-50%, -50%)' }}>
                                        <h2 className="card-title display-5" style={{ whiteSpace: 'nowrap' }}><b>{salesHistoryConfig.labels[r.name]}</b></h2>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    );
}

export default ReportsIndex;