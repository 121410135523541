export default {
    googleKeys: {
        googleAPIKey: "UA-137924762-1",
        fuelingGoogleAPIKey: "UA-116555619-1",
        brazilGoogleAPIKey: "UA-78077513-1",
        laGoogleAPIKey: "UA-91288315-2",
        italyGoogleAPIKey: "UA-15567537-7",
        australiaGoogleAPIKey: "UA-15567537-8",
        argentinaGoogleAPIKey: "UA-15567537-12",
        nlaGoogleAPIKey: "G-S8MQ3JNY6Y",
        indiaGoogleAPIKey: "G-B8Z14C4FHX",
        fuelingGoogleAPIKey: "G-Q6Q9FP47MY"
    },
    googleTags: {
        fuelingTag: "GTM-W5TGDHV",
        argentinaTag: "GTM-MMTKLVP",
        australiaTag: "GTM-MWFWTGT",
        brazilTag: "GTM-TXRMZHL",
        italyTag: "GTM-TH2KCNF",
        uscanTag: "GTM-PGVN76K",
        latinAmericaTag: "GTM-MFNLBM4",
        peruTag: "GTM-57SJRQF",
        chileTag: "GTM-P2C7RX4",
        nlaTag: "GTM-5SZQK43W",
        indiaTag: "GTM-NQM7BV7X",
        fuelingGermanyTag: "GTM-M5D37GBL"
    },
    uriParts: {
        defaultDomain: ".fe-direct.com",
        fuelingDomain: ".ffspro.com",
        fuelingGermanyDomain: ".ffspro.eu",
        defaultURISubdomain: "https://next",
        fuelingURISubdomain: "https://advantage",
        brazilURISubdomain: "https://fmb",
        laURISubdomain: "https://ss",
        italyURISubdomain: "https://italy",
        australiaURISubdomain: "https://aus",
        argentinaURISubdomain: "https://arg",
        chileURISubdomain: "https://chile",
        peruURISubdomain: "https://peru",
        nlaURISubdomain: "https://sc",
        indiaURISubdomain: "https://india",
        devURISubdomainPart: "-dv.",
        pyURISubdomainPart: "-py.",
        localhostURI: "localhost",
        defaultHost: "next.fe-direct.com",
        fuelingHost: "advantage.ffspro.com"
    },
    storefronts: {
        latinAmericaStorefront: "6400",
        brazilStorefront: "8700",
        usCanStorefront: "9000",
        fuelingStorefront: "5000",
        italyStorefront: "7000",
        australiaStorefront: "6500",
        argentinaStorefront: "8850",
        chileStorefront: "8500",
        peruStorefront: "4900",
        nlaStorefront: "6450",
        indiaStorefront: "7660",
        fuelingGermanyStorefront: "5700"
    }
}