import LocalizedStrings from 'react-localization';
import Localization from './../services/Localization';

class LanguageSelectStrings {
    localized = new LocalizedStrings({
        en: {
            SelectLanguage: "Select Your Language:"
        },

        pt: {
            SelectLanguage: "Selecione seu Idioma:"
        },

        es: {
            SelectLanguage: "Selecciona tu idioma:"
        },

        it: {
            SelectLanguage: "Seleziona la tua lingua:"
        },

        setLanguage: LocalizedStrings.setLanguage
    });


    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new LanguageSelectStrings();
