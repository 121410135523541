import React from 'react';

export default class AngleDown extends React.Component {
    render() {
        return (
            <div className="icon angle-down">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.122 6.477">
                    <defs>
                    </defs>
                    <path id="Shape" className="cls-1" d="M4.522,7.061.237,1.722A1.191,1.191,0,0,1,.237.3.7.7,0,0,1,1.382.3L6.24,6.348a1.191,1.191,0,0,1,0,1.427L1.382,13.827a.7.7,0,0,1-1.145,0,1.191,1.191,0,0,1,0-1.427Z" transform="translate(14.122) rotate(90)" />
                </svg>
            </div >
        );
    }
}
