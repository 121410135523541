import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import IconButton from "@mui/material/IconButton";
import FontAwesomeSvgIcon from "../icons/FontAwesomeSvgIcon";
import storefrontService from "../../services/Storefront";

const NotificationBanner = ({
  bgColor,
  textColor,
  subjectColor,
  variant: initialVariant,
  subject: initialSubject,
  message: initialMessage,
  showCloseButton = true,
  isActive = false,
  page,
  loadApi = true,
  locale,
  classOverride = "",
}) => {
  const [isVisible, setVisible] = useState(isActive);
  const [fadeState, setFadeState] = useState("hidden"); // hidden, fadingIn, visible, fadingOut
  const [subject, setSubject] = useState(initialSubject);
  const [message, setMessage] = useState(initialMessage);
  const [variant, setVariant] = useState(initialVariant);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (!loadApi) {
      return;
    }

    const fetchData = async () => {
      const response = await storefrontService.getNotificationBannerSettings(
        page
      );

      const { isActive, subjectTranslation, messageTranslation } = response;

      if (response && response.isActive) {
        setSubject(subjectTranslation);
        setMessage(messageTranslation);
        setVariant(response.variant);
        setVisible(isActive);
        setIsRendered(true);
      }

      if (!isRendered) {
        setFadeState("fadingIn");
      }
    };

    fetchData();
  }, [loadApi, page, locale]);

  useEffect(() => {
    if (fadeState === "fadingIn") {
      const timer = setTimeout(() => setFadeState("visible"), 500);

      return () => clearTimeout(timer);
    }
  }, [fadeState]);

  if (!isVisible || fadeState === "hidden") return null;

  const variantColors = {
    info: {
      bgColor: "#D9EDF7",
      textColor: "#31708F",
      subjectColor: "#31708F",
    },
    warning: {
      bgColor: "#FEFFBD",
      textColor: "#000000",
      subjectColor: "#000000",
    },
    error: {
      bgColor: "#F2DEDE",
      textColor: "#A94442",
      subjectColor: "#A94442",
    },
    success: {
      bgColor: "#DFF0D8",
      textColor: "#3C763D",
      subjectColor: "#3C763D",
    },
  };

  const selectedColors = variant ? variantColors[variant] : {};
  const finalBgColor = selectedColors.bgColor || bgColor || "#d9d9d9";
  const finalTextColor = selectedColors.textColor || textColor || "#000000";
  const finalSubjectColor =
    selectedColors.subjectColor || subjectColor || "#000000";

  const handleClose = () => {
    setFadeState("fadingOut");
    setTimeout(() => {
      setVisible(false);
      setFadeState("hidden");
    }, 500);
  };

  return (
    <div
      style={{
        backgroundColor: finalBgColor,
        color: finalTextColor,
        padding: "1.25rem 2.125rem",
        opacity: fadeState === "fadingIn" || fadeState === "fadingOut" ? 0 : 1,
        fontSize: "1.7rem",
        transition: "opacity 0.5s ease",
      }}
      className={`d-flex gap-2 align-items-center justify-content-between mw-100 ${classOverride}`}
    >
      <div>
        {/* Subject */}
        <span
          className="pe-3"
          style={{ color: finalSubjectColor, fontWeight: 700 }}
        >
          {subject}:
        </span>

        {/* Message */}
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </div>

      {/* Close Button */}
      {showCloseButton && (
        <IconButton aria-label="Close banner" onClick={handleClose}>
          <FontAwesomeSvgIcon icon={faTimes} />
        </IconButton>
      )}
    </div>
  );
};

NotificationBanner.propTypes = {
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  subjectColor: PropTypes.string,
  subject: PropTypes.string,
  message: PropTypes.string,
  showCloseButton: PropTypes.bool,
  isActive: PropTypes.bool,
  variant: PropTypes.oneOf(["info", "warning", "error", "success"]),
  page: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  classOverride: PropTypes.string,
};

export default NotificationBanner;
