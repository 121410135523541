/**
 * @component ClearCartButton
 *
 * @description
 * This component displays a button that allows users to clear all items from the cart.
 * The button is disabled when there are no items in the cart or when the cart is in a "fetching" state.
 *
 * @param {number} count - The number of items in the cart. The button is disabled if the count is 0.
 * @param {boolean} fetching - Indicates whether the cart is currently being calculated or updated.
 * @param {function} clearCart - The function to clear the cart. Triggered when the button is clicked.
 * @param {string} text - The text to display on the button.
 * @returns {JSX.Element} The ClearCartButton component.
 */

import React from "react";
import PropTypes from "prop-types";

const ClearCartButton = ({ count, fetching, clearCart, text, text2 }) => {
  return (
    <div className="button-wrapper">
      <button
        className="button button-secondary text-nowrap px-5"
        disabled={count === 0 || fetching}
        onClick={clearCart}
      >
              {`${text} - ${count} ${text2}`}
      </button>
    </div>
  );
};

ClearCartButton.propTypes = {
  count: PropTypes.number.isRequired,
  fetching: PropTypes.bool.isRequired,
  clearCart: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default ClearCartButton;
