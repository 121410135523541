import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import Auth from './services/Auth';
import App from './App';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';

Auth.run(() => {
    ReactDOM.render(
        <Provider store={configureStore}>
            <App />
        </Provider>,
        document.getElementById('root'));
});
