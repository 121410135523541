import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import Api from "../../services/Api";

const StyledRadio = styled(Radio)(() => ({
  color: "black",
  "&.Mui-checked": {
    color: "black",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 22,
  },
}));

const ServiceSelector = ({
  deliveryInstructions,
  deliveryInstructionsUpdated,
  selectedService,
  setSelectedService,
  localized,
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.fetch("/api/customer/GetServiceSelectors");

        if (response) {
          setList(response);
        }
      } catch (error) {
        console.error("Error fetching service selectors:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event) => {
    deliveryInstructionsUpdated("");
    setSelectedService({
      value: event.target.value,
      isValid: false,
      wasVisited: false,
    });
  };

  const handleInputChange = (e) => {
    deliveryInstructionsUpdated(e.target.value);
    setSelectedService({
      ...selectedService,
      wasVisited: true,
    });
  };

  useEffect(() => {
    if (selectedService.value === "OCU" && selectedService.wasVisited) {
      setSelectedService({
        ...selectedService,
        isValid: deliveryInstructions.length > 0,
      });
    } else {
      setSelectedService({
        ...selectedService,
        isValid: true,
      });
    }
  }, [selectedService.value, selectedService.wasVisited, deliveryInstructions]);

  return (
    <>
      {list.length > 0 ? (
        <div className="section-container">
          <div className="section d-flex flex-column gap-4">
            <FormControl>
              <label id="service-radio-buttons-group" className="heading3">
                {localized.Service}:
              </label>

              <RadioGroup
                name="service-radio-buttons-group"
                value={selectedService.value}
                onChange={handleChange}
              >
                {Object.values(list).map((service) => (
                  <FormControlLabel
                    key={service.value}
                    label={localized[service.display]}
                    control={<StyledRadio />}
                    disableTypography={true}
                    value={service.value}
                    sx={{ maxWidth: "fit-content" }}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {selectedService.value === "OCU" ? (
              <div className="d-flex flex-column gap-2">
                <label htmlFor="city-input">{localized.OCUCityLabel}*</label>

                <div className="fele-content">
                  <div className="input-wrapper" style={{ maxWidth: "30rem" }}>
                    <input
                      id="city-input"
                      type="text"
                      required
                      value={deliveryInstructions}
                      className={`form-control ${
                        selectedService.wasVisited &&
                        (selectedService.isValid ? "is-valid" : "is-invalid")
                      }`}
                      onChange={handleInputChange}
                      onBlur={() =>
                        setSelectedService({
                          ...selectedService,
                          wasVisited: true,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ServiceSelector;
