import LocalizedStrings from 'react-localization';
import Localization from './../../services/Localization';

class OrdersReportStrings {
    localized = new LocalizedStrings({
        en: {
            BackToReport: "Return to Results",
            CreditHoldLabel: "Credit Hold",
            SalesTerritory: "Sales Territory",
            SearchInputLabelPlaceholder: "Search",
            SelectCustomerLabel: "Customer",
            SelectCustomerPlaceholder: "All Customers",
            SelectTerritoryLabel: "Territory",
            SelectTerritoryPlaceholder: "All Territories",
            ShipToCityLabel: "City",
            ShipToLabel: "Ship to",
            ShipToNameLabel: "Name",
            ShipToStateLabel: "State"
        },

        pt: {
            BackToReport: "Return to Results",
            CreditHoldLabel: "Bloqueio",
            SalesTerritory: "Território de Vendas",
            SearchInputLabelPlaceholder: "Pesquisar",
            SelectCustomerLabel: "Cliente",
            SelectCustomerPlaceholder: "Todos os Clientes",
            SelectTerritoryLabel: "Território",
            SelectTerritoryPlaceholder: "All Territories",
            ShipToCityLabel: "Cidade",
            ShipToLabel: "Cód.",
            ShipToNameLabel: "Cliente",
            ShipToStateLabel: "Estado"
        },

        es: {
            BackToReport: "Volver a Resultados",
            CreditHoldLabel: "Crédito",
            SalesTerritory: "Territorio de Ventas",
            SearchInputLabelPlaceholder: "Buscar",
            SelectCustomerLabel: "Cliente",
            SelectCustomerPlaceholder: "Todos los Clientes",
            SelectTerritoryLabel: "Territory",
            SelectTerritoryPlaceholder: "All Territories",
            ShipToCityLabel: "Ciudad",
            ShipToLabel: "Número Cliente",
            ShipToNameLabel: "Cliente",
            ShipToStateLabel: "Estado"
        },

        it: {
            BackToReport: "Return to Results",
            CreditHoldLabel: "Credit Hold",
            SalesTerritory: "Sales Territory",
            SearchInputLabelPlaceholder: "Search",
            SelectCustomerLabel: "Customer",
            SelectCustomerPlaceholder: "All Customer",
            SelectTerritoryLabel: "Territory",
            SelectTerritoryPlaceholder: "All Territories",
            ShipToCityLabel: "City",
            ShipToLabel: "Ship to",
            ShipToNameLabel: "Name",
            ShipToStateLabel: "State"
        },

        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new OrdersReportStrings();