import * as types from './actionTypes';

export function LoadStorefrontSettings(storefrontSettings) {
    return {
        type: types.LOAD_STOREFRONT_SETTINGS, storefrontSettings: storefrontSettings
    };
}

export function loadFrontendSettings(frontendSettings) {
    return {
        type: types.LOAD_FRONTEND_SETTINGS, frontendSettings: frontendSettings
    };
}

