import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuoteSmall from "../icons/QuoteSmall";
import { useHistory, Link } from "react-router-dom";
import StatusHelper from '../../helpers/StatusHelper';
import Authorize from '../Authorize';
import * as Roles from '../../resources/UserRole';
import DateHelper from "../../helpers/DateHelper";
import QuoteTotalsPieGraph from "./QuoteTotalsPieGraph";

export const DashboardQuoteHistory = (props) => {
    const tdMoneyAlign = props.tdMoneyAlign;
    const tdDateAlign = tdMoneyAlign == 'left' ? 'left' : 'middle';
    const tdStatusAlign = tdMoneyAlign == 'left' ? 'left' : 'right';

    const storefrontNumber = props.storefrontNumber;
    const quoteData = props.quoteData;
    const history = useHistory();
    const currency = props.currency;
    const country = props.country;
    const currencySymbol = props.currencySymbol;
    const handleRowClick = (quote) => {
        history.push(`/quote/${quote.orderNumber}/${quote.orderCompany}/${quote.orderType}`);
    }
    return (
        <>
            <Authorize hasRoles={[Roles.VIEW_QUOTE_HISTORY]} displayMessage={false}>
            <div className="quote-panel">
                <div className="title-flex-container">
                    <div>
                        <div><QuoteSmall /></div>
                        <div className="title-label">{props.dashboardLabels.QUOTEHISTORY}</div>
                    </div>
                    <div className={props.isFueling ? "expand-wrapper-ffs" : "expand-wrapper"}>
                        <Link className="link-style" to={{ pathname: "/quotes", query: { statuses: [], dateRange: props.QuoteDayRange } }}>
                            <button className="expand-button">
                                <text className="expand-button-text">{props.dashboardLabels.ViewAll}</text>
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="data-flex-container">
                    {(quoteData && quoteData.headerResponse && quoteData.headerResponse.length > 0) ?
                        <table className="table-style">
                            <th className="th-left">{props.dashboardLabels.QUOTENUMBER}</th>
                            <th className="th-middle-right">{props.dashboardLabels.PONUMBER}</th>
                            <th className="th-middle-right">{props.dashboardLabels.DateCreated}</th>
                            <th className="th-middle-right">{props.dashboardLabels.Status}</th>
                            {quoteData.headerResponse.map((quote, key) => {
                                return <tr className="row-wrapper" >
                                    <td className="td-left-link" onClick={() => handleRowClick(quote)} key={key}>{quote.orderNumber}</td>
                                    <td className="td-po">{quote.poNumber}</td>
                                    <td className={"td-" + tdDateAlign}>{DateHelper.format(quote.orderDate, props.dateFormat)}</td>
                                    <td className={"td-" + tdStatusAlign}>{StatusHelper.formatQuoteStatus(quote.statusDescription, props.dashboardLabels)}</td>
                                </tr>
                            })
                            }
                        </table>
                        : <div>{props.dashboardLabels.NoQuotes}</div>
                    }
                    </div>
                    {(quoteData && quoteData.headerResponse && quoteData.headerResponse.length > 0) &&
                        <QuoteTotalsPieGraph chartData={quoteData} country={country} dashboardLabels={props.dashboardLabels} currencySymbol={currencySymbol} currency={currency} storefrontNumber={storefrontNumber}></QuoteTotalsPieGraph>
                    }
                </div>
            </Authorize>
        </>
    );
}

DashboardQuoteHistory.propTypes = {
    quoteData: PropTypes.object,
    storefrontNumber: PropTypes.string,
    currency: PropTypes.string,
    country: PropTypes.string,
    currencySymbol: PropTypes.string
};
function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
    };
}

export default connect(mapStateToProps, null)(DashboardQuoteHistory);