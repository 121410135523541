import React from 'react';
import Loading from '../Loading';
import DateDisplay from '../DateDisplay';
import Money from '../Money';
import StatusHelper from '../../helpers/StatusHelper';
import DateHelper from "../../helpers/DateHelper";
import PricingHelper from '../../helpers/PricingHelper';
import { CSVLink } from "../../modules/react-csv";
import UserHelper from '../../helpers/UserHelper';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';

function SalesTerritoryOrders(props) {

    const { orders, fetching, renderPaging, openOrderDetails } = props
    const salesHistoryConfig = useSalesHistoryConfig();

    const getCSVData = () => {
        if (props.orders) {
            const csvData = JSON.parse(JSON.stringify(props.orders));
            csvData.map(item => {
                item.submittedOn = item.submittedOn ? DateHelper.format(item.submittedOn, "yyyy-MMM-dd") : null;
                item.status = item.status ? StatusHelper.formatOrderStatus(item.status) : null;
                item.extendedAmount = item.extendedAmount ? PricingHelper.formatNumber("BR", item.extendedAmount) : null;
                item.shipTo = item.shipTo ? item.shipTo : "";
            });

            return csvData;
        }
        else
            return [];
    }

    const CSVHeaders = [
        { label: salesHistoryConfig.labels.CreditHoldLabel, key: "holdCode" },
        { label: salesHistoryConfig.labels.OrderNumber, key: "orderId" },
        { label: salesHistoryConfig.labels.OrderDate, key: "submittedOn" },
        { label: salesHistoryConfig.labels.PONumber, key: "referenceNumber" },
        { label: salesHistoryConfig.labels.ShipTo, key: "shipToId" },
        { label: salesHistoryConfig.labels.ShipToNameLabel, key: "shipTo.name" },
        { label: salesHistoryConfig.labels.City, key: "shipTo.city" },
        { label: salesHistoryConfig.labels.State, key: "shipTo.state" },
        { label: salesHistoryConfig.labels.OrderTotal, key: "extendedAmount" },
        { label: salesHistoryConfig.labels.Status, key: "status" },
        { label: salesHistoryConfig.labels.SalesTerritory, key: "salesTerritory" }
    ];

    return (
        <div data-cy="report-order-history" className="fele-order-history">
            {
                !fetching && (orders && orders.length > 0) &&
                <div className="export-button button-wrapper">
                    <button>
                        <CSVLink id="orders-csv-link" separator={salesHistoryConfig.labels.ExcelSeparator} filename={"Territory Order Report.xls"} data={getCSVData()}
                            headers={CSVHeaders} className="button button-alt">
                            {salesHistoryConfig.labels.Export}
                        </CSVLink>
                    </button>
                </div>
            }
            <div className="br-history-header">
                <div className="br-date">
                    <div className="label">{salesHistoryConfig.labels.CreditHoldLabel}</div>
                </div>
                <div className="br-order-number">
                    <div className="label">{salesHistoryConfig.labels.OrderNumber}</div>
                </div>
                <div className="br-date">
                    <div className="label">{salesHistoryConfig.labels.OrderDate}</div>
                </div>
                <div className="br-po-number">
                    <div className="label">{salesHistoryConfig.labels.PONumber}</div>
                </div>
                <div className="br-item-number">
                    <div className="label">{salesHistoryConfig.labels.ShipTo}</div>
                </div>
                <div className="br-item-description">
                    <div className="label">{salesHistoryConfig.labels.ShipToNameLabel}</div>
                </div>
                <div className="br-po-number">
                    <div className="label">{salesHistoryConfig.labels.City}</div>
                </div>
                <div className="br-item-quantity">
                    <div className="label">{salesHistoryConfig.labels.State}</div>
                </div>
                <div className="br-product-total">
                    <div className="label">{salesHistoryConfig.labels.OrderTotal}</div>
                </div>
                <div className="br-line-status">
                    <div className="label">{salesHistoryConfig.labels.Status}</div>
                </div>
                <div className="br-item-number">
                    <div className="label">{salesHistoryConfig.labels.SalesTerritory}</div>
                </div>
            </div>
            {
                fetching &&
                <Loading type="brand" />
            }
            {
                !fetching &&
                (orders && orders.length == 0) &&
                <div data-cy="report-details-no-results" className="details no-result">
                    {salesHistoryConfig.labels.NoOrdersFound}
                </div>
            }
            {
                !fetching &&
                (orders && orders.length > 0) &&
                <div>
                    <div data-cy="report-history" className="br-history">
                        {
                            orders.map((item, key) => renderOrder(item, key, openOrderDetails))
                        }
                    </div>
                    {renderPaging()}
                </div>
            }

        </div>
    )

    function renderOrder(order, index) {
        return (
            <div className="br-details" key={index}>
                <div className="br-date">
                    <div className="label">{salesHistoryConfig.labels.CreditHoldLabel}</div>
                    <div className="fele-content">{order.holdCode}</div>
                </div>

                <div className="br-order-number">
                    <div className="label">{salesHistoryConfig.labels.OrderNumber}</div>
                    <a className="fele-content" onClick={() => { openOrderDetails(order.orderId, order.orderCompany, order.orderType) }}>{order.orderId}</a>
                </div>

                <div className="br-date">
                    <div className="label">{salesHistoryConfig.labels.OrderDate}</div>
                    <div className="fele-content">
                        <DateDisplay date={order.submittedOn} />
                    </div>
                </div>

                <div className="br-po-number">
                    <div className="label">{salesHistoryConfig.labels.PONumber}</div>
                    <div className="fele-content">{order.referenceNumber}</div>
                </div>

                <div className="br-item-number">
                    <div className="label">{salesHistoryConfig.labels.ShipTo}</div>
                    <div className="fele-content">{order.shipToId}</div>
                </div>

                <div className="br-item-description">
                    <div className="label">{salesHistoryConfig.labels.ShipToNameLabel}</div>
                    <div className="fele-content">{order.shipTo ? order.shipTo.name : ''}</div>
                </div>

                <div className="br-po-number">
                    <div className="label">{salesHistoryConfig.labels.City}</div>
                    <div className="fele-content">{order.shipTo ? order.shipTo.city : ''}</div>
                </div>

                <div className="br-item-quantity">
                    <div className="label">{salesHistoryConfig.labels.State}</div>
                    <div className="fele-content">{order.shipTo ? order.shipTo.state : ''}</div>
                </div>

                <div className="br-product-total">
                    <div className="label">{salesHistoryConfig.labels.OrderTotal}</div>
                    <div className="fele-content">
                        <Money value={order.extendedAmount} currency={order.currency} country="BR" />
                    </div>
                </div>

                <div className="br-line-status">
                    <div className="label">{salesHistoryConfig.labels.Status}</div>
                    <div className="fele-content">{StatusHelper.formatOrderStatus(order.status)}</div>
                </div>

                <div className="br-item-number">
                    <div className="label">{salesHistoryConfig.labels.SalesTerritory}</div>
                    <div className="fele-content">{order.salesTerritory}</div>
                </div>

            </div>
        )
    }

}

export default SalesTerritoryOrders;