import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ImportCartService from '../../services/ImportCartService';
import FileUpload from './FileUpload';
import SubmitModal from '../shared/SubmitModal';
import QuantityInput from './QuantityInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Loading';
import Notifications from '../../services/Notifications';
import UserHelper from '../../helpers/UserHelper';
import { withRouter } from 'react-router-dom';
import * as cartActions from '../../actions/cartActions';
import Localization from '../../services/Localization';

const ImportCart = props => {

    const [items, setItems] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [modal, setModal] = useState(false);
    const [isValidItems, setIsValidItems] = useState([]);
    const [isItemUpdated, setItemUpdated] = useState(false);
    const [totalCount, setTotalCount] = useState();

    useEffect(() => {

    }, [items, isValidItems, totalCount])

    const parsedItems = (items) => {
        setTotalCount(props.itemCount + items?.length);
        fetchValidateItemsApi(items);
    }

    const fetchValidateItemsApi = async (items) => {
        if (items.length > 0) {
            setModal(true);
            setFetching(true);
            return await ImportCartService.fetchValidateItemsToCart(props.currentUser.storefrontId, props.selectedCustomer, Localization.language, items)
                .then(response => {
                    if (response) {
                        loaded(response);
                        return response.items;
                    }
                    else {
                        setModal(false);
                        setFetching(false);
                        return Notifications.error(props.localized.ImportCartInvalidFile);
                    }
                });
        }
        else {
            console.log("Invalid file");
            return Notifications.error(props.localized.ImportCartInvalidFile)
        }
    }

    const loaded = (data) => {
        const itemsData = data.items.map((item, index) => { return { ...item, key: index }; });
        setItems(itemsData);
        setFetching(false);
        setItemUpdated(false);
        setIsValidItems(itemsData.filter(o => o.isValid == false));
    }

    const onAddToCart = async () => {
        if (isItemUpdated) {
            const validItems = await fetchValidateItemsApi(items).then(data => {
                if (data) {
                    const val = data.filter(o => o.isValid == false);
                    if (val.length === 0) {
                        return data;
                    }
                } else {
                    return null;
                }
            });
            const valid = validItems?.filter(o => o.isValid == false);
            if (valid?.length === 0) {
                mapItemsToCart(validItems);
                hideModal();
            }
        }
        else {
            const valid = items.filter(o => o.isValid == false);
            if (valid.length === 0) {
                mapItemsToCart(items);
                hideModal();
            }
        }

    }

    const mapItemsToCart = (items) => {
        addItemsToCartApi(items); //api call
    }

    const addItemsToCartApi = (importItems) => {
        props.setImportItemsFetching(true);
        ImportCartService.addItemsToCart(importItems)
            .then(response => {
                if (response) {
                    props.cartActions.AddImportCartItems(response.cartItems);
                    Notifications.message(props.localized.OrderUpdated);
                }
                else {
                    Notifications.error(props.localized.UnableToAddToCart);
                }
                props.setImportItemsFetching(false);
            });
    }

    const hideModal = () => {
        setModal(false);
    }

    const remove = (e) => {
        // delete items and set invalid items
        const delItems = [...items];
        delItems.splice(e, 1);
        setItems(delItems);
        setIsValidItems(delItems.filter(o => o.isValid == false));
        setTotalCount(props.itemCount + delItems?.length);
    }

    const setItemNumber = (e, item) => {
        if (!UserHelper.isValidItemNumber(e.target.value)) {
            updateCartItem(e, item);
        }
    }

    const handleBlur = () => {
        setItemUpdated(true);
    }

    const updateCartItem = (e, item) => {
        let itemsUpdate = [...items];
        for (let i = 0; i < itemsUpdate.length; i++) {
            if (itemsUpdate[i] === item) {
                if (e === "") {
                    itemsUpdate[i] = item;
                }
                else {
                    itemsUpdate[i].sku = e.target.value;
                }
                break;
            }
        }
        setItems(itemsUpdate);
    }
    const updateLineItemQuantity = (item) => {
    }
    const validateItems = async () => {
        setFetching(false);
        //fetchValidateItemsApi(newItems); //send valid items if it shows performance slow merge and combine
        fetchValidateItemsApi(items);
    }

    return (
        <>
            <FileUpload localized={props.localized} multiple={false} accept=".xls, .csv, .xlsx" currentUser={props.currentUser} parsedItems={parsedItems} />
            {
                <SubmitModal
                    isOpen={modal}
                    onSubmit={onAddToCart}
                    onClose={hideModal}
                    submitText={props.localized.AddToCart}
                    closeText={props.localized.Cancel}
                    size={"lg"}
                    showFooter={totalCount <= 100 ? true : false}
                >
                    <div className="row" >
                        <div className="col">
                            <div className="heading2" style={{ marginLeft: '2rem', marginBottom: '2rem' }}> {props.localized.ImportExcelFilePreview}
                            </div>
                        </div>

                    </div>
                    {
                        !fetching &&
                        totalCount >= 100 &&
                        <div style={{ whiteSpace: 'nowrap', fontSize: 'large', marginTop: '1px', textAlign: 'right' }}>
                                <FontAwesomeIcon icon={faExclamationTriangle} color="orange" /> {props.localized.CartMaxLines}
                        </div>

                    }
                    <div className="fele-content" style={{ textAlign: 'right', marginTop:'1rem' }} >
                        {
                            props.cartConfig.childComponents.ImportCartSync.shown === true &&
                            <button style={{ background: 'none', marginRight: '1rem', border: 'none' }} onClick={validateItems}>
                                <FontAwesomeIcon icon={faSync} color="#118ABD" style={{ cursor: 'pointer' }} />
                            </button>
                        }
                        &nbsp;
                        {
                            <span style={{ fontSize: '1.6rem', marginRight: '1rem', textAlign: 'right', color: isValidItems.length === 0 ? 'green' : 'red' }}>
                                {isValidItems?.length} {props.localized.ImportExcelErrorMessage}
                            </span>
                        }
                    </div>
                    
                    <div className="fele-cart-items" style={{
                        overflowY: 'auto', overflowX: 'hidden',
                        height: '51rem',
                        margin: '1.2rem', padding: '1rem', borderTop: 'solid', borderColor: '#fcfcfc', borderTopWidth: '0.3rem'
                    }} >
                        {
                            <div>
                                {
                                    fetching &&
                                    <Loading type="brand" />
                                }
                            </div>
                        }
                        {
                            !fetching && items.length > 0 && items.map((item, key) => (
                                <React.Fragment>
                                    <div className="cart-item" key={key}>
                                        <div className="row">
                                            <div className="col">
                                                <div className="row pt-md-2 pb-md-2">
                                                    <div className="col-md-8">
                                                        <div className="part-name" style={{ fontSize: '1.7rem' }}>
                                                            <div className="fele-content">{item.name}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    item.product &&
                                                    <div className="row pt-md-2 pb-md-2">
                                                        <div className="col-md-8">
                                                            <div className="long-description" style={{ fontSize: '1.7rem' }}>
                                                                <div className="fele-content">{item.product.description}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row mb-md-3" style={{ marginTop: '1rem' }}>
                                                    <div className="col">
                                                        <div className="cart-part-number">
                                                            {/*<div className="label" style={{ verticalAlign: 'top', marginTop: '10px' }}>{item.key}  </div> &nbsp;*/}
                                                            <div className="label" style={{ verticalAlign: 'top', marginTop: '10px' }}>{props.localized.ItemNumber}:  </div> &nbsp;
                                                            <div className="fele-content mr-3">
                                                                <input className="form-control"
                                                                    className={`form-control ${(item.isValid === true) ? "is-valid" : "is-invalid"}`} onChange={(e) => setItemNumber(e, item)}
                                                                    type="text" value={item.sku} maxLength={25} required onBlur={handleBlur} />
                                                                <div className="invalid-feedback">
                                                                    {props.localized.InvalidItemNumber}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col text-end d-none d-sm-none d-md-block">
                                                        <div className="row pt-md-2 pb-md-2">
                                                            <div className="col-md-7">
                                                                <QuantityInput localized={props.localized} item={item} updateCartItem={updateCartItem} updateLineItemQuantity={updateLineItemQuantity} />
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="">
                                                                    <button className="remove link" value={item} onClick={() => remove(key)} alt="Remove" style={{ height: '2rem' }}></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                            )
                        }
                    </div>
                </SubmitModal>
            }
        </>
    );
}
ImportCart.propTypes = {
    cartActions: PropTypes.object,
    parsedItems: PropTypes.array,
    setImportItemsFetching: PropTypes.func,
    itemCount: PropTypes.number
};

function mapStateToProps(state) {
    return {
        currentUser: state.user.currentUser,
        items: state.cart.items,
        itemCount: state.cart.items.length
    };
}

function mapDispatchToProps(dispatch) {
    return {
        cartActions: bindActionCreators(cartActions, dispatch),
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportCart));
