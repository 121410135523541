import React from 'react';
import ShipmentInfo from './ShipmentInfo'
import ShipmentItems from './ShipmentItems'
import ShipmentSummary from './ShipmentSummary';
import AnimateHeight from 'react-animate-height';
import DoubleDownArrow from '../../icons/DoubleDownArrow'

export default class Shipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            animateHeight: 0,
            animateText: null
        }
        this.toggleAnimation = this.toggleAnimation.bind(this);
    }

    componentDidMount() {
        this.setState({
            animateText: this.props.localized.ShowMoreLabel,
        })
    }

    toggleAnimation() {
        this.setState({
            animateHeight: this.state.animateHeight === 0 ? "auto" : 0,
            animateText: this.state.animateHeight === 0 ? this.props.localized.ShowLessLabel : this.props.localized.ShowMoreLabel
        })
    }

    render() {
        const { shippingAddress, shipment, selectedCustomer, localized } = this.props;
        if (!shipment)
            return null;

        return (
            <div className="order-shipment">
                <ShipmentInfo shipment={shipment} shippingAddress={shippingAddress} localized={localized} />
                <ShipmentItems shipment={shipment} selectedCustomer={selectedCustomer} localized={localized} />
                <AnimateHeight duration={500} height={this.state.animateHeight}>
                    <ShipmentSummary shipment={shipment} selectedCustomer={selectedCustomer} localized={localized} />
                </AnimateHeight>
                <div className={`button-wrapper hide-show ${(this.state.animateHeight === 0) ? '' : 'active'} `} onClick={this.toggleAnimation}>
                    <button className="button">
                        {this.state.animateText}
                        <DoubleDownArrow />
                    </button>
                </div>
            </div>
        );
    }
}