import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Comments from '../cart/Comments';
import PONumber from '../cart/PONumber';
import Loading from '../Loading';
import SubmitModal from '../shared/SubmitModal';
export default function ConvertToOrderModal(props){  
    const { comments, poNumber, poNumberUpdated, commentsUpdated, requiredPO, maxPOLength, fetching, localized, show, onHide, callback } = props;

    return (
        <div>
            <SubmitModal  
            isOpen={show}
            onClose={onHide}
            onSubmit={callback}
            closeText={localized.Cancel}
            submitText={localized.Convert}>
                <Container>
                    <Row>
                        <Col className="heading2" style={{ paddingBottom: '4rem' }}>
                            {localized.ConvertToOrder}  
                        </Col>
                    </Row>
                    <Row>
                        {
                            fetching &&
                            <Loading type="brand" />
                        }
                    </Row>
                    {
                        !fetching &&
                        <>
                            <Row style={{ paddingBottom: '2rem' }}>
                                <Col xs={12} md={3} className="label">
                                    {localized.PONumber}
                                </Col>
                                <Col xs={12} md={9}>
                                    <PONumber isRequired={requiredPO} poNumberUpdated={poNumberUpdated} poNumber={poNumber} maxLength={maxPOLength} localized={localized} />
                                </Col>
                            </Row>
                            <Row style={{ paddingBottom: '2rem' }}>
                                <Col xs={12} md={3} className="label">
                                    {localized.Comments}
                                </Col>
                                <Col xs={12} md={9}>
                                    <Comments commentsUpdated={commentsUpdated} comments={comments} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container>
            </SubmitModal>
        </div>
    )
};

ConvertToOrderModal.propTypes = {
    comments: PropTypes.string,
    commentsUpdated: PropTypes.func,
    poNumber: PropTypes.string,
    poNumberUpdated: PropTypes.func,
}