import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateHelper from "../../helpers/DateHelper";
import Calendar from '../form-controls/Calendar';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';

const ShippingOptions = props => {
    const localized = props.localized;
    const { settingsList } = useStorefrontSettings();
    const handleChange = (e) => {
        updateShippingMethod(e.target.value);
    }
    
    let standardAdder = settingsList.StandardShippingDays;
    let secondDayAdder = parseInt(settingsList.SecondDayAirShippingDayAdder)
    let nextDayAdder = parseInt(settingsList.NextDayAirShippingDayAdder)

    const updateShippingMethod = (method) => {
        props.shippingMethodUpdated(method);
        let today = new Date();
        let offsetDate = today;
        let currentHour = DateHelper.getHours(today);
        let dayAdder = standardAdder;
        let after2 = currentHour >= 14;

        if (method === "Standard_Shipping") {
            offsetDate = DateHelper.addBusinessDays(today, standardAdder, props.dateFormat);
            dayAdder = standardAdder;
        }
        if (method === 'Second_Day_Air') {
            offsetDate = DateHelper.addBusinessDays(today, after2 ? (secondDayAdder + 1) : (secondDayAdder), props.dateFormat);
            dayAdder = after2 ? (secondDayAdder + 1) : (secondDayAdder)
        }
        if (method === 'Next_Day_Air') {
            offsetDate = DateHelper.addBusinessDays(today, after2 ? (nextDayAdder + 1) : nextDayAdder, props.dateFormat);
            dayAdder = after2 ? (nextDayAdder + 1) : nextDayAdder;
        }
        updateRequestDate(offsetDate, dayAdder);
    }

    const updateRequestDate = (date, dayAdder) => {
        props.requestDateUpdated(date,dayAdder??0);
    }
  
    const renderStandardShippingOption = () => {
        const { shippingMethod, requestDate, dayAdder } = props;
        return (
            <>
                <form>
                    <fieldset className="flex-parent" disabled={shippingMethod !== "Standard_Shipping"}>
                        <div className="label flex-label" >{localized.RequestedDateLabel}</div>
                        <div className="from-to-dates">                               
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <Calendar date={requestDate} minDate={DateHelper.addBusinessDays(new Date().setHours(0, 0, 0, 0), dayAdder !== 0 ? dayAdder : standardAdder, props.dateFormat)}
                                        callBackFunction={updateRequestDate} dateFormat={props.dateFormat} showIcon={true} disabled={shippingMethod !== "Standard_Shipping"} filterWeekends={true} />
                                </div>                      
                            </div>
                        <div className="note-light">{localized.FulfillmentNotificationLabel}</div>                 
                        </div>
                    </fieldset>
                </form>
            </>
        );
    }

    const renderShippingOption = (shippingOption, index) => {
        const { shippingMethod} = props;
        return (
            <div key={index}>
                {
                    shippingOption.method === "Standard_Shipping" && renderStandardShippingOption(shippingOption)
                }
                    <div className="radio-wrapper radio-adjusted">
                        <label>
                            <div className="label">{localized[shippingOption.method]}</div>
                            <input type="radio" name="shippingMethod" value={shippingOption.method} checked={shippingMethod === shippingOption.method} onChange={handleChange} />
                            <div className="radio-fake"></div>
                        </label>
                    </div>
            </div>
        );
    }

    const { shippingOptions } = props;
    return (
        <div className="fele-shipping-method">
            <div className="fele-content">
                {shippingOptions.map((item, key) => renderShippingOption(item, key))}
            </div>
        </div>
    )
}

ShippingOptions.propTypes = {
    requestDateUpdated: PropTypes.func,
    shippingMethodUpdated: PropTypes.func,
    shippingMethod: PropTypes.string,
    dateFormat: PropTypes.string,
    shippingOptions: PropTypes.array,
};

export default ShippingOptions;