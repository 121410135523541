import React from 'react';
import PropTypes from 'prop-types';

export default class DeliveryInstructions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            deliveryInstructions: props.deliveryInstructions,
        };

        this.stringLimit = 30;

    }

    handleChange = (e) => {
        let deliveryInstructions = e.target.value;
        if (deliveryInstructions.length < this.stringLimit + 1) {
            this.props.deliveryInstructionsUpdated(deliveryInstructions);
            this.setState({ deliveryInstructions: deliveryInstructions });
        }
    }

    render() {
        if (!this.props.isEnabled) {
            return null
        }
        const { deliveryInstructions } = this.state;
        return (
            <div id="OrderDeliveryInstructions" className="section">
                <div className="heading3">
                    {this.props.deliveryInstructionsLabel}
                </div>
                <div className="fele-cart-delivery-instructions">
                    <div className="fele-content">
                        <div className="input-wrapper">
                            <textarea value={deliveryInstructions} onChange={this.handleChange} onBlur={this.handleChange} />
                            <div className="textarea-note">
                                {`${deliveryInstructions ? this.stringLimit - deliveryInstructions.length : this.stringLimit} ${this.props.charactersLeft}`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

DeliveryInstructions.propTypes = {
    deliveryInstructionsUpdated: PropTypes.func,
    deliveryInstructions: PropTypes.string,
    deliveryInstructionsLabel: PropTypes.string,
    isEnabled: PropTypes.bool,
};