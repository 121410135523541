import React from 'react';

export default class MessageStandard extends React.Component {

    render() {
        return (
            <div className="fele-standard-message">
                {this.props.icon &&
                <>
                    { this.props.icon }
                    < br />
                </>
                }
                {
                    this.props.line1 &&
                    <div className="heading1">{this.props.line1}</div>
                }
                {
                    this.props.line2 &&
                    <div className="heading2">{this.props.line2}</div>
                }
                {
                    this.props.line3 &&
                    <div className="heading3">{this.props.line3}</div>
                }
                {
                    this.props.line4 &&
                    <div className="heading4">{this.props.line4}</div>
                }
                {
                    this.props.line5 &&
                    <div className="heading5">{this.props.line5}</div>
                }
            </div>
        );
    }
}