import React from 'react';

export default class Close extends React.Component {
    render() {
        return (
            <div className="icon close">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
                    <path id="Path_35" data-name="Path 35" d="M 1.7264062500000001 0.39374999999999893 L 9.01546875 7.682812500000001 L 16.30453125 0.3937500000000007 L 17.646562499999998 1.7357812500000005 L 10.357500000000002 9.024843749999999 L 17.646562499999998 16.31390625 L 16.30453125 17.655937499999997 L 9.01546875 10.366875 L 1.7264062500000001 17.655937499999997 L 0.38437500000000036 16.31390625 L 7.6734375 9.024843749999999 L 0.38437500000000036 1.7357812499999987 L 1.7264062500000001 0.39374999999999893 Z " fillRule="nonzero" fill="#4b4b4b" stroke="none" />
                </svg>
            </div>
        );
    }
}
