import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FullCartSummary from "./FullCartSummary";
import ShippingOptions from "./ShippingOptions";
import FreightCollectOption from "./FreightCollectOption";
import FreightOptions from "./FreightOptions";
import PONumber from "./PONumber";
import PhoneNumber from "./PhoneNumber";
import PlacingOrder from "./PlacingOrder";
import MessageWarning from "../MessageWarning";
import DeliveryInstructions from "./DeliveryInstructions";
import Loading from "../Loading";
import Comments from "./Comments";
import StorefrontCarriers from "./StorefrontCarriers";
import DropshipAddress from "./DropshipAddress";
import DateHelper from "../../helpers/DateHelper";
import { useCartConfig } from "../../hooks/CartConfigContext";
import { useStorefrontSettings } from "../../hooks/StorefrontSettingsContext";
import IncoTerms from "./IncoTerms";
import ServiceSelector from "./ServiceSelector";
import Notifications from "../../services/Notifications";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import PaymentSelector from "./PaymentSelector";

const StyledRadio = styled(Radio)(() => ({
  color: "black",
  "&.Mui-checked": {
    color: "black",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 22,
  },
}));

const ItemsReview = (props) => {
  const cartOptions = useCartConfig();
  const { settingsList } = useStorefrontSettings();
  const localized = cartOptions.labels;
  const submitOrderText = props.orderIsQuote
    ? localized.ButtonText_PlaceQuote
    : localized.ButtonText_PlaceOrder;

  const showExtendedFreightOptions =
    settingsList.UseExtendedFreightCodeOptions === "1";

  const [selectedService, setSelectedService] = useState({
    value: props.deliveryInstructions2 ? "OCU" : "DOM",
    isValid: false,
    wasVisited: false,
  });

  const submit = () => {
    const isOCUService =
      showExtendedFreightOptions && selectedService.value === "OCU";

    const { deliveryInstructions2, submitOrder } = props;

    if (isOCUService && !deliveryInstructions2) {
      Notifications.error(localized.OCUCityRequired);

      setSelectedService((prevService) => ({
        ...prevService,
        isValid: false,
        wasVisited: true,
      }));

      return;
    }

    submitOrder(isOCUService);
  };

  const shouldShowServiceSelector =
    showExtendedFreightOptions && props.showAdditionalFreightOptions;

  useEffect(() => {
    if (
      props.storefrontCarrierId.length > 0 &&
      settingsList.UseExtendedFreightCodeOptions === "1"
    ) {
      let freightCodeRequest = {
        storefrontNumber: props.storefrontNumber,
        customerId: props.selectedCustomer.id,
        amount: props.amount,
        carrierId: props.storefrontCarrierId,
        currency: props.currency,
      };
      props.getFreightCodesFromCarrier(freightCodeRequest);
    }

    if (props.showAdditionalFreightOptions) {
      setSelectedService({
        value: "DOM",
        isValid: false,
        wasVisited: false,
      });

      props.deliveryInstructions2Updated("");
    }
  }, [props.storefrontCarrierId]);

  return (
    <>
      {/* Loading Screen */}
      {!cartOptions.cartConfigLoaded && <Loading />}

      {/* Page Message */}
      {cartOptions.cartConfigLoaded && (
        <div style={{ padding: "2.5rem" }}>
          {props.orderIsQuote && (
            <div id="QuoteInventoryMessage">
              <MessageWarning
                title=""
                message={localized.QuoteInventoryMessage}
              />
            </div>
          )}

          {!props.placingOrder && (
            <React.Fragment>
              <div className="fele-cart-review">
                <div className="main-content">
                  {cartOptions.childComponents.DropShip.shown === true && (
                    <div>
                      {!props.orderIsQuote && (
                        <div id="drop-ship-radio" className="float-container">
                          <RadioGroup
                            name="dropShipmentAddressOption"
                            value={props.showDropship ? "1" : "0"}
                            onChange={props.toggleDropship}
                            row
                          >
                            <div className="radio-wrapper">
                              <FormControlLabel
                                label={localized.UseCustomerAddress}
                                disableTypography={true}
                                control={<StyledRadio />}
                                value="0"
                              />
                            </div>

                            <div className="radio-wrapper">
                              <FormControlLabel
                                label={localized.UseDropShipAddress}
                                disableTypography={true}
                                control={<StyledRadio />}
                                value="1"
                              />
                            </div>
                          </RadioGroup>
                        </div>
                      )}
                    </div>
                  )}

                  {props.showDropship && !props.orderIsQuote && (
                    <div>
                      {
                        <div className="section">
                          <div className="heading3">
                            {localized.DropshipAddress}
                          </div>

                          <DropshipAddress
                            localized={localized}
                            dropshipAddress={props.dropshipAddress}
                            updateDropshipAddress={props.updateDropshipAddress}
                          />
                        </div>
                      }
                    </div>
                  )}

                  {/* Ship Tp Address */}
                  {!props.showDropship && !props.orderIsQuote && (
                    <div>
                      {
                        <div className="section-container">
                          <div className="section flex">
                            <div
                              className="shipping-container"
                              style={{
                                maxWidth: "min-content",
                                minWidth: "max-content",
                                paddingRight: "2rem",
                              }}
                            >
                              <div className="heading3">
                                {" "}
                                {localized.ShipToAddress}:
                              </div>

                              <div
                                id="default-ship-to"
                                className="fele-cart-ship-to-address"
                              >
                                <div
                                  className="fele-content"
                                  id="review-cart-shipto"
                                >
                                  {props.selectedCustomer.address.lineOne}
                                  <br />
                                  {props.selectedCustomer.address.city},{" "}
                                  {props.selectedCustomer.address.state},{" "}
                                  {props.selectedCustomer.address.postalCode}
                                  <br />
                                  {props.selectedCustomer.address.country}
                                </div>
                              </div>
                            </div>

                            <div>
                              <IncoTerms
                                localized={props.localized}
                                selectedCustomer={props.selectedCustomer}
                                currentUser={props.currentUser}
                                subTotal={props.subTotal}
                                seprateDiv={true}
                              />
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  )}

                  {/* Carriers */}
                  <StorefrontCarriers
                    localized={localized}
                    selectedCustomer={props.selectedCustomer}
                    updateCarriers={props.carriersUpdated}
                    storefrontCarrierId={props.storefrontCarrierId}
                    storefrontCarrier2Id={props.storefrontCarrier2Id}
                  />

                  {shouldShowServiceSelector && (
                    <>
                      <ServiceSelector
                        deliveryInstructions={props.deliveryInstructions2}
                        deliveryInstructionsUpdated={
                          props.deliveryInstructions2Updated
                        }
                        selectedService={selectedService}
                        setSelectedService={setSelectedService}
                        localized={localized}
                      />

                      <PaymentSelector
                        options={props.freightPaymentOptions}
                        localized={localized}
                        selected={props.automaticFreightCode}
                      />
                    </>
                  )}

                  {/* Phone Number */}
                  {cartOptions.childComponents.PhoneNumber.shown && (
                    <div id="OrderPhoneNumber" className="section">
                      <div className="heading3">{localized.Phone}</div>

                      <PhoneNumber
                        localized={localized}
                        isRequired={props.requiredPhoneNo}
                        phoneNumberUpdated={props.phoneNumberUpdated}
                        phoneNumber={props.phoneNumber}
                        maxLength={settingsList.MaxPOLength}
                      />
                    </div>
                  )}

                  {!props.orderIsQuote &&
                    cartOptions.shippingOptions?.length > 0 &&
                    cartOptions.settings.ShowShippingOptions && (
                      <div className="section-container">
                        <div
                          id="ShipingOptions"
                          className="section flex-parent"
                        >
                          <div
                            className="shipping-container"
                            style={{
                              maxWidth: "min-content",
                              minWidth: "max-content",
                              paddingRight: "2rem",
                            }}
                          >
                            <div className="heading3">
                              {localized.ShippingOptionLabel}
                            </div>

                            <ShippingOptions
                              localized={localized}
                              requestDateUpdated={props.requestDateUpdated}
                              shippingMethodUpdated={
                                props.shippingMethodUpdated
                              }
                              shippingMethod={props.shippingMethod}
                              requestDate={props.requestDate}
                              dayAdder={props.dayAdder}
                              dateFormat={props.dateFormat}
                              shippingOptions={cartOptions.shippingOptions}
                              showShippingEstimates={
                                cartOptions.settings.ShowShippingEstimates
                              }
                            />
                          </div>

                          {cartOptions.freightOptions?.length > 0 && (
                            <div
                              style={{
                                maxWidth: "min-content",
                                minWidth: "max-content",
                                paddingRight: "2rem",
                              }}
                            >
                              <div className="heading3">
                                {localized.FreightOptions}
                              </div>

                              <FreightOptions
                                fOptions={cartOptions.freightOptions}
                                freightOptionUpdated={
                                  props.freightOptionUpdated
                                }
                                selectedFreightOptions={
                                  props.selectedFreightOptions
                                }
                              />
                            </div>
                          )}

                          <div
                            style={{
                              maxWidth: "min-content",
                              minWidth: "max-content",
                            }}
                          >
                            <div className="heading3">
                              {localized.FreightOptionLabel}
                            </div>

                            <FreightCollectOption
                              useLabel={localized.UseCollectFreightLabel}
                              preferredLabel={localized.PreferredCarrierLabel}
                              carrierLabel={localized.CarrierAccountLabel}
                              charactersLeft={localized.CharactersLeft}
                              accountString={props.accountString}
                              preferredString={props.preferredString}
                              freightHandlingCode={props.freightHandlingCode}
                              freightCollectOptionUpdated={
                                props.freightCollectOptionUpdated
                              }
                            />
                          </div>
                        </div>

                        <div className="note-light note-padding">
                          {localized.AirShipNotificationLabel}
                        </div>
                      </div>
                    )}

                  {/* Comments */}
                  {cartOptions.childComponents.Comments.shown === true && (
                    <div id="OrderComments" className="section">
                      <div className="heading3">{localized.Comments}</div>
                      <Comments
                        commentsUpdated={props.commentsUpdated}
                        comments={props.comments}
                      />
                    </div>
                  )}

                  <DeliveryInstructions
                    isEnabled={
                      cartOptions.childComponents.DeliveryInstructions.shown
                    }
                    deliveryInstructionsLabel={
                      localized.DeliveryInstructionsLabel
                    }
                    charactersLeft={localized.CharactersLeft}
                    deliveryInstructionsUpdated={
                      props.deliveryInstructionsUpdated
                    }
                    deliveryInstructions={props.deliveryInstructions}
                  />

                  <DeliveryInstructions
                    isEnabled={
                      cartOptions.childComponents.DeliveryInstructions2.shown
                    }
                    deliveryInstructionsLabel={
                      localized.DeliveryInstructionsLabel + " " + 2
                    }
                    charactersLeft={localized.CharactersLeft}
                    deliveryInstructionsUpdated={
                      props.deliveryInstructions2Updated
                    }
                    deliveryInstructions={props.deliveryInstructions2}
                  />

                  {/* PO Number */}
                  {cartOptions.childComponents.PONumber.shown && (
                    <div id="OrderPONumber" className="section">
                      <div className="heading3">{localized.PONumber}</div>

                      <PONumber
                        localized={localized}
                        isRequired={props.requiredPO}
                        poNumberUpdated={props.poNumberUpdated}
                        poNumber={props.poNumber}
                        maxLength={settingsList.MaxPOLength}
                      />
                    </div>
                  )}

                  {props.orderIsQuote && (
                    <div id="OrderCartSummary">
                      <FullCartSummary
                        localized={localized}
                        items={props.items}
                        requestDate={DateHelper.format(
                          props.requestDate,
                          "yyyy-MMM-dd"
                        )}
                        subTotal={props.subTotal}
                        currency={props.currency}
                        selectedCustomer={props.selectedCustomer}
                      />
                    </div>
                  )}

                  {
                    // check for storefront latin american
                    showExtendedFreightOptions && (
                      <div className="section">
                        <div id="noteSection" className="heading3">
                          {localized.Notes}
                        </div>
                        <ul className="list-disc ml-8">
                          <li>{localized.ShippingOptionsExtendedInfo1}</li>
                          <li>{localized.ShippingOptionsExtendedInfo2}</li>
                          <li>{localized.ShippingOptionsExtendedInfo3}</li>
                        </ul>
                      </div>
                    )
                  }
                </div>

                <div className="side-content">
                  <div className="side-item"></div>
                </div>
              </div>

              {/* Back or Submit */}
              <div className="fele-cart-navigation">
                <div className="button-wrapper">
                  <button
                    className="button previous"
                    onClick={props.toggleReviewingCart}
                  >
                    {localized.ButtonText_Back}
                  </button>
                </div>

                <div
                  className="button-wrapper"
                  style={
                    props.isValid === false
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  <button
                    data-cy="submit-order"
                    className="button submit-order"
                    onClick={submit}
                  >
                    {submitOrderText}
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}

          {props.placingOrder && (
            <PlacingOrder localized={localized} IsQuote={props.orderIsQuote} />
          )}
        </div>
      )}
    </>
  );
};

ItemsReview.propTypes = {
  currentUser: PropTypes.object,
  orderIsQuote: PropTypes.bool,
  placingOrder: PropTypes.bool,
  dateFormat: PropTypes.string,
  comments: PropTypes.string,
  currency: PropTypes.string,
  deliveryInstructions: PropTypes.string,
  preferredString: PropTypes.string,
  accountString: PropTypes.string,
  freightHandlingCode: PropTypes.string,
  dayAdder: PropTypes.string,
  items: PropTypes.array,
  poNumber: PropTypes.string,
  requestDate: PropTypes.object,
  selectedCustomer: PropTypes.object,
  shippingMethod: PropTypes.string,
  storefrontCarrierId: PropTypes.string,
  storefrontCarrier2Id: PropTypes.string,
  subTotal: PropTypes.number,
  dropshipAddress: PropTypes.object,
  updateDropshipAddress: PropTypes.func,
  toggleReviewingCart: PropTypes.func,
  toggleDropship: PropTypes.func,
  isvalid: PropTypes.bool,
  submitOrder: PropTypes.func,
  shippingMethodUpdated: PropTypes.func,
  requestDateUpdated: PropTypes.func,
  poNumberUpdated: PropTypes.func,
  deliveryInstructionsUpdated: PropTypes.func,
  commentsUpdated: PropTypes.func,
  carriersUpdated: PropTypes.func,
  shippingOptions: PropTypes.array,
  freightCollectOptionUpdated: PropTypes.func,
  components: PropTypes.object,
  freightOptionUpdated: PropTypes.func,
  selectedFreightOptions: PropTypes.array,
};

export default ItemsReview;
