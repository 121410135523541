import React from "react";
import { Modal, Button } from 'react-bootstrap';
import Strings from '../../translations/ModalPopup.Strings';

export default function BasicModal({
    isOpen,
    children,
    onClose,
    header = "",
}) {
    const localized = Strings.localize();
    return (
        <Modal
            show={isOpen}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            animation={true}
        >
            <div className="button-wrapper">
                <Modal.Header closeButton>
                    <Modal.Title>{header}</Modal.Title>
                </Modal.Header>
            </div>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <div className="button-wrapper">
                    <Button variant="secondary" className="button button-secondary" onClick={onClose}>{localized.Close_Label}</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}