import React from 'react';
import Checkmark from '../icons/Checkmark';
import { useProductConfig } from '../../hooks/ProductConfigContext';
import { useCartConfig } from '../../hooks/CartConfigContext';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext'; 

const Availability = props => {
    const cartConfig = useCartConfig();
    const storefrontConfig = useStorefrontSettings();
    const productConfig = useProductConfig();

    const localize = (availability) => {
        if (availability === "Unavailable")
            return productConfig.labels.Unavailable;

        if (availability === "Available")
            return productConfig.labels.Available;

        if (availability === "Backordered")
            return productConfig.labels.Backordered;

        if (availability === "Partially Available")
            return productConfig.labels.PartiallyAvailable;
        if (availability === "Unavailable At Branch")
            if (storefrontConfig.settingsList.UseCartBranchSelection!=='0') {
                return cartConfig.labels.UnavailableAtSelectedBranch;
            }
            else {
                return cartConfig.labels.UnavailableAtBranchToast
            }
            
        return productConfig.labels.Unknown;

    }
    if (!props.availability)
        return null;

    return (
        <>
            {
                !props.asIcon &&
                <div className={"pill " + props.availability.toLowerCase()} >
                    {localize(props.availability)}
                </div>
            }
            {
                props.asIcon &&
                <div className={`available-icon ${props.availability.toLowerCase()}`}>
                    {
                        props.availability === "Available" &&
                        <Checkmark />
                    }
                    {
                        props.availability === "Backordered" &&
                        <div className="icon">!</div>
                    }
                    {
                        props.availability === "Unavailable" &&
                        <div className="icon"></div>
                    }
                    {
                    props.availability === "Partially Available" &&
                    <div className="icon"></div>
                    }
                    {
                            props.availability === "Unavailable At Branch" &&
                        <div className="icon"></div>
                     }
                </div>

            }
        </>

    );    
}
export default Availability;
