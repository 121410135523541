import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartToolTip, Filler, Legend} from 'chart.js';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PricingHelper from '../../helpers/PricingHelper';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ChartToolTip,
    Legend,
    Filler
);


export const OrderSalesLineGraph = (props) => {
    const monthlyTotals = props.monthlyTotals;
    const dashboardLabels = props.dashboardLabels;
    const country = props.country;
    const currency = props.currency;
    const currencySymbol = props.currencySymbol;
    const [monthData, setMonthData] = React.useState([]);
    const [labels, setLabels] = React.useState([]);
    useEffect(() => {
        if (props && props.monthlyTotals) {
            arrangeSetMonthData(monthlyTotals);                   
        }
    }, [props.monthlyTotals]);

    const arrangeSetMonthData = (monthlyTotals) => {
        let months = [
            { key: "January", labelValue:dashboardLabels.January },
            { key:"February", labelValue:dashboardLabels.February },
            { key:"March", labelValue:dashboardLabels.March },
            { key:"April", labelValue:dashboardLabels.April },
            { key:"May", labelValue:dashboardLabels.May },
            { key:"June", labelValue:dashboardLabels.June },
            { key:"July", labelValue:dashboardLabels.July },
            { key:"August", labelValue:dashboardLabels.August },
            { key:"September", labelValue:dashboardLabels.September },
            { key:"October", labelValue:dashboardLabels.October },
            { key:"November", labelValue:dashboardLabels.November },
            { key: "December", labelValue: dashboardLabels.December }
        ];        
        let sortedMonths=[];
        if (monthlyTotals.length>0) {
            monthlyTotals.forEach(month => {
                sortedMonths.push({ total: month.total, month: months.find(y => y.key == month.month).labelValue })
            })
        }
        setMonthData(sortedMonths);
    }

    const getLabels = () => {
        let labels = [];
        monthData.forEach(x => {
            labels.push(x.month);
        });
        return labels;
    }

    const getData = () => {
        let data = [];
        if (monthData && monthData.length > 0) {
            monthData.forEach(x => {
                data.push(x.total);
            });
        }
        return data;
    }

    const options = {
        responsive: true,
        plugins: {
            datamonthData: false,
            legend: {
                display: false
            },
            title: {
                display: false

            },
            tooltip: {
                callbacks: {
                    label: (value) => {
                        if (country && currency && value && country != '' && currency != '') {
                            return (PricingHelper.formatValue(country, currency, value.raw));
                        }
                    }
                }
            }
        },
        scales: {            
            x: {                
                ticks: {
                    maxTicksLimit: 12
                },
                title: {
                    display: true,
                    text: props.dashboardLabels.Month
                }
            },
            y: {
                ticks: {
                    maxTicksLimit:6
                },
                title: {
                    display: true,
                    text: props.dashboardLabels.AmountOrdered +" "+currencySymbol
                }
            }
        },
        elements: {
            line: {
               
                backgroundColor:'rgb(23, 43, 66)',
                borderColor:'rgb(23, 43, 66)',
                borderWidth: 3
            }
        }
       
    };    
    
    const data = {
        labels: getLabels(),
        datasets: [
            {
                data: getData(),
                parsing: {
                    yAxisKey: "total",
                    xAxisKey: "month"
                },   
                fill:'start',
                backgroundColor: ({ chart: { ctx } }) => {
                    const bg = ctx.createLinearGradient(0,0 , 0, 200);
                    bg.addColorStop(0, "rgba(126,189,66,1)");
                    bg.addColorStop(1, "rgba(126,189,66,0)");
                    return bg;
                },
                pointBackgroundColor: "rgb(126, 189, 66)",
                pointStrokeColor: "rgba(126, 189, 66)"
            }
        ],
    };
    return <>
        <div style={{ display: 'flex', textAlign: "center", paddingLeft: "18rem", paddingRight:"18rem", paddingTop: "1rem", justifyContent: 'space-between' }}>
            <div>{props.dashboardLabels.TwelveMonthOrderTotal}</div>
            <div >
                <Tooltip title={<h3>{props.dashboardLabels.OrderHistoryDisclaimer}</h3>}>  <InfoOutlinedIcon fontSize="large" /></Tooltip>
            </div>
        </div>
        <div style={{ paddingRight: "2.5rem", paddingLeft:"1rem" }}>
            <Line options={options} data={data} />
        </div>
        
    </>;
}

OrderSalesLineGraph.propTypes = {
    monthlyTotals: PropTypes.object,
    currencySymbol: PropTypes.string,
    dashboardLabels: PropTypes.object,
    currency: PropTypes.string,
    country: PropTypes.string
};

export default OrderSalesLineGraph;
