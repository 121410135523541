import React, { useEffect } from 'react';
import Configuration from '../resources/Configuration';
import { withRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useStorefrontSettings } from '../hooks/StorefrontSettingsContext';
import Loading from './Loading';

export const GoogleAnalytics = props => {

    const { storefrontNumber: storefront, labelsLoaded } = useStorefrontSettings();

    useEffect(() => {
        // constructor things
        redirectToCorrectURIForStorefront();
        configureGoogleAnalytics();
    }, [storefront]);


    const redirectToCorrectURIForStorefront = () => {
        var host = window.location.origin;
        if (host.includes(Configuration.uriParts.localhostURI)) {
            return;
        }

        var addDev = host.includes(Configuration.uriParts.devURISubdomainPart) ? "-dv" : "";
        var addPY = host.includes(Configuration.uriParts.pyURISubdomainPart) ? "-py" : "";
        var subdomain = "";
        var domain = "";
        var uri = "";
       
        switch (storefront.substring(1)) {
            case Configuration.storefronts.brazilStorefront:
                subdomain = Configuration.uriParts.brazilURISubdomain;
                domain = Configuration.uriParts.defaultDomain;
                break;
            case Configuration.storefronts.latinAmericaStorefront:
                subdomain = Configuration.uriParts.laURISubdomain;
                domain = Configuration.uriParts.defaultDomain;
                break;
            case Configuration.storefronts.fuelingStorefront:
                subdomain = Configuration.uriParts.fuelingURISubdomain;
                domain = Configuration.uriParts.fuelingDomain;
                break;
            case Configuration.storefronts.italyStorefront:
                subdomain = Configuration.uriParts.italyURISubdomain;
                domain = Configuration.uriParts.defaultDomain;
                break;
            case Configuration.storefronts.australiaStorefront:
                subdomain = Configuration.uriParts.australiaURISubdomain;
                domain = Configuration.uriParts.defaultDomain;
                break;
            case Configuration.storefronts.argentinaStorefront:
                subdomain = Configuration.uriParts.argentinaURISubdomain;
                domain = Configuration.uriParts.defaultDomain;
                break;
            case Configuration.storefronts.peruStorefront:
                subdomain = Configuration.uriParts.peruURISubdomain;
                domain = Configuration.uriParts.defaultDomain;
                break;
            case Configuration.storefronts.chileStorefront:
                subdomain = Configuration.uriParts.chileURISubdomain;
                domain = Configuration.uriParts.defaultDomain;
                break;
            case Configuration.storefronts.nlaStorefront:
                subdomain = Configuration.uriParts.nlaURISubdomain;
                domain = Configuration.uriParts.defaultDomain;
                break;
            case Configuration.storefronts.indiaStorefront:
                subdomain = Configuration.uriParts.indiaURISubdomain;
                domain = Configuration.uriParts.defaultDomain;
                break;
            case Configuration.storefronts.fuelingGermanyStorefront:
                subdomain = Configuration.uriParts.fuelingURISubdomain;
                domain = Configuration.uriParts.fuelingGermanyDomain;
                break;
            default:
                subdomain = Configuration.uriParts.defaultURISubdomain;
                domain = Configuration.uriParts.defaultDomain;
                break;
        }
        uri = subdomain + addDev + addPY + domain;
        if (host !== uri) {
            window.location.assign(uri);
        }
        return;
    }

    const configureGoogleAnalytics = () => {
        if (typeof ga !== 'function') {
            var gtmKey = "";
            var testMode = true;
            var host = window.location.origin;

            testMode = host.includes(Configuration.uriParts.devURISubdomainPart) ||
                host.includes(Configuration.uriParts.pyURISubdomainPart) || host.includes(Configuration.uriParts.localhostURI) ? true : false;
        
            //var storefront = (props?.user?.storefrontNumber || '00000');
            switch (storefront.substring(1)) {
                case Configuration.storefronts.brazilStorefront:
                    gtmKey = Configuration.googleTags.brazilTag;
                    break;
                case Configuration.storefronts.latinAmericaStorefront:
                    gtmKey = Configuration.googleTags.latinAmericaTag;
                    break;
                case Configuration.storefronts.fuelingStorefront:
                    gtmKey = Configuration.googleTags.fuelingTag;
                    break;
                case Configuration.storefronts.italyStorefront:
                    gtmKey = Configuration.googleTags.italyTag;
                    break;
                case Configuration.storefronts.australiaStorefront:
                    gtmKey = Configuration.googleTags.australiaTag;
                    break;
                case Configuration.storefronts.argentinaStorefront:
                    gtmKey = Configuration.googleTags.argentinaTag;
                    break;
                case Configuration.storefronts.peruStorefront:
                    gtmKey = Configuration.googleTags.peruTag;
                    break;
                case Configuration.storefronts.chileStorefront:
                    gtmKey = Configuration.googleTags.chileTag;
                    break;
                case Configuration.storefronts.nlaStorefront:
                    gtmKey = Configuration.googleTags.nlaTag;
                    break;
                case Configuration.storefronts.indiaStorefront:
                    gtmKey = Configuration.googleTags.indiaTag;
                    break;
                case Configuration.storefronts.fuelingGermanyStorefront:
                    gtmKey = Configuration.googleTags.fuelingGermanyTag;
                    break;
                default:
                   gtmKey = Configuration.googleTags.uscanTag;// redirection issue research more here.
                   break;
            }

            const tagManagerArgs = {
                gtmId: gtmKey
            }
            // only initialize for Production Site.
            if (!testMode) {
                TagManager.initialize(tagManagerArgs);
            }
        }
    }

    return (
        <>
            {labelsLoaded && props.children}
            {!labelsLoaded &&
                <Loading type="brand" />
            }
        </>
    );
}

export default withRouter(GoogleAnalytics);
