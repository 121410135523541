import React from 'react';
import PropTypes from 'prop-types';
import Api from '../../services/Api';
import Loading from '../Loading';
import Localization from '../../services/Localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faLock } from '@fortawesome/free-solid-svg-icons';

export default class QuickAdd extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
            isValidSku: true,
            sku: "",
            availableSKUs: null,
            isAvailable: false,
            typingTimeOutReached: false,
            displayResults: false,
            fetchingAvailableSKUs: true,
            typingTimeout: 0
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.selectItemNumberInput();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    handleChange = (e) => {
        this.setMountedState({ isValidSku: true, [e.target.name]: e.target.value });
    }

    handleItemChange = (e) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setMountedState({ typingTimeOutReached: false });
        self.setMountedState({
            typingTimeout: setTimeout(function () {
                self.getSuggestedProducts();
            }, 500)
        });
        this.handleChange(e);
    }
    handleQtyChange = (e) => {
        (isPositiveNumber(e.target.value) || e.target.value === "") && this.handleChange(e);
    }

    handleQtyBlur = (e) => {
        if (e.target.value === "")
            e.target.value = 1;
        this.handleQtyChange(e);
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter')
            this.handleSubmit();
    }

    handleSubmit = () => {
        if (!this.state.fetchingAvailableSKUs) {
            let brandCode = this.props.selectedCustomer.brandCode;
            let companyCode = this.props.selectedCustomer.companyCode;
            let customerId = this.props.selectedCustomer.id;
            let storefrontId = this.props.currentUser.storefrontId;
            let url = `/api/products/${this.state.sku}/${customerId}/${companyCode}/${storefrontId}`;
            if (brandCode) {
                url = `${url}/${brandCode}`;
            }
            url = `${url}?locale=${Localization.language}`
            if (this.state.sku !== "" && (this.state.isAvailable || this.state.fetchingAvailableSKUs)) {
                Api.fetch(url).then(this.addToCart);
            }
            else {
                this.setState({ isValidSku: false });
            }
        }
    }

    getSuggestedProducts = () => {
        this.setMountedState({ typingTimeOutReached: true });
        (this.state.sku.length >= 3) ?
            this.fetchAvailableSKUs() :
            this.clearAvailableSKUs();
    }
    fetchAvailableSKUs = () => {
        this.setMountedState({ fetchingAvailableSKUs: true, availableSKUs: null, displayResults: true }, this.callApiWithFilter);
    }

    callApiWithFilter = async () => {
        await Api.fetch(this.skusFilterUri()).then(this.loadAvailableSKUs);
    }

    skusFilterUri = () => {
        let brandCode = this.props.selectedCustomer.brandCode;
        let storefrontId = this.props.currentUser.storefrontId;
        let customerId = this.props.selectedCustomer.id;
        let url = `/api/products/GetSuggestedProducts/${storefrontId}/${customerId}/${this.state.sku}`;
        if (brandCode) {
            url = `${url}/${brandCode}`;
        }
        url = `${url}?locale=${Localization.language}`
        return url;
    }

    clearAvailableSKUs = () => {
        this.setMountedState({ availableSKUs: null, displayResults: false });
    }

    loadAvailableSKUs = (data) => {
        this.setState({ isAvailable: data?.length > 0 ? true : false });
        this.setMountedState({ availableSKUs: data, fetchingAvailableSKUs: false });
    }
    populateInput = (item) => {
        this.setMountedState({ sku: item });
        this.clearAvailableSKUs();
        this.selectItemNumberInput();
    }

    addToCart = (product) => {
        if (product && product.landedCostRule !== "NEW") {
            let item = { product: product, quantity: this.state.quantity === "" ? 1 : this.state.quantity * 1 };
            this.props.addItemToCart(item, this.props.localized);
            this.resetQuantity();
            this.setMountedState({ sku: "" });
            this.clearAvailableSKUs();
            return this.selectItemNumberInput();
        }
        this.setState({ isValidSku: false }, this.selectItemNumberInput);
    }

    selectItemNumberInput = () => {
        if (this.skuInput)
            this.skuInput.select();
    }
    resetQuantity = () => {
        this.setState({ quantity: 1 });
    }

    render() {
        const { quantity, isValidSku } = this.state;
        const maxLines = 100;
        return (
            <React.Fragment>
                <div className="fele-quick-add">
                    <div className="control-group item-number result-dropdown">
                        <div className="label">{this.props.localized.ItemNumberLabel}</div>
                        <div ref={node => (this.node = node)}>
                            <div className="input-wrapper">
                                {
                                    this.props.cartLines < maxLines &&
                                    <input type="text" onChange={this.handleItemChange} name="sku" value={this.state.sku} data-cy="userskus-input" onKeyDown={this.handleKeyDown} ref={input => { this.skuInput = input; }} autoComplete="one-time-code" />
                                }
                                {
                                    (this.state.sku.length >= 3) && this.state.typingTimeOutReached &&
                                    displaySuggestedResults(this.state.displayResults, this.state.availableSKUs, this.populateInput, this.state.fetchingAvailableSKUs)
                                }
                                {
                                    this.props.cartLines >= maxLines &&
                                    <div style={{ border: '1px #dbdbdb solid', padding: '6px', textAlign: 'center', color: '#dbdbdb', backgroundColor: '#efefef' }}>
                                        <FontAwesomeIcon icon={faLock} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="control-group quantity">
                        <div className="label">{this.props.localized.QuantityLabel}</div>
                        <div className="input-wrapper">
                            {
                                this.props.cartLines < maxLines &&
                                <input inputMode="decimal" pkeyfilter="pnum" type="text" value={quantity} onChange={this.handleQtyChange} onKeyDown={this.handleKeyDown} onBlur={this.handleQtyBlur} name="quantity" />
                            }
                            {
                                this.props.cartLines >= maxLines &&
                                <div style={{ border: '1px #dbdbdb solid', padding: '6px', textAlign: 'center', color: '#dbdbdb', backgroundColor: '#efefef' }}>
                                    <FontAwesomeIcon icon={faLock} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="control-group action">
                        <div className="label">&nbsp;</div>
                        <div className="button-wrapper small">
                            {
                                this.props.cartLines < maxLines &&
                                <button className="button button-alt text-nowrap px-2" style={{ width: 'auto' }} onClick={this.handleSubmit}>+ {this.props.localized.Add} {this.props.localized.Item}</button>
                            }
                            {
                                this.props.cartLines >= maxLines &&
                                <div style={{ whiteSpace: 'nowrap', fontSize: 'large', marginTop: '4px' }}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} color="orange" /> {this.props.localized.CartMaxLines}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    !isValidSku &&
                    <div className="message error">{this.props.localized.NotValidSkuMessage}</div>
                }
            </React.Fragment>
        )
    }
}

function isPositiveNumber(value) {
    let exp = new RegExp(/^[1-9]\d*$/);
    return exp.test(value);
}

function displaySuggestedResults(displayResults, availableSKUs, populateInput, fetchingAvailableSKUs) {
    return (
        <div className={`results ${displayResults ? "active" : ""}`}>
            {
                fetchingAvailableSKUs && !availableSKUs &&
                <button data-cy="sku-results" className="result">
                    <Loading />
                </button>
            }

            {
                !fetchingAvailableSKUs && availableSKUs && availableSKUs.map((item, key) =>
                    <button key={key} className="result" onClick={() => populateInput(item)} data-cy="userskus-result">
                        {item}
                    </button>
                )
            }
            {
                !fetchingAvailableSKUs && (!availableSKUs || (availableSKUs.length === 0))
                && displayResults
                &&
                <button className="result">no results found</button>
            }
        </div>
    )
}

QuickAdd.propTypes = {
    addItemToCart: PropTypes.func,
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
};