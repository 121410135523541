import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../form-controls/CheckBox';

const FreightOptions = (props) => {
    return (
        <div id="freightOptions" className="fele-content">
            {props.fOptions.map((option, i) => (
                <>

                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id={`custom-checkbox-${i}`}
                            value={option.description}
                            checked={props.selectedFreightOptions.includes(option.description)}
                            onChange={() => props.freightOptionUpdated(option.description)}
                        />
                        <label class="form-check-label" for={`custom-checkbox-${i}`}>
                            {option.description}
                        </label>

                    </div>

                </>

            ))}
        </div>
    );
};

FreightOptions.propTypes = {
    fOptions: PropTypes.array,
    freightOptionUpdated: PropTypes.func,
    selectedFreightOptions: PropTypes.array,
};

export default FreightOptions;