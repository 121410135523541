import React from 'react';

export default class LoadingGif extends React.Component {
    render() {
        return (

            <div className="loading">
                <svg className="lds-message" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(20 50)">
                        <circle cx="0" cy="0" r="6" transform="scale(0.000445187 0.000445187)">
                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                        </circle>
                        </g><g transform="translate(40 50)">
                        <circle cx="0" cy="0" r="6" transform="scale(0.180858 0.180858)">
                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                        </circle>
                        </g><g transform="translate(60 50)">
                        <circle cx="0" cy="0" r="6" transform="scale(0.518344 0.518344)">
                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                        </circle>
                        </g><g transform="translate(80 50)">
                        <circle cx="0" cy="0" r="6" transform="scale(0.846898 0.846898)">
                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                        </circle>
                    </g>
                </svg>
            </div>
        );
    }
}
