import Api from './Api';

class QuoteService {

    convertQuoteToOrder(convertQuoteRequest) {
        return Api.fetch('/api/quotes/convertQuoteToOrder', { method: 'POST', body: JSON.stringify(convertQuoteRequest) }, false)
            .then(this.handleSubmitOrderResponse);
    }

    handleSubmitOrderResponse(res) {
        if (res)
            return res.json();
    }

    rejectQuote(documentNumber, companyCode, customerId, implementationId) {
        let cancelQuoteRequest = { documentNumber: documentNumber, documentCompany: companyCode, customerId: customerId, implementationId: implementationId };
        return Api.fetch('/api/quotes/rejectQuote', { method: 'POST', body: JSON.stringify(cancelQuoteRequest) }, false);
    }

    submitEditQuote(quote) {
        return Api.fetch('/api/quotes/editQuote', { method: 'POST', body: JSON.stringify(quote) }, false).then(this.handleSubmitEditQuoteResponse);
    }
}

export default new QuoteService();