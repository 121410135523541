import React, { useState, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import OrderStrings from '../../translations/Orders.Strings';
import UserService from '../../services/UserService';
import FilterSelect from '../form-controls/FilterSelect';
import Calendar from '../form-controls/Calendar';
import { useCustomers } from '../../hooks/CustomerContext';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import DateHelper from '../../helpers/DateHelper';


const addCustomerDropdownDescription = customers => {
    return customers.map(c => { return { ...c, dropdownDescription: `${c.externalId} - ${c.name}` } });
}
function AvailabilityLogReportFilter(props) {
    const date = new Date().setDate(new Date().getDate() - 30);
    const { customerList } = useCustomers();
    const storefrontConfig = useStorefrontSettings();
    const salesHistoryConfig = useSalesHistoryConfig();
    const [flatCustomerList, setFlatCustomerList] = useState(customerList);
    const store = props.store ? props.store : useSelector((state) => state);
    const [customers, setCustomers] = useState(addCustomerDropdownDescription(flatCustomerList));
    const localized = salesHistoryConfig.labels;
    const [toFullDate, setToFullDate] = useState(new Date());
    const [fromFullDate, setFromFullDate] = useState(new Date(date));
    const localizedOrders = OrderStrings.localize();
    const [animateHeight, setAnimateHeight] = useState("auto");
    const [territories, setTerritories] = useState([]);
    const [allTerritories, setAllTerritories] = useState([]);
    const [noTerritory, setNoTerritory] = useState(false);
    const [businessUnits, setBusinessUnits] = useState([]);
    const dateFormat = storefrontConfig.settingsList.DateFormat;

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            UserService.getUserBusinessUnits(store.user.currentUser.id, loadBusinessUnits);
            UserService.getUserSalesTerritories(store.user.currentUser.id, loadTerritories);            
        }
        flattenCustomers();
        return () => { mounted = false; return null; }
    }, [])
    const loadBusinessUnits = (data) => {
        if (data) {
            data = data.map(businessUnit => {return { ...businessUnit, dropdownDescription: `${businessUnit}` } })
            setBusinessUnits(data);
            if (data.length === 1) changeBusinessUnit(data[0].code);
        }
    }
    const loadTerritories = (data) => {
        if (data) {
            data = data.map(territory => { return { ...territory, dropdownDescription: `${territory.code} - ${territory.description}` } })
            setTerritories(data);
            setAllTerritories(data);
            if (data.length === 1) changeTerritory(data[0].code);
        }
    }
    
    const flattenCustomers = () => {
        let flat = [];
        flat = customerList.flatMap(c => {
            return (c.children.length > 0) ? [].concat(...c.children, c) : c;
        });
        setFlatCustomerList(flat.sort((a, b) => (a.id > b.id) ? 1 : -1));
    }

    const loadCustomers = (newTerritory) => {
        //when territory changes, filter customers by the new territory
        if (props.businessUnit !== null && props.businessUnit !== 0 && props.businessUnit !== 0) {
            if (newTerritory==="") {
                setCustomers(flatCustomerList.flatMap(c => {
                    return (c.companyCode === props.businessUnit) ? { ...c, dropdownDescription: `${c.externalId} - ${c.name}` } : [];
                }));
            }
            else {
                setCustomers(flatCustomerList.flatMap(c => {
                    return (c.salesTerritoryCode === newTerritory && c.companyCode === props.businessUnit) ? { ...c, dropdownDescription: `${c.externalId} - ${c.name}` } : [];
                }));
            }
        }
        else setCustomers(addCustomerDropdownDescription(flatCustomerList));
    }    

    const changeBusinessUnit =  (newBusinessUnit) => {
        props.setBusinessUnit(newBusinessUnit);
        props.setTerritory(0);
        props.setCustomer({ target: { value: 0 } });
        let filteredCustomers;
        let filteredTerritories = [];
        if (newBusinessUnit !== "") {
            filteredCustomers = flatCustomerList.flatMap(c => {
                return c.companyCode === newBusinessUnit  ? { ...c, dropdownDescription: `${c.externalId} - ${c.name}` } : [];
            });
            for (const t in allTerritories) {
                for (const c in filteredCustomers) {
                    if (filteredCustomers[c].salesTerritoryCode !== null && filteredCustomers[c].salesTerritoryCode !== "") {
                        if ((filteredCustomers[c].salesTerritoryCode === allTerritories[t].code) && !filteredTerritories.some(tt => tt.code === allTerritories[t].code)) {
                            filteredTerritories.push(allTerritories[t]);
                        }
                    }
                }
            }
            setTerritories(filteredTerritories);
            setCustomers(filteredCustomers); 
        }
        else {
            setCustomers(addCustomerDropdownDescription(flatCustomerList));
            setTerritories(allTerritories);
        }

        if (filteredTerritories) {
            if (filteredTerritories.length > 0) {
                setNoTerritory(false);
            }
        }
        else {
            setNoTerritory(true);
        }

          
        
        
    }
    const changeTerritory = (newTerritory) => {
        props.setTerritory(newTerritory);
        if (props.customer !== 0) props.setCustomer({ target: { value: 0 } });
        loadCustomers(newTerritory);
        }

    const businessUnitChange = (e) => {
        changeBusinessUnit(JSON.parse(JSON.stringify(e.target.value)));
    }
    const territoryChange = (e) => {
        changeTerritory(JSON.parse(JSON.stringify(e.target.value)));
    }

    const handleSetFromDate = (e) => {
        const currentDate = new Date();
        const toDate = toFullDate;
        // From Date can't be in the future
        var futureFromDateResult = DateHelper.compareDates(e, currentDate);
        // From Date must be less than to date
        var result = DateHelper.compareDates(e, toDate);
        if (futureFromDateResult == 1) {
            return Notifications.error(localized.FromDateFutureError);
        }
        else if (result == 1) {
            return Notifications.error(localized.FromDateToDateError);
        }
        else {
            setFromFullDate(e);
            props.setStartDate(e);
        }
    };

    const handleSetToDate = (e) => {
        const fromDate = fromDate;
        // To Date must be greater than from date
        const parseToDate = Date.parse(e);
        const parseFromDate = Date.parse(fromDate);
        if (parseToDate < parseFromDate) {
            return Notifications.error(localized.ToDateError);
        }
        props.setEndDate(e);
        setToFullDate(e);
    }

    const { businessUnit, setBusinessUnit, itemNumber, setItemNumber, customer, setCustomer, territory, setTerritory, setStartDate, setEndDate, fetchItems } = props;
    return (
        <div className="fele-order-history-filter">
            <AnimateHeight duration={500} height={animateHeight}>
                <div className="flex">
                    <FilterSelect wrapperClass={'report-status'} label={localized.BusinessUnit} onChange={businessUnitChange} value={businessUnit}
                        placeholder="All Business Units" options={businessUnits} valueField={'externalId'} descriptionField={'dropdownDescription'} />
                    <FilterSelect wrapperClass={'report-status'} label={localized.SelectTerritoryLabel} onChange={territoryChange} value={territory} disabled={noTerritory}
                        placeholder={localized.SelectTerritoryPlaceholder} options={territories} valueField={'code'} descriptionField={'dropdownDescription'} />   
                    <FilterSelect wrapperClass={'report-status'} label={localized.Customer} onChange={setCustomer} value={customer}
                        placeholder={localized.SelectCustomerPlaceholder} options={customers} valueField={'externalId'} descriptionField={'dropdownDescription'} />                                     

                    <div style={{ visibility: "hidden", width: "30.1px" }}> Extra div </div>                   
                    <div className="from-to-dates" data-cy="order-from-date">                       
                        <div className="label">{localized.FromDate}</div>
                        <div className="fele-content">
                            <div className="input-wrapper">
                                <Calendar date={fromFullDate} minDate={new Date().setDate(new Date().getDate() - 730)}
                                    showIcon={true} callBackFunction={handleSetFromDate} dateFormat={dateFormat} localized={localized} />
                            </div>
                        </div>
                    </div>

                    <div className="from-to-dates" data-cy="order-to-date">
                        <div className="label">{localized.ToDate}</div>
                        <div className="fele-content">
                            <div className="input-wrapper">
                                <Calendar date={toFullDate} minDate={new Date().setDate(new Date().getDate() - 730)}
                                    showIcon={true} callBackFunction={handleSetToDate} dateFormat={dateFormat} />
                            </div>
                        </div>
                    </div>

                    <div className="report-search">
                        <div className="label">{localized.ItemNumber}</div>
                        <div className="fele-content">
                            <div className="input-wrapper">
                                <input data-cy="order-report-search" type="text" placeholder={localizedOrders.SearchInputLabelPlaceholder}
                                    onChange={setItemNumber}
                                    value={itemNumber} />
                            </div>
                            <div className="button-wrapper small">
                                <button data-cy="order-submit-report-search" className="button search-button button-alt" onClick={fetchItems}>
                                    {localizedOrders.SearchButtonText}
                                </button>
                            </div>                            
                                                   
                        </div>                        
                    </div>                   
                </div>
            </AnimateHeight>
            <div className="seperator"></div>
        </div>
    );
}
export default AvailabilityLogReportFilter;