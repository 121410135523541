import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QuoteSummary from './QuoteSummary';
import QuoteItem from './QuoteItem';
import Api from '../../services/Api';
import Loading from '../Loading';
import { withRouter } from 'react-router-dom';
import QuoteService from '../../services/QuoteService';
import Notifications from '../../services/Notifications';
import HistoryItemComments from '../HistoryItemComments';
import { withSelectedCustomer } from '../../hooks/withSelectedCustomer';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import ConvertToOrderModal from '../quotes/ConvertToOrderModal';
import { withSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';

export class Quote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDetails: null,
            fetching: true,
            convertingQuote: false,
            convertedQuote: false,
            documentNumber: null,
            showError: false,
            quote: null,
            comments: '',
            poNumber: ''
        }
    }
    
    componentDidMount() {
        Api.fetch(`/api/orders/${this.props.orderId}/${this.props.orderCompany}/${this.props.docType}`).then(this.itemsLoaded);
    }

    itemsLoaded = (response) => {
        this.setState({
            fetching: false,
            quote: response.order,
            poNumber: response.order.referenceNumber,
            comments: response.order.comments,
            orderDetails: response.orderDetails
        });
    }

    convertQuoteToOrder = (convertQuoteToOrderData) => {
        this.setState({
            confirmationOptions: {
                show: true,
                message: this.props.salesHistoryConfig.labels.ConvertToOrderWarning,
                callback: () => { this.handleConvertToOrderCOnfirmation(convertQuoteToOrderData) }
            }
        });
    }

    hideConfirmation = () => {
        this.setState({
            confirmationOptions: {
                show: false
            }
        });
    }

    commentsUpdated = (comment) => {
        this.setState({ comments: comment });
    }

    poNumberUpdated = (referenceNumber) => {
        this.setState({ poNumber: referenceNumber });
    }

    handleConvertToOrderCOnfirmation = () => {
        this.hideConfirmation();
        this.setState({
            convertingQuote: true
        });
        const convertToQuoteOrderData = {
            documentNumber : this.props.orderId,
            documentCompany : this.props.selectedCustomer.companyCode,
            comments : this.state.comments,
            customerPO : this.state.poNumber,
            customerId : this.props.selectedCustomer.id,
            implementationId : this.props.StorefrontConfig.storefront.implementationId
        }
        QuoteService.convertQuoteToOrder(convertToQuoteOrderData).then(this.handleQuoteConvertResponse);
    }

    handleQuoteConvertResponse = (res) => {
        if (res) {
            Notifications.message(this.props.salesHistoryConfig.labels.YourQuoteIsBeingConverted);
            this.setState({
                showError: (res.errors && res.errors.length > 0),
                documentNumber: res.documentNumber,
                convertingQuote: false,
                convertedQuote: true
            });
            this.props.history.push({ pathname: '/cart/confirmation', state: { IsQuote: false, DocumentNumber: res.documentNumber } });
        }
        else {
            Notifications.error("Order could not be placed.Please contact sales representative");
            this.setState({
                convertingQuote: false,
                showError: true
            });
        }
    }

    render() {
        const { selectedCustomer } = this.props;
        const convertQuoteEnabled = this.props.salesHistoryConfig.settings.ConvertQuoteEnabled === true;
        const maxPOLength = this.props.StorefrontConfig.settingsList.MaxPOLength;

        const { quote, orderDetails, convertedQuote, convertingQuote, fetching } = this.state;
        let paymentTermsAndDescription        
        if (orderDetails) {
            if (orderDetails.lineItems[0]) {
                if (orderDetails.lineItems[0].paymentTerms) {
                    paymentTermsAndDescription = orderDetails.lineItems[0].paymentTerms + ' - ' + orderDetails.lineItems[0].paymentTermsDescription;
                }
            }
        }
        else {
            paymentTermsAndDescription = "";
        }
        
        return (
            <div>
                {
                    !convertingQuote && !convertedQuote && !fetching &&
                    <div className="fele-order-history-details">
                        {
                            !orderDetails &&
                            <div className="no-results">
                                {this.props.salesHistoryConfig.labels.NoQuoteResultsFound}
                            </div>
                        }
                        {
                            quote &&
                                <React.Fragment>
                                        <QuoteSummary convertQuoteToOrder={this.convertQuoteToOrder} quote={quote}
                                            storefrontNumber={this.props.StorefrontConfig.storefrontNumber} convertQuoteEnabled={convertQuoteEnabled}
                                            selectedCustomer={selectedCustomer.id} paymentTerms={paymentTermsAndDescription} localized={this.props.salesHistoryConfig.labels}
                                            dateDisplayFormat={this.props.StorefrontConfig.settingsList.DateDisplayFormat} implementationId={this.props.StorefrontConfig.storefront.implementationId} />
                                <HistoryItemComments comments={quote.comments} localized={this.props.salesHistoryConfig.labels} />
                            </React.Fragment>
                            }
                            <div className="heading2 quote-heading2">{this.props.salesHistoryConfig.labels.ItemsQuoted}</div>
                        <div className="quote-items">
                            {

                                orderDetails && orderDetails.lineItems &&
                                orderDetails.lineItems.map((item, key) => {
                                    return <QuoteItem item={item} key={key} selectedCustomer={selectedCustomer} localized={this.props.salesHistoryConfig.labels} />
                                })

                            }
                        </div>
                    </div>
                }
                <div>
                    {
                        (convertingQuote || fetching) &&
                        <Loading type="brand" />
                    }
                </div>
                <ConvertToOrderModal {...this.state.confirmationOptions}
                    poNumberUpdated={this.poNumberUpdated}
                    commentsUpdated={this.commentsUpdated}
                    comments={this.state.comments}
                    poNumber={this.state.poNumber}
                    requiredPO={false}
                    maxPOLength={maxPOLength}
                    onHide={this.hideConfirmation}
                    localized={this.props.salesHistoryConfig.labels} />
            </div>
        );
    }
}

Quote.propTypes = {
    selectedCustomer: PropTypes.object,
    convertQuoteEnabled: PropTypes.bool
}

function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
        //convertQuoteEnabled: state.settings.storefront.convertQuoteEnabled,        
    };
}

export default withRouter(connect(
    mapStateToProps,
    null
)(withSalesHistoryConfig(withStorefrontConfig(withSelectedCustomer(Quote)))));