import React from 'react';

export default class MessageSystem extends React.Component {

    render() {
        return (
            <div className="fele-message-system">
                <div className="flex align-center">
                    <div className="messageHeader">
                        <div className="logo"></div>
                    </div>
                </div>
                <div className="flex align-center">
                    <div className="heading1">{this.props.title}</div>
                </div>
                <div className="message" >{this.props.message}</div>
            </div>
        );
    }
}