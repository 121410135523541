import React from 'react';

export default class ShipmentInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { shippingAddress, shipment, localized } = this.props;
        return (
            shipment &&
            <div className="shipment-info">
                <div className="control-group shipto">
                    <div className="label">{localized.ShipToLabel}</div>
                    <address className="fele-content">
                        <div className="name">{shippingAddress.name}</div>
                        <div className="line1">{shippingAddress.lineOne}</div>
                        <div className="line2">{shippingAddress.lineTwo}</div>
                        <div className="cityStateZip">{shippingAddress.city}, {shippingAddress.state} {shippingAddress.postalCode}</div>
                        <div className="country">{shippingAddress.country}</div>
                    </address>
                </div>
                <div className="control-group carrier">
                    <div className="label">{localized.CarrierLabel}</div>
                    <div className="fele-content">
                        {
                            shipment.carrierName && shipment.trackingUrl &&
                            <a className="link"
                                href={shipment.trackingUrl} target="_blank" rel="noopener noreferrer">
                                {shipment.carrierName}
                            </a>
                        }
                        {
                            !shipment.carrierName &&
                            localized.CarrierNotFoundMessage
                        }
                        {
                            shipment.carrierName && !shipment.trackingUrl &&
                            shipment.carrierName
                        }

                    </div>
                </div>
                <div className="control-group tracking-number">
                    <div className="label">{localized.TrackingNumber}</div>
                    <div className="fele-content">
                        {
                            !shipment.trackingNumber &&
                            localized.CarrierNotFoundMessage
                        }
                        {
                            !shipment.trackingUrl && shipment.trackingNumber &&
                            shipment.trackingNumber
                        }
                        {
                            shipment.trackingUrl && shipment.trackingNumber &&
                            <a className="link"
                                href={shipment.trackingUrl} target="_blank" rel="noopener noreferrer">
                                {shipment.trackingNumber.trim()}
                            </a>
                        }
                    </div>
                </div>
            </div>
        );
    }
}