import Auth from '../services/Auth';
import APIMSettingsService from '../services/APIMSettingsService';
import Notifications from '../services/Notifications';
import Localization from '../services/Localization';
import store from '../store/configureStore';

class Api {

    async headers(includeKey = true) {
        let headers =
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + await Auth.getToken(),
            'Release-Id': await APIMSettingsService.getReleaseId(),
            'Cache-Control': 'no-cache, no-store Expires: 0'
        };

        if (includeKey)
        {
            headers['Ocp-Apim-Subscription-Key'] = await APIMSettingsService.getSubscriptionKey();
        }

        return headers;
    }

    failed(response) {
        return this.notify(response.statusText);
    }

    async fetch(url, options, includeKey = true) {
        options = { method: "GET", ...options, headers: { ...await this.headers(includeKey) } };
        return fetch(url, options)
            .then(res => this.handleResponse(res, options.method));
    }

    async fetchFormFile(url, options, includeKey = true) {
        let headers =
        {
            //redirect: 'follow',
            'Authorization': 'Bearer ' + await Auth.getToken(),
            'Release-Id': await APIMSettingsService.getReleaseId(),
            'Cache-Control': 'no-cache, no-store Expires: 0'
        };

        options = {
            method: "GET", ...options, headers: { ...await headers }, };
        return fetch(url, options)
            .then(res => this.handleResponse(res, options.method));
    }

    async download(url, options) {
        options = { method: "GET", ...options, headers: { ...await this.headers() } };
        let fileName = url.substring(url.lastIndexOf('/') + 1);
        return fetch(url, options)
            .then(r => {
                if (r.ok) {
                    return r.blob();
                }
                return null;
            })
            .then(b => this.renderFile(b, fileName));
    }

    async downloadFileName(url, fileName) {
        let options = { method: "GET", headers: { ...await this.headers() } };
        return fetch(url, options)
            .then(r => {
                if (r.ok) {
                    return r.blob();
                }
                return null;
            })
            .then(b => this.renderFile(b, fileName));
    }

    getUserStoreFront() {
        try {
            return store.getState().user.currentUser.storefrontNumber;
        } catch
        {
            return "9000";
        }
    }

    handleResponse(res, method) {

        if (res.status === 204)
            return null;

        if (res.status === 409) {
            return this.handleConflictReleaseId(res);
        }

        if (res.status === 503) {
            return Auth.signOut(`/MaintenanceMode`);
        }

        if (res.ok) {
            if (method === "GET" || method === "PATCH")
                return res.json();
            return res;
        }
        console.log(res);
        return false;
    }

    handleConflictReleaseId(response) {
        const storefrontNumber = this.getUserStoreFront();
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(data => {
                if (data.placeOrderAttempt) {
                    Auth.signOut(`/${Localization.language}/unauthenticated/release/order/${storefrontNumber}`);
                }
                else {
                    Auth.signOut(`/${Localization.language}/unauthenticated/release/request/${storefrontNumber}`);
                }
            });
        } else {
            Auth.signOut(`/${Localization.language}/unauthenticated/release/request/${storefrontNumber}`);
        }

        return null;
    }

    notify(message) {
        return Notifications.error(message);
    }

    renderFile(blob, fileName) {
        if (!blob)
            return false;

        var newBlob = new Blob([blob], { type: "application/pdf" });

        if (isInternetExplorer()) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return true;
        }

        downloadBlob(newBlob);
        return true;

        function downloadBlob(newBlob) {
            const url = window.URL.createObjectURL(newBlob);

            downloadUsingLink(fileName, url);
            revokeDataUrl(url);
        }

        function revokeDataUrl(url) {
            setTimeout(function () {
                window.URL.revokeObjectURL(url);
            }, 100)
        }

        function downloadUsingLink(fileName, url) {
            var link = document.createElement('a');
            link.href = url;
            link.download = `${fileName}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        function isInternetExplorer() {
            return /*@cc_on!@*/false || !!document.documentMode;
        }

    }

}
export default new Api();