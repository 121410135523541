import React from 'react';
import AngleDown from "../icons/AngleDown";

const FilterSelect = props => {

    const { wrapperClass, label, onChange, value, placeholder, options, valueField, descriptionField, dataCy } = props;
    return (
        <div data-cy={dataCy} className={ wrapperClass } >
        <div className="label">{label} </div>
        <div className="fele-content">
            <div className="select-wrapper">
                <select onChange={onChange} value={value}>
                    { placeholder ? 
                        <option value="">- {placeholder} -</option>
                        : ''
                    }                    
                    {
                        options.map((item, index) =>
                            <option key={index} value={item[valueField]}>{item[descriptionField]}</option>
                        )
                    }
                </select >
                <AngleDown />
            </div>
        </div>
    </div>
    )
}
export default FilterSelect;