import LocalizedStrings from 'react-localization';
import Localization from './../../services/Localization';

class CartStrings {
    localized = new LocalizedStrings({
        en: {
            AvailabilityByLocation_Label: "Availability by Location",
            AvailabilityCost_ButtonText: "Get Availability/Cost",
            AvailabilityLabel: "Availability",
            ButtonText_AddItem: "Add Item",
            ButtonText_AddToOrder: "Add to Order",
            ButtonText_Back: "Back",
            ButtonText_CheckAvailability: "Check Quantity",
            ButtonText_PlaceOrder: "Place Your Order",
            ButtonText_PlaceQuote: "Save As Quote",
            ButtonText_Reprice: "Calculate Price",
            ButtonText_ReviewOrder: "Review and Complete Order",
            ButtonText_ReviewQuote: "Review and Complete Quote",
            CarrierRequiredMessage: "A carrier is required",
            CartEmptyMessage: "Your cart is empty.",
            CartMaxLines: "Cart limit of 100 lines reached",
            CartUpdatedMessage: "Order updated",
            CommentsLabel: "Comments",
            ConfiguredItemsNotAvailable: "Configured items are not currently available for purchase in FE-Direct. Please contact your account manager for price and availability",
            CreditHold_Warning: "Please contact your customer service representative to review your statement.",
            DeliveryInstructionsLabel: "Delivery Instructions",
            DeliveryInstructionsLabel2: "Delivery Instructions 2",
            Description_Label: "Description",
            DropshipAddress: "Drop Ship Address",
            DropshipAddressNotComplete: "Please enter a full name and select the drop ship address from the drop down.",
            EditTitle: "My Order",
            FetchingPriceFailed: "Fetching price failed",
            FetchingPriceMessage: "Price and availability must be loaded before adding to cart",
            HideDropship: "Hide Drop Ship Address",
            InvalidDateMessage: "Invalid date selected",
            Item_Label: "Item #",
            ItemNumberLabel: "Add Item Number",
            ListPriceLabel: "List Price",
            ModelNumber_Label: "Model #",
            NoProductFoundMessage: "No results found for",
            NotValidSkuMessage: "The item number you entered is not valid.",
            OboleteItemsNotAvailable: "Obsolete items are not available for purchase in FE-Direct. Please contact your account manager for more information",
            ObsoleteItem: "Obsolete Item",
            OrderCancelDateLabel: "Order Cancel Date",
            OrderUpdated: "Order updated!",
            PoNumberLabel: "PO Number",
            PoRequiredMessage: "A PO number is required.",
            QuantityLabel: "Quantity",
            QuantityRequired: "Item quantity is required",
            QuoteInventoryMessage: "Quote locks in pricing but does not commit inventory.",
            RequestDateLabel: "Requested Delivery",
            ReviewOrderTitle: "Review and Complete Order",
            ShippingOptionLabel: "Select Shipping Option",
            ShipToAddress: "Ship To Address",
            ShipToNotSelectedWarningMessage: 'Please select a valid "SHIP TO" from the "SHIP TO / BILL TO" list.',
            ShipToNotSelectedWarningTitle: "Before Proceeding",
            ShowDropship: "Add Drop Ship Address",
            UnableToAddToCart: "Unable to add to cart",
            UnableToAddToCartMessage: "Unable to add to cart",
            UnabletoUpdateQuantity: "Unable to update quantity",
            Unavailable: "Unavailable",
            UnavailableItemsInCartMessage: "You have unavailable items in your cart. Please remove the items before proceeding to checkout",
            UnavailableMessage: "Product is not available. Please contact admin for more information",
            UpateCartPriceMessage: "Cart pricing needs to be updated",
            UseCustomerAddress: "Use Customer Address",
            UseDropShipAddress: "Use Drop Ship Address",
            WaitForPriceAndAvailability: "Please wait while price and availability finish loading",
            YourCostLabel: "Your Cost"
        },

        pt: {
            AvailabilityByLocation_Label: "Availability by Location (pt)",
            AvailabilityCost_ButtonText: "Disponibilidade/Preço",
            AvailabilityLabel: "Disponibilidade",
            ButtonText_AddItem: "Adicionar",
            ButtonText_AddToOrder: "Adicionar ao Pedido",
            ButtonText_Back: "Voltar",
            ButtonText_CheckAvailability: "Confirmar quantidade",
            ButtonText_PlaceOrder: "Confirmar Pedido",
            ButtonText_PlaceQuote: "Salvar como orçamento",
            ButtonText_Reprice: "Atualizar Preço/Disponibilidade",
            ButtonText_ReviewOrder: "Revise antes de confirmar o PEDIDO",
            ButtonText_ReviewQuote: "Revise antes de confirmar o ORÇAMENTO",
            CarrierRequiredMessage: "Uma transportadora é necessária",
            CartEmptyMessage: "Carrinho Vazio",
            CartMaxLines: "Limite de 100 linhas atingido",
            CartUpdatedMessage: "Pedido Atualizado",
            CommentsLabel: "Observação no Pedido",
            ConfiguredItemsNotAvailable: "No momento, os itens configurados não estão disponíveis para compra no FE-Direct. Entre em contato com o gerente ou atendente de sua região para preços e disponibilidade",
            CreditHold_Warning: "Entre em contato com seu representante de atendimento ao cliente para revisar sua declaração.",
            DeliveryInstructionsLabel: "Instruções de Entrega (Informação na Nota Fiscal)",
            DeliveryInstructionsLabel2: "Delivery Instructions 2 (pt)",
            Description_Label: "Descrição",
            DropshipAddress: "Endereço de Entrega",
            EditTitle: "Meu Pedido",
            FetchingPriceFailed: "Falha na busca do preço",
            FetchingPriceMessage: "Preço e disponibilidade devem ser carregados antes de adicionar ao carrinho",
            HideDropship: "Ocultar endereço de entrega",
            InvalidDateMessage: "Seleção inválida",
            Item_Label: "Produto",
            ItemNumberLabel: "Código",
            ListPriceLabel: "Lista de Preço",
            ModelNumber_Label: "Model #",
            NoProductFoundMessage: "Nenhum resultado encontrado para",
            NotValidSkuMessage: "Código não é válido",
            OboleteItemsNotAvailable: "Itens obsoletos não estão disponíveis para compra no FE-Direct. Entre em contato com o gerente ou atendente de sua região para obter mais informações",
            ObsoleteItem: "Itens obsoletos",
            OrderCancelDateLabel: "Data cancelamento do pedido",
            OrderUpdated: "Pedido atualizado!",
            PoNumberLabel: "Ordem de Compra do Cliente",
            PoRequiredMessage: "Ordem de Compra do cliente é obrigatória",
            QuantityLabel: "Quantidade",
            QuantityRequired: "Item quantity is required (pt)",
            QuoteInventoryMessage: "O orçamento bloqueia os preços, mas não confirma o estoque",
            RequestDateLabel: "Data solicitada para faturamento",
            ReviewOrderTitle: "Revise antes de confirmar o pedido",
            ShippingOptionLabel: "Selecione a opção de envio",
            ShipToAddress: "Endereço Entrega",
            ShipToNotSelectedWarningMessage: "Por favor, selecione um Código de cliente  válido na lista de clientes",
            ShipToNotSelectedWarningTitle: "Antes de continuar",
            ShowDropship: "Adicionar endereço de entrega",
            UnableToAddToCart: "Não foi possível adicionar ao carrinho",
            UnableToAddToCartMessage: "Não é possível adicionar ao carrinho",
            UnabletoUpdateQuantity: "Não foi possível atualizar a quantidade",
            Unavailable: "Não disponível",
            UnavailableItemsInCartMessage: "Você tem itens indisponíveis no seu carrinho. Por favor, verifique os itens antes de prosseguir com a confirmação",
            UnavailableMessage: "Produto não está disponível. Por favor contate seu atendente para mais informações",
            UpateCartPriceMessage: "O preço precisa ser atualizado",
            WaitForPriceAndAvailability: "Aguarde enquanto o preço e a disponibilidade estão em processamento",
            YourCostLabel: "Preço"
        },

        es: {
            ObsoleteItem: "Item obsoleto",
            FetchingPriceFailed: "Fetching price failed (es)",
            PoRequiredMessage: "La orden de compra del cliente es obligatoria",
            CarrierRequiredMessage: "A carrier is required (es)",
            ShipToNotSelectedWarningTitle: "Antes de continuar",
            ShipToNotSelectedWarningMessage: "Por favor, seleccione un número de cliete válido en la lista de clientes",
            EditTitle: "Mi pedido",
            ReviewOrderTitle: "Confirmar pedido",
            CartEmptyMessage: "Tu carrito está vacío",
            CartMaxLines: "Limite de 100 linhas atingido",
            ShippingOptionLabel: "Seleccione la opción de envío",
            PoNumberLabel: "Orden de compra del cliente",
            DeliveryInstructionsLabel: "Instrucciones de entrega",
            DeliveryInstructionsLabel2: "Delivery Instructions 2 (es)",
            ButtonText_Back: "Volver",
            ButtonText_PlaceQuote: "Guardar como cotización",
            ButtonText_ReviewQuote: "Revisar y completar cotización",
            ButtonText_PlaceOrder: "Confirmar pedido",
            ButtonText_ReviewOrder: "Haga la revisíon antes de la confirmación del pedido",
            ButtonText_Reprice: "Calcular Precio",
            UnavailableItemsInCartMessage: "Tienes artículos que no están disponibles en tu carrito. Por favor, haga la verificacíon de los artículos antes de confirmar",
            HideDropship: "Ocultar dirección de Entrega Directa",
            ShowDropship: "Agregar dirección de Entrega Directa",
            DropshipAddress: "Dirección de Entrega Directa",
            RequestDateLabel: "Fecha de entrega solicitada",
            InvalidDateMessage: "Selección inválida",
            OrderCancelDateLabel: "Fecha de cancelación de orden",
            QuoteInventoryMessage: "La cotización se ajusta al precio pero no compromete inventario",
            ShipToAddress: "Dirección de Envio",
            UpateCartPriceMessage: "Los precios en el carro necesitan ser actualizados",
            CommentsLabel: "Comentarios",
            ItemNumberLabel: "Número de parte",
            QuantityLabel: "Cantidad",
            NotValidSkuMessage: "Número de parte no existe",
            ButtonText_AddItem: "Añadir",
            ConfiguredItemsNotAvailable: "Configured items are not currently available for purchase in FE-Direct. Please contact your account manager for price and availability (es)",
            OboleteItemsNotAvailable: "Item obsoleto, por favor contacta a tu representante de servicio al cliente para información de reemplazo",
            OrderUpdated: "Order updated! (es)",
            UnableToAddToCart: "Unable to add to cart (es)",
            FetchingPriceMessage: "Precio y disponibilidad deben ser cargados antes de añadir al carrito",
            UnavailableMessage: "Producto no disponible. Por favor haga contacto con tu asistente para más informaciones",
            CartUpdatedMessage: "Carrito actualizado",
            UnableToAddToCartMessage: "No es posible añadir al carrito",
            NoProductFoundMessage: "Ningún resultado encontrado para",
            ListPriceLabel: "Precio de lista",
            YourCostLabel: "Precio Neto",
            AvailabilityLabel: "Disponibilidad",
            ButtonText_AddToOrder: "Agregar al carrito",
            ButtonText_CheckAvailability: "Confirme la cantidad",
            Description_Label: "Descripción",
            Item_Label: "Número de item",
            Unavailable: "No disponible",
            AvailabilityCost_ButtonText: "Obtener disponibilidad / costo",
            WaitForPriceAndAvailability: "Please wait while price and availability finish loading (es)",
            QuantityRequired: "Item quantity is required (es)",
            UnabletoUpdateQuantity: "Unable to update quantity (es)",
            AvailabilityByLocation_Label: "Availability by Location (es)",
            ModelNumber_Label: "Model #",
            CreditHold_Warning: "Por favor contacte a su representante de servicio al cliente para revisión de su estado de cuenta."
        },

        it: {
            AvailabilityByLocation_Label: "Availability by Location",
            AvailabilityCost_ButtonText: "Disponibilità/Costo",
            AvailabilityLabel: "Disponibilità",
            ButtonText_AddItem: "Aggiungi",
            ButtonText_AddToOrder: "Aggiungi all'Ordine",
            ButtonText_Back: "Indietro",
            ButtonText_CheckAvailability: "Check Quantity",
            ButtonText_PlaceOrder: "Conferma il tuo Ordine",
            ButtonText_PlaceQuote: "Save As Quote",
            ButtonText_Reprice: "Calcola il tuo prezzo",
            ButtonText_ReviewOrder: "Rivedi e Completa e l’Ordine",
            ButtonText_ReviewQuote: "Rivedi e completa l’offerta",
            CarrierRequiredMessage: "A carrier is required",
            CartEmptyMessage: "Your cart is empty.",
            CartMaxLines: "Raggiunto limite massimo di 100 righe",
            CartUpdatedMessage: "Order updated",
            CommentsLabel: "Note",
            ConfiguredItemsNotAvailable: "Configured items are not currently available for purchase in FE-Direct. Please contact your account manager for price and availability",
            CreditHold_Warning: "Si prega di contattare il rappresentante del servizio clienti per rivedere la dichiarazione.",
            DeliveryInstructionsLabel: "Istruzioni per la Consegna",
            DeliveryInstructionsLabel2: "Istruzioni per la Consegna 2",
            Description_Label: "Description",
            DropshipAddress: "Drop Ship Address",
            DropshipAddressNotComplete: "Please enter a full name and select the drop ship address from the drop down.",
            EditTitle: "Offerta",
            FetchingPriceFailed: "Fetching price failed",
            FetchingPriceMessage: "Price and availability must be loaded before adding to cart",
            HideDropship: "Hide Drop Ship Address",
            InvalidDateMessage: "Invalid date selected",
            Item_Label: "Codice Articolo #",
            ItemNumberLabel: "Aggiungi codice articolo",
            ListPriceLabel: "Prezzo di Listino",
            ModelNumber_Label: "Articolo #",
            NoProductFoundMessage: "No results found for",
            NotValidSkuMessage: "The item number you entered is not valid.",
            OboleteItemsNotAvailable: "Obsolete items are not available for purchase in FE-Direct. Please contact your account manager for more information",
            ObsoleteItem: "Obsolete Item",
            OrderCancelDateLabel: "Order Cancel Date",
            OrderUpdated: "Order updated!",
            PoNumberLabel: "Numero Ordine di Acquisto",
            PoRequiredMessage: "A PO number is required.",
            QuantityLabel: "Quantità",
            QuantityRequired: "Item quantity is required",
            QuoteInventoryMessage: "Quote locks in pricing but does not commit inventory.",
            RequestDateLabel: "Data di spedizione richiesta",
            ReviewOrderTitle: "Review and Complete Order",
            ShippingOptionLabel: "Select Shipping Option",
            ShipToAddress: "Indirizzo di Spedizione",
            ShipToNotSelectedWarningMessage: 'Please select a valid "SHIP TO" from the "SHIP TO / BILL TO" list.',
            ShipToNotSelectedWarningTitle: "Before Proceeding",
            ShowDropship: "Add Drop Ship Address",
            UnableToAddToCart: "Unable to add to cart",
            UnableToAddToCartMessage: "Unable to add to cart",
            UnabletoUpdateQuantity: "Unable to update quantity",
            Unavailable: "Unavailable",
            UnavailableItemsInCartMessage: "You have unavailable items in your cart. Please remove the items before proceeding to checkout",
            UnavailableMessage: "Product is not available. Please contact admin for more information",
            UpateCartPriceMessage: "L’importo del carrello deve essere aggiornato",
            UseCustomerAddress: "Use Customer Address",
            UseDropShipAddress: "Use Drop Ship Address",
            WaitForPriceAndAvailability: "Please wait while price and availability finish loading",
            YourCostLabel: "Prezzo Netto"
        },

        setLanguage: LocalizedStrings.setLanguage
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new CartStrings();
