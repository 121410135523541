import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressBar, Step } from "react-step-progress-bar";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const StepProgressBar = (props) => {

    const getStepPosition = (currentStepCode, currentStepDescription) => {
        // This step optional to use this progress bar else where, needed this for warranty nft statuses. 
        // can use either statuscode / description to get current step position using isDescriptionStep property
        if (props.isDescriptionStep === true) {
            return props.steps.findIndex(({ descriptionText }) => descriptionText === currentStepDescription);
        }
        else {
            return props.steps.findIndex(({ statusCode }) => statusCode === currentStepCode);
        }
    };

    return (
        <ProgressBar
            width={props.width}
            percent={
                100 * ((getStepPosition(props.stepCode, props.stepDescription))
                    / (props.steps?.length - 1))
            }

            filledBackground="linear-gradient(to left, green, #D0F0C0)" >
            {props.steps.map((step, index, arr) => {
                return (
                    <Step key={index}
                        transition="scale"
                        position={100 * (index / arr.length - 1)}
                        children={({ accomplished }) => (
                            <div style={{ marginTop: 50, display: "flex", justifyContent: "spacebetween", marginBottom: "20" }} >
                                <div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center", flex: 1, marginLeft: "30", }}>
                                    {accomplished ?
                                        <div style={{
                                            position: "relative", display: "flex", justifyContent: "center", width: 25,
                                            height: 25,
                                            alignItems: "center", marginBottom: 30
                                        }} >
                                            <FontAwesomeIcon icon={faCheckCircle} style={{ width: 25, height: 25, backgroundColor: "white", }} color="green" />
                                        </div>
                                        :
                                        <div style={{
                                            position: "relative", display: "flex", justifyContent: "center", alignItems: "center", width: 25,
                                            height: 25, marginBottom: 30, borderRadius: "50%", fontSize: "12px", color: "white",
                                            backgroundColor: 'gray'
                                        }} >
                                            {index + 1}
                                        </div>
                                    }
                                    <div style={{ color: accomplished ? "green" : "#333333", fontSize: "13px", marginBottom: 10, fontWeight: accomplished ? 'bold' : '600' }}>
                                        {step.descriptionText}
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                );
            })}
        </ProgressBar>
    );
}

export default StepProgressBar;