import React from 'react';
import OrderItem from './OrderItem';
import { withSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';

export  default class OrderItems extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        if (!this.props.items || this.props.items.length < 1)
            return null;
        return (
            < div className="to-be-shipped" >
                <div className="order-items pending-items" >                    
                    {
                        this.props.items.map((item, key) => {
                            return <OrderItem item={item} key={key} selectedCustomer={this.props.selectedCustomer} />
                        })
                    }
                </div>
            </div >
        )
    }
}