import Api from './Api';

class OrderService {

    fetchOrders = (state, props, fullList) => {
        let url = this.getUrl(props.isQuote, props.salesHistoryConfig.settings.OrdersByLine === true);
        let ordersLookup = {
            isQuote: props.isQuote,
            searchValue: state.number,
            selectedCustomer: props.selectedCustomer.id,
            userStorefront: props.currentUser.storefrontNumber,
            pageSize: fullList ? 0 : state.pageSize,
            page: state.page,
            days: state.dateRange,
            sort: state.sort,
            fromDate: state.fromDate,
            toDate: state.toDate,
            orderType: props.salesHistoryConfig.settings.ShowSIOrderType ? props.StorefrontConfig.settingsList.UseOrderType : "",
        };

        ordersLookup = props.salesHistoryConfig.settings.OrdersByLine === true ?
            { ...ordersLookup, orderLineStatuses: state.status } : { ...ordersLookup, orderStatus: state.status[0] ?? null };
        if (state.status.length > 0 && !props.isQuote && props.salesHistoryConfig.settings.OrdersByLine === true) {
            ordersLookup = { ...ordersLookup, orderHeaderStatuses:state.status }
        }
       if (props.isQuote) {
           //ordersLookup = { ...ordersLookup, quoteStatus: state.status[0] ?? null }
           ordersLookup = props.salesHistoryConfig.settings.OrdersByLine === true ?
               { ...ordersLookup, quoteLineStatuses: state.status } : { ...ordersLookup, quoteStatus: state.status[0] ?? null };
       }

        return Api.fetch(url, {
            method: 'POST', body: JSON.stringify(ordersLookup)
        }).then(this.handleResponse)
    }

    handleResponse = (response) => {
        if (response) {
            return response.json();
        }
    }

    getUrl = (isQuote, ordersByLine) => {
        if (isQuote)
            return '/api/quotes/getQuotes';
        else
            return ordersByLine ? '/api/orders/getOrderLines' : '/api/orders/getOrders';
    }

    fetchOrdersByTerritory = (state, currentUser, fullList) => {
        let url = '/api/orders/getOrdersByTerritory';

        let ordersLookup = {
            searchValue: state.number,
            selectedCustomer: state.filterCustomer,
            selectedTerritory: state.territory,
            userStorefront: currentUser.storefrontNumber,
            pageSize: fullList ? 0 : 100,
            page: state.page,
            days: state.dateRange,
            sort: 0,
            orderStatus: state.status,
            creditHoldFilter: state.creditHoldFilter
        };

        return Api.fetch(url, {
            method: 'POST', body: JSON.stringify(ordersLookup)
        }).then(this.handleResponse)
    }
    getCompleteLinePricing = (orderNumber, orderType, orderCompany, orderLine, orderLocale, callback) => {
        let data = {
            OrderNumber: orderNumber,
            OrderType: orderType,
            OrderCompany: orderCompany,
            OrderLine: orderLine,
            OrderLocale: orderLocale
        };
        return Api.fetch(`/api/orders/getCompleteLinePricing`, { method: 'POST', body: JSON.stringify(data) })
            .then((res) => {
                if (res)
                    return res.json();
            })
            .then(callback);
    }
    getOrderLineTaxes = (orderNumber) => {
        return Api.fetch(`/api/orders/getOrderLineTaxes/${orderNumber}`, { method: 'GET' })
            .then((res) => {
                if (res)
                    return res;
            })          
    }
    getDashboardOrders = (customerId, storefrontId, orderCompany, ) => {
        let data = {
            CustomerNumber: customerId,
            StorefrontId : storefrontId,
            OrderCompany : orderCompany       
        };
        return Api.fetch(`/api/dashboard/getDashboardSalesOrders`, { method: 'POST', body: JSON.stringify(data) })
            .then((res) => {
                if (res)
                    return res.json();
            });
    }
    getDashboardQuotes = (customerId, storefrontId, orderCompany, rows,numberDays) => {
        let data = {
            CustomerNumber: customerId,
            StorefrontId: storefrontId,
            OrderCompany: orderCompany,
            NoOfRows: rows,
            NumberDays: numberDays
        };
        return Api.fetch(`/api/dashboard/getDashBoardSalesQuotes`, { method: 'POST', body: JSON.stringify(data) })
            .then((res) => {
                if (res)
                    return res.json();
            });
    }
    getDashboardInvoices = (customerId, storefrontId, orderCompany, rows) => {
        let data = {
            CustomerNumber: customerId,
            StorefrontId: storefrontId,
            OrderCompany: orderCompany,
            NoOfRows: rows
        };
        return Api.fetch(`/api/dashboard/getDashBoardSalesInvoices`, { method: 'POST', body: JSON.stringify(data) })
            .then((res) => {
               
                    return res.json();
            });
    }
    getDashboardFESelections = (emailAddress) => {
        let data = {
            EmailAddress: emailAddress
        };
        return Api.fetch(`/api/dashboard/getDashBoardFESelectSelections`, { method: 'POST', body: JSON.stringify(data) })
            .then((res) => {
                return res.json();
            });
    }
}

export default new OrderService();