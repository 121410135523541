import React from 'react';

export default class Search extends React.Component {
    render() {
        return (
            <div className="icon search-icon">
                <svg id="magnifying-glass-browser" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.842 65.8">
                    <defs>

                    </defs>
                    <g id="Group_59" data-name="Group 59" transform="translate(0)">
                        <path id="Path_111" data-name="Path 111" className="cls-1" d="M51.533,42.954A25.259,25.259,0,1,0,48.1,46.08L67.124,65.106a2.286,2.286,0,0,0,1.621.695,2.222,2.222,0,0,0,1.621-.695,2.353,2.353,0,0,0,0-3.28ZM11.821,25.587A20.956,20.956,0,1,1,32.777,46.543,20.975,20.975,0,0,1,11.821,25.587Z" transform="translate(-7.19)" />
                    </g>
                </svg>
            </div>
        );
    }
}
