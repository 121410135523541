import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Notifications from '../../services/Notifications';
import { withUserConfig } from '../../hooks/UserConfigContext'; 

const ImportCSV = props => {
    const [parsedCustomers, setParsedCustomers] = useState([]);
    const fileInputRef = useRef('');
    const fileReader = new FileReader();
    const localized = props.UserConfig.labels;

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        fileInputRef.current.value = '';

        const allowedExtensions = ".csv";
        // file size val
        if (fileUploaded.size > parseInt(100) * 1024) {
            return Notifications.error(localized.FileTooLarge);
        }

        if (allowedExtensions.includes(fileUploaded.name.split('.').pop()) === true) {
            // File Reader Process
            if (fileUploaded.size > parseInt('10485760') * 1024) {
                return Notifications.error(localized.FileTooLarge);
            }
            if (fileUploaded) {
                fileReader.onload = (e) => {
                    const wholeText = e.target.result;
                    
                    
                    const csvData = wholeText.split(/\r\n|\n/);
                    let cleanedCSVData = [];
                    //removes empty lines
                    for (let i = 0; i < csvData.length; i++) {
                        if (csvData[i] === "") { 
                            cleanedCSVData = csvData.slice(0, -1);
                        }
                    };
                    //make sure csvData is not empty
                    if (cleanedCSVData.length === 0  || cleanedCSVData[0]=="") {
                        return Notifications.warning(localized.NoValidData);
                    };
                    for (let i = 0; i < cleanedCSVData.length; i++){
                        if (checkIsNumber(cleanedCSVData[i]) == false) {
                            return Notifications.warning(localized.InvalidCharacters);
                        }
                    };

                    
                    props.setParsedCustomers(cleanedCSVData);
                    props.setValidFileName(fileUploaded.name);
                };
               fileReader.readAsText(fileUploaded);
                
            }
        }
        else {
            return Notifications.error(localized.InvalidFileType);
        }
    };

    const checkIsNumber = (text) => {
        let isNum = /^\d+$/.test(text);
        return isNum;
    }

    return (
        <>
            <button data-cy="import-cart" className="button search-button button-alt text-nowrap px-5" onClick={() => fileInputRef.current.click()} >
                <input accept={props.accept} onChange={handleChange} multiple={props.multiple} ref={fileInputRef} type='file' hidden className="form-control" />
                <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: "5px" }} />
                {localized.ImportFromCSV}
            </button>
        </>
    );
}
ImportCSV.propTypes = {
    fileUploadUpdated: PropTypes.func,
    uploadItems: PropTypes.array,
    parsedCustomers: PropTypes.array
};
export default withUserConfig(ImportCSV);
