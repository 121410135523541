import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CollapsedTable } from "../tables/CollapsedTable";
import ChecklistIcon from '@mui/icons-material/Checklist';
import { Link } from "react-router-dom";
import Selection from '../../design/img/FE_Select_Logo_BLK.png';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const DashboardYourSelections = (props) => {
    const selectionData = props.selections;
    const filteredSelectionDate = selectionData != undefined ? selectionData.filter((selection) => selection.itemNumber != '') : selectionData;
    const groupBySelectionData = filteredSelectionDate != undefined ? Map.groupBy(selectionData, ({ productLine_ID }) => productLine_ID) : selectionData;
    const someArray = groupBySelectionData != undefined ? Array.from(groupBySelectionData, ([name, value]) => ({ name, value })).slice(0, 10) : [];
  
    const data = {
        labels: someArray.map((order) => order.name),
        datasets: [
            {
                label: 'Number of Selections',
                data: someArray.map((order) => order.value.length),
                backgroundColor: [
                    'rgba(0, 0, 102, .8)',
                    'rgba(173, 216, 230, .8)',
                    'rgba(169, 169, 169, .8)',
                    'rgba(0, 0, 0, .8)',
                    'rgba(126,189,66,.8)',
                    'rgba(139,69,19, .8)',
                    'rgba(245,213,196, .8)',
                    'rgba(210 ,180 ,140, .8)',
                    'rgba(255 ,253 ,208, .8)',
                    'rgba(105 ,105 ,105, .8)'


                ],
                borderColor: [
                    'rgba(0, 0, 102, 1)',
                    'rgba(173, 216, 230, 1)',
                    'rgba(169, 169, 169, 1)',
                    'rgba(0, 0, 0, 1)',
                    'rgba(126,189,66,1)',
                    'rgba(139,69,19, 1)',
                    'rgba(245,213,196, 1)',
                    'rgba(210 ,180 ,140, 1)',
                    'rgba(255 ,253 ,208, 1)',
                    'rgba(105 ,105 ,105, 1)'

                ],
                hoverOffset: 30,
                borderWidth: 1
            },
        ],
    };
    const options = {
        responsive: true,
        layout: {
            padding: {
                bottom: 20,
                left: 20,
                right: 20
            }
        },
        plugins: {
            legend: {
                events: [],
                position: 'left',
                align: 'left',
                padding: {
                    right: 10
                },
                labels: {
                    boxHeight: 10,
                    boxWidth: 10
                }
            },
            title: {
                display: true,
                text: 'Selections by Product Line',
                font: {
                    size: 20,
                    weight: 'bold',
                    color: 'black'
                }
            },
        },
    };
  
    return (
        <>
            {props.storeSettings.settingsList.UseDashboardSelections == '1' &&
                <div className="order-panel">
                    <>
                        {someArray != undefined &&
                            <>
                            <div className="title-flex-container">
                                <div>
                                    <ChecklistIcon fontSize="large" style={{ color: "black", height: "4rem", width: "5.25rem", marginTop: '10px' }} />
                                </div>
                                <div>
                                    <div className="title-label" style={{ marginRight: "30px" }}> YOUR SELECTIONS </div>
                                </div>
                                <div>
                                    <Link className="link-style" to={{ pathname: "https://franklin.portal-center.betanext.intelliquip.com/dashboard" }} target='_blank'>
                                        <img style={{height: "50px"}} src={Selection} alt="Selections" />
                                    </Link>
                                </div>
                                <div>
                                    <Link className="link-style" to={{ pathname: "https://franklin.portal-center.betanext.intelliquip.com/dashboard" }} target='_blank'>
                                        <button className="expand-button" style={{marginTop: "10px"} }>
                                            <text className="expand-button-text">{"GO"}</text>
                                        </button>
                                    </Link>
                                </div>
                             </div>
                            <div className="data-flex-container">
                                {(someArray.length > 0) ?
                                    <table className="table-style">

                                        <th className="th-middle-left">{"Product Lines"}</th>
                                        <th className="th-middle-right">{"# Of Selections"}</th>
                                        <th className="th-middle-right">{"Quoted Items"}</th>
                                        {someArray.slice(0, 10).map((selection, key) => {
                     
                                               let quotedItems = selection.value.filter(x => x.orderNmbr_DriveControl != "" && x.orderNmbr_DriveControl != null).length
                                                + selection.value.filter(x => x.orderNmbr_PumpMotor != "" && x.orderNmbr_PumpMotor != null).length
                                                + selection.value.filter(x => x.orderNmbr_Pump != "" && x.orderNmbr_Pump != null && x.orderNmbr_Pump != '-').length
                                                + selection.value.filter(x => x.orderNmbr_Motor != "" && x.orderNmbr_Motor != null && x.orderNmbr_Motor != '-').length
                                                + selection.value.filter(x => x.orderNmbr_addons != "" && x.orderNmbr_addons != null).length
                                            return <tr className="row-wrapper" >

                                                <td className="td-po">{selection.name}</td>
                                                <td className="td-right">{selection.value.length}</td> 
                                                <td className="td-right">{quotedItems}</td>
                                                
                                            </tr>

                                        }
                                    )}
                                    </table> : <div>{"No Selections in the past year."}</div>
                                }

                            </div>
                            {(someArray.length > 0) &&
                                <div style={{
                                    paddingRight: "2.5rem", paddingLeft: "1rem", paddingBottom: "2rem", display: "flex", flexDirection: "row", justifyContent: "center"

                                }}>
                                <div style={{ width: "40rem", height: "40rem" }}>
                                <Pie options={options} data={data} />
                                    </div>
                            </div>
                            }
                        </>
                        }
                </> 
                </div>
            }
        </>
    );
}

DashboardYourSelections.propTypes = {
    orderData: PropTypes.object
};
function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
    };
}

export default connect(mapStateToProps, null)(DashboardYourSelections);