import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";

const StyledRadio = styled(Radio)(() => ({
  color: "black",
  "&.Mui-checked": {
    color: "black",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 22,
  },
}));

const PaymentSelector = ({ localized, selected, options }) => {
  return (
    <div className="section-container">
      <div className="section d-flex flex-column gap-4">
        <FormControl>
          <label id="payment-radio-buttons-group" className="heading3">
            {localized.FreightPaidOrCollectLabel}:
          </label>

          <RadioGroup name="payment-radio-buttons-group" value={selected}>
            <FormControlLabel
              label={localized[options.label1]}
              control={<StyledRadio disableFocusRipple disableTouchRipple />}
              disableTypography={true}
              value={options.freightCode1}
              sx={{ maxWidth: "fit-content" }}
            />

            {options.freightCode1 !== options.freightCode2 && (
              <FormControlLabel
                label={localized[options.label2]}
                control={<StyledRadio disableFocusRipple disableTouchRipple />}
                disableTypography={true}
                value={options.freightCode2}
                sx={{ maxWidth: "fit-content" }}
              />
            )}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default PaymentSelector;
