import React from "react";
import { Switch, Route } from "react-router-dom";
import ReportsIndex from "./ReportsIndex";
import OrdersReport from "./OrdersReport";
import TwelveMonthSales from "./TwelveMonthSales";
import SalesHistoryReport from "./SalesHistoryReport";
import CustomerDetailsByTerritory from "./CustomerDetailsByTerritory";
import AvailabilityLogReport from "./AvailabilityLogReport";

export default function Reports(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/reporting/sales-territory-report"
                ]}
                render={(props) => (
                    <OrdersReport {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/reporting/12-month-sales"
                ]}
                render={(props) => (
                    <TwelveMonthSales {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/reporting/sales-history"
                ]}
                render={(props) => (
                    <SalesHistoryReport {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/reporting/customer-details-report"
                ]}
                render={(props) => (
                    <CustomerDetailsByTerritory {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/reporting/availability-log"
                ]}
                render={(props) => (
                    <AvailabilityLogReport {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/reporting/index",
                    "/reporting",
                ]}
                render={(props) => (
                    <ReportsIndex {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
