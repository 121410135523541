import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
    geocodeByAddress
} from 'react-places-autocomplete';


export default class DropshipAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullAddress: "",
            fullName: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            stateProvenceRegion: "",
            zipCode: "",
            country: "",
          
        }
    }

    componentDidMount() {
        this.setDropshipAddress();
    }

    componentWillUnmount() {
        this.handleEvent();
    }
    handleChange = address => {
        this.setState({ fullAddress: address });
    }

    handleSelect = address => {
        this.setState({ fullAddress: address });
        geocodeByAddress(address)
            .then(results => this.handleComponents(results[0].address_components))
            .catch(error => console.error('Error', error));
    }

    handleComponents = addressComponents => {
        const componentForm = {
            street_number: "short_name",
            route: "long_name",
            administrative_area_level_1: "short_name", //state
            country: "short_name",
            postal_code: "short_name"
        };
        let addressLine1 = '';

        for (const component of addressComponents) {
            const addressType = component.types[0];
            if (componentForm[addressType]) {
                const val = component[componentForm[addressType]];
                if (addressType === 'street_number') {
                    addressLine1 = val + ' ';
                } else if (addressType === 'route') {
                    addressLine1 = addressLine1 + val;
                }
                else if (addressType === 'administrative_area_level_1') {
                    this.setState({ stateProvenceRegion: val });
                }
                else if (addressType === 'country') {
                    this.setState({ country: val });
                }
                else if (addressType === 'postal_code') {
                    this.setState({ zipCode: val });
                }
            }
        }
        this.setCity(addressComponents);
        this.setState({ addressLine1: addressLine1 }, this.handleEvent);
    }

    setCity = addressComponents => {
        const componentForm = {
            locality: "long_name", //city priority 1
            sublocality_level_1: "short_name", //city priority 2
            neighborhood: "short_name", //city priority 3
        };
        const payloadSections = ['neighborhood', 'sublocality_level_1', 'locality'];// overwrite based on priority
        for (const payloadSection of payloadSections) {
            addressComponents.forEach(element => {
                element.types.forEach(t => {
                    if (t === payloadSection) {
                        if (element) {
                            this.setState({ city: element[componentForm[payloadSection]] });
                        }
                    }
                })
            });

        }
    }

    handleEvent = () => {
        var dropship = {
            fullName: this.state.fullName,
            addressLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            city: this.state.city,
            stateProvenceRegion: this.state.stateProvenceRegion,
            zipCode: this.state.zipCode,
            country: this.state.country,          
            fullAddress: this.state.fullAddress,
        };
        this.props.updateDropshipAddress(dropship);
    }

    setDropshipAddress = () => {
        if (this.props.dropshipAddress) {
            this.setState({
                fullName: this.props.dropshipAddress.fullName,
                addressLine1: this.props.dropshipAddress.addressLine1,
                addressLine2: this.props.dropshipAddress.addressLine2,
                city: this.props.dropshipAddress.city,
                stateProvenceRegion: this.props.dropshipAddress.stateProvenceRegion,
                zipCode: this.props.dropshipAddress.zipCode,
                country: this.props.dropshipAddress.country,              
                fullAddress: this.props.dropshipAddress.fullAddress,
            });
        }
    }

    render() {
        const { fullAddress, fullName, addressLine2 } = this.state;
        const { localized } = this.props;
        return (
            <div className="fele-dropship-address">
                <div className="fele-content">
                    <div className="input-wrapper">
                        <label>
                            <div className="label">{localized.FullName}:</div>
                        </label>
                        <input type="text" id="fullName" value={fullName} maxLength="40" onChange={e => this.setState({ fullName: e.target.value })} onBlur={this.handleEvent} />
                        <div>Characters remaining: {40 - fullName.length}</div>
                        <label>
                            <div className="label">{localized.AddressLine1}:</div>
                        </label>
                        <PlacesAutocomplete
                            value={fullAddress}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search full address ...',
                                            className: 'location-search-input',
                                            autoComplete: 'off'
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion, index) => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div key={index}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        <label>
                            <div className="label">{localized.AddressLine2}:</div>
                        </label>
                        <input type="text" id="addressLine2" value={addressLine2} onChange={e => this.setState({ addressLine2: e.target.value })} onBlur={this.handleEvent} />             
                    </div>
                </div>
            </div>
        )
    }
}


DropshipAddress.propTypes = {
    dropshipAddress: PropTypes.object,
    updateDropshipAddress: PropTypes.func,
};
