import React from 'react';

export default class InvoiceIcon extends React.Component {
    render() {
        return (
            <div className="icon invoice-icon">
                <svg data-cy="invoice-icon" id="Group_105" data-name="Group 105" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22">
                    <path id="Path_181" data-name="Path 181" d="M39.1,22.342H55.9a.856.856,0,0,0,.854-.854V6.987a.849.849,0,0,0-.27-.626L49.877.228A.882.882,0,0,0,49.293,0H39.1a.856.856,0,0,0-.854.854V21.488A.856.856,0,0,0,39.1,22.342ZM55.042,7.357V7.4H49.208V1.935ZM39.958,1.708H47.5V8.254a.856.856,0,0,0,.854.854h6.688V20.635H39.958Z" transform="translate(-38.25)"  />
                </svg>

            </div>
        );
    }
}
