import React from 'react';
import Configuration from '../resources/Configuration';

export default class Pingdom extends React.Component {
    componentDidMount() {
        if (window.location.host === Configuration.uriParts.defaultHost) {
            const pingdomScript = document.createElement("script");
            pingdomScript.src = "//rum-static.pingdom.net/pa-5ceed86be98940001600025d.js";
            pingdomScript.async = true;
            document.body.appendChild(pingdomScript);
        }
    }
    
    render() {
        return null;
    }
}