import React from 'react';
import PropTypes from 'prop-types';
import DateDisplay from '../DateDisplay';
import Loading from '../Loading';
import StatusHelper from '../../helpers/StatusHelper';
import { useHistory } from 'react-router-dom';

function Orders({ orders, fetching, localized, renderPaging }) {
    const history = useHistory();

    const viewOrder = (order) => {
        history.push({
            pathname: `/order/${order.orderId}/${order.orderCompany}/${order.orderType}`,
        });
    };

    const renderOrder = (order, index) => {
        return (
            <div key={index} className="details">
                <div className="date">
                    <div className="label">{localized.DateOrdered}</div>
                    <div className="fele-content">
                        <DateDisplay date={order.submittedOn} />
                    </div>
                </div>
                <div className="order-number">
                    <div className="label">{localized.OrderNumber}</div>
                    <div className="fele-content">{order.orderId + ' ' + order.orderType}</div>
                </div>
                <div className="status">
                    <div className="label">{localized.Status}</div>
                    <div className="fele-content">{StatusHelper.formatOrderStatus(order.status, localized)}</div>
                </div>
                <div className="po-number">
                    <div className="label">{localized.PONumber}</div>
                    <div className="fele-content">{order.referenceNumber}</div>
                </div>
                <div className="view-order">
                    <div className="button-wrapper">
                        <button data-cy="view-order" className="button button-secondary" onClick={() => viewOrder(order)}>
                            {localized.ViewDetails}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="fele-order-history">
            <div className="history-header">
                <div className="date">
                    <div className="label">{localized.DateOrdered}</div>
                </div>
                <div data-cy="order-number" className="order-number">
                    <div className="label">{localized.OrderNumber}</div>
                </div>
                <div className="status">
                    <div className="label">{localized.Status}</div>
                </div>
                <div className="po-number">
                    <div className="label">{localized.PONumber}</div>
                </div>
                <div>&nbsp;</div>
            </div>
            {fetching && <Loading type="brand" />}
            {!fetching && (orders && orders.length === 0) && (
                <div data-cy="order-details" className="details no-results">
                    {localized.NoOrdersFound}
                </div>
            )}
            {!fetching && (orders && orders.length > 0) && (
                <div>
                    <div data-cy="history" className="history">
                        {orders.map((item, key) => renderOrder(item, key))}
                    </div>
                    {renderPaging}
                </div>
            )}
        </div>
    );
}

Orders.propTypes = {
    renderPaging: PropTypes.func,
    fetching: PropTypes.bool,
    orders: PropTypes.arrayOf(PropTypes.object),
};

export default Orders;
