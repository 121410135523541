import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export class CartIcon extends React.Component {
    render() {
        return (
            <div className="icon shopping-cart">
                <svg id="online-shopping-cart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.998 54.454">
                    <defs>
                    </defs>
                    <g id="Group_53" data-name="Group 53" transform="translate(0 0)">
                        <path id="Path_104" data-name="Path 104" className="cls-1" d="M2.22,25.67H8.582l9.063,32.776a2.22,2.22,0,0,0,2.146,1.628H47.647a2.224,2.224,0,0,0,2.035-1.332L59.818,35.436a2.246,2.246,0,0,0-.185-2.109,2.2,2.2,0,0,0-1.85-1H27a2.22,2.22,0,1,0,0,4.439H54.38L46.167,55.635H21.456L12.393,22.859a2.22,2.22,0,0,0-2.146-1.628H2.22a2.22,2.22,0,1,0,0,4.439Z" transform="translate(0 -21.231)" />
                        <path id="Path_105" data-name="Path 105" className="cls-1" d="M101.931,371.294a5.031,5.031,0,1,0-5.031-5.031A5.036,5.036,0,0,0,101.931,371.294Z" transform="translate(-84.248 -316.84)" />
                        <path id="Path_106" data-name="Path 106" className="cls-1" d="M342.935,371.218h.37a5.2,5.2,0,1,0-.37,0Z" transform="translate(-293.808 -316.764)" />
                    </g>
                </svg>
                {
                    !this.props.hideCount &&
                    <div className="count"> <span id="cartCount">{this.props.count}</span></div>
                }
            </div>
        );
    }
}

CartIcon.propTypes = {
    count: PropTypes.number
};

function mapStateToProps(state) {
    return {
        count: state.cart.itemCount
    };
}

export default connect(
    mapStateToProps,
    null
)(CartIcon);
