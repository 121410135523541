import React from 'react';
import checkbox from '../../img/checkbox.png';
import checkbox_active from '../../img/checkbox_active.png';

export default class CheckBox extends React.Component {
    render() {
        return (
            <button className="checkbox-wrapper" onClick={this.props.onClick}>
                {
                    this.props.label &&
                    <div className="label">{this.props.label}</div>}
                {
                    !this.props.checked &&
                    <img className="checkbox unchecked" alt="checkbox unchecked" src={checkbox} />
                }
                {
                    this.props.checked &&
                    <img className="checkbox checked" alt="checkbox checked" src={checkbox_active} />
                }
            </button>
        );
    }
}
