import * as React from 'react';
import { useState, useEffect } from 'react';

const StateStorageConfigContext = React.createContext();

function StateStorageConfigProvider({ children, ...props }) {
    /*OrderFilter Filter */

    const initialState = {
        historyFilters: {
            number: '',
            status: [],
            toDate: "",
            fromDate: '',
            fromFullDate: '',
            toFullDate: '',
        },
    };

    const [orderFilters, setOrderFilter] = useState(initialState);

    const setHistoryFilters = (filters) => {
        setOrderFilter((prevOrderFilters) => ({
            ...prevOrderFilters,
            historyFilters: {
                ...prevOrderFilters.historyFilters,
                ...filters,
            },
        }));
    };

    const resetHistoryFilters = () => {
        setOrderFilter({
            ...orderFilters,
            historyFilters: {
                number: '',
                status: [],
                toDate: '',
                fromDate: '',
                fromFullDate: '',
                toFullDate: '',
            },
        });
    };

    return (
        <StateStorageConfigContext.Provider
            value={{
                orderFilters,
                setHistoryFilters,
                resetHistoryFilters
            }}
        >
            {children}
        </StateStorageConfigContext.Provider >
    );
}

function useStateStorageConfig() {
    const context = React.useContext(StateStorageConfigContext);
    if (context === undefined) {
        throw new Error('useStateStorageConfig must be used within a StateStorageConfigContext');
    }
    return context;
}

function withStateStorageConfig(Component) {
    return function WrappedComponent(props) {
        const stateStorageConfig = useStateStorageConfig();
        return <Component {...props}
            stateStorageConfig={stateStorageConfig}
        />;
    }
}

export { StateStorageConfigProvider, useStateStorageConfig, withStateStorageConfig }