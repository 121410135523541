import Api from './Api';

class ImportCartService {

    fetchValidateItemsToCart = (storefrontId, selectedCustomer, locale, items) => {

        let url = '/api/importCart/validateBatchForCart';

        let itemsLookup = {
            storefrontId: storefrontId,
            selectedCustomer: selectedCustomer,
            locale: locale,
            items: items
        };

        return Api.fetch(url, {
            method: 'POST', body: JSON.stringify(itemsLookup)
        }).then(this.handleResponse);
    }

    addItemsToCart = (items) => {

        let url = '/api/importCart/AddBatchToCart';

        let itemsLookup = {
            items: items
        };

        return Api.fetch(url, {
            method: 'POST', body: JSON.stringify(itemsLookup)
        }).then(this.handleResponse);
    }

    handleResponse = (response) => {
        if (response) {
            return response.json();
        }
    }
}

export default new ImportCartService();