import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WarrantyIcon from './icons/Warranty';
import Strings from './WarrantyLink.Strings';
import Config from '../resources/Configuration.js';
import UserHelper from '../helpers/UserHelper';
import * as Roles from '../resources/UserRole';
import { withSelectedCustomer } from '../hooks/withSelectedCustomer';

export class WarrantyLink extends React.Component {
    constructor(props) {
        super(props);

        this.localized = Strings.localize();
    }

    setWarrantyCookie = () => {
        if (this.props.currentUser && this.props.selectedCustomer) {
            this.bakeRawCookie();
            this.openWarrantyApp();
        }
    };

    openWarrantyApp = () => {
        window.open("https://warranty.fe-direct.com", "_blank");
    }

    bakeRawCookie = () => {
        const Customer = this.props.selectedCustomer;
        const curUser = this.props.currentUser;
        const addrType = Customer.addressType;
        const billType = Customer.billingType;
        const effectiveCustNumber = (addrType === 0 && billType === 0) ? Customer.billToExternalId : Customer.externalId;
        document.cookie = `SC_FeleWarrantySystem=Referrer=https://${Config.uriParts.defaultHost}&EmailAddress=${curUser.email}&AddressBookNumber=${effectiveCustNumber}&CustomerAddressBookNumber=${effectiveCustNumber}&ContactID=${curUser.email}&UserType=WR1;path=/;domain=.fe-direct.com;`;
    }

    render() {
        return (
            <li className="button-wrapper">
                {
                    <button onClick={this.setWarrantyCookie}>
                        <WarrantyIcon />
                        <div className="caption">
                            {
                                this.localized.Warranty
                            }
                            {
                                this.props.currentUser.roles && UserHelper.hasRoles([Roles.HAS_WARRANTY_ACCESS], this.props.currentUser) &&
                                " (Legacy)"
                            }
                        </div>
                    </button>
                }
            </li>
        )
    }
}

WarrantyLink.propTypes = {
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        //selectedCustomer: state.user.selectedCustomer,
        currentUser: state.user.currentUser,
    };
}

export default connect(
    mapStateToProps,
    null
)(withSelectedCustomer(WarrantyLink));