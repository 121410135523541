import React from 'react';
import PropTypes from 'prop-types';

export default class PhoneNumber extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    handleChange = (e) => {
        if (e.target.value.length <= this.props.maxLength)
            this.props.phoneNumberUpdated(e.target.value);
    }

    render() {
        const { phoneNumber, isRequired, localized } = this.props;
        return (
            <div className="fele-cart-po-number">
                <div className="fele-content">
                    <div className="input-wrapper">
                        <input name='phoneNumber' type="text" value={phoneNumber} onChange={this.handleChange} onBlur={this.handleChange} required={isRequired} />
                       
                    </div>
                </div>
            </div>
        )
    }
}

PhoneNumber.propTypes = {
    isRequired: PropTypes.bool,
    phoneNumberUpdated: PropTypes.func,
    phoneNumber: PropTypes.string,
};