import React from "react";
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import CloseIcon from '../icons/Close';

export default function ViewModal({
    isOpen,
    children,
    onClose,
    closeText,
    showFooter = true,
    size = "md"
}) {

    const ModalHeaderStyle = {
        backgroundColor: 'rgba(0,0,0,0)',
        border: 'none',
        paddingRight: '1rem',
        paddingTop: '2rem'
    }

    const ModalBodyStyle = {
        paddingTop: '0.1rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
    }

    const ModalFooterStyle = {
        padding: '0.75rem 3rem',
    }

    return (
        <Modal
            show={isOpen}
            backdrop="static"
            keyboard={false}
            centered
            animation={true}
            onHide={onClose}
            size={size}
        >
            <Modal.Header style={ModalHeaderStyle}>
                <Container>
                    <Row>
                        <Col className="button-wrapper" style={{ textAlign: 'right' }}>
                            <span style={{ cursor: 'pointer' }} onClick={onClose}>
                                <CloseIcon />
                            </span>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body style={ModalBodyStyle}>
                {children}
            </Modal.Body>
            {showFooter &&
                <Modal.Footer style={ModalFooterStyle}>
                    <Row>
                        <Col xs={4} md={4} className="button-wrapper">
                            <Button variant="secondary" className="button button-secondary" onClick={onClose}>{closeText}</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            }
        </Modal>
    );
}