// Roles
export const ALLOW_SHIPTO_OVERRIDE = 'AllowShipToOverrideonOrder';
export const CAN_APPROVE_REJECT_CLAIM = 'CanApproveRejectClaim';
export const CAN_SELECT_CASH_PAYMENT = 'CanSelectCashPayment';
export const CAN_SELECT_FREIGHT_CODES = 'CanSelectFreightCodes';
export const CAN_VIEW_PC_CODES = 'CanViewPCCodes';
export const CONVERT_REJECT_QUOTE = 'ConvertRejectQuote';
export const EDIT_ORDER_DETAILS = 'EditOrderDetails';
export const EDIT_QUOTE = 'EditQuote';
export const HAS_LEGACY_WARRANTY_ACCESS = 'HasLegacyWarrantyAccess';
export const HAS_REPORTING = 'HasReporting';
export const HAS_WARRANTY_ACCESS = 'HasWarrantyAccess';
export const IMPORT_ORDERS = 'ImportOrders';
export const MAINTNANCE_ACCOUNTS = 'MaintnanceAccounts';
export const MANAGE_CONTACTS = 'ManageContacts';
export const MANAGE_USERS = 'ManageUsers';
export const PLACE_ORDERS = 'PlaceOrders';
export const PLACE_QUOTES = 'PlaceQuotes';
export const SUBMIT_CLAIM = 'SubmitClaim';
export const SUMBIT_FEEDBACK = 'SubmitFeedback';
export const VIEW_12_MONTH_SALES_PARENT_SHIP = 'View12MonthSalesParentShip';
export const VIEW_ACCOUNT_BALANCE = 'ViewAccountBalance';
export const VIEW_AVAILABILITY = 'ViewAvailability';
export const VIEW_AVAILABILITY_BY_LOCATION = 'ViewAvailabilityByLocation';
export const VIEW_INVOICE_HISTORY = 'ViewInvoiceHistory';
export const VIEW_ITEM_CROSS_REFERENCE = 'ViewItemCrossReference';
export const VIEW_NET_PRICE = 'ViewNetPrice';
export const VIEW_ORDER_HISTORY = 'ViewOrderHistory';
export const VIEW_QUOTE_HISTORY = 'ViewQuoteHistory';
export const VIEW_SALES_REPORTS = 'ViewSalesReports';
export const VIEW_SALES_HISTORY_REPORTS = 'ViewSalesHistoryReport';
export const VIEW_CUSTOMER_DETAILS_REPORTS = 'ViewCustomerDetailsReport';
export const BULK_IMPORT = 'BulkImport';
export const HAS_PAYMENT_PORTAL_ACCESS = 'HasPaymentPortalAccess';

export const BETA_USER = 'BetaUser';
export const DEVELOPER = 'Developer';