import React from 'react';
import PropTypes from 'prop-types';
import Money from '../Money';
import Loading from '../Loading';

export default class OrderSummary extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    render() {
        const localized = this.props.localized;
        return (
            <div className="fele-order-summary">
                <div className="summary">
                    <div className="line-items">
                        <div className="line-item subtotal">
                            <div className="fele-label">{localized.SubtotalLabel}</div>
                            <div className="fele-content">
                                {
                                    this.props.fetching &&
                                    <Loading />
                                }
                                {
                                    !this.props.fetching &&
                                    <Money value={this.props.subTotal} currency={this.props.currency} country={this.props.selectedCustomer.address.country} />
                                }
                            </div>
                        </div>
                        <div className="line-item">
                            <div className="fele-label">{localized.EstimatedTaxLabel}</div>
                            <div className="fele-content"></div>
                        </div>
                    </div>
                    <div className="total">
                        <div className="line-item total">
                            <div className="fele-label">{localized.TotalLabel}</div>
                            <div className="fele-content">
                                {
                                    this.props.fetching &&
                                    <Loading />
                                }
                                {
                                    !this.props.fetching &&
                                    <Money value={this.props.subTotal} currency={this.props.currency} country={this.props.selectedCustomer.address.country} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="note">*{localized.Note}</div>

                </div>
            </div>
        )
    }
}


OrderSummary.propTypes = {
    subTotal: PropTypes.number,
    currency: PropTypes.string,
    fetching: PropTypes.bool,
};
