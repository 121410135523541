import React from 'react';
import Shipment from './Shipment';

export default class Shipments extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { shippingAddress, shipments, selectedCustomer, localized } = this.props;
        return (
            <React.Fragment>
                {
                    (!shipments || shipments.length === 0) &&
                    <div className="no-results">
                        {localized.NoShipmentsMessage}
                    </div>
                }
                {
                    (shipments && shipments.length > 0) &&
                    renderShipments(shipments, selectedCustomer, shippingAddress, localized)
                }
            </React.Fragment>
        );
    }
}

function renderShipments(shipments, selectedCustomer, shippingAddress, localized) {
    return (
        <React.Fragment>
            <div className="heading2 shipments-heading">{localized.ShipmentsHeading} ( {shipments.length} )</div>
            {shipments.map((s, i) => renderShipment(s, i, selectedCustomer, shippingAddress, localized))}
        </React.Fragment>
    )
}

function renderShipment(shipment, key, selectedCustomer, shippingAddress, localized) {
    return <Shipment shipment={shipment} key={key} shippingAddress={shippingAddress} selectedCustomer={selectedCustomer} localized={localized} />
}