import * as React from 'react';
import { useEffect } from 'react';
import Localization from '../services/Localization';
import storefrontService from '../services/Storefront';
import * as Components from '../resources/Components';

const StorefrontSettingsContext = React.createContext();

function StorefrontSettingsProvider({ children, ...props }) {
    const [storefrontSettingsLoaded, setStorefrontSettingsLoaded] = React.useState(false);
    const [storefrontList, setStorefrontList] = React.useState([]);
    const [settingsList, setSettingsList] = React.useState(props.settings || []);
    const [storefrontOrderQuoteStatuses, setStorefrontOrderQuoteStatuses] = React.useState([]);
    const [storefrontNumber, setStorefrontNumber] = React.useState(('00000' + props.storefrontNumber).slice(-5));
    const [labels, setLabels] = React.useState({});
    const [labelsLoaded, setLabelsLoaded] = React.useState(false);
    const [locale, setLocale] = React.useState(props.locale);
    const [storefront, setStorefront] = React.useState(props.storefront || []);
    const [branches, setBranches] = React.useState(props.branches || []);

    useEffect( () => {
        storefrontService.fetchStorefronts()
            .then(res => {
                fetchBranches();
                setStorefrontList(res);
                storefrontService.getStorefrontSettings(storefrontNumber)
                    .then(res => {
                        setSettingsList(res);
                        setStorefrontSettingsLoaded(true);

                    });
                storefrontService.getStorefrontOrderQuoteStatuses(storefrontNumber)
                    .then(res => {
                        setStorefrontOrderQuoteStatuses(res);
                    });
            })
    }, [])

    useEffect(() => {
        Localization.getComponentLabels(Components.NAVIGATION, locale, storefrontNumber)
            .then(res => {
                setLabels(res);
                setLabelsLoaded(true);
            })
    }, [locale])

    const fetchBranches = React.useCallback(async () => {
        storefrontService.getBranchDetails(storefrontNumber)
            .then(res => {
                setBranches(res);
            });
    }, [storefrontNumber]);

    const fetchStorefrontSettings = React.useCallback(async () => {
        storefrontService.getStorefrontSettings(storefrontNumber)
            .then(res => {
                setSettingsList(res);
            });
    }, [storefrontNumber]);

    const fetchStorefrontOrderStatuses = React.useCallback(async () => {
        storefrontService.getStorefrontOrderQuoteStatuses(storefrontNumber)
            .then(res => {
                setStorefrontOrderQuoteStatuses(res);
            });
    }, [storefrontNumber]);

    const getStorefrontById = (id) => {
        const storefront = storefrontList.find((s) => s.id.includes(id));
        return storefront;
    }
    const getStorefrontByNumber = (number) => {
        const storefront = storefrontList.find((s) => s.number.includes(number));
        return storefront;
    }

    const renderAddressType = (addressType) => {
        if (addressType === 0) {
            return labels.ShipTo;
        }
        else if (addressType === 1) {
            return labels.BillTo;
        }
        else if (addressType === 2) {
            return labels.Both;
        }
        else if (addressType === 3) {
            return labels.Parent;
        }

        return "";
    }

    const updateLocale = (locale) => {
        Localization.setLocale(locale);
        setLocale(locale);
    }

    return (
        <StorefrontSettingsContext.Provider
            value={{
                branches,
                storefrontSettingsLoaded,
                storefrontList,
                settingsList,
                storefrontOrderQuoteStatuses,
                storefront,
                storefrontNumber,
                setStorefrontNumber,
                fetchStorefrontSettings,
                fetchStorefrontOrderStatuses,
                getStorefrontById,
                getStorefrontByNumber,
                fetchBranches,
                labels,
                labelsLoaded,
                locale,
                updateLocale,
                renderAddressType,
            }}
        >
            {children}
        </StorefrontSettingsContext.Provider>
    );
}

function useStorefrontSettings() {
    const context = React.useContext(StorefrontSettingsContext);
    if (context === undefined) {
        throw new Error('useStorefrontSettings must be used within a StorefrontSettingsContext');
    }

    return context;
}

function withStorefrontConfig(Component) {
    return function WrappedComponent(props) {
        const StorefrontConfig = useStorefrontSettings();
        return <Component {...props}
            StorefrontConfig={StorefrontConfig}
        />;
    }
}

export { StorefrontSettingsProvider, useStorefrontSettings, withStorefrontConfig }