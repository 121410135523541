import React from 'react';

export default class Logo extends React.Component {
    render() {
        return (

            <div className="icon logo">
                {this.props.size === "small" ?
                    <svg className="small" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="44.39" height="47.546" viewBox="0 0 44.39 47.546">
                        <defs>
                            <linearGradient id="linear-gradient-small" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                                <stop offset="0" stopColor="#33bde5" />
                                <stop offset="1" stopColor="#0070a9" />
                            </linearGradient>
                        </defs>
                        <g id="Group_777" data-name="Group 777" transform="translate(0 0)">
                            <g id="Group_775" data-name="Group 775" transform="translate(12.137)">
                                <g id="Group_774" data-name="Group 774">
                                    <path className="cls-1" id="Path_217" data-name="Path 217" d="M67.943,33.635a5.564,5.564,0,0,1,11.089.324h4.544a1.252,1.252,0,0,0-.324-.974l-9.737-9.872c-2.921,2.948-9.744,9.868-9.744,9.868-.3.3-.287.506-.306.976h4.461Z" transform="translate(-63.461 -23.113)"  />
                                    <path className="cls-1" id="Path_218" data-name="Path 218" d="M76.486,45.548l-.039.026V43.663L75.121,42.48v-.944l1.326,1.178V41.059L75.121,39.9v-.944l1.326,1.158V38.09L73.8,35.473h-.581L70.54,37.68v7.87a5.662,5.662,0,0,1-2.6-4.086l-.017-.322H63.457c0,.6.012.719.317,1.053,3.162,3.16,6.816,6.843,9.737,9.791l9.737-9.872a1.145,1.145,0,0,0,.324-.97l-4.544,0A5.873,5.873,0,0,1,76.486,45.548Zm-4.652.649a5.661,5.661,0,0,1-1.272-.633l1.406-.934V41.244l1.218-.953V38.5l-1.218.965v-.827l1.489-1.265v5.382l2.977,2.831A5.837,5.837,0,0,1,71.833,46.2Z" transform="translate(-63.457 -29.703)" />
                                </g>
                            </g>
                        </g>
                            <path className="cls-3" id="Union_11" data-name="Union 11" d="M23.047,13.82l2.164,7.149,15.338-5.716.021,8.236L23.047,30.007ZM3.819,23.489l.021-8.236,15.338,5.716,2.163-7.149V30.007Zm19.9-12.433,18.243-6.8,2.429,8.025-18.244,6.8ZM0,12.283,2.429,4.258l18.243,6.8-2.429,8.025ZM2.95,3.071,13.841,0l1.138.932L6.791,3.216,22.156,8.831,37.584,3.216,29.453.932l1.166-.9,10.82,3.039L22.155,10.089Z" transform="translate(0 17.539)" />
                    </svg>
                    :
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 244.57 57.67">
                        <defs>
                            <linearGradient id="linear-gradient" x1="35.05" y1="64.21" x2="35.05" y2="27.82" gradientTransform="translate(0 5)" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#4ac7e9"></stop><stop offset="1" stopColor="#0081b7"></stop>
                            </linearGradient>
                        </defs>
                        <title>FE_Direct_Logo</title>
                        <path className="cls-1" d="M28.29,24.3A7.1,7.1,0,0,1,33,18.56a7.05,7.05,0,0,1,5.64.79,7.15,7.15,0,0,1,3.09,5.35h5.51a1.54,1.54,0,0,0-.39-1.18l-11.81-12c-3.55,3.58-11.82,12-11.82,12-.36.36-.35.61-.37,1.19h5.41Z" transform="translate(-8.13 -11.54)"></path>
                        <path className="cls-1" d="M38.65,30.76l0,0V28.48L37,27V25.89l1.61,1.43v-2L37,23.91V22.77l1.61,1.4V21.72L35.4,18.54h-.7l-3.26,2.68v9.54a6.85,6.85,0,0,1-3.15-5l0-.39H22.85c0,.72,0,.87.39,1.27,3.83,3.84,8.26,8.3,11.81,11.88l11.81-12a1.4,1.4,0,0,0,.39-1.18H41.74A7.13,7.13,0,0,1,38.65,30.76ZM33,31.55a6.93,6.93,0,0,1-1.54-.77l1.7-1.13V25.54l1.48-1.16V22.21l-1.48,1.17v-1L35,20.84v6.53l3.61,3.43A7.07,7.07,0,0,1,33,31.55Z" transform="translate(-8.13 -11.54)"></path>
                        <path className="cls-2" d="M45.27,32.86,43.85,34l9.87,2.77L35,43.53,16.37,36.72,26.3,34l-1.38-1.13L11.71,36.54,35,45.05l23.39-8.51ZM38.71,58.25l-2.63-8.67V69.21l21.26-7.9,0-10ZM39.84,56,62,47.71,59,38,36.9,46.23ZM33.2,46.23,11.08,38,8.13,47.71,30.26,56ZM12.79,51.32l0,10L34,69.21V49.58l-2.63,8.67Z" transform="translate(-8.13 -11.54)"></path><path className="cls-1" d="M69.69,19.15H87.08v7.91H78v12h7.92V47H78V66.9H69.69Z" transform="translate(-8.13 -11.54)"></path>
                        <path className="cls-1" d="M90.84,19.15h18.28v7.91h-10V38.93H108v7.92h-8.8V59h10.16V66.9H90.84Z" transform="translate(-8.13 -11.54)"></path>
                        <path className="cls-1" d="M122.83,19.15h7.77c10.64,0,16.72,7.71,16.72,23.06v1.63c0,15.35-6.08,23.06-16.72,23.06h-7.77Zm8.32,7.64V59.26h.34c4,0,7.37-3.48,7.37-15.42V42.21c0-11.94-3.41-15.42-7.37-15.42Z" transform="translate(-8.13 -11.54)"></path><path className="cls-1" d="M150.18,19.15h8.32V66.9h-8.32Z" transform="translate(-8.13 -11.54)"></path>
                        <path className="cls-1" d="M162.18,19.15h8.6c4.43,0,8.18,1.23,10.78,3.82s3.95,6.21,3.95,11.39V35c0,6.2-2.45,10.16-5.59,12.55l6.41,19.37h-8.87l-5.38-16.64h-1.57V66.9h-8.33Zm8.74,23.6c3.61,0,6.27-2.11,6.27-7.71V34.5c0-6-2.86-7.57-6.48-7.57h-.2V42.75Z" transform="translate(-8.13 -11.54)"></path>
                        <path className="cls-1" d="M189.33,19.15h18.28v7.91h-10V38.93h8.79v7.92h-8.79V59h10.16V66.9H189.33Z" transform="translate(-8.13 -11.54)"></path>
                        <path className="cls-1" d="M210.34,43.84V42.21c0-16.78,6.21-23.4,14-23.4a11.46,11.46,0,0,1,5.45,1.09v8.6a8.7,8.7,0,0,0-4.64-1.57c-3.68,0-6.34,3.27-6.34,15.21v1.77c0,12.62,2.66,15.21,6.48,15.21a9.34,9.34,0,0,0,4.64-1.5v8.19a11.53,11.53,0,0,1-6.14,1.43C216.14,67.24,210.34,60.49,210.34,43.84Z" transform="translate(-8.13 -11.54)"></path>
                        <path className="cls-1" d="M238.51,27.06h-5.86V19.15h20v7.91h-5.86V66.9h-8.33Z" transform="translate(-8.13 -11.54)"></path>
                    </svg>
                }
            </div>
        );
    }
}
