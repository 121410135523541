import * as types from './actionTypes';
import userService from '../services/UserService';
import customerService from '../services/Customer';

export function ReceivedCurrentUser(userJson) {
    return {
        type: types.RECEIVE_USER, user: userJson
    };
}

export function setSelectedUser(userJson) {
    return {
        type: types.SET_SELECTED_USER, user: userJson
    };
}

export function receivedFilteredUsers(filteredUsers) {
    return {
        type: types.RECEIVED_USERS, filteredUsers: filteredUsers
    };
}

export function receivedCustomerPreferences(prefs) {
    return {
        type: types.RECEIVED_CUSTOMER_PREFERENCES, preferences: prefs
    };
}

export function UpdateWarrantyAccess(id) {
    return function (dispatch) {
        userService.updateWarrantyAccess(id)
            .then(() => fetchFilteredUsers(dispatch));
    };
}

export function GetFilteredUsers() {
    return function (dispatch) {
        fetchFilteredUsers(dispatch);            
    };
}

export function ReceivedCustomers(customers) {
    return {
        type: types.RECEIVED_CUSTOMERS, customers: customers
    };
}

export function GetUserCustomers() {
    return function (dispatch) {
        customerService.fetchCustomers()
            .then(res => {
                dispatch(ReceivedCustomers(res));
            });
    }
}

export function LoadInitialUserCustomers() {
    return function (dispatch) {
        customerService.fetchCustomers()
            .then(res => {
                dispatch(ReceivedCustomers(res));
            });
    }
}

export function SetCustomer(customer) {
    customerService.set(customer);
    return {
        type: types.SET_CUSTOMER, customer: customer
    }
}

export function SetUserFlags(userFlags) {
    return {
        type: types.UPDATE_USERFLAGS, userFlags: userFlags
    }
}

function fetchFilteredUsers(dispatch) {
    return userService
        .fetchFilteredUsers()
        .then(filtered => {
            dispatch(receivedFilteredUsers(filtered));
        })
        .catch(error => {
            throw error;
        });
}
