import * as React from 'react';
import { useState, useEffect } from 'react';
import storefrontService from '../services/Storefront';
import CustomerService from '../services/Customer';
import { useCustomers } from './CustomerContext';
import DateHelper from '../helpers/DateHelper';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import Localization from '../services/Localization';
import * as Components from '../resources/Components';

const CartConfigContext = React.createContext();

function CartConfigProvider({ children, ...props }) {

    // have all the config settings here.
    const [cartSettingsLoaded, setCartSettingsLoaded] = useState(false);
    const [cartConfigLoaded, setCartConfigLoaded] = useState(false);
    const [shippingOptions, setShippingOptions] = useState(props.shippingOptions || []);
    const [freightHandlingCodes, setFreightHandlingCodes] = useState(props.freightHandlingCodes || []);
    const [pcDiscountCodes, setPCDiscountCodes] = useState(props.pcDiscountCodes || []);
    const [settings, setSettings] = useState(props.settings || []);
    const [childComponents, setChildComponents] = useState(props.childComponents || {});
    const [storefrontPaymentTerms, setStorefrontPaymentTerms] = useState(props.paymentTerms || []);
    const [freightOptions, setFreightOptions] = useState(props.freightOptions || []);
    const [labels, setLabels] = React.useState({});

    // get current customer
    const { currentCustomer } = useCustomers();
    // get current locale
    const { locale } = useStorefrontSettings();

    // customer specific settings
    const [transitDays, setTransitDays] = useState(0);
    // might have to just make this a parameter that gets passed in
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);
    const [brandCode, setBrandCode] = useState(props?.brandCode || currentCustomer?.brandCode || '');

    // useEffect?
    useEffect(() => {
        getCartConfigSettings();
        getCustomerCartSettings();
    }, []);

    // update on customer change
    useEffect(() => {
        setBrandCode(currentCustomer?.brandCode || '');
        getCartConfigSettings();
        getCustomerCartSettings(currentCustomer?.brandCode || null);
    }, [currentCustomer]);

    // get language labels
    useEffect(() => {
        Localization.getComponentLabels(Components.CART, locale, storefrontNumber)
            .then(res => setLabels(res))
    }, [locale]);

    // fetchSettings
    const getCartConfigSettings = () => {
        storefrontService.getCartSettings(storefrontNumber).then((res) => {
            setShippingOptions(res.shippingOptions || []);
            setFreightHandlingCodes(res.freightHandlingCodes || []);
            setFreightOptions(res.freightOptions || []);
            setPCDiscountCodes(res.pcDiscountCodes || []);
            setSettings(res.settings || []);
            setChildComponents(res.childComponents || []);
            setCartConfigLoaded(true);
        });
    }

    // fetch Customer Settings
    const getCustomerCartSettings = (brand = null) => {
        const oldStorefrontNumber = storefrontNumber.substring(1);
        // Adding customer null check condition
        if (storefrontNumber === "06400" && currentCustomer != null) {
            // var tDays = 
            CustomerService.getTransitDays(oldStorefrontNumber, currentCustomer.id)
                .then((res) => {
                    let today = new Date();

                    let calcDate = DateHelper.addBusinessDays(today, 1); // Latin America wanted "prep days" to be set at 2 weekdays
                    calcDate = DateHelper.subtract(calcDate, { days: (res * -1) }); // Then add transit days from the DB

                    let tDays = (DateHelper.differenceInSeconds(calcDate, today) / 86400);

                    setTransitDays(tDays);
                });
        }
        // fetch customer payment terms
        const code = brand || brandCode;
        CustomerService.getPaymentTerms(storefrontNumber, code)
            .then((res) => {
                setStorefrontPaymentTerms(res);
                setCartSettingsLoaded(true);
            });
    }

    const loadPaymentTerms = (storefrontNumber, brandCode) => {
        // fetch customer payment terms
        CustomerService.getPaymentTerms(storefrontNumber, brandCode)
            .then((res) => {
                setStorefrontPaymentTerms(res);
            });
    }


    return (
        <CartConfigContext.Provider
            value={{
                shippingOptions,
                freightHandlingCodes,
                freightOptions,
                pcDiscountCodes,
                transitDays,
                settings,
                childComponents,
                storefrontPaymentTerms,
                cartConfigLoaded,
                cartSettingsLoaded,
                labels,
            }}
        >
            {children}
        </CartConfigContext.Provider >
    );
}

function useCartConfig() {
    const context = React.useContext(CartConfigContext);
    if (context === undefined) {
        throw new Error('useCartConfig must be used within a CartConfigContext');
    }

    return context;
}

function withCartConfig(Component) {
    return function WrappedComponent(props) {
        const CartConfig = useCartConfig();
        return <Component {...props}
            CartConfig={CartConfig}
        />;
    }
}

export { CartConfigProvider, useCartConfig, withCartConfig }