import React, { useState } from "react";
import DateDisplay from "../DateDisplay";
import Money from "../Money";
import AnimateHeight from "react-animate-height";
import Api from "../../services/Api";
import Notifications from "../../services/Notifications";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import { withInvoiceConfig } from "../../hooks/InvoiceConfigContext";
import OrderAckIcon from "../icons/OrderAckIcon";
import WhatsAppIcon from "../icons/WhatsAppIcon";

const DURATION = 500;
const ZERO = 0;

export function InvoiceDetail(props) {
  const localized = props.InvoiceConfig.labels;
  const [animateHeight, setAnimateHeight] = useState(ZERO);
  const [animateText, setAnimateText] = useState(
    props.InvoiceConfig.labels.ShowMoreText
  );
  const [poNumbers, setPoNumbers] = useState("");
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [fetchingPONumbers, setFetchingPONumbers] = useState(false);
  const [fetchingOrderNumbers, setFetchingOrderNumbers] = useState(false);

  const toggleAnimation = () => {
    setAnimateHeight(animateHeight === ZERO ? "auto" : ZERO);
    setAnimateText(
      animateHeight === ZERO
        ? props.InvoiceConfig.labels.ShowLessText
        : props.InvoiceConfig.labels.ShowMoreText
    );

    if (!(poNumbers.length > ZERO) && animateHeight === ZERO) {
      fetch();
    }
  };

  const handleError = (res) => {
    if (!res) Notifications.error(props.InvoiceConfig.labels.NoInvoiceMessage);
  };

  const download = (invoice) => {
    if (
      props.InvoiceConfig.settings &&
      props.InvoiceConfig.settings.UseInvoiceRefNo === true
    ) {
      Api.downloadFileName(
        `api/invoices/GetPDF/${invoice.billToId}/${invoice.facturaRefNo}`,
        `${invoice.billToId}-${invoice.facturaRefNo}`
      ).then(handleError);
    } else {
      Api.downloadFileName(
        `api/invoices/GetPDF/${invoice.billToId}/${invoice.invoiceNumber}/${invoice.invoiceType}`,
        `${invoice.billToId}-${invoice.invoiceNumber}${invoice.invoiceType}`
      ).then(handleError);
    }
  };

  const getPDFLink = (invoice) => {
    if (
      props.InvoiceConfig.settings &&
      props.InvoiceConfig.settings.UseInvoiceRefNo === true
    ) {
      Api.fetch(
        `/api/invoices/GetPDFShareLink/${invoice.billToId}/${invoice.facturaRefNo}`,
        `${invoice.billToId}-${invoice.facturaRefNo}`
      ).then((res) => {
        window.open(res.shareUrl, "_blank");
      });
    } else {
      Api.fetch(
        `/api/invoices/GetPDFShareLink/${invoice.billToId}/${invoice.invoiceNumber}/${invoice.invoiceType}`,
        `${invoice.billToId}-${invoice.invoiceNumber}${invoice.invoiceType}`
      ).then((res) => {
        window.open(res.shareUrl, "_blank");
      });
    }
  };

  const fetch = () => {
    if (props.invoice && props.selectedCustomer) {
      setFetchingPONumbers(true);
      setFetchingOrderNumbers(true);
      Api.fetch(
        `/api/invoices/ponumbers/${props.invoice.invoiceNumber}/${props.selectedCustomer.companyCode}/${props.invoice.invoiceType}`
      ).then(poLoad);
      Api.fetch(
        `/api/invoices/ordernumbers/${props.invoice.invoiceNumber}/${props.selectedCustomer.companyCode}/${props.invoice.invoiceType}`
      ).then(load);
    }
  };

  const poLoad = (data) => {
    setFetchingPONumbers(false);
    setPoNumbers(data);
  };

  const load = (data) => {
    setFetchingOrderNumbers(false);
    setOrderNumbers(data);
  };

  const displayOrderNumbers = (orders) => {
    if (!orders) return;

    return orders.map((item, key) => (
      <Link
        key={key}
        to={`/order/${item.orderId}/${item.orderCompany}/${item.orderType}`}
      >
        {item.orderId + (key !== orders.length - 1 ? ", " : "")}
      </Link>
    ));
  };

  const renderDisplayInvoiceNumber = () => {
    if (props.invoicePDFLink) {
      return (
        <React.Fragment>
          <div className="link" onClick={() => download(props.invoice)}>
            <OrderAckIcon />
          </div>
        </React.Fragment>
      );
    }
  };

  const renderMoreInvoiceDetails = () => {
    return (
      <AnimateHeight duration={DURATION} height={animateHeight}>
        <div className="more-details">
          <div className="currency">
            <div className="label">{localized.Currency}</div>
            <div className="fele-content">
              {props.invoice.openAmount.currency}
            </div>
          </div>

          <div className="order-number">
            <div className="label">{localized.OrderNumberLabel}</div>
            {fetchingOrderNumbers && <Loading />}
            {!fetchingOrderNumbers && (
              <div className="fele-content">
                {displayOrderNumbers(orderNumbers)}
              </div>
            )}
          </div>

          <div className="po-number">
            <div className="label">{localized.CustomerPoNumberLabel}</div>
            {fetchingPONumbers && <Loading />}
            {!fetchingPONumbers && (
              <div className="fele-content">{poNumbers}</div>
            )}
          </div>
        </div>
      </AnimateHeight>
    );
  };

  const getDaysPastDueColor = (daysPastDue) => {
    const colorMapping = [
      { threshold: 90, color: "#C20000" },
      { threshold: 60, color: "#FD8500" },
      { threshold: 30, color: "#E1B700" },
      { threshold: 0, color: "#585858" },
    ];

    const { color } = colorMapping.find(
      ({ threshold }) => daysPastDue >= threshold
    );

    return color;
  };

  const PastDueDisplay = () => {
    const daysPastDue = props.invoice.daysPastDue;
    if (!props.invoice.isPastDue) {
      return null;
    }

    const color = getDaysPastDueColor(daysPastDue);

    return (
      <div style={{ color: color, fontWeight: "bold" }}>
        {daysPastDue} {localized[`Day(s)`]}
      </div>
    );
  };

  const renderInvoiceDetails = () => {
    const moreInvoiceDetails = renderMoreInvoiceDetails();
    const displayInvoiceNumber = renderDisplayInvoiceNumber();
    const shareViaWhatsAppLink = props.shareViaWhatsAppLink;
      const showDiscountDueDate = props.showDiscountDueDate;
    return (
      <div className="invoice-detail">
        <div className="details">
          {/* Invoice Date */}
          <div className="invoice-date">
            <div className="label"> {localized.InvoiceDateLabel} </div>
            <div className="fele-content">
              <DateDisplay
                date={props.invoice.invoiceDate}
                dateFormat={props.dateDisplayFormat}
              />
            </div>
          </div>

          {/* Due Date */}
          <div className="due-date">
            <div className="label"> {localized.DueDateLabel} </div>
            <div className="fele-content">
              <DateDisplay
                date={props.invoice.dueDate}
                dateFormat={props.dateDisplayFormat}
              />
            </div>
          </div>

          {/* Invoice Number */}
          <div data-cy="invoice-number" className="number">
            <div className="label"> {localized.InvoiceNumber} </div>
            <div className="fele-content">
              {props.InvoiceConfig.settings.UseInvoiceRefNo === true ? (
                <div className="flex">
                  <span>{formatRefNumber(props.invoice.facturaRefNo)}</span>
                </div>
              ) : (
                <div className="flex">
                  <span>
                    {props.invoice.invoiceType} {props.invoice.invoiceNumber}
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Open Amount */}
          <div className="open-amount">
            <div className="label"> {localized.OpenAmountLabel} </div>
            <div className="fele-content">
              <Money
                value={props.invoice.openAmount.amount}
                currency={props.invoice.openAmount.currency}
                country={props.selectedCustomer.address.country}
              />
            </div>
          </div>

          {/* Gross Amount */}
          <div className="gross-amount">
            <div className="label"> {localized.GrossAmountLabel} </div>
            <div className="fele-content">
              <Money
                value={props.invoice.grossAmount.amount}
                currency={props.invoice.grossAmount.currency}
                country={props.selectedCustomer.address.country}
              />
            </div>
          </div>

                {/* Discount Date */}
                {showDiscountDueDate &&
                    <div className="discount-date">
                        <div className="label"> {localized.DiscountDateLabel} </div>
                        <div className="fele-content">
                            <DateDisplay
                                date={props.invoice.discountDate}
                                dateFormat={props.dateDisplayFormat}
                            />
                        </div>
                    </div>
                }

          {/* PDF */}
          <div className="pdf d-inline-flex" style={{ fontSize: "18px" }}>
            <div className="label"> {localized.DownloadPDF} </div>
            {displayInvoiceNumber}
            {shareViaWhatsAppLink && (
              <div
                style={{ cursor: "pointer", paddingLeft: "15px" }}
                onClick={() => getPDFLink(props.invoice)}
              >
                <WhatsAppIcon />
              </div>
            )}
          </div>

          {/* Past Due */}
          {props.InvoiceConfig.settings.UsePastDue ? (
            <div className="past-due" style={{ flex: 1 }}>
              <div className="label">{localized.PastDue}</div>
              <div className="fele-content">{PastDueDisplay()}</div>
            </div>
          ) : null}

          {/* Show More */}
          <div className="action">
            <div className="button-wrapper">
              <button
                className={`button button-secondary ${
                  animateHeight === ZERO ? "closed" : ""
                }`}
                onClick={toggleAnimation}
              >
                <span>{animateText}</span>
              </button>
            </div>
          </div>
        </div>
        {moreInvoiceDetails}
      </div>
    );
  };

  const isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  const formatRefNumber = (str) => {
    if (str !== "" && str.length > 15) {
      const remaining = str.substring(4);
      const formattedStr = `${remaining.substring(0, 5)}-${remaining.substring(
        5
      )}`;
      return formattedStr;
    } else {
      return str;
    }
  };

  return isEmpty(props.invoice) ? null : (
    <div data-cy="invoice" className="invoice">
      {renderInvoiceDetails()}
    </div>
  );
}

export default withInvoiceConfig(InvoiceDetail);
