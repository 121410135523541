import React from "react";
import PropTypes from "prop-types";

const ActionButton = ({
  text,
  type,
  onClick,
  dataCy,
  disabled,
  style,
  visible,
}) => {
  const buttonClassMap = {
    "gray-outline": "button-secondary-alt",
    "green-outline": "button-alt",
    "red-outline": "button-cancel",
    "blue-outline": "button-secondary",
  };

  const buttonClass = buttonClassMap[type] || "";

  return (
    <div
      className="fele-content fele-content-responsive"
      style={{ visibility: visible ? "visible" : "hidden" }}
    >
      <div className="button-wrapper large w-100">
        <button
          data-cy={dataCy}
          className={`button ${buttonClass} text-nowrap px-5`}
          onClick={onClick}
          disabled={disabled}
          style={style}
        >
          {text}
        </button>
      </div>
    </div>
  );
};

ActionButton.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  visible: PropTypes.bool,
};

ActionButton.defaultProps = {
  type: "green-outline",
  visible: true,
};

export default ActionButton;
