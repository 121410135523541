import LocalizedStrings from 'react-localization';
import Localization from '../services/Localization';

class WarrantyLinkStrings {
    localized = new LocalizedStrings({

        en: {
            Warranty: "Warranty"
        },

        pt: {
            Warranty:"Suporte"
        },
        
        es: {
            Warranty: "Soporte"
        },

        it: {
            Warranty: "Garanzia"
        },

        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new WarrantyLinkStrings();
