import React from 'react';
import PropTypes from 'prop-types';
import AngleDown from './icons/AngleDown';
import Hierarchy from './icons/Hierarchy';
import LogoSelector from './LogoSelector';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';

export class CustomersList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openParent: -1,
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    selectShipTo = (customer, level) => {
        if (level === "expand") {
            if (customer.externalId === this.state.openParent)
                this.setMountedState({ openParent: -1 });
            else
                this.setMountedState({ openParent: customer.externalId });
        }
        else {
            if (this.props.updateCustomer) {
                this.props.updateCustomer(customer);
            }
            this.props.setOpenFromList(false);
        }
    }

    toggleSelect = () => {
        this.setMountedState({ selectIsOpen: !this.state.selectIsOpen });
    }

    displayIfNotNull = (object, property) => {
        if (object && object[property])
            return object[property];
        return ""
    }

    cityAndStateExists = (address) => {
        return address["city"] && address["state"];
    }

    isSelected = (shipTo, selected) => {
        if (!selected)
            return false;
        return selected.externalId === shipTo.externalId;
    }

    isOpenParent = (current) => {
        if (this.props.openAllParents)
            return true;

        if (!current)
            return false;

        if (current.parentExternalId)
            return current.parentExternalId.toString() === this.state.openParent;

        return current.externalId.toString() === this.state.openParent;
    }

    renderAddress = (address) => {
        return (
            <div className="address">
                {
                    address && address.lineOne &&
                    <div className="line-two">
                        {address.lineOne}
                    </div>
                }
                {
                    address && address.lineTwo &&
                    <div className="line-two">
                        {address.lineTwo}
                    </div>
                }
                <div className="city-state">
                    {`${this.displayIfNotNull(address, "city")}
                          ${this.cityAndStateExists(address) ? "," : ""} 
                          ${this.displayIfNotNull(address, "state")} 
                          ${this.displayIfNotNull(address, "postalCode")}`}
                </div>
            </div>
        )
    }

    render = () => {
        const { customers, selectedCustomer } = this.props;
        return (
            <div>
                {!customers &&
                    <div></div>
                }
                {
                    customers.length > 0 &&
                    <div></div>
                }
                {
                    customers && customers.length > 0 &&
                    customers.map((option) =>
                        <div className={`${option.children && option.children.length > 0 ? "parent-border" : "non-parent-border"} ${this.isSelected(option, selectedCustomer) ? "selected" : ""}`} key={option.externalId + '-' + option.brandCode}>
                            <div onClick={() => this.selectShipTo(option)} className={`option  ${option.children && option.children.length > 0 ? "parent" : ""}`}>
                                <div className="button-wrapper">
                                    <button data-cy="shipto-item" className="shipto-item">
                                        <div className="content">
                                            <div className="left-side">
                                                {!option.isActive && <FontAwesomeIcon icon={faUserSlash} size="xs" />}
                                                <div className="number" data-cy="shiptos-select">
                                                    {option.externalId}
                                                </div>
                                                <div className="type">
                                                    {this.props.StorefrontConfig.renderAddressType(option.addressType)}
                                                </div>
                                                <LogoSelector BrandCode={option.brandCode} />
                                            </div>
                                            <div className="right-side">
                                                <div className="name">{option.name} </div>
                                                {
                                                    option.taxId &&
                                                    <div>
                                                        <em> {option.taxId}</em>
                                                    </div>
                                                }
                                                <div>
                                                    {option.address && this.renderAddress(option.address)}
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {
                                option.children && option.children.length > 0 &&
                                <div className="button-wrapper" data-cy="child-customer-button">
                                    <button className="hierarchy-button" onClick={() => this.selectShipTo(option, 'expand')}>
                                        <span className={`icon-div angle-icon ${this.isOpenParent(option) ? "up" : " "}`} >
                                            <AngleDown onClick={() => this.openParentOption(option.externalId)} />
                                        </span>
                                        <div className='branches-text'>
                                            Locations
                                        </div>
                                        <span className='icon-div hier-icon'>
                                            <Hierarchy />
                                        </span>
                                    </button>
                                </div>
                            }
                            {option.children && option.children.map((childOption) =>
                                <div key={'c' + childOption.externalId + '-' + childOption.brandCode} onClick={() => this.selectShipTo(childOption)} className={`option child ${!this.isOpenParent(childOption) ? "hide" : " "} ${this.isSelected(childOption, selectedCustomer) ? "selected" : ""} `}>
                                    <div className="button-wrapper ">
                                        <button data-cy="shipto-item" className="shipto-item">
                                            <div className="child content">
                                                <div className="left-side">
                                                    {!childOption.isActive && <FontAwesomeIcon icon={faUserSlash} size="xs" />}
                                                    <div className="number" data-cy="shiptos-select">
                                                        {childOption.externalId}
                                                    </div>
                                                    <div className="type">
                                                        {this.props.StorefrontConfig.renderAddressType(childOption.addressType)}
                                                    </div>
                                                    <LogoSelector BrandCode={childOption.brandCode} />
                                                </div>
                                                <div className="right-side">
                                                    <div className="name">{childOption.name} </div>
                                                    {
                                                        childOption.taxId &&
                                                        <div className="number">
                                                            <em>{childOption.taxId}</em>
                                                        </div>
                                                    }
                                                    <div>
                                                        {childOption.address && this.renderAddress(childOption.address)}
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }
            </div>
        )
    }

}

CustomersList.propTypes = {
    updateCustomer: PropTypes.func,
    customers: PropTypes.array,
}

export default (withStorefrontConfig(CustomersList))