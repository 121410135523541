import React from 'react';
import PropTypes from 'prop-types';
import DateDisplay from '../DateDisplay';
import Loading from '../Loading';
import QuoteService from '../../services/QuoteService';
import Api from '../../services/Api';
import StatusHelper from '../../helpers/StatusHelper';
import Notifications from '../../services/Notifications';
import OrderAckIcon from '../icons/OrderAckIcon';
import { withRouter } from 'react-router-dom';
import ModalPopup from '../../services/ModalPopup';
import ConvertToOrderModal from '../quotes/ConvertToOrderModal';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';

export class Quotes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            convertingQuote: false,
            convertedQuote: false,
            documentNumber: null,
            convertModalConfirmationOptions: {
                poNumber: '',
                comments: '',
                show: false,
                fetching: false,
                message: '',
                callback: () => { }
            },
            confirmationOptions: {
                show: false,
                message: '',
                callback: () => { }
            }
        }
    }

    componentDidMount = () => { }

    commentsUpdated = (comment) => {
        this.setState({ comments: comment });
    }

    poNumberUpdated = (referenceNumber) => {
        this.setState({ poNumber: referenceNumber });
    }

    convertQuoteToOrder = (documentNumber, documentCompany, documentType, poNumber, customerId, implementationId) => {

        
        this.setState({
            fetching: true,
            convertModalConfirmationOptions: {
                show: true,
                message: this.props.localized.ConvertToOrderWarning,
                callback: () => { this.handleConvertToOrderCOnfirmation(documentNumber, documentCompany, this.state.comments, this.state.poNumber, customerId, implementationId) }
            }
        });

        Api.fetch(`/api/orders/getCommentsBySale/${documentNumber}/${documentCompany}/${documentType}`).then(response => {
            this.setState({
                fetching: false,
                poNumber: poNumber,
                comments: response
            });
        });
    }

    viewQuote = (quote) => {
        this.props.history.push({ pathname: "/quote/" + quote.orderId + '/' + quote.orderCompany + '/' + quote.orderType });
    }

    editQuote = (quoteId, orderCompany) => {
        let key = `${quoteId}-${orderCompany}000SQ`;
        this.props.history.push(`/quote/edit/${key}`);
    }

    handleQuoteConvertResponse = (res) => {
        if (res) {
            Notifications.message(this.props.localized.YourQuoteIsBeingConverted);
            this.setState({
                documentNumber: res.documentNumber,
                convertingQuote: false,
                convertedQuote: true
            });
            this.props.hideQuotesFilter(true);
            this.props.history.push({ pathname: '/cart/confirmation', state: { IsQuote: false, DocumentNumber: res.documentNumber } });
        }
        else {
            Notifications.error("Order could not be placed.Please contact sales representative");
            this.setState({
                convertingQuote: false
            });
        }
    }

    getPDF = (orderNumber, orderType) => {
        Api.downloadFileName(`/api/orders/GetPDF/${this.props.selectedCustomer.billToId}/${orderNumber}/${orderType}`, `${this.props.selectedCustomer.billToId}-${orderNumber}${orderType}`).then(this.handleError);
    }

    handleError = (res) => {
        if (!res)
            Notifications.error(this.props.localized.NoPDFMessage);
    }

    rejectQuoteConfirmation = (documentNumber, documentCompany, customerId, implementationId) => {
        this.setState({
            confirmationOptions: {
                show: true,
                message: this.props.localized.QuoteRejectWarning,
                callback: () => { this.handleRejectClick(documentNumber, documentCompany, customerId, implementationId) }
            }
        });
    }

    handleRejectClick = (documentNumber, documentCompany, customerId, implementationId) => {
        this.hideConfirmation();
        this.props.setFetching(true);
        return QuoteService.rejectQuote(documentNumber, documentCompany, customerId, implementationId).then(this.handleQuoteRejectResponse);
    }

    handleQuoteRejectResponse = (response) => {
        if (response) {
            this.props.fetchOrders();
            Notifications.message(this.props.localized.QuoteRejected);
        }
        else {
            Notifications.error(this.props.localized.QuoteNotRejected);
            this.props.setFetching(false);
        }
    }

    hideConfirmation = () => {
        this.setState({
            confirmationOptions: {
                show: false
            },
            convertModalConfirmationOptions: {
                show: false
            }
        });
    }

    handleConvertToOrderCOnfirmation = (documentNumber, documentCompany, comments, poNumber, customerId, implementationId) => {

        const convertQuoteToOrderData = {
            documentNumber: documentNumber,
            documentCompany: documentCompany,
            customerPO: this.state.poNumber,
            comments: this.state.comments,
            customerId: customerId,
            implementationId: implementationId
        }

        this.hideConfirmation();
        this.setState({
            convertingQuote: true
        });
        
        QuoteService.convertQuoteToOrder(convertQuoteToOrderData).then(this.handleQuoteConvertResponse);
    }

    render() {
        const { convertedQuote, convertingQuote } = this.state;
        const { quotes, fetching } = this.props;
        const isBeta = this.props.currentUser.userFlags.includes("Beta");
        return (
            <React.Fragment>
                {
                    !convertedQuote &&
                    <div className="fele-quote-history">
                        <div className="history-header">
                            <div className="date">
                                <div className="label">{this.props.localized.QuoteDate}</div>
                            </div>
                            <div data-cy="order-number" className="order-number">
                                <div className="label">{this.props.localized.QuoteNumber}</div>
                            </div>
                            <div className="status">
                                <div className="label">{this.props.localized.Status}</div>
                            </div>
                            <div className="po-number">
                                <div className="label">{ this.props.localized.PONumber}</div>
                            </div>
                            {
                                this.props.downloadQuotePDF &&
                                <div className="pdf">
                                    <div className="label">{this.props.localized.PDF}</div>
                                </div>
                            }
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            {
                                isBeta &&
                                <div>&nbsp;</div>
                            }
                        </div>
                        {
                            fetching &&
                            <Loading type="brand" />
                        }
                        {
                            !fetching && (quotes && quotes.length === 0) &&
                            <div data-cy="order-details" className="details no-results">
                                {this.props.localized.NoQuotesFound}
                            </div>
                        }
                        {
                            !fetching && (quotes && quotes.length > 0) &&
                            <div>
                                <div data-cy="history" className="history">
                                    {quotes.map((item, key) => renderOrder(item, key, this.props.localized,
                                        this.props.selectedCustomer.id,
                                        this.props.selectedCustomer.companyCode,
                                        this.convertQuoteToOrder,
                                        convertingQuote,
                                        this.editQuote,
                                        isBeta,
                                        this.getPDF,
                                        this.props.downloadQuotePDF,
                                        this.props.editQuoteEnabled,
                                        this.props.convertQuoteEnabled,
                                        this.props.rejectQuoteEnabled,
                                        this.viewQuote,
                                        this.rejectQuoteConfirmation,
                                        this.props.StorefrontConfig.settingsList.DateDisplayFormat,
                                        this.props.StorefrontConfig.storefront.implementationId
                                    )
                                    )}
                                </div>
                                {this.props.renderPaging()}
                            </div>
                        }
                    </div>
                }

                <ModalPopup {...this.state.confirmationOptions} onHide={this.hideConfirmation} />
                <ConvertToOrderModal {...this.state.convertModalConfirmationOptions}
                    poNumberUpdated={this.poNumberUpdated}
                    commentsUpdated={this.commentsUpdated}
                    comments={this.state.comments}
                    fetching={this.state.fetching}
                    poNumber={this.state.poNumber}
                    requiredPO={true}
                    maxPOLength={this.props.StorefrontConfig.settingsList.MaxPOLength}
                    onHide={this.hideConfirmation}
                    localized={this.props.localized} />
            </React.Fragment>
        );
    }
}

function renderOrder(quote, index, localized, customerId, companyCode, convertQuoteToOrder, convertingQuote, editQuote,
    isBeta, getPDF, downloadQuotePDF, editQuoteEnabled, convertQuoteEnabled, rejectQuoteEnabled, viewQuote,
    rejectQuote, dateDisplayFormat, implementationId) {
    return (
        <div key={index} className="details" >
            <div className="date">
                <div className="label">{localized.QuoteDate}</div>
                <div className="fele-content">
                    <DateDisplay date={quote.submittedOn} dateFormat={dateDisplayFormat} />
                </div>
            </div>
            <div className="order-number">
                <div className="label">{localized.QuoteNumber}</div>
                <div className="fele-content">{quote.orderId + " " + quote.orderType}</div>
            </div>
            <div className="status">
                <div className="label">{localized.Status}</div>
                <div className="fele-content">{StatusHelper.formatQuoteStatus(quote.quoteStatus, localized)}</div>
            </div>
            <div className="po-number">
                <div className="label">{localized.PONumber}</div>
                <div className="fele-content">{ quote.referenceNumber}</div>
            </div>
            {
                downloadQuotePDF &&
                <div className="pdf">
                    <div style={{ cursor: 'pointer' }} onClick={() => { getPDF(quote.orderId, quote.orderType) }}>
                        <OrderAckIcon />
                    </div>
                </div>
            }
            <div className="quote-history-buttons">
                {
                    rejectQuoteEnabled &&
                    <div className="reject-order">
                        <div className="button-wrapper">
                            <button className="button button-secondary" id="reject-quote"
                                    disabled={quote.convertedToOrder}
                                    onClick={() => rejectQuote(quote.orderId, companyCode, customerId, implementationId)}
                            >
                                {localized.Reject}
                            </button>
                        </div>
                    </div>
                }
                <div className="view-order">
                    <div className="button-wrapper">
                        <button data-cy="view-order" className="button button-secondary" onClick={() => viewQuote(quote)}>
                            {localized.ViewDetails}
                        </button>
                    </div>
                </div>
                {
                    !convertingQuote && convertQuoteEnabled &&
                    <div className="convert-quote">
                        <div className="button-wrapper">
                            <button data-cy="convert-quote" id="convert-quote" className="button button-secondary" disabled={quote.convertedToOrder}
                                    onClick={() => convertQuoteToOrder(quote.orderId, companyCode, quote.orderType, quote.referenceNumber, customerId, implementationId)}>
                                {localized.ConvertToOrder}
                            </button>
                        </div>
                    </div>
                }
                {
                    editQuoteEnabled &&
                    <div className="edit-quote">
                        <div className="button-wrapper">
                            <button data-cy="edit-quote" className="button button-secondary" disabled={quote.convertedToOrder} onClick={() => editQuote(quote.orderId, quote.orderCompany)}>
                                {localized.EditQuote}
                            </button>
                        </div>
                    </div>
                }
                {
                    convertingQuote &&
                    <Loading />
                }
            </div>
        </div>
    );
}

Quotes.propTypes = {
    fetchOrders: PropTypes.func,
    setFetching: PropTypes.func,
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
    quotes: PropTypes.arrayOf(PropTypes.object),
    fetching: PropTypes.bool,
    downloadQuotePDF: PropTypes.bool,
    renderPaging: PropTypes.func,
    hideQuotesFilter: PropTypes.func,
    editQuoteEnabled: PropTypes.bool,
    convertQuoteEnabled: PropTypes.bool,
    rejectQuoteEnabled: PropTypes.bool
}

export default withRouter(withStorefrontConfig(Quotes));