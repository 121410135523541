import Api from './Api';

class Localization {
    _locale = null;
    _language = null;
    _callbacks = [];

    get language() {
        return this._language;
    }

    get locale() {
        return this._locale;
    }

    setLocale(locale) {
        this._locale = locale.replace('-', '_');
        this.set(this._locale.substring(0, 2));
    }

    set(language) {
        document.getElementsByTagName('html')[0].setAttribute('lang', language);
        this._language = language;
        this.updateSubscribers();
    }

    subscribe(callback) {
        this._callbacks.push(callback);
    }

    updateSubscribers() {
        this._callbacks.forEach(cb => cb());
    }

    getComponentLabels(component, locale, storefront) {
        return Api.fetch(`/api/Labels/GetComponentLabels/${component}/${locale}/${storefront}`)
            .then(res => { return res; })
            .catch(this.handleError);
    }

    handleError = (e) => {
        console.log("Failed to get translations");
        console.error(e);
    }
}

export default new Localization();
