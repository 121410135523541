import React from 'react';
import PropTypes from 'prop-types';

export default class HistoryItemComments extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { localized } = this.props;
        return (
            <div>
                <div className="left-side">
                    <div>
                        <div className="label">{localized.Comments}:</div>
                        <div className="content">{this.props.comments}</div>
                    </div>
                </div>
            </div>
        );
    }
}

HistoryItemComments.propTypes = {
    comments: PropTypes.string,
}