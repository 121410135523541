import React from 'react';
import Strings from './ProductSearchResults.Strings';
import Loading from '../Loading';
import Api from '../../services/Api';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MessageStandard from '../MessageStandard';
import Product from './Product';
import Localization from '../../services/Localization';
import { withSelectedCustomer } from '../../hooks/withSelectedCustomer';
import { withProductConfig } from '../../hooks/ProductConfigContext'


export class ProductDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false,
            sku: "",
            product: null,
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.setSku(this.props);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setSku(this.props);
        }
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }


    setSku = (props) => {
        if (props && props.sku)
            this.setMountedState({ sku: props.sku }, this.fetchResults)
    }

    fetchResults = () => {
        if (!this.state.fetching) {
            this.startFetching();
            if (this.props.selectedCustomer) {
                let brandCode = this.props.selectedCustomer.brandCode;
                let companyCode = this.props.selectedCustomer.companyCode;
                let customerId = this.props.selectedCustomer.id;
                let storefrontId = this.props.currentUser.storefrontId;
                let url = `/api/products/${this.state.sku}/${customerId}/${companyCode}/${storefrontId}`;
                if (brandCode) {
                    url = `${url}/${brandCode}`;
                }
                url = `${url}?locale=${Localization.language}`
                Api.fetch(url)
                    .then(this.handleSearchResponse)
                    .catch(this.handleError);
            }
        }
    }

    startFetching = () => {
        this.setMountedState({ fetching: true, product: null })
    }

    stopFetching = () => {
        this.setMountedState({ fetching: false });
    }

    handleSearchResponse = (response) => {
        if (response) {
            this.setMountedState({ product: response }, this.stopFetching);
        } else {
            this.setMountedState({ product: null }, this.stopFetching);
        }
    }

    handleError = () => {
        this.setMountedState({ error: true }, this.stopFetching);
    }

    render() {
        const { fetching, sku, error, product } = this.state;

        if (sku.length === 0)
            return null;
        const localized = this.props.productConfig.labels;

        return (
            <div style={{ padding: "2.5rem" }} className="product-details">
                {
                    fetching &&
                    <Loading type="brand" />
                }

                {
                    !fetching && error &&
                    <div className="error">
                        <MessageStandard line1={localized.ErrorRetrievingItemsMessage} />
                    </div>
                }
                {
                    !fetching && !product &&
                    <div className="no-product-found">
                        <MessageStandard line1={localized.NoItemMessage} />
                    </div>
                }
                {
                    !fetching && product &&
                    <Product product={product} fetchPriceOnLoad={true} showLink={false} selectedCustomer={this.props.selectedCustomer} />
                }
            </div>
        );
    }
}

Product.propTypes = {
    selectedCustomer: PropTypes.object
}
function mapStateToProps(state) {
    return {
        currentUser: state.user.currentUser
    };
}

export default connect(mapStateToProps)(withSelectedCustomer(withProductConfig(ProductDetail)));