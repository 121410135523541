import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import UserService from '../../services/UserService';
import Notifications from '../../services/Notifications';
import Loading from '../Loading';
import * as userActions from '../../actions/userActions';

export class SalesTerritorySelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSalesTerritory: null,
            salesTerritoryInput: "",
            displayResults: false,
            fetching: true,
            salesTerritories: [],
            userSalesTerritories: []
        };
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.user)
            this.setMountedState({ user: this.props.user }, this.fetchUserSalesTerritories);
    }

    componentDidUpdate() {
        if (this.props.user !== this.state.user)
            this.setMountedState({ user: this.props.user }, this.fetchUserSalesTerritories);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    populate = () => {
        this.setMountedState({ fetching: true, salesTerritories: null, displayResults: true },
            this.fetchingAvailableSalesTerritories
        );
    }
    fetchingAvailableSalesTerritories = () => {
        UserService.getAvailableSalesTerritories(this.props.user.id, this.state.salesTerritoryInput, this.loadAvailableSalesTerritories);
    }
    fetchUserSalesTerritories = () => {
        UserService.getUserSalesTerritories(this.state.user.id, this.loadUserSalesTerritories);
    }
    loadUserSalesTerritories = (data) => {
        if (this.mounted && data)
            this.setMountedState({ userSalesTerritories: data, fetching: false, salesTerritoryInput: "" }, this.props.userActions.LoadInitialUserCustomers());
    }
    loadAvailableSalesTerritories = (response) => {
        if (response) {
            this.setMountedState({ salesTerritories: response, fetching: false });
        }
    }
    clearAvailableSalesTerritories = () => {
        this.setMountedState({ salesTerritories: null, selectedSalesTerritory: null, displayResults: false });
    }
    submitSalesTerritory = (e) => {
        if (this.state.selectedSalesTerritory) {
            UserService.updateSalesTerritory(this.state.selectedSalesTerritory, this.props.user.id, this.fetchUserSalesTerritories);
        }
    }
    populateInput = (item) => {
        this.setMountedState({ selectedSalesTerritory: item, salesTerritoryInput: item, displayResults: false, salesTerritories: null });
    }

    setSalesTerritory = (e) => {
        const value = e.currentTarget.value;
        this.setMountedState({ salesTerritoryInput: value }, this.populate());
    }
    removeSalesTerritory = (e, localized) => {
        Notifications.confirmation(localized.ConfirmRemoveSalesTerritoryMessage, () => { UserService.removeSalesTerritory(e, this.props.user.id, this.fetchUserSalesTerritories) });

    }
    render() {
        const localized = this.props.localized;
        if (!this.props.user)
            return null
        return (
            <React.Fragment>
                <div className="add-sales-territory" >
                    <div className="label">{localized.SalesTerritory}</div>
                    <div className="flex" ref={node => (this.node = node)}>
                        {displaySalesTerritoryInput(
                            this.state.salesTerritoryInput,
                            this.setSalesTerritory,
                            this.state.displayResults,
                            this.populateInput,
                            this.state.salesTerritories,
                            this.state.fetching,
                            localized
                        )}

                        {displaySalesTerritorySubmit(this.submitSalesTerritory, localized)}
                    </div>
                </div>
                {displayUserSalesTerritories(this.state.userSalesTerritories, this.state.fetching, this.removeSalesTerritory, localized)}
            </React.Fragment>
        );
    }
}
function displaySalesTerritorySubmit(submitSalesTerritory, localized) {
    return (
        <div className="button-wrapper">
            <button data-cy="add-sales-territory" className="button add-sales-territory" onClick={submitSalesTerritory}>{localized.Add}</button>
        </div>
    )
}

function displaySalesTerritoryInput(salesTerritoryInput, setSalesTerritory, displayResults, populateInput, salesTerritories, fetching, localized) {
    return (
        <div className="input-wrapper">
            <input type="text" onChange={setSalesTerritory} value={salesTerritoryInput} data-cy="user-sales-territory-input" />
            {
                (salesTerritoryInput.length > 1) &&
                displaySearchResults(displayResults, salesTerritories, populateInput, fetching, localized)
            }
        </div>
    )
}

function displaySearchResults(displayResults, salesTerritories, populateInput, fetching, localized) {
    return (
        <div className={`results ${displayResults ? "active" : ""}`}>
            {
                fetching && !salesTerritories &&
                <button data-cy="sales-territories-results" className="result">
                    <Loading />
                </button>
            }

            {
                !fetching && salesTerritories && salesTerritories.map((s) =>
                    <button key={s.code} className="result" onClick={() => populateInput(s.code)} data-cy="usersales-territories-result">
                        {s.code}
                    </button>
                )
            }
            {
                !fetching && (!salesTerritories || (salesTerritories.length === 0)) && displayResults &&
                <button className="result">{localized.NoResultsFoundMessage}</button>
            }
        </div>
    )
}

function displayUserSalesTerritories(userSalesTerritories, fetching, removeSalesTerritory, localized) {
    return (
        <div className="sales-territories-list" data-cy="user-sales-territories-list">
            <div className="label">{localized.SalesTerritoriesLabel}:</div>
            <div className="list">
                {
                    !userSalesTerritories && fetching &&
                    <div className="fele-container"><Loading /></div>
                }
                {
                    userSalesTerritories && userSalesTerritories.length === 0 && !fetching &&
                    <div>{localized.NoSalesTerritoriesMessage}</div>
                }
                {
                    userSalesTerritories && !fetching &&
                    userSalesTerritories.map((s) =>
                        <div className="sales-territory flex space-between" key={s.code}>
                            <div className="field-wrapper flex">
                                <div className="number">{s.code} </div>
                            </div>
                            <div className="button-wrapper">
                                <button className="remove" data-cy="sales-territory-remove" onClick={() => { removeSalesTerritory(s.code, localized) }}> <span>X </span><span>{localized.Remove}</span></button>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

SalesTerritorySelector.propTypes = {
    userActions: PropTypes.object
}


function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps
)(SalesTerritorySelector);