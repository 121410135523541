import React from "react";
import PropTypes from "prop-types";
import InfoHover from "./../../icons/InfoHover";

const FormSection = ({
  label,
  subLabel,
  children,
  required,
  datacy,
  className,
  toolTipMsg,
}) => {
  return (
    <div className={`${className} section-form`} data-cy={datacy}>
      <label className="label">
        {label}
        {required ? "*" : ""}

        {subLabel ? (
          <span
            className="ps-2"
            style={{
              fontWeight: 400,
              fontFamily: '"Gotham XNarrow SSm A", "Gotham XNarrow SSm B"',
            }}
          >
            {" "}
            {subLabel}
          </span>
        ) : null}

        {toolTipMsg ? (
          <>
            {"  "}
            <span className="ps-2">
              <InfoHover id="warranty-tooltip" toolTipMsg={toolTipMsg} />
            </span>
          </>
        ) : null}
      </label>

      <div className="fele-content">
        <div className="input-wrapper">{children}</div>
      </div>
    </div>
  );
};

FormSection.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
  datacy: PropTypes.string,
  className: PropTypes.string,
  toolTipMsg: PropTypes.string,
};

FormSection.defaultProps = {
  required: false,
  className: "claim-submission-text",
};

export default FormSection;
