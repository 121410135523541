import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import ClaimLine from "./ClaimLine";
import { CSSTransition } from "react-transition-group";
import useMediaQuery from "@mui/material/useMediaQuery";

const ClaimsSidebar = ({
  claimLines,
  uploadClaimAttachments,
  removeClaimItem,
  loadClaimLineItemForEdit,
  isEditMode,
  localized,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isEditMode && claimLines.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [claimLines, isEditMode]);

  const isTablet = useMediaQuery("(max-width: 768px)");

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="sidebar-transition"
      unmountOnExit
    >
      <Col
        md={4}
        className={`${
          isTablet ? "border-top pt-5 mt-5" : "border-start px-4 ms-4"
        } border-black`}
      >
        <Typography
          variant="h3"
          className="mb-2 fw-bold fs-1"
          style={{
            fontFamily: '"Gotham XNarrow SSm A", "Gotham XNarrow SSm B"',
          }}
        >
          {localized.ItemsAddedToClaim}
        </Typography>

        <div className="claims-sidebar-content">
          {claimLines.map((claimLine) => (
            <ClaimLine
              key={claimLine.key}
              claimLine={claimLine}
              uploadFiles={uploadClaimAttachments}
              removeClaimItem={removeClaimItem}
              loadClaimLineItemForEdit={loadClaimLineItemForEdit}
            />
          ))}
        </div>
      </Col>
    </CSSTransition>
  );
};

export default ClaimsSidebar;
