import * as React from 'react';
import { useState, useEffect } from 'react';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import storefrontService from '../services/Storefront';
import Localization from '../services/Localization';
import * as Components from '../resources/Components';

const WarrantyConfigContext = React.createContext();

function WarrantyConfigProvider({ children, ...props }) {

    const [warrantyConfigLoaded, setWarrantyConfigLoaded] = useState(false);
    const [labels, setLabels] = useState({});
    const [settings, setSettings] = useState(props.settings || []);
    const [childComponents, setChildComponents] = useState(props.childComponents || {});

    // get current locale
    const { locale } = useStorefrontSettings();

    // might have to just make this a parameter that gets passed in
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);

    useEffect(() => {
        getWarrantyConfigSettings();
    }, []);

    useEffect(() => {
        Localization.getComponentLabels(Components.WARRANTY, locale, storefrontNumber)
            .then(res => setLabels(res))
    }, [locale]);

    const getWarrantyConfigSettings = () => {
        storefrontService.getWarrantySettings(storefrontNumber).then((res) => {
            setSettings(res.settings || []);
            setChildComponents(res.childComponents || []);
            setWarrantyConfigLoaded(true);
        });
    }

    return (
        <WarrantyConfigContext.Provider
            value={{
                settings,
                childComponents,
                warrantyConfigLoaded,
                labels
            }}
        >
            {children}
        </WarrantyConfigContext.Provider >
    );
}

function useWarrantyConfig() {
    const context = React.useContext(WarrantyConfigContext);
    if (context === undefined) {
        throw new Error('useWarrantyConfig must be used within a WarrantyConfigContext');
    }

    return context;
}

function withWarrantyConfig(Component) {
    return function WrappedComponent(props) {
        const WarrantyConfig = useWarrantyConfig();
        return <Component {...props}
            WarrantyConfig={WarrantyConfig}
        />;
    }
}

export { WarrantyConfigProvider, useWarrantyConfig, withWarrantyConfig }