import React from 'react';

export default class Warranty extends React.Component {
    render() {
        return (
            <div className="icon warranty">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
                    <path id="Path_35" data-name="Path 35" d="M17.12,10.606,14.762,8.973,14.533,6.12a.54.54,0,0,0-.193-.376.578.578,0,0,0-.4-.128l-2.853.266L9.074,3.844a.571.571,0,0,0-.395-.165.548.548,0,0,0-.395.165L6.275,5.881,3.422,5.615a.55.55,0,0,0-.6.5L2.6,8.973.238,10.606a.559.559,0,0,0-.229.358.547.547,0,0,0,.092.413l1.651,2.33L.991,16.469a.568.568,0,0,0,.055.422.536.536,0,0,0,.339.257l2.771.725,1.193,2.606a.528.528,0,0,0,.312.284.52.52,0,0,0,.422-.018L8.67,19.515l2.587,1.229a.536.536,0,0,0,.239.055.479.479,0,0,0,.193-.037A.558.558,0,0,0,12,20.478l1.193-2.606,2.771-.725a.536.536,0,0,0,.339-.257.562.562,0,0,0,.055-.422L15.6,13.707l1.661-2.34a.547.547,0,0,0,.092-.413A.507.507,0,0,0,17.12,10.606Zm-2.569,2.679a.563.563,0,0,0-.083.468l.679,2.477-2.486.651a.563.563,0,0,0-.358.3l-1.073,2.33-2.321-1.1a.544.544,0,0,0-.477,0l-2.321,1.1-1.073-2.33a.586.586,0,0,0-.358-.3L2.193,16.23l.679-2.477a.568.568,0,0,0-.083-.468L1.321,11.193l2.11-1.459a.567.567,0,0,0,.239-.4l.211-2.56,2.56.239a.525.525,0,0,0,.44-.165l1.8-1.835,1.807,1.826a.566.566,0,0,0,.44.165l2.56-.239.211,2.56a.523.523,0,0,0,.239.4l2.11,1.459Z" transform="translate(0 -3.679)" />
                </svg>
            </div>
        );
    }
}
