import * as React from 'react';
import { useState, useEffect } from 'react';
import storefrontService from '../services/Storefront';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import Localization from '../services/Localization';
import * as Components from '../resources/Components';

const InvoiceConfigContext = React.createContext();

function InvoiceConfigProvider({ children, ...props }) {

    // have all the config settings here.
    const [invoiceConfigLoaded, setInvoiceConfigLoaded] = useState(false);
    const [settings, setSettings] = useState(props.settings || []);
    const [childComponents, setChildComponents] = useState(props.childComponents || {});
    const [labels, setLabels] = React.useState({});

    // get current locale
    const { locale } = useStorefrontSettings();

    // might have to just make this a parameter that gets passed in
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);

    // useEffect?
    useEffect(() => {
        getInvoiceConfigSettings();
    }, []);

    useEffect(() => {
        Localization.getComponentLabels(Components.INVOICE_HISTORY, locale, storefrontNumber)
            .then(res => setLabels(res))
    }, [locale]);

    // fetchSettings
    const getInvoiceConfigSettings = () => {
        storefrontService.getInvoiceSettings(storefrontNumber).then((res) => {
            setSettings(res.settings || []);
            setChildComponents(res.childComponents || []);
            setInvoiceConfigLoaded(true);
        });
    }

    return (
        <InvoiceConfigContext.Provider
            value={{
                settings,
                childComponents,
                invoiceConfigLoaded,
                labels,
            }}
        >
            {children}
        </InvoiceConfigContext.Provider >
    );
}

function useInvoiceConfig() {
    const context = React.useContext(InvoiceConfigContext);
    if (context === undefined) {
        throw new Error('useInvoiceConfig must be used within a InvoiceConfigContext');
    }

    return context;
}

function withInvoiceConfig(Component) {
    return function WrappedComponent(props) {
        const InvoiceConfig = useInvoiceConfig();
        return <Component {...props}
            InvoiceConfig={InvoiceConfig}
        />;
    }
}

export { InvoiceConfigProvider, useInvoiceConfig, withInvoiceConfig }