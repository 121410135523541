import React from 'react';
import { withRouter } from 'react-router-dom';
import Money from '../Money';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';

const OrderPayment = props => {
    const salesHistoryConfig = useSalesHistoryConfig();
    if (!props.orderDetails) {
        return null;
    }
    else {
        return (
            <div data-cy="history-payments" className="history-payments">
                <div className="fele-header">{props.localized.Payments}</div>
                <div className="billing-address">
                    <div className="label">{props.localized.BillingAddress}</div>
                    <address className="fele-content">
                        <div className="name">{props.billTo.name}</div>
                        <div className="line1">{props.billTo.lineOne}</div>
                        <div className="line2">{props.billTo.lineTwo}</div>
                        <div className="cityStateZip">{props.billTo.city}, {props.billTo.state} {props.billTo.postalCode}</div>
                        <div className="country">{props.billTo.country}</div>
                    </address>
                </div>

                <div className="payment-summary">
                    <div className="flex">
                        <div className="label">{props.localized.SubtotalOfProduct}</div>
                        <div className="fele-content subtotal">
                            <Money value={props.orderDetails.productTotal} currency={props.currency} country={props.selectedCustomer.address.country} />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="label">{props.localized.TotalFreight}</div>
                        <div className="fele-content freight">
                            <Money value={props.orderDetails.totalFreight} currency={props.currency} country={props.selectedCustomer.address.country} />
                        </div>
                    </div>
                    {salesHistoryConfig.childComponents.AdditionalLineTaxes.shown &&
                        <div className="flex">
                            <div className="label">{props.localized.ExtendedLineTaxesTotal}</div>
                            <div className="fele-content tax">
                                <Money value={props.additionalTaxes} currency={props.currency} country={props.selectedCustomer.address.country} />
                            </div>
                        </div>
                    }
                    {props.hideOrderTaxLine !== true &&
                        <div className="flex">
                            <div className="label">{props.localized.TotalTax}</div>
                            <div className="fele-content tax">
                                <Money value={props.orderDetails.totalTax} currency={props.currency} country={props.selectedCustomer.address.country} />
                            </div>
                        </div>
                    }
                    
                    <div className="flex payment-total">
                        <div className="label">{props.localized.OrderTotal}</div>
                        <div className="fele-content order-total">
                            <Money value={props.orderDetails.productTotal + props.orderDetails.totalFreight + props.additionalTaxes + props.orderDetails.totalTax} currency={props.currency} country={props.selectedCustomer.address.country} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(OrderPayment);