import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import { useWarrantyConfig } from '../../hooks/WarrantyConfigContext';
import ClaimRecord from './ClaimRecord';
import * as Roles from '../../resources/UserRole';

const Claims = props => {
    const { labels: localized } = useWarrantyConfig();

    const { claims, fetching, renderPaging, setClaimApproval, statusCodes, claimsStatus, roles } = props;

    return (
        <div className="fele-claim-history">
            <div className="history-header">
                <div className="order-number">
                    <div className="label">{localized.ClaimNumber}</div>
                </div>
                <div className="order-number">
                    <div className="label">{localized.ItemNumber}</div>
                </div>
                <div className="item-description">
                    <div className="label">{localized.SerialNumber}</div>
                </div>
                <div className="date">
                    <div className="label">{localized.ClaimDate}</div>
                </div>
                <div className="status">
                    <div className="label">{localized.Status}</div>
                </div>
                <div className="order-number">
                    <div className="label">{localized.ReferenceNumber}</div>
                </div>
                <div className="fast-track">
                    <div className="label">{localized.FastTrack}</div>
                </div>
                {roles.includes(Roles.CAN_APPROVE_REJECT_CLAIM) &&
                    <div className="claim-action">
                        <div className="label">{localized.Action}</div>
                    </div>
                }

                <div className="rma">
                    <div className="label">{localized.RMA}</div>
                </div>

                <div className="order-number">
                    <div className="label">{"Comment"}</div>
                </div>
            </div>
            {
                fetching &&
                <Loading type="brand" />
            }
            {
                !fetching && (claims && claims.length === 0) &&
                <div data-cy="claim-details" className="details no-results">
                    {localized.NoClaimsFound}
                </div>
            }
            {
                !fetching && (claims && claims.length > 0) &&
                <div>
                    <div data-cy="history" className="history">
                        {
                            claims.map((item, key) => <ClaimRecord claim={item} setClaimApproval={setClaimApproval} statusCodes={statusCodes} key={key} claimsStatus={claimsStatus} roles={roles} />)
                        }
                    </div>
                    {renderPaging()}
                </div>
            }
        </div>
    );
}

Claims.propTypes = {
    currentUser: PropTypes.object,
    claims: PropTypes.arrayOf(PropTypes.object),
    fetching: PropTypes.bool,
    renderPaging: PropTypes.func
}
export default Claims;