import React from 'react';

export default class FeedbackRightArrow extends React.Component {
    render() {
        return (
            <div className="icon feedback-right-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.477 14.122">
                    <path id="Shape" d="M4.522,7.061.237,1.722A1.191,1.191,0,0,1,.237.3.7.7,0,0,1,1.382.3L6.24,6.348a1.191,1.191,0,0,1,0,1.427L1.382,13.827a.7.7,0,0,1-1.145,0,1.191,1.191,0,0,1,0-1.427Z" />
                </svg>
            </div>
        );
    }
}