import * as React from 'react';
import { useState, useEffect } from 'react';
import customerService from '../services/Customer';

const CustomerContext = React.createContext();

function CustomerProvider({ children, ...props }) {

    const [customerList, setCustomerList] = useState(props.customers || []);
    const [currentCustomer, setCurrentCustomer] = useState(props.defaultCustomer);//getDefaultCustomer(getDefaultCustomer) old code function as a arg which shouldn't work before as well(bug)

    // useEffect?
    useEffect(() => {
        customerService.fetchCustomers()
            .then(res => {
                setCustomerList(res);
                getDefaultCustomer();
            });
    }, []);

    function getDefaultCustomer() {

        if (currentCustomer === null || currentCustomer === {} || currentCustomer === undefined) {  
               // This check no longer needed keep it for now.
                let curCustomer = customerService.storedSelectedCustomer();
                if (curCustomer) {
                    let exists = null;

                    customerList.forEach(function (c) {
                        if (c.id === curCustomer.id) {
                            exists = c;
                            return;
                        }
                        if (c.children.length > 0) {
                            c.children.forEach(function (child) {
                                if (child.id === curCustomer.id) {
                                    exists = child;
                                    return;
                                }
                            });
                            if (exists !== null) return;
                        }
                    });

                    if (exists) {
                        return curCustomer;
                    }
                } else {
                    return customerList[0] || {};
                }
            }
    }

    const fetchCustomers = React.useCallback(async () => {
        customerService.fetchCustomers()
            .then(res => {
                setCustomerList(res);
            });
    }, []);

    return (
        <CustomerContext.Provider
            value={{
                customerList,
                currentCustomer,
                setCurrentCustomer,
                fetchCustomers,
                setCustomerList,
            }}
        >
            {children}
        </CustomerContext.Provider>
    );
}

function useCustomers() {
    const context = React.useContext(CustomerContext);
    if (context === undefined) {
        throw new Error('useCustomers must be used within a CustomerProvider');
    }

    return context;
}

export { CustomerProvider, useCustomers }