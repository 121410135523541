import React from 'react';

export default class Menu extends React.Component {
    render() {
        return (
            <div className="icon menu">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 21">
                    <g id="Group_647" data-name="Group 647" transform="translate(181 2342)">
                        <g id="Group_646" data-name="Group 646">
                            <rect id="Rectangle_573" data-name="Rectangle 573" className="cls-1" width="4" height="3" transform="translate(-181 -2342)" />
                            <rect id="Rectangle_574" data-name="Rectangle 574" className="cls-1" width="23" height="3" transform="translate(-172 -2342)" />
                        </g>
                        <g id="Group_644" data-name="Group 644">
                            <rect id="Rectangle_576" data-name="Rectangle 576" className="cls-1" width="4" height="3" transform="translate(-181 -2324)" />
                            <rect id="Rectangle_575" data-name="Rectangle 575" className="cls-1" width="23" height="3" transform="translate(-172 -2324)" />
                        </g>
                        <g id="Group_645" data-name="Group 645">
                            <rect id="Rectangle_578" data-name="Rectangle 578" className="cls-1" width="4" height="3" transform="translate(-181 -2333)" />
                            <rect id="Rectangle_577" data-name="Rectangle 577" className="cls-1" width="18" height="3" transform="translate(-172 -2333)" />
                        </g>
                    </g>
                </svg>

            </div>
        );
    }
}
