import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import classnames from "classnames";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ActionButton from "./../shared/button/ActionButton";

const WarrantyClaimDialog = ({
  localized,
  children,
  open,
  toggleDialog,
  count,
  fetching,
}) => {
  const buttonType = classnames({
    "gray-outline": fetching,
    "green-outline": !fetching,
  });

  const buttonText =
    count === 0
      ? localized.BtnAddNewClaim
      : localized.BtnReviewUnsubmittedItems.replace("{count}", `${count}`);

  return (
    <>
      <ActionButton
        text={buttonText}
        type={buttonType}
        onClick={toggleDialog}
        disabled={fetching}
        tabIndex={fetching ? -1 : 0}
      />

      <Dialog
        open={open}
        onClose={toggleDialog}
        fullWidth
        className="dialog"
        maxWidth="lg"
      >
        <DialogTitle
          variant="h3"
          gutterBottom
          className="fw-bold"
          style={{
            fontFamily: '"Gotham XNarrow SSm A", "Gotham XNarrow SSm B"',
          }}
        >
          {localized.EnterItemDetails}
        </DialogTitle>

        <DialogActions>
          <IconButton
            aria-label="close"
            onClick={toggleDialog}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon sx={{ fontSize: "3rem" }} />
          </IconButton>
        </DialogActions>

        <DialogContent
          className="pt-0"
          style={{ marginLeft: "1rem", overflowX: "hidden" }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WarrantyClaimDialog;
