import initialState from './initialState';
import * as actions from '../actions/actionTypes';

export default function settingsReducer(state = initialState.settings, action) {
    
    let newState = null;
    switch (action.type) {
        case actions.LOAD_STOREFRONT_SETTINGS:
            newState = {
                ...state,
                storefront: action.storefrontSettings,
            };
            break;

        case actions.LOAD_FRONTEND_SETTINGS:
            newState = {
                ...state,
                frontendSettings: action.frontendSettings,
            };
            break;
      
        default:
            newState = state;
            break;
    }
    return newState;
}