import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Api from '../../services/Api'
import Notifications from '../../services/Notifications';
import Loading from '../Loading';
import * as userActions from '../../actions/userActions';
import { withSelectedCustomer } from '../../hooks/withSelectedCustomer';
export class UserCustomers extends React.Component {

    constructor(props) {
        super(props);

        // this.props.currentUser
        this.state = {
            user: null,
            customers: null,
            customerInput: "",
            availableCustomers: null,
            selectedComponentCustomer: null,
            fetching: true,
            displayResults: false,
            fetchingAvailableCustomers: true
          
        };
       
    }

    componentDidMount() {       
        this.mounted = true;
        if (this.props.user)
            this.setMountedState({ user: this.props.user }, this.fetchCustomers);
    }

    componentDidUpdate() {
        if (this.props.user !== this.state.user)
            this.setMountedState({ fetching: true, customers: null, user: this.props.user }, this.fetchCustomers)
    }

    componentWillUnmount() {
        this.mounted = false;
    }


    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    fetchCustomers = () => {
        Api.fetch(`/api/user/${this.state.user.id}/customers`).then(this.loadCustomers);
    }

    loadCustomers = (data) => {
        if (this.mounted && data)
            this.setMountedState({ customers: data, fetching: false });
    }

    handleChange = (e) => {
        this.setMountedState({ customerInput: e.target.value }, this.handleCustomerChange)
    }

    handleCustomerChange = () => {
        (this.state.customerInput.length >= 3) ?
            this.fetchAvailableCustomers() :
            this.clearAvailableCustomers();
    }

    fetchAvailableCustomers = () => {
        this.setMountedState({ fetchingAvailableCustomers: true, availableCustomers: null, displayResults: true }, this.callApiWithFilter)
    }

    callApiWithFilter = () => {
        Api.fetch(this.customersFilterUri()).then(this.loadAvailableCustomers);
    }

    customersFilterUri = () => {
        return `/api/user/customers?customerFilter=${this.state.customerInput}`;
    }

    clearAvailableCustomers = () => {
        this.setMountedState({ availableCustomers: null, selectedComponentCustomer: null, displayResults: false })
    }

    loadAvailableCustomers = (data) => {
        this.setMountedState({ availableCustomers: data, fetchingAvailableCustomers: false })
    }

    populateInput = (item) => {
        this.setMountedState({ selectedComponentCustomer: item, customerInput: `${item.externalId} - ${item.name}`, displayResults: false });
    }

    submitCustomer = (e) => {
        if (this.state.selectedComponentCustomer)
            Api.fetch(`/api/user/${this.state.user.id}/customers/${this.state.selectedComponentCustomer.id}`, { method: 'POST' }).then(this.handleResponse);
    }

    handleResponse = (res) => {
        (res) ? this.updateCustomers() : Notifications.error(this.props.localized.RequestFailedMessage);
    }

    updateCustomers = () => {
        this.setMountedState({ customerInput: "" });
        this.fetchCustomers();
        this.props.userActions.LoadInitialUserCustomers();
    }

    removeCustomer = (customerId) => {
        Api.fetch(`/api/user/${this.state.user.id}/customers/${customerId}`, { method: 'DELETE' }).then(this.handleResponse);
    }

    confirm = (externalId) => {
        Notifications.confirmation(this.props.localized.ConfirmRemoveCustomerMessage, () => { this.removeCustomer(externalId) });

    }
    render() {
        if (!this.state.user)
            return null
        return (
            <React.Fragment>
                <div className="add-customer" >
                    <div className="label">{this.props.localized.CustomerSearchLabel}</div>
                    <div className="flex" ref={node => (this.node = node)}>
                        {displayCustomerInput(
                            this.state.customerInput,
                            this.handleChange,
                            this.state.displayResults,
                            this.populateInput,
                            this.state.availableCustomers,
                            this.state.fetchingAvailableCustomers,
                        )}

                        {displayCustomerSubmit(this.submitCustomer, this.props.localized.Add)}
                    </div>
                </div>
                {displayUserCustomers(this.state.customers, this.state.fetching, this.confirm, this.props.localized, this.props.currentUser, this.props.selectedCustomer)}
            </React.Fragment>
        )
    }


}

function displayCustomerSubmit(submitCustomer, buttonText) {
    return (
        <div className="button-wrapper">
            <button data-cy="add-customer" className="button add-customer" onClick={submitCustomer}>{buttonText}</button>
        </div>
    )
}

function displayCustomerInput(customerInput, handleChange, displayResults, populateInput, availableCustomers, fetchingAvailableCustomers) {
    return (
        <div className="input-wrapper">
            <input type="text" onChange={handleChange} value={customerInput} data-cy="usercustomers-input" />
            {
                (customerInput.length >= 3) &&
                displaySearchResults(displayResults, availableCustomers, populateInput, fetchingAvailableCustomers)
            }
        </div>
    )
}

function displaySearchResults(displayResults, availableCustomers, populateInput, fetchingAvailableCustomers) {
    return (
        <div className={`results ${displayResults ? "active" : ""}`}>
            {
                fetchingAvailableCustomers && !availableCustomers &&
                <button data-cy="customer-results" className="result">
                    <Loading />
                </button>
            }

            {
                !fetchingAvailableCustomers && availableCustomers && availableCustomers.map((item, key) =>
                    <button key={key} className="result" onClick={() => populateInput(item)} data-cy="usercustomers-result">
                        {item.externalId} - {item.name}
                    </button>
                )
            }
            {
                !fetchingAvailableCustomers && (!availableCustomers || (availableCustomers.length === 0)) &&
                <button className="result">no results found</button>
            }
        </div>
    )
}

function customerInUserList(customers, customer) {
    const c = customers.find((c) => c.id === customer.id);
    console.log(customer);
    console.log(c);
    if (c !== null && c !== undefined)
        return true;
    else
        return false;
}

function displayUserCustomers(customers, fetching, confirm, localized, currentUser,selectedCustomer) {
    return (
        <div className="customer-list" data-cy="usercustomers-list">
            <div className="label">{localized.CustomersLabel}:</div>
            <div className="list">
                {
                    !customers && fetching &&
                    <div className="fele-container"><Loading /></div>
                }
                {
                    customers && customers.length === 0 && !fetching &&
                    <div>{localized.NoCustomersMessage}</div>
                }
                {
                    customers && !fetching &&
                    customers.map((item, key) =>
                        <div className="customer flex space-between" key={key}>
                            <div className="field-wrapper flex">
                                <div className="number">{item.externalId} </div><span>- </span>
                                <div className="name">{item.name}</div>
                            </div>

                            {
                                currentUser.persona.name === "CustomerAdministrator" && customerInUserList(currentUser.assignedCustomers, item) === true && item.id !== selectedCustomer.id &&
                                <div className="button-wrapper">
                                    <button className="remove" data-cy="customer-remove" onClick={() => { confirm(item.externalId) }}> <span>X </span><span>{localized.Remove}</span></button>
                                </div>
                            }
                            {
                                currentUser.persona.name !== "CustomerAdministrator" && item.id !== selectedCustomer.id  &&
                                <div className="button-wrapper">
                                    <button className="remove" data-cy="customer-remove" onClick={() => { confirm(item.externalId) }}> <span>X </span><span>{localized.Remove}</span></button>
                                </div>
                            }
                            {
                                currentUser.persona.name !== "CustomerAdministrator" && item.id === selectedCustomer.id &&
                                <div className="button-wrapper">
                                        <span className="remove currentCustomer" data-cy="customer-remove" ><span>{localized.CurrentCustomer}</span></span>
                                   
                                       
                                </div>
                            }
                            
                        </div>
                    )
                }
            </div>
        </div>
    )
}

UserCustomers.propTypes = {
    userActions: PropTypes.object,
    selectedCustomer: PropTypes.object
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps
)(withSelectedCustomer(UserCustomers));