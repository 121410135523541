import React from 'react';

export default class MapPin extends React.Component {
    render() {
        return (
            <div className="icon map-pin-icon">
                <svg id="map-point-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.774 65.8">
                    <defs>

                    </defs>
                    <g id="Group_63" data-name="Group 63" transform="translate(0)">
                        <path id="Path_117" data-name="Path 117" className="cls-1" d="M175.952,114.467a10.343,10.343,0,1,0,10.343,10.343A10.343,10.343,0,0,0,175.952,114.467Zm0,16.054a5.712,5.712,0,1,1,5.712-5.712A5.723,5.723,0,0,1,175.952,130.521Z" transform="translate(-152.565 -98.876)" />
                        <path id="Path_118" data-name="Path 118" className="cls-1" d="M110.4,7.719A23.17,23.17,0,0,0,92.534,0c-14.9,0-22.692,12.118-22.692,24.082,0,15.669,21.419,39.866,22.345,40.908a2.24,2.24,0,0,0,1.7.81h.039a2.407,2.407,0,0,0,1.7-.772,115.154,115.154,0,0,0,10.42-13.392c7.024-10.5,10.574-19.8,10.574-27.594A24.922,24.922,0,0,0,110.4,7.719ZM93.962,59.857c-5.017-6.02-19.451-24.275-19.451-35.814,0-9.339,5.673-19.412,18.061-19.412,12.62,0,19.412,10,19.412,19.412C111.985,37.628,98.748,54.261,93.962,59.857Z" transform="translate(-69.842)" />
                    </g>
                </svg>
            </div>
        );
    }
}
