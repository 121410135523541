import React from 'react';
import PropTypes from 'prop-types';
import { useCartConfig } from '../../hooks/CartConfigContext';
import PricingHelper from '../../helpers/PricingHelper';

const IncoTerms = props => {
    const cartOptions = useCartConfig();
    const localized = cartOptions.labels;

    if (cartOptions.childComponents.IncoTerms.shown === false) {
        return null
    }
    const incoTerms = PricingHelper.getIncoTerms(props.subTotal);
    let incoTermsMsg = incoTerms?.key === "CPT" ? localized.NLAExIncoTermsliteralCPT : localized.NLAExIncoTermsliteralEXW;
   
    return (
        <React.Fragment>
            <>
                {cartOptions.childComponents.IncoTerms.shown === true && props.seprateDiv &&
                    <>
                        <div className="heading3">
                            {localized.IncoTerms}
                        </div>
                        <div className="fele-content" style={{
                            marginTop: props.termsAlignStyling === true ? '10px' : ''
                        }}>
                            {incoTerms?.value}
                        </div>

                        {
                            cartOptions.settings.ShowNLAExportIncoTermsliteralMsg &&
                            <div className="fele-content" style={{
                                marginTop: props.termsAlignStyling === true ? '10px' : ''
                            }}>
                                {incoTermsMsg}
                            </div>
                        }
                    </>
                }

                {cartOptions.childComponents.IncoTerms.shown === true && !props.seprateDiv &&
                    <>
                        <div className="fele-cart-payment-instrument" style={{ maxWidth: '18rem' }}>
                            <div className="heading3">
                                {localized.IncoTerms}
                            </div>
                            <div className="fele-content" style={{
                                marginTop: props.termsAlignStyling === true ? '10px' : ''
                            }}>
                                {incoTerms?.value}
                            </div>
                        </div>
                        {
                            cartOptions.settings.ShowNLAExportIncoTermsliteralMsg  &&
                            <div className="fele-cart-payment-instrument-incoterms" >
                                <div className="fele-content" style={{
                                    marginTop: props.termsAlignStyling === true ? '10px' : ''
                                }}>
                                    {incoTermsMsg}
                                </div>
                            </div>
                        }
                    </>
                }
            </>

        </React.Fragment>
    )
}


IncoTerms.propTypes = {
    subTotal: PropTypes.number,
    seprateDiv: PropTypes.bool
};

export default IncoTerms;