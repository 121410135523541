import * as React from 'react';
import { useState, useEffect } from 'react';
import storefrontService from '../services/Storefront';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import Localization from '../services/Localization';
import * as Components from '../resources/Components';

const FeedbackConfigContext = React.createContext();

function FeedbackConfigProvider({ children, ...props }) {

    // have all the config settings here.
    const [labels, setLabels] = useState({});
    const { locale } = useStorefrontSettings();

    // might have to just make this a parameter that gets passed in
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);

    useEffect(() => {
        Localization.getComponentLabels(Components.FEEDBACK, locale, storefrontNumber)
            .then(res => {
                setLabels(res);
            })
    }, [locale]);

    return (
        <FeedbackConfigContext.Provider
            value={{
                labels
            }}
        >
            {children}
        </FeedbackConfigContext.Provider >
    );
}

function useFeedbackConfig() {
    const context = React.useContext(FeedbackConfigContext);
    if (context === undefined) {
        throw new Error('FeedbackConfigContext must be used within a FeedbackConfigContext');
    }
    return context;
}

function withFeedbackConfig(Component) {
    return function WrappedComponent(props) {
        const FeedbackConfig = useFeedbackConfig();
        return <Component {...props}
            FeedbackConfig={FeedbackConfig}
        />;
    }
}

export { FeedbackConfigProvider, useFeedbackConfig, withFeedbackConfig }