import React from 'react';
import LoadingGif from "./icons/LoadingGif";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSpinner } from '@fortawesome/free-solid-svg-icons'
import Strings from './Loading.Strings';

export default class Loading extends React.Component {
    constructor(props) {
        super(props);
        this.localized = Strings.localize();
    }
    
    render() {
        return (
            <div data-cy="fele=loading" className="fele-loading">
                {
                    !this.props.type &&
                    <div className="simple">
                        <FontAwesomeIcon icon={faSpinner} pulse/>
                        <span>{this.localized.Loading}</span>
                    </div>
                }
                {
                    this.props.type === "brand" &&
                    <div className="brand">
                        <div className="logo">
                            <LoadingGif />
                        </div>
                    </div>
                }
            </div>
        );
    }
}