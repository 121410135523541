import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Notifications from '../../services/Notifications';

const FileUpload = props => {
    const [items, setItems] = useState([]);
    const fileInputRef = useRef('');
    const fileReader = new FileReader();


    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        fileInputRef.current.value = '';

        const allowedExtensions = ".xls, .csv, .xlsx";
        // file size val
        if (fileUploaded.size > parseInt(props.localized.ImportCartFileSize) * 1024) {
            return Notifications.error("File is big to process to add items to cart");
        }

        if (allowedExtensions.includes(fileUploaded.name.split('.').pop()) === true) {
            // File Reader Process
            if (fileUploaded) {
                fileReader.onload = function (e) {
                    const csvOutput = e.target.result;
                    csvFileToArray(csvOutput);
                };
                fileReader.readAsText(fileUploaded); //reader.readAsText(file, 'ISO-8859-1');
            }
        }
        else {
            return Notifications.error(props.localized.InvalidFile);
        }
        /*Papa Parse Other way if file reader one taking more time.
       Papa.parse(event.target.files[0], {
           header: true,
           skipEmptyLines: true,
           dynamicTyping: true,
           complete: function (results) {
               console.log(results.data);
           },
       }); */
    };

    // check for delimiter in excel file
    const checkDelimiter = (text) => {
        let val;
        if (text.includes(',')) {
            val = ",";
        }
        else if (text.includes(';')) {
            val = ";";
        }
        else if (text.includes('|')) {
            val = "|";
        }
        else {
            val = "\t";
        }

        return val;
    }

    //csv file string to int
    const isPositiveNumber = (value) => {
        let exp = new RegExp(/^[1-9]\d*$/)
        return exp.test(parseInt(value)); 
    }

    // csv file to array
    const csvFileToArray = string => {
        let csvData = [];                
        const delim = checkDelimiter(string);
        var headerString = string.split('\n')[0];
        var bodyString = string.split('\n').slice(1).join('\n');
        var headerRegex = new RegExp(delim + "[\n\r\t " + delim + "]{2,}", "g");
        headerString = headerString.replace(headerRegex, delim);

        var newLineRegex = new RegExp(delim + "[\n\r\t " + delim +"]{2,}", "g");
        bodyString = bodyString.replace(newLineRegex, delim);
        
        let csvHeader, csvRows;
        
        if (delim.trim() == "") {
            csvHeader = headerString.slice(0, headerString.indexOf('\r\n')).split("\t"); //tab
            csvRows = bodyString.split("\r\n");
        }
        else {
            csvHeader = headerString.slice(0, headerString.indexOf('\n')).split(delim); //all other            
            csvRows = bodyString.split("\r\n");
        }
        //Remove empty rows
        csvRows = csvRows.filter((row) => row != "");
        // Update headers because of translations 
        if (csvHeader.length == 2) {
            csvHeader[0] = "Sku";
            csvHeader[1] = "Quantity";

            csvData = csvRows?.filter(o => o)?.map(i => {
                const values = i.split(delim == "" ? "\t" : delim);
                const obj = csvHeader.reduce((object, header, index) => {
                    object[header] = values[index];
                    return object;
                }, {});
                return obj;
            });
        }
        else {
            console.log("Improper format in excel");
        }

        // parse quantity to integer, validation rules can be added while uploading file.
        csvData.map(item => {
            if (!isNaN(item.Quantity)) {
                item.Quantity = parseInt((item.Quantity.trim() === "" || isPositiveNumber(item.Quantity) === false) ? "0" : item.Quantity);
            }
        });

        // Max Lines check restricting to 100 for now. 
        if (csvData.length > 100) {
            return Notifications.warning(props.localized.ImportCartLimitLines);
        }

        setItems(csvData);
        props.parsedItems(csvData); // dont parse if data is not in right format.    
    };

    return (
        <>
            <button data-cy="import-cart" className="button search-button button-alt text-nowrap px-5" onClick={() => fileInputRef.current.click()} >
                <input accept={props.accept} onChange={handleChange} multiple={props.multiple} ref={fileInputRef} type='file' hidden className="form-control" />
                <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: "5px" }} />
                {props.localized.ImportExcelFile}
            </button>
        </>
    );
}
FileUpload.propTypes = {
    fileUploadUpdated: PropTypes.func,
    uploadItems: PropTypes.array,
};
export default FileUpload;
