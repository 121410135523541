import React from 'react';
import Loading from '../Loading';
import DateDisplay from '../DateDisplay';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import { CSVLink } from "../../modules/react-csv";
import DateHelper from "../../helpers/DateHelper";

function LoggedItems(props) {

    const { items, fetching, renderPaging } = props
    const salesHistoryConfig = useSalesHistoryConfig();
    const localized = salesHistoryConfig.labels;
     
    const getCSVData = () => {
        if (items && items.length > 0) {
            let content = [];
            items.map((item) => content.push(
                {
                    businessUnit: item.companyCode ?? "",
                    customerId: item.customerId ?? "",
                    customerName: item.customerName ?? "",
                    territory: item.salesTerritoryCode ?? "",
                    city: item.city ?? "",
                    state: item.state ?? "",
                    itemNumber: item.sku ?? "",
                    itemDescription: item.description ?? "",
                    searchedQuantity: item.searchedQuantity ?? null,
                    backorderedQuantity: item.backorderedQuantity ?? null,
                    captureLocation: (item.captureLocation ? (item.captureLocation == "PC" ? localized.PriceCartLookup : localized.SearchLookup) : ""),                   
                    dateLogged: DateHelper.format(item.dateLogged, "dd/MM/yyyy") ?? "",
                    timeLogged: new Date(item.dateLogged).toLocaleTimeString() ?? ""
                }

            ));

            return content;
        }

        else
            return [];
    }

  const  getCSVHeaders = () => {
        let headers = [
            { label: localized.BusinessUnit, key: "businessUnit" },
            { label: localized.CustomerId, key: "customerId" },
            { label: localized.CustomerName, key: "customerName" },
            { label: localized.SelectTerritoryLabel, key: "territory" },
            { label: localized.City, key: "city" },
            { label: localized.State, key: "state" },
            { label: localized.ItemNumber, key: "itemNumber" },
            { label: localized.ItemDescription, key: "itemDescription" },
            { label: localized.SearchedQuantity, key: "searchedQuantity" },
            { label: localized.QuantityBackordered, key: "backorderedQuantity" },
            { label: localized.CaptureLocation, key: "captureLocation" },
            { label: localized.SearchDate, key: "dateLogged" },
            { label: localized.SearchTime, key: "timeLogged" }
        ];
        return headers;
    }

  


    return (
        <div data-cy="report-order-history" className="fele-order-history">

            {
                !fetching && (items && items.length > 0) &&
                <div className="export-button button-wrapper">
                    <button>
                            <CSVLink id="orders-csv-link"
                                separator={localized.ExcelSeparator}
                                data={getCSVData()}
                                filename={"Lost Sales Report.xls"}
                                headers={getCSVHeaders()}
                                className="button button-alt" >
                                {localized.Export}</CSVLink>
                    </button>
                    </div>                
            }         


            <div className="br-history-header">
                <div className="report-small">
                    <div className="label">{localized.BusinessUnit}</div>
                </div>
                <div className="report-small">
                    <div className="label">{localized.CustomerId}</div>
                </div>
                <div className="report-large">
                    <div className="label">{localized.CustomerName}</div>
                </div>
                <div className="report-small">
                    <div className="label">{localized.SelectTerritoryLabel}</div>
                </div>
                <div className="report-medium">
                    <div className="label">{localized.City}</div>
                </div>
                <div className="report-small">
                    <div className="label">{localized.State}</div>
                </div>
                <div className="report-medium">
                    <div className="label">{localized.ItemNumber}</div>
                </div>
                <div className="report-large">
                    <div className="label">{localized.ItemDescription}</div>
                </div>                
                <div className="report-small">
                    <div className="label">{localized.SearchedQuantity}</div>
                </div>
                <div className="report-small">
                    <div className="label">{localized.QuantityBackordered}</div>
                </div>
                <div className="report-medium">
                    <div className="label">{localized.CaptureLocation}</div>
                </div>
                <div className="report-medium">
                    <div className="label">{localized.SearchDate}</div>
                </div>
                <div className="report-medium">
                    <div className="label">{localized.SearchTime}</div>
                </div>
            </div>
            {
                fetching &&
                <Loading type="brand" />
            }
            {
                !fetching &&
                (items && items.length == 0) &&
                <div data-cy="report-details-no-results" className="details no-result">
                    No results found.
                </div>
            }
            {
                !fetching &&
                (items && items.length > 0) &&
                <div>
                    <div data-cy="report-history" className="br-history">
                        {
                            items.map((item, key) => renderItem(item, key))
                        }
                    </div>
                    {renderPaging()}
                </div>
            }

        </div>
    )

    function renderItem(item, index) {
        return (
            <div className="br-details" key={index}>
                <div className="report-small">
                    <div className="label">{localized.BusinessUnit}</div>
                    <div className="fele-content">{item.companyCode}</div>
                </div>
                <div className="report-small">
                    <div className="label">{localized.CustomerId}</div>
                    <div className="fele-content">{item.customerId}</div>
                </div>
                <div className="report-large">
                    <div className="label">{localized.CustomerName}</div>
                    <div className="fele-content">{item.customerName}</div>
                </div>
                <div className="report-small">
                    <div className="label">{localized.SelectTerritoryLabel}</div>
                    <div className="fele-content">{item.salesTerritoryCode}</div>
                </div>
                <div className="report-medium">
                    <div className="label">{localized.City}</div>
                    <div className="fele-content">{item.city}</div>
                </div>
                <div className="report-small">
                    <div className="label">{localized.State}</div>
                    <div className="fele-content">{item.state}</div>
                </div>
                <div className="report-medium">
                    <div className="label">{localized.ItemNumber}</div>
                    <div className="fele-content">{item.sku}</div>
                </div>
                <div className="report-large">
                    <div className="label">{localized.ItemDescription}</div>
                    <div className="fele-content">{item.description}</div>
                </div>
                <div className="report-small">
                    <div className="label">{localized.SearchedQuantity}</div>
                    <div className="fele-content">{item.searchedQuantity}</div>
                </div>
                <div className="report-small">
                    <div className="label">{localized.QuantityBackordered}</div>
                    <div className="fele-content">{item.backorderedQuantity}</div>
                </div>
                <div className="report-medium">
                    <div className="label">{localized.CaptureLocation}</div>
                    <div className="fele-content">{item.captureLocation === 'PC' ? localized.PriceCartLookup : localized.SearchLookup}</div>
                </div>
                <div className="report-medium">
                    <div className="label">{localized.SearchDate}</div>
                    <div className="fele-content">
                        <DateDisplay date={item.dateLogged} />
                    </div>
                </div>
                <div className="report-medium">
                    <div className="label">{localized.SearchTime}</div>
                    <div className="fele-content">{new Date(item.dateLogged).toLocaleTimeString()}</div>
                </div>                

            </div>
        )
    }

}

export default LoggedItems;