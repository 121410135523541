import React from 'react';

export default class Account extends React.Component {
    render() {
        return (
            <div className="icon accounts-icon">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="5 0 66 65">
                    <path  d="M16.2,15.8H6.8C5.8,15.8,5,15,5,14v-2.4c0-1,0.8-1.8,1.8-1.8h9.5c1,0,1.8,0.8,1.8,1.8V14
                        C18,15,17.2,15.8,16.2,15.8z"/>
                    <path  d="M16.2,28.4H6.8c-1,0-1.8-0.8-1.8-1.8v-2.4c0-1,0.8-1.8,1.8-1.8h9.5c1,0,1.8,0.8,1.8,1.8v2.4
	                    C18,27.6,17.2,28.4,16.2,28.4z"/>
                    <path  d="M16.2,41.1H6.8c-1,0-1.8-0.8-1.8-1.8v-2.4c0-1,0.8-1.8,1.8-1.8h9.5c1,0,1.8,0.8,1.8,1.8v2.4
	                    C18,40.3,17.2,41.1,16.2,41.1z"/>
                    <path  d="M16.2,53.8H6.8C5.8,53.8,5,53,5,52v-2.4c0-1,0.8-1.8,1.8-1.8h9.5c1,0,1.8,0.8,1.8,1.8V52
	                    C18,53,17.2,53.8,16.2,53.8z"/>
                    <g>
	                    <path  d="M63.5,0H11.5C10.1,0,9,1.1,9,2.5v5.3h4.5V4.5h48v56h-48v-4.7H9v6.7c0,1.4,1.1,2.5,2.5,2.5h52.1
		                    c1.4,0,2.5-1.1,2.5-2.5V2.5C66,1.1,64.9,0,63.5,0z"/>
	                    <rect x="9" y="17.5"  width="4.5" height="3"/>
	                    <rect x="9" y="30.3"  width="4.5" height="3"/>
	                    <rect x="9" y="43"  width="4.5" height="3"/>
                    </g>
                    <path  d="M40.3,29.5c-4.1-1-5.1-1.5-5.1-3.1v-0.1c0-1.1,1-2,3-2c2,0,4,0.9,6.1,2.3l2.7-3.9c-1.8-1.4-3.9-2.4-6.2-2.8
	                    v-4.9h-3.5v4.8c-4.3,0.4-7.2,3.1-7.2,7v0.1c0,4.7,3.1,6,7.8,7.2c3.9,1,4.7,1.7,4.7,3v0.1c0,1.4-1.3,2.2-3.4,2.2
	                    c-2.7,0-4.9-1.1-7-2.8l-3.1,3.7c2.3,2.1,5.2,3.3,8.2,3.6v4.8h3.5v-4.8c4.1-0.5,6.9-3,6.9-7.2v-0.1C47.8,32.5,45.1,30.8,40.3,29.5z"
	                    />
                </svg>

            </div>
        );
    }
}
