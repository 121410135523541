/*
 HasWarrantyAccess
 SubmitClaim
 CanApproveRejectClaim
 */

import React, { useState, useEffect } from 'react';
import UserHelper from "../../helpers/UserHelper";
import CheckBox from '../form-controls/CheckBox';

const WarrantyAccess = props => {
    const [hasWarrantyAccess, setHasWarrantyAccess] = useState(UserHelper.hasRoles(['HasWarrantyAccess'], props.user) || false);
    const [submitClaim, setSubmitClaim] = useState(UserHelper.hasRoles(['SubmitClaim'], props.user) || false);
    const [canApproveRejectClaim, setCanApproveRejectClaim] = useState(UserHelper.hasRoles(['CanApproveRejectClaim'], props.user) || false);

    useEffect(() => { loadInitalRoles(); }, []);

    const loadInitalRoles = () => {
        props.updateSelectedRoles(getWarrantyRoles(hasWarrantyAccess, submitClaim, canApproveRejectClaim));

    }

    const getWarrantyRoles = (warranty, submit, approve) => {
        let rolesArray = [];
        if (warranty)
            rolesArray.push("HasWarrantyAccess");

        if (submit)
            rolesArray.push("SubmitClaim");

        if (approve)
            rolesArray.push("CanApproveRejectClaim");

        if (rolesArray.length < 1)
            rolesArray.push("noWarrantyRolesSelected");

        return rolesArray.join();
    }

    const setWarrantyRoles = (warranty, submit, approve) => {

        if (warranty) {
            setHasWarrantyAccess(true);
        } else {
            setSubmitClaim(false);
            setCanApproveRejectClaim(false);
            setHasWarrantyAccess(false);
            submit = false;
            approve = false;
        }

        if (submit) {
            setSubmitClaim(true);
            if (!hasWarrantyAccess) {
                setHasWarrantyAccess(true);
                warranty = true;
            }
        } else {
            setSubmitClaim(false);
        }

        if (approve) {
            setCanApproveRejectClaim(true);
            if (!hasWarrantyAccess) {
                setHasWarrantyAccess(true);
                warranty = true;
            }
        } else {
            setCanApproveRejectClaim(false);
        }


        props.updateSelectedRoles(getWarrantyRoles(warranty, submit, approve));
    }

    return (
        <div className="w-100">
            <p className="fs-2 fw-bolder">Warranty Access:</p>

            <div className="w-100 d-flex justify-content-between">
                <div style={{ width: '48%' }}>
                    Has Warranty Access:
                </div>
                <div style={{ width: '48%' }}>
                    <CheckBox checked={hasWarrantyAccess} label="" onClick={() => { setWarrantyRoles(!hasWarrantyAccess, submitClaim, canApproveRejectClaim); }} />
                </div>
            </div>

            <div className="w-100 d-flex justify-content-between">
                <div style={{ width: '48%' }}>
                    Can Submit Warranty Claims:
                </div>
                <div style={{ width: '48%' }}>
                    <CheckBox checked={submitClaim} label="" onClick={() => { setWarrantyRoles(hasWarrantyAccess, !submitClaim, canApproveRejectClaim); }} />
                </div>
            </div>
            {props.user.email.toUpperCase().includes('@FELE.COM') &&
                <div className="w-100 d-flex justify-content-between">
                    <div style={{ width: '48%' }}>
                        Can Approve/Reject Warranty Claims:
                    </div>
                    <div style={{ width: '48%' }}>
                        <CheckBox checked={canApproveRejectClaim} label="" onClick={() => { setWarrantyRoles(hasWarrantyAccess, submitClaim, !canApproveRejectClaim); }} />
                    </div>
                </div>
            }
        </div>);
}

export default WarrantyAccess;