import LocalizedStrings from 'react-localization';
import Localization from './../../services/Localization';

class ProductSearchResultsStrings {
    localized = new LocalizedStrings({
        en: {
            AvailabilityCost_Label: "Availability/Cost",
            Description_Label: "Description",
            ErrorRetrievingItemsMessage: "An error occured while searching for items.",
            Item_Label: "Item",
            ListPrice_Label: "List Price",
            NoItemMessage: "No items found.",
            SearchResults: "Search Results",
            ShipToNotSelectedWarningMessage: 'Please select a valid "SHIP TO" from the "SHIP TO / BILL TO" list.',
            ShipToNotSelectedWarningTitle: "Before Proceeding"
        },

        pt: {
            AvailabilityCost_Label: "Disponibilidade/Preço",
            Description_Label: "Descrição",
            ErrorRetrievingItemsMessage: "Ocorreu um erro ao procurar itens",
            Item_Label: "Item",
            ListPrice_Label: "Preço de Lista",
            NoItemMessage: "Nenhum item encontrado",
            SearchResults: "Resultado da Pesquisa",
            ShipToNotSelectedWarningMessage: "Por favor, selecione um Código de cliente  válido na lista de clientes",
            ShipToNotSelectedWarningTitle: "Antes de continuar"
        },

        es: {
            AvailabilityCost_Label: "Disponibilidad / Costo",
            Description_Label: "Descripción",
            ErrorRetrievingItemsMessage: "Ocurrió un error al buscar el item",
            Item_Label: "Item",
            ListPrice_Label: "Precio de lista",
            NoItemMessage: "No se encontraron items",
            SearchResults: "Search Results (es)",
            ShipToNotSelectedWarningMessage: "Por favor, seleccione un número de cliete válido en la lista de clientes",
            ShipToNotSelectedWarningTitle: "Antes de continuar"
        },

        it: {
            AvailabilityCost_Label: "Disponibilità/Costo",
            Description_Label: "Description",
            ErrorRetrievingItemsMessage: "An error occured while searching for items.",
            Item_Label: "Item",
            ListPrice_Label: "List Price",
            NoItemMessage: "No items found.",
            SearchResults: "Risultati della ricerca",
            ShipToNotSelectedWarningMessage: 'Please select a valid "SHIP TO" from the "SHIP TO / BILL TO" list.',
            ShipToNotSelectedWarningTitle: "Before Proceeding"
        },

        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new ProductSearchResultsStrings();
