import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import StatusHelper from '../../helpers/StatusHelper';
import Money from '../Money'
import OrderSalesLineGraph from "./OrderSalesLineGraph";
import DateHelper from "../../helpers/DateHelper";
import OrderIconSmall from "../icons/OrderIconSmall";

export const DashboardOrderHistory = (props) => {
    const tdMoneyAlign = props.tdMoneyAlign;
    const tdDateAlign = tdMoneyAlign == 'left' ? 'left' : 'middle';
    const tdStatusAlign = tdMoneyAlign == 'left' ? 'left' : 'right';

    const orderData = props.orderData;
    const monthlyTotals = props.monthlyTotals;
    const currency = props.currency;   
    const country = props.country;
    const currencySymbol = props.currencySymbol;
    const history = useHistory();
    const handleRowClick = (order) => {
        history.push(`/order/${order.orderNumber}/${order.orderCompany}/${order.orderType}`); 
    }
    return (
        <>
            {props.storeSettings.settingsList.UseDashboardOrderHistory == '1' &&
                <div className="order-panel">
                    <div className="title-flex-container">
                        <div>
                            <div><OrderIconSmall /></div>
                            <div className="title-label">{props.dashboardLabels.ORDERHISTORY}</div>
                        </div>
                        <div className={props.isFueling ? "expand-wrapper-ffs" : "expand-wrapper"}>
                            <Link className="link-style" to={{ pathname: "/orders", query: { statuses: [], dateRange: 729 } }}>
                                <button className="expand-button">
                                   <text className="expand-button-text">{props.dashboardLabels.ViewAll}</text>
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className="data-flex-container">
                        {(orderData && orderData.salesOrdersResponse && orderData.salesOrdersResponse.length > 0) ?
                            <table className="table-style">
                                
                                <th className= "th-left">{props.dashboardLabels.ORDERNUMBER}</th>
                                <th className="th-middle-right">{props.dashboardLabels.PONUMBER}</th>
                                <th className="th-middle-right">{props.dashboardLabels.Total}</th>
                                <th className="th-order-date">{props.dashboardLabels.OrderDate}</th>
                                <th className="th-middle-right">{props.dashboardLabels.Status}</th>
                                {orderData.salesOrdersResponse.slice(0, 10).map((order, key) => {
                                    return <tr className="row-wrapper" >

                                        <td onClick={() => handleRowClick(order)} key={key} className="td-left-link">{order.orderNumber}</td>                                        
                                        <td className="td-po">{order.poNumber}</td>
                                        <td className={"td-" + tdMoneyAlign}><Money country={country} currency={order.priceCurrency} value={order.total} /></td>
                                        <td className={"td-" + tdDateAlign}>{DateHelper.format(order.orderDate, props.dateFormat)}</td>
                                        <td className={"td-" + tdStatusAlign}>{StatusHelper.formatStatus(order.statusDescription, props.dashboardLabels)}</td>

                                    </tr>

                                }
                                )}
                            </table> : <div>{props.dashboardLabels.NoOrders}</div>
                        }
                     
                    </div>
                    {(orderData && orderData.salesOrdersResponse && orderData.salesOrdersResponse.length > 0) &&
                        <OrderSalesLineGraph monthlyTotals={monthlyTotals} country={country} dashboardLabels={props.dashboardLabels} currencySymbol={currencySymbol} currency={currency}></OrderSalesLineGraph>
                    }
                </div>
            }
        </>
    );
}

DashboardOrderHistory.propTypes = {
    orderData: PropTypes.object,
    monthlyTotals: PropTypes.object,
    currency: PropTypes.string,
    country: PropTypes.string,
    currencySymbol: PropTypes.string
};
function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
    };
}

export default connect(mapStateToProps, null)(DashboardOrderHistory);