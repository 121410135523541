import LocalizedStrings from 'react-localization';
import Localization from '../services/Localization';

class OrdersStrings {
    localized = new LocalizedStrings({

        en: {
            BackToOrderHistory: "Back to Order History",
            BeingProcessed: "Being Processed",
            BillingAddress: "Billing Address",
            ContactCustomerService: "Contact Customer Service",
            Currency: "Currency",
            DateOrdered: "Date Ordered",
            DateRange: "Date Range",
            DownloadPDF: "Download PDF",
            Export: "Export",
            InvoiceNumber: "Invoice Number",
            ItemDescription: "Description",
            ItemNumber: "Item",
            ItemsToBeShipped: "Items to be Shipped",
            Last2Years: "Last 2 Years",
            Last30Days: "Last 30 Days",
            Last90Days: "Last 90 Days",
            LastYear: "Last Year",
            LinesSearchInputLabel: "Item/SKU, PO Number, or Order Number",
            NoInvoiceMessage: "Invoice not available",
            NoOrdersFound: "No orders found.",
            NoPDFMessage: "No PDF found for this order",
            NoPurchaserInfo: "Order was not placed through FE-Direct",
            NoResultsFound: "No results found for this order.",
            OrderConfirmation: "Your order has been submitted.",
            OrderDate: "Order Date",
            OrderNumber: "Order Number",
            OrderStatus: "Order Status",
            OrderTotal: "Order Total",
            PartiallyShipped: "Partially Shipped",
            Payments: "Payments",
            PDF: "PDF",
            PoNumberLabel: "PO Number",
            ProductTotal: "Total",
            QtyBackOrderedLabel: "Qty Backordered",
            QtyLabel: "Qty",
            QuoteDate: "Date Created",
            QuoteNumber: "Quote Number",
            RequestDate: "Request Date",
            RequestedDate: "Requested",
            SearchButtonText: "Search",
            SearchForOrders: "Search For Orders",
            SearchInputLabel: "PO Number, or Order Number",
            SearchInputLabelPlaceholder: "Search",
            SelectStatus: "All Status",
            ShareViaWhatsApp: "Share via WhatsApp",
            ShipDate: "Estimated Ship Date",
            Shipped: "Shipped",
            Status: "Status",
            SubtotalOfProduct: "Subtotal of Product",
            SummaryTitle: "Order Summary",
            Total: "Total",
            TotalFreight: "Total Freight",
            TotalTax: "Total Tax",
            UnitPrice: "Each",
            ViewDetails: "View Details"
        },

        pt: {
            BackToOrderHistory: "Voltar para Consulta de Pedidos",
            BeingProcessed: "Sendo Processado",
            BillingAddress: "Endereço",
            ContactCustomerService: "Contact Customer Service",
            Currency: "Moeda",
            DateOrdered: "Data do Pedido",
            DateRange: "Período",
            DownloadPDF: "Baixar PDF",
            Export: "Exportar",
            InvoiceNumber: "Número Nota Fiscal",
            ItemDescription: "Descrição do Produto",
            ItemNumber: "Código do Produto",
            ItemsToBeShipped: "Itens a serem enviados",
            Last2Years: "2 Anos",
            Last30Days: "30 dias",
            Last90Days: "90 dias",
            LastYear: "1 Ano",
            LinesSearchInputLabel: "Pesquisa por Código, Ordem de Compra ou  Número do Pedido",
            NoInvoiceMessage: "Nota Fiscal não disponível",
            NoOrdersFound: "Pedido não encontrado",
            NoPDFMessage: "Nenhum PDF encontrado para este pedido",
            NoPurchaserInfo: "O pedido não foi feito através do FE-Direct",
            NoResultsFound: "Nenhum resultado encontrado",
            OrderConfirmation: "Your order has been submitted.",
            OrderDate: "Data do Pedido",
            OrderNumber: "Número do Pedido",
            OrderStatus: "Status do Pedido",
            OrderTotal: "Total Pedido (Prod. Fat. c/IPI e Aberto s/IPI)",
            PartiallyShipped: "Enviado Parcialmente",
            Payments: "Total Pedido (Faturado e Aberto)",
            PDF: "PDF",
            PoNumberLabel: "Ordem de Compra",
            ProductTotal: "Preço total",
            QtyBackOrderedLabel: "Quant. Indisponível",
            QtyLabel: "Quant. Solicitada",
            QuoteDate: "Data do Orçamento",
            QuoteNumber: "Número orçamento",
            RequestDate: "Data Solicitada para Faturamento",
            RequestedDate: "Solicitado",
            SearchButtonText: "Pesquisar",
            SearchForOrders: "Pesquisa por Pedido",
            SearchInputLabel: "Pesquisa por Ordem de Compra ou  Número do Pedido",
            SearchInputLabelPlaceholder: "Pesquisar",
            SelectStatus: "Todo o Status",
            ShareViaWhatsApp: "Compartilhar pelo WhatsApp",
            ShipDate: "Data de entrega estimada",
            Shipped: "Enviado",
            Status: "Status",
            SubtotalOfProduct: "Subtotal",
            SummaryTitle: "Resumo do Pedido",
            Total: "Total",
            TotalFreight: "Total Frete",
            TotalTax: "Total IPI (Produtos Faturados)",
            UnitPrice: "Preço unitário",
            ViewDetails: "Ver Detalhes"
        },

        es: {
            BackToOrderHistory: "Volver a la consulta de pedidos",
            BeingProcessed: "En proceso",
            BillingAddress: "Dirección",
            ContactCustomerService: "Contact Customer Service",
            Currency: "Moneda",
            DateOrdered: "Fecha del pedido",
            DateRange: "Período",
            DownloadPDF: "Descargar PDF",
            Export: "Exportar",
            InvoiceNumber: "Número de factura eletrónica",
            ItemDescription: "Descripción de item",
            ItemNumber: "Item",
            ItemsToBeShipped: "Items to be Shipped",
            Last2Years: "2 años",
            Last30Days: "30 días",
            Last90Days: "90 días",
            LastYear: "1 año",
            LinesSearchInputLabel: "Búsqueda por número de parte, orden de compra y número de pedido",
            NoInvoiceMessage: "Factura eletónica no disponible",
            NoOrdersFound: "Pedido no encontrado",
            NoPDFMessage: "No PDF found for this order (es)",
            NoPurchaserInfo: "La orden no fue colocada a través de FE-Direct",
            NoResultsFound: "Ningún resultado encontrado",
            OrderConfirmation: "Su pedido ha sido enviado.",
            OrderDate: "Fecha del pedido",
            OrderNumber: "Número de pedido",
            OrderStatus: "Estado del pedido",
            OrderTotal: "Total del pedido sin impuesto",
            PartiallyShipped: "Parcialmente enviado",
            Payments: "Condición de pago",
            PDF: "PDF (es)",
            PoNumberLabel: "Orden de compra",
            ProductTotal: "Precio total",
            QtyBackOrderedLabel: "Qty Backordered (es)",
            QtyLabel: "Cantidad",
            QuoteDate: "Fecha creada",
            QuoteNumber: "Número de cotización",
            RequestDate: "Fecha requerida para facturación",
            RequestedDate: "Solicitado",
            SearchButtonText: "Búsqueda",
            SearchForOrders: "Búsqueda por pedido",
            SearchInputLabel: "Búsqueda por orden de compra y número de pedido",
            SearchInputLabelPlaceholder: "Búsqueda",
            SelectStatus: "Todos los estados",
            ShareViaWhatsApp: "Compartir por WhatsApp",
            ShipDate: "Fecha estimada de entrega",
            Shipped: "Enviado",
            Status: "Estado",
            SubtotalOfProduct: "Subtotal",
            SummaryTitle: "Resumen de la orden",
            Total: "Total",
            TotalFreight: "Total transporte",
            TotalTax: "Tasas",
            UnitPrice: "Precio unitario",
            ViewDetails: "Ver detalles"
        },

        it: {
            BackToOrderHistory: "Back to Order History",
            BeingProcessed: "In lavorazione",
            BillingAddress: "Indirizzo di Fatturazione",
            ContactCustomerService: "Contact Customer Service",
            Currency: "Valuta",
            DateOrdered: "Data Ordine",
            DateRange: "Filtra per data",
            DownloadPDF: "Scarica il PDF",
            Export: "Export",
            InvoiceNumber: "Numero Fattura",
            ItemDescription: "Descrizione",
            ItemNumber: "Codice Articolo",
            ItemsToBeShipped: "Articoli da spedire",
            Last2Years: "Ultimo 2 Anni",
            Last30Days: "Ultimi 30 giorni",
            Last90Days: "Ultimi 90 giorni",
            LastYear: "Ultimo Anno",
            LinesSearchInputLabel: "Codice Articolo, Ordine di Acquisto, Conferma d’Ordine",
            NoInvoiceMessage: "Invoice not available",
            NoOrdersFound: "Nessun ordine trovato.",
            NoPDFMessage: "No PDF found for this order",
            NoPurchaserInfo: "L'ordine non è stato inserito via FE-Direct",
            NoResultsFound: "No results found for this order.",
            OrderConfirmation: "Your order has been submitted.",
            OrderDate: "Data Ordine",
            OrderNumber: "Numero d’Ordine",
            OrderStatus: "Stato dell’ordine",
            OrderTotal: "Totale Ordine",
            PartiallyShipped: "Partially Shipped",
            Payments: "Pagamento",
            PDF: "PDF",
            PoNumberLabel: "Ordine di Acquisto",
            ProductTotal: "Prezzo totale",
            QtyBackOrderedLabel: "Q.tà non disponibile a magazzino",
            QtyLabel: "Q.tà",
            QuoteDate: "Date Created",
            QuoteNumber: "Quote Number",
            RequestDate: "Data Richiesta",
            RequestedDate: "Data Richiesta",
            SearchButtonText: "Cerca",
            SearchForOrders: "Search For Orders",
            SearchInputLabel: "Ordine di Acquisto, Conferma d’Ordine",
            SearchInputLabelPlaceholder: "Cerca",
            SelectStatus: "Tutti gli stati",
            ShareViaWhatsApp: "Condividi via Whatsapp",
            ShipDate: "Data di Spedizione Prevista",
            Shipped: "Shipped",
            Status: "Stato Ordine",
            SubtotalOfProduct: "Totale Netto",
            SummaryTitle: "Order Summary",
            Total: "Totale",
            TotalFreight: "Totale Trasporto",
            TotalTax: "Totale IVA",
            UnitPrice: "Prezzo unitario",
            ViewDetails: "Vedi Dettagli"
        },

        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new OrdersStrings();
