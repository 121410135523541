import React from 'react';
import PropTypes from 'prop-types';

export default class QuantityInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateButtonIsVisible: false,
        }
    }

    handleQtyBlur = (e) => {
        if (this.nodeQuantity && this.nodeQuantity.contains(e.target)) {
            this.setState({ updateButtonIsVisible: false });
            this.handleQtyUpdateClick();
            return;
        }

    }

    handleQtyOnFocus = (e) => {
        this.setState({ updateButtonIsVisible: true });
    }

    handleQtyChange = (e) => {
        this.setState({ updateButtonIsVisible: true });
        if (this.isPositiveNumber(e.target.value) || e.target.value === "") {
            let newItem = this.props.item;
            if (e.target.value === "") {
                newItem.quantity = e.target.value;
            }
            else {
                newItem.quantity = parseInt(e.target.value);
            }
            this.props.updateCartItem(newItem);
        }
    }

    isPositiveNumber = (value) => {
        let exp = new RegExp(/^[1-9]\d*$/)
        return exp.test(value);
    }

    handleQtyKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.handleQtyUpdateClick();
        }
    }

    handleQtyUpdateClick = () => {
        let quantity = this.props.item.quantity;
        this.props.updateLineItemQuantity(this.props.item, quantity);
        this.setState({ updateButtonIsVisible: false });
    }

    render() {
        const { localized } = this.props;
        return (
            <div className="cart-quantity">
                <div className="label">{localized.QuantityLabel}:</div>
                {
                    this.state.updateButtonIsVisible &&
                    <div className="update button-wrapper small">
                        <button className="button" onClick={this.handleQtyUpdateClick} >{localized.ButtonText_Update}</button>
                    </div>
                }
                <div className="fele-content" ref={node => (this.nodeQuantity = node)}>
                    <div className="flex">
                        <div className="input-wrapper">
                            <input
                                inputMode="decimal"
                                pkeyfilter="pnum"
                                type="text"
                                value={this.props.item.quantity}
                                name="quantity"
                                onChange={this.handleQtyChange}
                                onBlur={this.handleQtyBlur}
                                onFocus={this.handleQtyOnFocus}
                                onKeyUp={this.handleQtyKeyUp}
                                autoComplete="off" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

QuantityInput.propTypes = {
    item: PropTypes.object,
    updateCartItem: PropTypes.func,
    updateLineItemQuantity: PropTypes.func,
};