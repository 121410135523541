export default {
    user: {
        isAuthorized: false,
        selectedUser: null,
        currentUser: null,
        filteredUsers: [],
        customers: [],
        selectedCustomer: null
    },
    cart: {
        items: [],
        netSubtotal: 0,
        netCurrency: "",
        errors: [],
        warnings: [],
        estimatedDeliveryDate: null,
        shippingMethod: "",
        requestDate: new Date(),
        poNumber: "",
        phoneNumber: "",
        deliveryInstructions: "",
        deliveryInstructions2: "",
        accountString: "",
        preferredString:"",
        comments: "",
        paymentTermId: "",
        paymentInstrument: "",
        dropshipAddress: null,
        fetchingPricesAndAvailability: [],
        storefrontCarrierId: "",
        storefrontCarrier2Id: "",
        freightHandlingCode: "",
        repriceMessageDisplayed: true,
        itemCount: 0,
    },
    settings: {
        frontendSettings: null,
    },
    orders: {
        historyFilters: [],        
    }
};