import React from 'react';

export default class DoubleDownArrow extends React.Component {
    render() {
        return (
            <div className="icon double-down-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.922 9.145">
                    <defs>
                    </defs>
                    <g id="Group_644" data-name="Group 644" transform="translate(972.56 3591)">
                        <g id="up-chevron" transform="translate(-966.637 -3586.855) rotate(180)">
                            <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
                                <path id="Path_3" data-name="Path 3" className="cls-1" d="M2.96,56.4a.267.267,0,0,0-.214.107L.053,60.114a.268.268,0,0,0,.214.429.266.266,0,0,0,.214-.107L2.96,57.113l2.479,3.318a.268.268,0,1,0,.429-.322l-2.693-3.6A.267.267,0,0,0,2.96,56.4Z" transform="translate(0 -56.398)" />
                            </g>
                        </g>
                        <g id="up-chevron-2" data-name="up-chevron" transform="translate(-966.637 -3581.855) rotate(180)">
                            <g id="Group_3-2" data-name="Group 3" transform="translate(0 0)">
                                <path id="Path_3-2" data-name="Path 3" className="cls-1" d="M2.96,56.4a.267.267,0,0,0-.214.107L.053,60.114a.268.268,0,0,0,.214.429.266.266,0,0,0,.214-.107L2.96,57.113l2.479,3.318a.268.268,0,1,0,.429-.322l-2.693-3.6A.267.267,0,0,0,2.96,56.4Z" transform="translate(0 -56.398)" />
                            </g>
                        </g>
                    </g>
                </svg>
            </div >
        );
    }
}
