import React from 'react';
import PropTypes from 'prop-types';
import UserHelper from '../../helpers/UserHelper';
import * as Roles from '../../resources/UserRole';
import AngleDown from '../icons/AngleDown';

export default class PCDiscountCodeSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pcDiscountCodeId: props.item[`pc${props.pcDiscountNumber}DiscountCodeId`]
        };        
    }

    handleDiscountChange = (e) => {
        let newItem = this.props.item;
        newItem[`pc${this.props.pcDiscountNumber}DiscountCodeId`] = e.target.value;
        this.props.updateCartItem(newItem);
        this.setState({ pcDiscountCodeId: e.target.value });
    }


    render() {
        const localized = this.props.localized;
        if (!this.props.pcDiscountCodes[`PC${this.props.pcDiscountNumber}`]) {
            return null
        }
        return (
            <div className="discount mb-3 align-baseline">
                <div className="fele-content">
                    <div className="input-wrapper">
                        <div className="select-wrapper">
                            <select disabled={!UserHelper.hasRoles([Roles.EDIT_ORDER_DETAILS], this.props.currentUser)} onChange={this.handleDiscountChange} value={this.state.pcDiscountCodeId} style={{ minWidth: '90px' }}>
                                <option value="">{localized.DiscountCodesLabel}</option>
                                {
                                    this.props.pcDiscountCodes[`PC${this.props.pcDiscountNumber}`].map(
                                        (pcDiscountCode, index) =>
                                            <option key={index} value={pcDiscountCode.id}>{pcDiscountCode.description}</option>
                                    )}</select>
                            <AngleDown />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PCDiscountCodeSelector.propTypes = {
    currentUser: PropTypes.object,
    pcDiscountCodes: PropTypes.object,
    item: PropTypes.object,
    updateCartItem: PropTypes.func,
    pcDiscountNumber: PropTypes.number
};