import React, { useState, useEffect } from 'react';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import Storefront from '../../services/Storefront';
import Box  from '@mui/material/Box';
import Loading from '../Loading';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton,GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid'; 
import Paper from '@mui/material/Paper';


export const LabelAdministration = (props) => {
    const [translationLabelData, setTranslationLabelData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const storeData = useStorefrontSettings();

    useEffect(() => {
        getTranslationLabels();
    }, []);   

    const customToolbar =()=> {
        return (
            <GridToolbarContainer>
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{
                        tooltip: { title: 'Change density' },
                    }}
                />
                
                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />                
            </GridToolbarContainer>
        );
    }

    const getTranslationLabels = () => {
        setLoading(true);
        Storefront.getStorefrontTranslations(storeData.storefrontNumber, "47D9BE2F-47B4-4A40-9197-B75EDDA280C8").then(loaded);       
    }

    const loaded = (data) => {
        if (data) {
            setTranslationLabelData(data)
            mapRows(data);
        }
        else {
            setTranslationLabelData({})
        }
        setLoading(false)
    }

    const mapRows = (data) => {
        var rows = [];
        data.translations.forEach((translation, index) => {

            rows.push({ id: index, components: translation.componentName, labelName: translation.labelName, labelValue: translation.labelValue });
        });
        setRows(rows);
    }

    const renderUpdateButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={() => {}}
                >
                    Update
                </Button>
            </strong>
        )
    }

    const columns=[
        { field: 'components', headerName: 'Components-replace', width: 200 },
        { field: 'labelName', headerName: 'Label-replace', width: 200 },
        { field: 'labelValue', headerName: 'Label Value', width: 1000 },
        {
            field: "updateButton",
            headerName: "Update",
            renderCell: renderUpdateButton,
            disableClickEventBubbling: true
        }
    ];

    

    return (
        <>            
            <Paper sx={{ height: 700, width: '100%' }}>
                <DataGrid
                    slots={{ toolbar: customToolbar }}                    
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
                    pageSizeOptions={[50, 100]}
                    sx={{ border: 0 }}
                />
            </Paper>            
        </>
    );
}

export default LabelAdministration;

