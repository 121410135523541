import React from 'react';
import { withProductConfig } from '../../hooks/ProductConfigContext';
import DateDisplay from '../DateDisplay';
import DateHelper from '../../helpers/DateHelper';

export class EstimatedDate extends React.Component {
    constructor(props) {
        super(props);
        
    }   
    render() {
        if (!this.props.branchStockingType)
            return null;

        return (
            this.props.branchStockingType === "M" && 
            <div className="list-price">
                <div className="label">{this.props.localized.EstimatedShipDate}</div>
                <div className="content">
                    <div className="price">
                            <DateDisplay date={DateHelper.addShipDays(this.props.standardShippingDays)}
                            dateFormat={this.props.DateFormat} />
                    </div>
                </div>
            </div>
        );
    }
}
export default (withProductConfig(EstimatedDate))
