import React from 'react';
import { withRouter } from 'react-router-dom';
import Strings from './Pager.Strings';

class Pager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: null
        }
        this.localized = Strings.localize();
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }

    componentDidMount() {
        this.setState({
            activePage: this.props.activePage
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({activePage:this.props.activePage})
        }
    }

    previousPage() {
        this.setState({ activePage: parseInt(this.state.activePage) - 1 }, () => {            
            this.props.history.push({ pathname: this.updatedUrl(-1), state: { selectedCustomer: this.props.selectedCustomer } });
        })
    }

    nextPage() {
        this.setState({ activePage: parseInt(this.state.activePage) + 1 }, () => {            
            this.props.history.push({ pathname: this.updatedUrl(1), state: { selectedCustomer: this.props.selectedCustomer } });
        })
    }

    updatedUrl(value) {
        let path = this.props.history.location;
        return path.pathname.replace(`/page/${this.state.activePage - value}`, `/page/${this.state.activePage}`) + path.search;
    }

    render() {
        return (
            this.props.count > 1 &&
            <div className="fele-pager">
                <div className="page-navigation">
                    <button
                        disabled={this.state.activePage === 1 ? "disabled" : ""}
                        onClick={this.previousPage}
                        className="previous-page page-link"><span>{"<"}</span></button>
                    <div className="current-page">
                        <span>{ this.localized.Page_Label } </span>
                        <span className="active-page">{this.state.activePage}</span>
                        <span className="page-count"> { this.localized.OfPage_Label} {this.props.count}</span>
                    </div>
                    <button
                        disabled={this.state.activePage === this.props.count ? "disabled" : ""}
                        onClick={this.nextPage}
                        className="next-page page-link">
                        <span>{">"}</span>
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(Pager);

