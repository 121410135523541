import React from 'react';
import ShipmentItem from './ShipmentItem';

export default class ShipmentItems extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { shipment, selectedCustomer, localized } = this.props;
        if (!shipment)
            return null

        return (
            <div className="order-items">
                {
                    this.props.shipment.orderLines.map((item, key) => {
                        return <ShipmentItem item={item} key={key} selectedCustomer={selectedCustomer} localized={localized} />
                    })
                }
            </div>
        );
    }
}
