import React from 'react';

export default class QuoteLarge extends React.Component {
	render() {
		return (
			<div className="icon quotes-icon-large">
				<svg style={{ width: "5rem", height: "6.25rem", marginTop: "15px" }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 66 65">
					<g>
						<g>
							<path fill="gray" d="M59.2,4.6v55.8H4.6V4.6H59.2 M61.3,0H2.5C1.1,0,0,1.1,0,2.5v60C0,63.9,1.1,65,2.5,65h58.8c1.4,0,2.5-1.1,2.5-2.5v-60
								C63.8,1.1,62.7,0,61.3,0L61.3,0z"/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<rect fill="gray" x="27" y="14" width="25" height="2" />
							</g>
						</g>
						<g>
							<g>
								<path fill="gray" d="M18.3,19c-2.8-0.6-3.5-1.1-3.5-2.1v-0.1c0-0.7,0.6-1.4,2-1.4s2.8,0.6,4.2,1.6l1.9-2.7c-1.3-1-2.7-1.6-4.4-1.9V10h-2.3
									v2.3c-3,0.3-4.9,2.1-4.9,4.8l0,0c0,3.2,2.1,4.1,5.4,4.9c2.7,0.6,3.2,1.2,3.2,2v0.1c0,1-0.9,1.5-2.3,1.5c-1.9,0-3.3-0.7-4.8-1.9
									l-2.3,2.7c1.6,1.4,3.5,2.2,5.6,2.4v2.3h2.4v-2.3c2.8-0.3,4.7-2,4.7-4.9v-0.1C23.4,21,21.5,19.9,18.3,19z"/>
								<g>
									<g>
										<rect fill="gray"  x="27" y="19" width="25" height="2" />
									</g>
								</g>
							</g>
							<g>
								<g>
									<rect fill="gray"  x="27" y="24" width="25" height="2" />
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<rect fill="gray" x="27" y="38" width="25" height="2" />
							</g>
						</g>
						<g>
							<g>
								<path fill="gray" d="M18.3,43.1c-2.8-0.6-3.5-1.1-3.5-2.1v-0.1c0-0.7,0.6-1.4,2-1.4s2.8,0.6,4.2,1.6l1.9-2.7c-1.3-1-2.7-1.6-4.4-1.9V34h-2.3
									v2.3c-3,0.3-4.9,2.1-4.9,4.8l0,0c0,3.2,2.1,4.1,5.4,4.9c2.7,0.6,3.2,1.2,3.2,2v0.1c0,1-0.9,1.5-2.3,1.5c-1.9,0-3.3-0.7-4.8-1.9
									l-2.3,2.7c1.6,1.4,3.5,2.2,5.6,2.4v2.3h2.4v-2.3c2.8-0.3,4.7-2,4.7-4.9v-0.1C23.4,45,21.5,43.9,18.3,43.1z"/>
								<g>
									<g>
										<rect fill="gray" x="27" y="43" width="25" height="2" />
									</g>
								</g>
							</g>
							<g>
								<g>
									<rect fill="gray"  x="27" y="48" width="25" height="2" />
								</g>
							</g>
						</g>
					</g>
				</svg>

			</div>
		);
	}
}