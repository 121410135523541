import React from 'react';
import PropTypes from 'prop-types';
import PCDiscountCodeSelector from './PCDiscountCodeSelector';
import { withCartConfig } from '../../hooks/CartConfigContext';
import Authorize from '../Authorize';
import * as Roles from '../../resources/UserRole';

export class PCDiscountCodes extends React.Component {

    render() {
        const localized = this.props.CartConfig.labels;
        if (this.props.CartConfig.childComponents.LinePCDiscountCodes.shown === false || !this.props.CartConfig.pcDiscountCodes) {
            return null
        }
        return (
            <Authorize hasRoles={[Roles.CAN_VIEW_PC_CODES]} displayMessage={false} >
                <PCDiscountCodeSelector localized={localized} pcDiscountNumber={1} item={this.props.item} updateCartItem={this.props.updateCartItem} currentUser={this.props.currentUser} pcDiscountCodes={this.props.CartConfig.pcDiscountCodes} requestDate={this.props.requestDate} />
                <PCDiscountCodeSelector localized={localized} pcDiscountNumber={2} item={this.props.item} updateCartItem={this.props.updateCartItem} currentUser={this.props.currentUser} pcDiscountCodes={this.props.CartConfig.pcDiscountCodes} requestDate={this.props.requestDate} />
                <PCDiscountCodeSelector localized={localized} pcDiscountNumber={3} item={this.props.item} updateCartItem={this.props.updateCartItem} currentUser={this.props.currentUser} pcDiscountCodes={this.props.CartConfig.pcDiscountCodes} requestDate={this.props.requestDate} />
            </Authorize >
        )
    }
}

PCDiscountCodes.propTypes = {
    item: PropTypes.object,
    updateCartItem: PropTypes.func,
    currentUser: PropTypes.object,
    pCDiscountCodes: PropTypes.object,
    requestDate: PropTypes.object
};

export default withCartConfig(PCDiscountCodes);