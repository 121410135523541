import * as React from 'react';
import { useState, useEffect } from 'react';
import storefrontService from '../services/Storefront';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import * as Components from '../resources/Components';
import Localization from '../services/Localization';

const ProductConfigContext = React.createContext();

function ProductConfigProvider({ children, ...props }) {

    // have all the config settings here.
    const [productConfigLoaded, setProductConfigLoaded] = useState(false);
    const [settings, setSettings] = useState(props.settings || []);
    const [childComponents, setChildComponents] = useState(props.childComponents || {});
    const [labels, setLabels] = React.useState({});
    const { locale } = useStorefrontSettings();

    // might have to just make this a parameter that gets passed in
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);

    // useEffect?
    useEffect(() => {
        getProductConfigSettings();
    }, []);

    useEffect(() => {
        Localization.getComponentLabels(Components.PRODUCT, locale, storefrontNumber)
            .then(res => setLabels(res))
    }, [locale]);

    // fetchSettings
    const getProductConfigSettings = () => {
        storefrontService.getProductSettings(storefrontNumber).then((res) => {
            setSettings(res.settings || []);
            setChildComponents(res.childComponents || []);
            setProductConfigLoaded(true);
        });
    }

    return (
        <ProductConfigContext.Provider
            value={{
                settings,
                childComponents,
                productConfigLoaded,
                labels
            }}
        >
            {children}
        </ProductConfigContext.Provider >
    );
}

function useProductConfig() {
    const context = React.useContext(ProductConfigContext);
    if (context === undefined) {
        throw new Error('useProductConfig must be used within a ProductConfigContext');
    }

    return context;
}

function withProductConfig(Component) {
    return function WrappedComponent(props) {
        const productConfig = useProductConfig();
        return <Component {...props}
            productConfig={productConfig}
        />;
    }
}

export { ProductConfigProvider, useProductConfig, withProductConfig }