import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AngleDown from '../icons/AngleDown';
import Api from '../../services/Api';
import { withCartConfig } from '../../hooks/CartConfigContext';

const StorefrontCarriers = props => {

    const [storefrontCarriers, setStorefrontCarriers] = React.useState([]);
    const [carrier1, setCarrier1] = React.useState(props.storefrontCarrierId.length > 0 ? props.storefrontCarrierId : "");
    const [carrier2, setCarrier2] = React.useState(props.storefrontCarrier2Id.length > 0 ? props.storefrontCarrier2Id : "");
    const isStateNLE = props.selectedCustomer?.address?.state === "NLE" || false;

    const loadStorefrontCarriers = async () => {
        if (carriersEnabled()) {
            await Api.fetch(`/api/customer/${props.selectedCustomer.id}/carriers`).then((resCarriers) => {
                if (resCarriers) {
                    setStorefrontCarriers(resCarriers);
                }
            });
        }
    }

    useEffect(() => {        
        const loadData = async () => {
            await loadStorefrontCarriers();
        };

        loadData();
    }, []);

    useEffect(() => {
        if (isStateNLE && Array.isArray(storefrontCarriers) && storefrontCarriers.length > 0) {
            const carrierId = String(storefrontCarriers.find(item => item.name === "LOCAL")?.id || "");

            if (carrierId) {
                setCarrier1(carrierId);
                props.updateCarriers({ carrier1: carrierId, carrier2: carrier2 });
            }
        }
    }, [storefrontCarriers, isStateNLE]);

    const handleChange = (e) => {
        setCarrier1(e.target.value);
        props.updateCarriers({ carrier1: e.target.value, carrier2: carrier2 });
    }

    const handleCarrier2Change = (e) => {
        setCarrier2(e.target.value);
        props.updateCarriers({ carrier1: carrier1, carrier2: e.target.value });
    }

    const carriersEnabled = () => {
        return props.CartConfig.childComponents.Carrier1.shown === true || props.CartConfig.childComponents.Carrier2.shown === true;
    }

    const { localized } = props;
    return (
        <>
            {carriersEnabled && storefrontCarriers && storefrontCarriers.length > 0 && !isStateNLE &&
        <div className="section">
            <div className="heading3">
                {localized.StorefrontCarriersLabel}
            </div>
            <div className="fele-cart-storefront-carriers">
                <div className="fele-content">
                    <div className="input-wrapper">
                        {
                            props.CartConfig.childComponents.Carrier1.shown === true &&
                            <div className="select-wrapper">
                                <select onChange={handleChange} value={carrier1}>
                                    <option value="" style={{ color: 'gray' }}>{localized.SelectACarrier}</option>
                                    {
                                        storefrontCarriers.map(
                                            (storefrontCarrier, index) =>
                                                <option key={index} value={storefrontCarrier.id}>{storefrontCarrier.name}</option>
                                        )}</select>
                                <AngleDown />
                            </div>
                        }
                        {
                            props.CartConfig.childComponents.Carrier2.shown === true &&
                            <div className="select-wrapper">
                                <select onChange={handleCarrier2Change} value={carrier2}>
                                    <option value="" style={{ color: 'gray' }}>{localized.SelectACarrier2}</option>
                                    {
                                        storefrontCarriers.map(
                                            (storefrontCarrier, index) =>
                                                <option key={index} value={storefrontCarrier.id}>{storefrontCarrier.name}</option>
                                        )}</select>
                                <AngleDown />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
            } 
        </>
    )
}

StorefrontCarriers.propTypes = {
    carriersUpdated: PropTypes.func,
    selectedCustomer: PropTypes.object,
    storefrontCarrierId: PropTypes.string,
    storefrontCarrier2Id: PropTypes.string,
};

export default withCartConfig(StorefrontCarriers);