import React from 'react';
import PropTypes from 'prop-types';
import AngleDown from '../icons/AngleDown';
import UserHelper from '../../helpers/UserHelper';
import * as Roles from '../../resources/UserRole';
import { withCartConfig } from '../../hooks/CartConfigContext';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import CheckBox from '../form-controls/CheckBox';

export class PaymentTerms extends React.Component {
    constructor(props) {
        super(props);
       this.state = {
            paymentTermsArray :[{ id: "", instrument: ' ', description: '', brand: '' }, ...this.props.CartConfig.storefrontPaymentTerms]
        }
    }

    handleChange = (e) => {
        let paymentInstrument = "";
        const paymentInstruments = this.props.CartConfig.storefrontPaymentTerms.filter(function (i) {
            return i.id === e.target.value;
        });
        if (paymentInstruments.length > 0 && paymentInstruments[0].instrument.trim()) {
            paymentInstrument = paymentInstruments[0].instrument;
        }
        else if (this.props.editingQuote) {
            paymentInstrument = this.props.selectedCustomer.paymentInstrument;
        }

        this.props.updatePaymentTerms({ "paymentTermId": e.target.value, "paymentInstrument": paymentInstrument });
    }

   

    handleCashPayment = () => {
        if (this.props.isCashPayment) {
            this.props.updatePaymentTerms({ "paymentTermId": "" });            
        }
        else {
            this.props.updatePaymentTerms({ "paymentTermId": this.props.StorefrontConfig.settingsList.CashPaymentTerm });
        }
        !this.props.isCashPayment;
    }

    render() {
        if ((this.props.CartConfig.childComponents.PaymentTerms.shown === false) || !this.props.CartConfig.storefrontPaymentTerms || this.props.CartConfig.storefrontPaymentTerms.length < 1) {
            return null
        }
        const localized = this.props.CartConfig.labels;
         
        return (
            <React.Fragment>
                <div className="fele-cart-payment-terms"
                    style={UserHelper.hasRoles([Roles.EDIT_ORDER_DETAILS], this.props.currentUser) ? { opacity: 1 } : { opacity: 0.6 }} >
                    <div className="label">
                        {localized.PaymentTermsLabel}
                    </div>
                    <div className="fele-cart-storefront-carriers">
                        <div className="fele-content">
                            <div className="input-wrapper">
                                <div className="select-wrapper">
                                    <select disabled={this.props.isFetching || !UserHelper.hasRoles([Roles.EDIT_ORDER_DETAILS], this.props.currentUser)} onChange={this.handleChange} value={this.props.paymentTermId}>                                        
                                        {
                                            this.state.paymentTermsArray.map(
                                                (storefrontPaymentTerm, index) =>
                                                    storefrontPaymentTerm.id === ""
                                                        ?
                                                        <option key={index} value="">{localized.SelectLabel}</option>
                                                    : < option key={index} value={storefrontPaymentTerm.id} > {storefrontPaymentTerm.id + ' - ' + storefrontPaymentTerm.description}</option>
                                            )
                                        }                                              
                                    </select>
                                    <AngleDown />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                this.props.CartConfig.childComponents.PaymentInstrument.shown &&
                <div className="fele-cart-payment-instrument"
                    style={UserHelper.hasRoles([Roles.EDIT_ORDER_DETAILS], this.props.currentUser) ? { opacity: 1 } : { opacity: 0.6 }} >
                    <div className="label">
                        {localized.PaymentInstrumentLabel}
                    </div>
                    <div className="fele-content">
                        <div className="input-wrapper">
                            <input id="PaymentInstrumentInput" type="text" readOnly value={this.props.paymentInstrument} />
                        </div>
                    </div>
                </div>
                }
                {
                    this.props.CartConfig.childComponents.CashInAdvance.shown && UserHelper.hasRoles([Roles.CAN_SELECT_CASH_PAYMENT], this.props.currentUser) &&
                    <div className="fele-cart-cash-payment"
                        style={this.props.selectedCustomer.paymentTermId === this.props.StorefrontConfig.settingsList.CashPaymentTerm ? { opacity: 0.6 } : { opacity: 1 }} >
                        <div className="label">
                            {localized.CashPaymentLabel}
                        </div>
                        <div className="fele-content">
                            <div className="button-wrapper" >
                                <CheckBox onClick={this.handleCashPayment}
                                    disabled={this.props.selectedCustomer.paymentTermId === this.props.StorefrontConfig.settingsList.CashPaymentTerm}
                                        checked={this.props.isCashPayment || this.props.paymentTermId === this.props.StorefrontConfig.settingsList.CashPaymentTerm}
                                />
                            </div>
                        </div>
                    </div>
                }

            </React.Fragment>
        )
    }
}

PaymentTerms.propTypes = {
    currentUser: PropTypes.object,
    selectedCustomer: PropTypes.object,
    updatePaymentTerms: PropTypes.func,
    paymentTermId: PropTypes.string,
    editingQuote: PropTypes.bool,
    paymentInstrument: PropTypes.string,
    components: PropTypes.object,
    storefrontPaymentTerms: PropTypes.array,
    isCashPayment: PropTypes.bool,
};

export default withStorefrontConfig(withCartConfig(PaymentTerms));