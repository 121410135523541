import React, { useEffect, useState } from 'react';
import Api from '../services/Api';
import UserService from '../services/UserService';

export const LanguageSelect = props => {
    const [locales, setLocales] = useState([]);
    const [language, setLanguage] = useState(props.language || {});
    const localized = props.localized;
    const languages = [];
    languages.push({ name: "English", value: localized.English }, { name: "Spanish", value: localized.Spanish }, { name: "Spanish_Argentina", value: localized.Spanish_Argentina }, { name: "Spanish_Chile_Peru", value: localized.Spanish_Chile_Peru }, { name: "Italian", value: localized.Italian }, { name: "French", value: localized.French }, { name: "Portuguese", value: localized.Portuguese });
 
    useEffect(() => {
        fetchLocale();
    }, []);

    function fetchLocale() {
        return Api.fetch(`/api/Labels/locales`)
            .then(loadLocale);
    }

    async function handleLanguageChange(e) {
        setLanguage(e);
        await UserService.setUserLanguage(props.user.currentUser.id, e);
        props.changeLanguage(e);
    }

    function loadLocale(response) {
        if (response) {
            setLocales(response);
        }
    }

    function mapLanguagesToLocale(e) {
        switch (e.target.value) {
            case "English": handleLanguageChange("en_US")
                break;
            case "Spanish": handleLanguageChange("es_MX")
                break;
            case "Portuguese": handleLanguageChange("pt_BR")
                break;
            case "Italian": handleLanguageChange("it_IT")
                break;
            case "French": handleLanguageChange("fr_FR")
                break;
            case "Spanish_Argentina": handleLanguageChange("es_AR")
                break;  
            case "Spanish_Chile_Peru": handleLanguageChange("es_CL")
                break;
            default: ""
                break;
                
        }
    }

    return (
        <div className='item' style={{ display: 'flex', flexDirection: 'row' }}>
            <select onChange={mapLanguagesToLocale} value={props.language}>
                {
                    <React.Fragment>
                    <option value={0}>{localized.SelectLanguage}</option>
                    {
                            Object.keys(languages).map((o) =>
                                <option key={o} value={languages[o].name}>{languages[o].value}</option>
                            )
                    }
                </React.Fragment>
                }
            </select>
        </div >
    );
}

export default LanguageSelect;