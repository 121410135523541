import React from 'react';
import { Switch, Route } from "react-router-dom";
import LabelAdministration from './LabelAdministration';
import AdminIndex from './AdminIndex';
import FreightHandlingCodeAdministration from './FreightHandlingCodeAdministration';

export default function StorefrontAdministration(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/storefront-administration/label-administration"
                ]}
                render={(props) => (
                    <LabelAdministration {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/storefront-administration/freight-handling-code-administration"
                ]}
                render={(props) => (
                    <FreightHandlingCodeAdministration {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/storefront-administration/index",
                    "/storefront-administration",
                ]}
                render={(props) => (
                    <AdminIndex {...parentProps} {...props} />
                )}
            />
            
        </Switch>
    );
}