import React from "react";
import PropTypes from "prop-types";

const AdditionalCommentsInput = ({
  setDetailsCommentSubmit,
  detailComment,
  editable,
  needsValidation,
  required,
}) => {
  const handleChange = (e) => {
    let comments = e.target.value;
    setDetailsCommentSubmit(comments);
  };

  const inputClass = needsValidation
    ? required && detailComment === ""
      ? "is-invalid"
      : "is-valid"
    : "";

  return (
    <div className="fele-cart-comments">
      <div className="fele-content">
        <div className="input-wrapper">
          <textarea
            className={`form-control ${inputClass}`}
            value={detailComment}
            onChange={handleChange}
            onBlur={handleChange}
            readOnly={editable}
            autoresizeenabled="false"
            rows="3"
            required={required}
            style={{ resize: "none", fontSize: "1.4rem" }}
          />
        </div>
      </div>
    </div>
  );
};

AdditionalCommentsInput.propTypes = {
  setDetailsCommentSubmit: PropTypes.func,
  detailComment: PropTypes.string,
  editable: PropTypes.bool,
};

export default AdditionalCommentsInput;
