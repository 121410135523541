import React from 'react';

export default class User extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            this.props.user &&
            <div className="button-wrapper">
                <button data-cy="fele-user" className={`user ${this.props.user.isActive === true ? "active" : ""} ${this.props.isSelected ? "is-visible" : ""}`}>
                    <div className="col">
                        <div className="indicator" style={{ backgroundColor: (this.props.user.isActive === true ? 'limegreen' : 'lightgray') }}></div>
                    </div>
                    <div className="col-wrapper">
                        <div className="col">
                            <div className="name">{this.props.user.firstName} {this.props.user.lastName}</div>
                            <div className="role">{this.props.user.personaDisplayName}</div>
                        </div>
                        <div className="col">
                            <div className="number"></div>
                            <div className="email">{this.props.user.email}</div>
                        </div>
                    </div>
                </button>
            </div>
        )
    }
}