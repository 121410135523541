import LocalizedStrings from 'react-localization';
import Localization from './../services/Localization';

class LoadingStrings {
    localized = new LocalizedStrings({
        en: {
            Loading:"Loading"
        },

        pt: {
            Loading:"Carregando"
        },

        es: {
            Loading: "Cargando"
        },

        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new LoadingStrings();
