import React from 'react';
import UsersService from '../../services/UserService';
import Notifications from '../../services/Notifications';
import Authorize from '../Authorize';
import CustomerSelector from '../CustomerSelector';
import UserHelper from '../../helpers/UserHelper';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import Loading from '../Loading';
import StorefrontPersona from './StorefrontPersona';

export class Create extends React.Component {
    constructor(props) {
        super(props);

        const defaultStorefront = props.defaultStorefront || this.props.StorefrontConfig.getStorefrontByNumber(props.currentUser.storefrontNumber);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            selectedPersona: { id: '', value: '' },
            isActive: true, // should create users as active
            selectIsOpen: false,
            selectedCustomer: null,
            selectedStorefront: defaultStorefront,
        }

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.updateSelectedCustomer = this.updateSelectedCustomer.bind(this);
        this.setOpenFromList = this.setOpenFromList.bind(this);
    }

    clearForm() {
        const defaultStorefront = this.props.StorefrontConfig.getStorefrontByNumber(props.currentUser.storefrontNumber);
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            selectedPersona: null,
            selectedStorefront: { value: defaultStorefront.id, label: defaultStorefront.displayName },
        })
    }

    updateStorefrontPersona = (storefront, persona) => {
        this.setState({ selectedStorefront: storefront, selectedPersona: persona });
    }

    updateSelectedCustomer = (customer) => {
        this.setState({ selectedCustomer: customer });
    }

    setOpenFromList = (openState) => {
        this.setState({ selectIsOpen: openState });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    submit() {
        const localized = this.props.StorefrontConfig.labels;
        let storefront = this.props.StorefrontConfig.getStorefrontById(this.state.selectedStorefront.value);
        let user = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            persona: { id: this.state.selectedPersona.value, name: this.state.selectedPersona.label },
            storefrontId: storefront.id,
            storefrontNumber: storefront.number,
            isActive: this.state.isActive,
        };
        let isValid = true;

        if (!UserHelper.isValidEmail(user.email))
            isValid = notValid(localized.InvalidEmailMessage);

        if (this.state.role === "")
            isValid = notValid(localized.SelectRoleMessage);

        if (this.props.currentUser.role === "Customer_Administrator" && !this.state.selectedCustomer) {
            isValid = notValid(localized.SelectInitialCustomerMessage);
        }
        if (!user.storefrontNumber) {
            isValid = notValid(localized.SelectStorefrontMessage);
        }
        if (isValid) {
            UsersService.add(user, this.state.selectedCustomer, this.props.callback);
        }
    }

    render() {
        const localized = this.props.StorefrontConfig.labels;
        if (this.props.StorefrontConfig.storefrontSettingsLoaded !== true) {
            return (<Loading type="brand" />)
        } else {
            return (
                <div className={`fele-user-create ${(this.state.isActive) ? "active" : ""}`}>
                    <div className="form-wrapper">
                        <div className="title">{localized.NewUserTitle}</div>
                        <div className="form">
                            <div className="control-wrapper">
                                <div className="label">{localized.FirstName}</div>
                                <div className="input-wrapper">
                                    <input name="firstName" type="text" value={this.state.firstName} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="control-wrapper">
                                <div className="label">{localized.LastName}</div>
                                <div className="input-wrapper">
                                    <input name="lastName" type="text" value={this.state.lastName} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="control-wrapper">
                                <div className="label">{localized.EmailLabel}</div>
                                <div className="input-wrapper">
                                    <input name="email" type="email" value={this.state.email} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="control-wrapper">
                                <StorefrontPersona updateStorefrontPersona={this.updateStorefrontPersona} currentUser={this.props.currentUser} />
                            </div>
                            <Authorize as={["CustomerAdministrator"]} displayMessage={false}>
                                <div className="control-wrapper">
                                    <div className="label">Select Initial Customer</div>
                                    <div className="shipto">
                                        <CustomerSelector showLabel={false} customers={this.props.customers} addCustomer={true} selectedCustomer={this.state.selectedCustomer} updateSelectedCustomer={this.updateSelectedCustomer} />
                                    </div>
                                </div>
                            </Authorize>
                            <div className="button-wrapper">
                                <button name="addUser" className="button submit" onClick={this.submit}>+ {localized.ButtonText_AddUser}</button>
                            </div>
                        </div>
                    </div>
                </div >
            )
        }
    }
}
function notValid(message) {
    Notifications.error(message);
    return false;
}

export default withStorefrontConfig(Create);