import React from 'react';

export default class User extends React.Component {
    render() {
        return (
            <div className="icon user-icon">
                <svg id="messenger-user-avatar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.098 54.787">
                    <defs>
                    </defs>
                    <g id="Group_45" data-name="Group 45">
                        <path id="Path_75" data-name="Path 75" className="cls-1" d="M48.592,52.567a2.226,2.226,0,0,0,2.22,2.22H88.471a2.226,2.226,0,0,0,2.22-2.22c0-12.282-5.549-22.825-13.354-27.042a13.928,13.928,0,1,0-15.352,0C54.141,29.743,48.592,40.286,48.592,52.567ZM69.641,4.439a9.47,9.47,0,1,1-9.47,9.47A9.488,9.488,0,0,1,69.641,4.439Zm0,23.491c8.656,0,15.759,9.877,16.536,22.418H53.105C53.882,37.807,60.985,27.93,69.641,27.93Z" transform="translate(-48.592)" />
                    </g>
                </svg>
            </div>
        );
    }
}
