import React from "react";
import Strings from '../../translations/ModalPopup.Strings';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import ViewModal from '../shared/ViewModal';
import { Container, Row, Col } from 'react-bootstrap';

export default function CompletePricing({
    isOpen,
    completeLinePricingData,
    onClose
}) {

    const localized = Strings.localize();
    const salesHistoryConfig = useSalesHistoryConfig();
    const localizedFromSalesHistoryConfig = salesHistoryConfig.labels;


    //The add function has an exact copy used for testing in the corresponding test class.  Any update to it must also be updated in the test class.
    //This due to testing limitations of current React version (instance() can not be called through children as of 16.x) and the need to test this business logic which, for performance reasons, resides in the javascript.
    //If a future react update would make testing this function properly possible, please write better tests!
    const add =()=>  {
        let total = 0;
        completeLinePricingData.forEach(a => {
            if (!a.override) {
                total = total + a.unitPrice;
            }
            else {
                total = 0 + a.unitPrice;
            }            
        });
        return Number(Math.round(total + 'e4') + 'e-4').toFixed(4);
    }

    const renderPricingLines = () => {
        return completeLinePricingData.map(({ descAdjName, factorValueNumeric, unitPrice, basePrice }) => {
            return <tr key={descAdjName} >
                <td className="t-left">{descAdjName}</td>
                <td className="t-right">{basePrice ? '' : factorValueNumeric.toFixed(4)}</td>
                <td className="t-right">{unitPrice.toFixed(4)}</td>
            </tr>
        })
    }

    const renderHeader = () => {
        return <tr className="header">
            <th className="t-header-padding"></th>
            <th className="t-header-padding">{localizedFromSalesHistoryConfig.FactorValue}</th>
            <th className="t-header-padding">{localizedFromSalesHistoryConfig.UnitPriceCompletePricingDetails}</th>
        </tr>
    }

    const renderTable = () => {
        return (         
            <table className="fele-pricing-modal t-float-right">
                    {renderHeader()}
                    <tbody>
                        {renderPricingLines()}
                    </tbody>
                    {renderFooter()}
            </table>           
                
        )
    }
    const renderFooter = () => {
        return <tr>
            <th className="t-footer-left"></th>
            <th className="t-footer-left">{localizedFromSalesHistoryConfig.TotalUnitPrice}</th>
            <th className="t-footer-right">{add()}</th>
        </tr>
    }
    return (
        <ViewModal
            isOpen={isOpen}
            onClose={onClose}
            closeText={localized.Close_Label}
        >            
            <Container>
                <Row>
                    <Col xs={8} >
                        <div className="heading2" style={{ paddingBottom: '1.2rem' }}>
                            {localizedFromSalesHistoryConfig.CompletePricingDetails}
                        </div>
                    </Col>
                </Row>
                <Row> &nbsp;</Row>
               <Row>
                   {renderTable()}
               </Row>
                <Row> &nbsp;</Row>
            </Container>
        </ViewModal>
    );
}