import React from 'react';

export default class LeaoLogo extends React.Component {
    render() {
        return (
            <div className="brand-logo leao-logo">

                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 370 134" enableBackground="new 0 0 370 134">
                    <g>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#0047BA" d="M54.2,1l15.5,8.5v8.3h2.8V9.5h11l21,17H88.6L101,38.8h-4.7
                            l-1.5,2.3l10,11.7l-4,22.1l-8.3-8.8L91,68.2l12,13.5l-32.6,45.1l-4.2-7.2L56.7,133h-3.3L66,115H38.9l12.8,18h-3.5l-9.5-13.3
                            l-4.2,7.2L2.1,81.7l12-13.5l-1.5-2.2l-8.5,8.8l-4-22.1l10.2-11.7l-1.5-2.3H3.9l12.3-12.3H0.4l21-17h11.2v8.3h2.8V9.5L50.7,1H54.2
                            L54.2,1z M38.7,113.4v-7l-18.1-15l3.5-9.3l-11.7-20V46.8h2.2v15L25,79.4l1.8-5.2l2,1.5l-5.8,15l15.6,13l13.5-10.2L41.4,80.9V64.1
                            l-15.6-12l14,2.3l4,9.7v16.8H61V64.1l4-9.7l14-2.3l-15.6,12v16.8l-11,12.7l13.5,10.2l15.8-13l-5.8-15l2-1.5l1.8,5.2l10.5-17.6v-15
                            h2.2v15.3l-11.7,20l3.5,9.3l-18.1,15v7H38.7L38.7,113.4z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#0047BA" d="M61.5,111.2l4.7-4.7l-13.6-10l-13.5,10l4.8,4.7H61.5L61.5,111.2z
                            M63.2,106.7l-10.7-7.2L42,106.7H63.2L63.2,106.7z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#0047BA" d="M118.1,68.9v22.3c0,3.1,1.7,5.2,4.9,4.9l28.6,0v-7.2h-15.5v-20
                            H118.1L118.1,68.9z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#0047BA" d="M155.6,73.7v18c0.3,3.1,3.1,4.6,6.3,4.3l28.3,0v-6.9H174V86h16.3
                            v-6.6H174v-2.9h16.3v-7.4h-29.8C156.8,69.4,155.6,71.1,155.6,73.7L155.6,73.7z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#0047BA" d="M234.6,72.9l10.3,23.5h-18.3l-2.9-4.9h-8l-2,4.9h-18.3l10.3-23.2
                            c0.9-2.9,3.1-4,6.9-4l16.6,0C232.3,69.1,234.3,70.6,234.6,72.9L234.6,72.9z M217.7,84.6l2.6-6.9l2.3,6.9H217.7L217.7,84.6z"/>
                        <polygon fillRule="evenodd" clipRule="evenodd" fill="#0047BA" points="210.6,66.3 210.6,63.1 229.8,63.1 229.8,66.3 210.6,66.3 
                                "/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#0047BA" d="M252.9,69.1h39.5c2.3,0,4.6,1.7,4.6,4v18.3c0,2.6-2.3,4.9-4.6,4.9
                            l-39.5,0c-2.9,0-4.6-2.3-4.6-4.9l0-18.3C248.4,70.9,250.1,69.1,252.9,69.1L252.9,69.1z M269.5,76.3h8.3c1.1,0,2.3,1.1,2.3,2.6
                            l0,7.7c0,1.4-1.1,2.6-2.3,2.6l-8.3,0c-1.4,0-2.6-1.1-2.6-2.6l0-7.7C267,77.4,268.1,76.3,269.5,76.3L269.5,76.3z"/>
                        <path fillRule="evenodd" clipRule="evenodd" fill="#0047BA" d="M137,46.6c1.9,0.1,3.5,0.6,4.6,1.4c1.1,0.9,1.7,1.9,1.7,3
                            c0,1.3-0.8,2.4-2.3,3.4c-1.5,1-3.6,1.4-6.3,1.4l-16.5,0V38h15.3c2.8,0,5,0.5,6.5,1.4c1.5,0.9,2.2,2,2.2,3.3c0,1-0.5,1.8-1.4,2.6
                            C140,46,138.7,46.4,137,46.6L137,46.6z M126.5,48.7v3.2h4.4c1,0,1.8-0.2,2.4-0.5c0.6-0.3,0.9-0.7,0.9-1.1c0-0.5-0.3-0.9-0.8-1.2
                            c-0.5-0.3-1.3-0.5-2.4-0.5L126.5,48.7L126.5,48.7z M126.5,44.8h4.8c0.7,0,1.2-0.1,1.6-0.4c0.4-0.3,0.6-0.6,0.6-1
                            c0-0.5-0.2-0.8-0.7-1.1c-0.5-0.3-1.2-0.4-2.2-0.4l-4.1,0L126.5,44.8L126.5,44.8z M155.4,47c0,3.4,1.5,5.1,4.5,5.1
                            c3,0,4.5-1.7,4.5-5.1c0-1.6-0.4-2.9-1.1-3.8c-0.8-0.9-1.9-1.4-3.3-1.4c-1.3,0-2.4,0.4-3.2,1.2C155.8,43.7,155.4,45.1,155.4,47
                            L155.4,47z M145.6,47c0-2.7,1.3-5,4-6.8c2.5-1.6,6-2.5,10.3-2.5c2.1,0,4.1,0.2,5.8,0.6c1.7,0.4,3.2,1,4.5,1.8
                            c1.2,0.8,2.2,1.8,2.9,2.9c0.7,1.1,1,2.4,1,3.9c0,2-0.7,3.7-2,5.1c-1.3,1.4-3.1,2.4-5.3,3.1c-2.2,0.7-4.5,1-7,1
                            c-2.5,0-4.8-0.4-7-1.1c-2.2-0.7-3.9-1.7-5.3-3.1C146.3,50.6,145.6,49,145.6,47L145.6,47z M177.4,55.9V38h12l4.3,9.6l4-9.6h12.1
                            v17.9h-8.5V43l-5.5,12.9H190l-5.7-12.8v12.8H177.4L177.4,55.9z M233.4,46.6c1.9,0.1,3.5,0.6,4.6,1.4c1.1,0.9,1.7,1.9,1.7,3
                            c0,1.3-0.8,2.4-2.3,3.4c-1.5,1-3.6,1.4-6.3,1.4l-16.5,0V38H230c2.8,0,5,0.5,6.5,1.4c1.5,0.9,2.2,2,2.2,3.3c0,1-0.5,1.8-1.4,2.6
                            C236.4,46,235.1,46.4,233.4,46.6L233.4,46.6z M223,48.7v3.2h4.4c1,0,1.8-0.2,2.4-0.5c0.6-0.3,0.9-0.7,0.9-1.1
                            c0-0.5-0.3-0.9-0.8-1.2c-0.5-0.3-1.3-0.5-2.4-0.5L223,48.7L223,48.7z M223,44.8h4.8c0.7,0,1.2-0.1,1.6-0.4c0.4-0.3,0.6-0.6,0.6-1
                            c0-0.5-0.2-0.8-0.7-1.1c-0.5-0.3-1.2-0.4-2.2-0.4l-4.1,0L223,44.8L223,44.8z M241.3,55.9l9.4-17.9h9.6l9.3,17.9h-9.3l-1.8-3.5h-8
                            l-1.7,3.5H241.3L241.3,55.9z M257,48.4l-2.4-5.1l-2.4,5.1H257L257,48.4z M270.2,50.7l8.4-1c0.3,0.7,1,1.3,2.1,1.6
                            c1.1,0.4,2.2,0.6,3.4,0.6c1.1,0,2-0.2,2.6-0.4c0.7-0.2,1-0.5,1-0.8c-0.1-0.6-1.8-1.2-5.3-1.7c-3.4-0.5-6.1-1.1-8.1-1.9
                            c-2-0.8-2.8-2.3-2.5-4.5c0.1-1,0.8-1.9,2-2.7c1.2-0.8,2.7-1.4,4.6-1.8c1.9-0.4,3.8-0.7,5.8-0.7c2.6,0,5,0.4,7.2,1.1
                            c2.2,0.7,3.7,1.8,4.8,3.3l-7.5,1.4c-0.3-0.6-0.9-1-1.8-1.3c-0.9-0.3-1.8-0.4-2.9-0.4c-0.9,0-1.7,0.1-2.3,0.3c-0.6,0.2-1,0.4-1,0.7
                            c-0.1,0.5,0.7,0.9,2.3,1.2c1.6,0.2,3.6,0.5,5.9,0.8c2.3,0.3,4.2,0.9,5.7,1.7c1.5,0.8,2.3,2.1,2.4,3.7c0,1.3-0.6,2.4-1.8,3.3
                            c-1.2,0.9-2.8,1.6-4.9,2.1c-2,0.5-4.2,0.7-6.5,0.7c-3.4,0-6.3-0.5-8.9-1.4C272.2,53.8,270.7,52.5,270.2,50.7L270.2,50.7z"/>
                    </g>
                 </svg>

            </div>
        );
    }
}
