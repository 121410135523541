import DateHelper from '../helpers/DateHelper';

class Auth {
    _app = null;

    async run(app) {
        this._app = app;
        let token;

        if (window.sessionStorage)
            token = window.sessionStorage['fele:id_token'];

        // if the token is already set, run the app
        if (token && !this.expired()) {
            app();
        }
        else if (token && this.expired()) {
            await this.refresh_token();
            app();
        }

        // otherwise we need a token
        else {
            // parse the url for any incoming parameters
            var parts = getJsonFromUrl(window.location.href);
            // if B2C has redirected back to us with a "code" then call the proxy to acquire a token
            if (parts.code) {
                await fetch(`/api/Authentication/token`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        code: parts.code
                    })
                }).then((res) => {
                    res.json().then((data) => {
                        window.sessionStorage['fele:id_token'] = data.id_token;
                        window.sessionStorage['fele:refresh_token'] = data.refresh_token;
                        window.sessionStorage['fele:expires_on'] = data.expires_on;
                        app();
                    });
                });
            }
            // if B2C has sent us an error with a specific description, redirect to password reset
            else if (parts.error_description && parts.error_description.indexOf('AADB2C90118') > -1) {
                window.location.href = `${window.appSettings.Instance}${window.appSettings.Tenant}/oauth2/v2.0/authorize?scope=openid%20profile&p=${window.appSettings.ResetPolicy}&client_id=${window.appSettings.ClientId}&redirect_uri=${window.location.protocol}//${window.location.host}&response_type=id_token`;
            }
            // lastly, if no parameters came in, redirect to B2C login flow
            else {
                window.location.href = `${window.appSettings.Instance}${window.appSettings.Tenant}/oauth2/v2.0/authorize?client_id=${window.appSettings.ClientId}&response_type=code&redirect_uri=${window.location.protocol}//${window.location.host}&response_mode=query&scope=openid ${window.appSettings.ClientId} offline_access&p=${window.appSettings.SignInPolicy}`;
            }
        }

        function getJsonFromUrl(url) {
            if (!url) url = window.location.href;
            var question = url.indexOf("?");
            var hash = url.indexOf("#");
            if (hash === -1 && question === -1) return {};
            if (hash === -1) hash = url.length;
            var query = question === -1 || hash === question + 1 ? url.substring(hash) :
                url.substring(question + 1, hash);
            var result = {};
            query.split("&").forEach(function (part) {
                if (!part) return;
                part = part.split("+").join(" "); // replace every + with space, regexp-free version
                var eq = part.indexOf("=");
                var key = eq > -1 ? part.substr(0, eq) : part;
                var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
                var from = key.indexOf("[");
                if (from === -1) result[decodeURIComponent(key)] = val;
                else {
                    var to = key.indexOf("]", from);
                    var index = decodeURIComponent(key.substring(from + 1, to));
                    key = decodeURIComponent(key.substring(0, from));
                    if (!result[key]) result[key] = [];
                    if (!index) result[key].push(val);
                    else result[key][index] = val;
                }
            });
            return result;
        }
    }

    async refresh_token() {
        return new Promise(async (done, error) => {
            await fetch("/api/Authentication/refresh", {
                method: "POST", headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ refresh_token: window.sessionStorage["fele:refresh_token"] })
            }).then((res) => {
                res.json().then((data) => {
                    window.sessionStorage['fele:id_token'] = data.id_token;
                    window.sessionStorage['fele:refresh_token'] = data.refresh_token;
                    window.sessionStorage['fele:expires_on'] = data.expires_on;
                    done(data.id_token);
                });
            });
        });
    }

    async getToken() {
        let token;
        if (window.sessionStorage)
            token = window.sessionStorage['fele:id_token'];

        if (token && !this.expired())
            return token;
        else if (token && this.expired()) {
            token = await this.refresh_token();
            return token;
        }
    }

    signOut(redirectPath = window.location.pathname) {
        window.sessionStorage.removeItem('fele:id_token');
        window.sessionStorage.removeItem('fele:refresh_token');
        window.sessionStorage.removeItem('fele:expires_on');
        window.sessionStorage.removeItem('fele:release_id');
        window.sessionStorage.removeItem('fele:apim_url');
        window.sessionStorage.removeItem('fele:subscription_Key');
        window.location.href = `${window.appSettings.Instance}${window.appSettings.Tenant}/oauth2/v2.0/logout?p=${window.appSettings.SignInPolicy}&post_logout_redirect_uri=${window.location.protocol}//${window.location.host}${redirectPath}`;
    }

    expired() {
        let now = DateHelper.getNewDate();
        let end = new Date(parseInt(window.sessionStorage['fele:expires_on']) * 1000);
        if (DateHelper.differenceInSeconds(end, now) < 300)
            return true;
        return false;
    }
}
export default new Auth();