
class APIMSettingsService {

    async reloadAPIMSettings() {
        return new Promise(async (done, error) => {
            await fetch("/api/settings/getMicroServices", {
                method: "Get", headers: { "Content-Type": "application/json" }
            }).then((res) => {
                res.json().then((data) => {                   
                    window.sessionStorage['fele:release_id'] = data["releaseId"];
                    window.sessionStorage['fele:apim_url'] = data["apiManagementUrl"];
                    window.sessionStorage['fele:subscription_Key'] = data["subscriptionKey"];
                    done(data);
                });
            });
        });
    }

    async getReleaseId() {
        let releaseId;
        if (window.sessionStorage) {
            releaseId = window.sessionStorage['fele:release_id'];
        }
        if (!releaseId) {
            let data = await this.reloadAPIMSettings();
            releaseId = data["releaseId"];
        }
        return releaseId;
    }

    async getSubscriptionKey() {
        let subscriptionKey;
        if (window.sessionStorage) {
            subscriptionKey = window.sessionStorage['fele:subscription_Key'];
        }
        if (!subscriptionKey) {
            let data = await this.reloadAPIMSettings();
            subscriptionKey = data["subscriptionKey"];
        }
        return subscriptionKey;
    }

    async getAPIManagementUrl() {
        let apiManagementUrl;
        if (window.sessionStorage) {
            apiManagementUrl = window.sessionStorage['fele:apim_url'];
        }
        if (!apiManagementUrl) {
            let data = await this.reloadAPIMSettings();
            apiManagementUrl = data["apiManagementUrl"];
        }
        return apiManagementUrl;
    }

}

export default new APIMSettingsService();