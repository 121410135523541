import React from "react";
import Strings from '../../translations/ModalPopup.Strings';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import ViewModal from '../shared/ViewModal';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
export default function TaxByLine({
    isOpen,
    lineTaxData,
    onClose
}) {

    const localized = Strings.localize();
    const salesHistoryConfig = useSalesHistoryConfig();
    const localizedFromSalesHistoryConfig = salesHistoryConfig.labels;


    const add = () => {
        let total = 0;
        lineTaxData.forEach(a => {
            total = total + a.extendedTaxAmount;
        });
        return Number(Math.round(total + 'e4') + 'e-4').toFixed(2);
    }

    const renderPricingLines = () => {
        return lineTaxData.map(({ description, adjustmentControlCode, extendedTaxAmount }) => {
            return <tr key={adjustmentControlCode} >
                <td className="t-left">{description}</td>
                <td className="t-right">{extendedTaxAmount.toFixed(2)}</td>
            </tr>
        })
    }

    const renderHeader = () => {
        return <tr className="header">
            <th className="t-left">{localizedFromSalesHistoryConfig.LineTaxDescription}</th>
            <th className="t-right">{localizedFromSalesHistoryConfig.LineTaxAmount}</th>
        </tr>
    }

    const renderTable = () => {
        return (
            <table className="fele-pricing-modal t-float-right">
                {renderHeader()}
                <tbody>
                    {renderPricingLines()}
                </tbody>
                {renderFooter()}
            </table>

        )
    }
    const renderFooter = () => {
        return <tr>
            <th className="t-footer-left">{localizedFromSalesHistoryConfig.ExtendedLineTaxTotal}</th>
            <th className="t-footer-right">{add()}</th>
        </tr>
    }
    return (
        <ViewModal
            isOpen={isOpen}
            onClose={onClose}
            closeText={localized.Close_Label}
        >
            <Container>
                <Row>
                    <Col xs={8} >
                        <div className="heading2" style={{ paddingBottom: '1.2rem' }}>
                            {localizedFromSalesHistoryConfig.LineTaxDetails}
                        </div>
                    </Col>
                </Row>
                <Row> &nbsp;</Row>
                <Row>
                    {renderTable()}
                </Row>
                <Row> &nbsp;</Row>
            </Container>
        </ViewModal>
    );
}