import React from 'react';

export default class OrderIconLarge extends React.Component {
    render() {
        return (
            <div className="icon order-icon-large">
                <svg style={{ width: "5rem", height: "6.25rem", marginTop: "15px" }} id="office-monthly-calendar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.628 64.368">
                    <defs>

                    </defs>
                    <g id="Group_51" data-name="Group 51" transform="translate(0)">
                        <path fill="gray" d="Path_91" data-name="Path 91" className="cls-1" d="M64.242,45.333H57.583a2.22,2.22,0,1,0,0,4.439h4.439V99.343H7.272V49.772h5.919a2.22,2.22,0,1,0,0-4.439H5.053a2.226,2.226,0,0,0-2.22,2.22v54.01a2.226,2.226,0,0,0,2.22,2.22H64.242a2.226,2.226,0,0,0,2.22-2.22V47.553A2.226,2.226,0,0,0,64.242,45.333Z" transform="translate(-2.833 -39.414)" />
                        <path fill="gray" d="Path_92" data-name="Path 92" className="cls-1" d="M212.508,45.333H206.22a2.22,2.22,0,0,0,0,4.439h6.289a2.22,2.22,0,1,0,0-4.439Z" transform="translate(-177.735 -39.414)" />
                        <path fill="gray" d="Path_93" data-name="Path 93" className="cls-1" d="M95.232,351.333H90.053a2.226,2.226,0,0,0-2.22,2.22v3.7a2.226,2.226,0,0,0,2.22,2.22h5.179a2.226,2.226,0,0,0,2.22-2.22v-3.7A2.226,2.226,0,0,0,95.232,351.333Z" transform="translate(-76.735 -305.462)" />
                        <path fill="gray" d="Path_94" data-name="Path 94" className="cls-1" d="M214.6,351.333h-5.549a2.226,2.226,0,0,0-2.22,2.22v3.7a2.226,2.226,0,0,0,2.22,2.22H214.6a2.226,2.226,0,0,0,2.22-2.22v-3.7A2.226,2.226,0,0,0,214.6,351.333Z" transform="translate(-180.198 -305.462)" />
                        <path fill="gray" d="Path_95" data-name="Path 95" className="cls-1" d="M97.451,260.053a2.226,2.226,0,0,0-2.22-2.22H90.053a2.226,2.226,0,0,0-2.22,2.22v3.7a2.226,2.226,0,0,0,2.22,2.22h5.179a2.226,2.226,0,0,0,2.22-2.22Z" transform="translate(-76.735 -224.169)" />
                        <path fill="gray" d="Path_96" data-name="Path 96" className="cls-1" d="M216.821,260.053a2.226,2.226,0,0,0-2.22-2.22h-5.549a2.226,2.226,0,0,0-2.22,2.22v3.7a2.226,2.226,0,0,0,2.22,2.22H214.6a2.226,2.226,0,0,0,2.22-2.22Z" transform="translate(-180.198 -224.169)" />
                        <path fill="gray" d="Path_97" data-name="Path 97" className="cls-1" d="M336.065,257.833h-5.179a2.226,2.226,0,0,0-2.22,2.22v3.7a2.226,2.226,0,0,0,2.22,2.22h5.179a2.226,2.226,0,0,0,2.22-2.22v-3.7A2.226,2.226,0,0,0,336.065,257.833Z" transform="translate(-286.124 -224.169)" />
                        <path fill="gray" d="Path_98" data-name="Path 98" className="cls-1" d="M87.833,163.72v3.7a2.226,2.226,0,0,0,2.22,2.22h5.179a2.226,2.226,0,0,0,2.22-2.22v-3.7a2.226,2.226,0,0,0-2.22-2.22H90.053A2.226,2.226,0,0,0,87.833,163.72Z" transform="translate(-76.735 -140.414)" />
                        <path fill="gray" d="Path_99" data-name="Path 99" className="cls-1" d="M216.821,163.72a2.226,2.226,0,0,0-2.22-2.22h-5.549a2.226,2.226,0,0,0-2.22,2.22v3.7a2.226,2.226,0,0,0,2.22,2.22H214.6a2.226,2.226,0,0,0,2.22-2.22Z" transform="translate(-180.198 -140.414)" />
                        <path fill="gray" d="Path_100" data-name="Path 100" className="cls-1" d="M338.284,163.72a2.226,2.226,0,0,0-2.22-2.22h-5.179a2.226,2.226,0,0,0-2.22,2.22v3.7a2.226,2.226,0,0,0,2.22,2.22h5.179a2.226,2.226,0,0,0,2.22-2.22Z" transform="translate(-286.124 -140.414)" />
                        <path fill="gray" d="Path_101" data-name="Path 101" className="cls-1" d="M126.272,14.8a2.226,2.226,0,0,0,2.22-2.22V2.22A2.226,2.226,0,0,0,126.272,0h-2.22a2.226,2.226,0,0,0-2.22,2.22V12.578a2.226,2.226,0,0,0,2.22,2.22Z" transform="translate(-106.296 0)" />
                        <path fill="gray" id="Path_102" data-name="Path 102" className="cls-1" d="M321.772,0h-2.22a2.226,2.226,0,0,0-2.22,2.22V12.578a2.226,2.226,0,0,0,2.22,2.22h2.22a2.226,2.226,0,0,0,2.22-2.22V2.22A2.226,2.226,0,0,0,321.772,0Z" transform="translate(-276.271)" />
                    </g>
                </svg>

            </div>
        );
    }
}
