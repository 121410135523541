import React from 'react';
import PropTypes from 'prop-types';

export default class PONumber extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    handleChange = (e) => {
        if (e.target.value.length <= this.props.maxLength)
            this.props.poNumberUpdated(e.target.value);
    }

    render() {
        const { poNumber, isRequired, localized } = this.props;
        return (
            <div className="fele-cart-po-number">
                <div className="fele-content">
                    <div className="input-wrapper">
                        <input name='poNumber' type="text" value={poNumber} onChange={this.handleChange} onBlur={this.handleChange} required={isRequired} />
                        <div className="textarea-note">
                            {`${poNumber ? this.props.maxLength - poNumber.length : this.props.maxLength} ${localized.CharactersLeft}`}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PONumber.propTypes = {
    isRequired: PropTypes.bool,
    poNumberUpdated: PropTypes.func,
    poNumber: PropTypes.string,
};