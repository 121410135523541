import LocalizedStrings from 'react-localization';
import Localization from '../services/Localization';

class ModalPopup {
    localized = new LocalizedStrings({
        en: {
            Close_Label: "Close",
            NoButton_Label: "No",
            YesButton_Label: "Yes"
        },

        pt: {
            Close_Label: "Fechar",
            NoButton_Label: "Não",
            YesButton_Label: "Sim"
        },

        es: {
            Close_Label: "Cerrar",
            NoButton_Label: "No",
            YesButton_Label: "Si"
        },

        it: {
            Close_Label: "Close",
            NoButton_Label: "No",
            YesButton_Label: "Sì"
        },
        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new ModalPopup();