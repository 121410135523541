import React from 'react';

export default class Hierarchy extends React.Component {
    render() {
        return (
            <div className="icon">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32" >
		                <path d="M7.5,7.8l-1-2.5H0V32h32V7.8H7.5z M24.2,22h-6.7v-1.6h-6v5.8h6v-1.6h6.7v4.2h-6.7V27h-6.9V15.7H7.8v-4.2h6.7v4.2h-2.9v3.8h6v-1.6h6.7V22z" />
                </svg>
            </div>
        );
    }
}