import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import UsersService from '../../services/UserService';
import SearchIcon from '../icons/Search';
import PropTypes from 'prop-types';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import Select from 'react-select';
import StorefrontService from '../../services/Storefront';
import CheckBox from '../form-controls/CheckBox';

export class Filter extends React.Component {
    constructor(props) {
        super(props);
        const defaultStorefront = props.defaultStorefront || props.StorefrontConfig.getStorefrontByNumber(props.currentUser.storefrontNumber);

        this.state = {
            menuIsOpen: false,
            sort: 'lastName',
            selectedStorefront: { value: defaultStorefront?.id, label: defaultStorefront?.displayName, number: defaultStorefront?.number },
            isCustomerAdmin: defaultStorefront?.personas[0].name === "CustomerAdministrator" ? true : false,
            result: null,
            personaList: null,
            allChecked: false
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.populateFilters();
        document.addEventListener("mousedown", this.handleClick, false);
    }

    fetchPersonaList(storefrontNumber) {
        StorefrontService.getPersonas(storefrontNumber).then((res) => {
            var list = [];
            res.forEach((p) => {
                if ((this.props.currentUser.persona.name !== ("Administrator" || "StorefrontAdministrator") && (p.name !== "Administrator" || "StorefrontAdministrator")) || this.props.currentUser.persona.name === ("Administrator" || "StorefrontAdministrator")) {
                    list.push({ value: p.id, label: p.displayName, name: p.name, checked: false });
                }
            });
            this.setState({
                personaList: list,
                allChecked: false
            });
            this.checkedPersonaList(this.state.personaList);
        });
    }

    getOptions() {
        var result = [];

        this.props.StorefrontConfig.storefrontList.map((s) => {
            if (s.number !== '00000') {
                result.push({ value: s.id, label: s.displayName, number: s.number });
            }
        });
        return result;
    }

    componentWillUnmount() {
        this.mounted = false;
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    handleClick = (e) => {
        if (this.nodeToggle.contains(e.target) || this.nodeMenu.contains(e.target))
            return;
        this.setMountedState({ menuIsOpen: false })
    }

    toggleMenu = () => {
        this.setMountedState({ menuIsOpen: !this.state.menuIsOpen });
        this.setSort();
        if (!this.state.menuIsOpen && !this.state.allChecked)
            this.fetchPersonaList(this.props.currentUser.storefrontNumber);
    }

    populateFilters = () => {
        this.setActiveFilter();
        this.setSort();
        this.setSearchFilter();
        this.setStorefrontNumber();
    }


    handleSearchChange = (e) => {
        this.setMountedState({ filterSearch: e.target.value }, this.searchIfValid)
    }

    searchIfValid = () => {
        if (this.state.filterSearch.length >= 3 || this.state.filterSearch.length === 0) {
            this.search();
        }
    }

    search = async () => {
       await UsersService.updateFilter("search", this.state.filterSearch);
        this.props.userActions.GetFilteredUsers();
    }

    setSearchFilter = () => {
        this.setMountedState({ filterSearch: UsersService.filters.search });
    }

    toggleActiveFilter = async () => {
       await UsersService.updateFilter("active", !this.state.filterActive);
        this.props.userActions.GetFilteredUsers();
        this.setActiveFilter();
    }

    setActiveFilter = () => {
        this.setMountedState({ filterActive: UsersService.filters.active });
    }

    selectSort = async (value) => {
        await UsersService.updateFilter("sort", value);
        this.props.userActions.GetFilteredUsers();
        this.setSort();
    }

    setSort = () => {
        this.setMountedState({ sort: UsersService.filters.sort });
    }

    setStorefrontNumber = () => {
        this.setMountedState({ sort: this.state.selectedStorefront.number });
    }

    storefrontOnChange = (e) => {
        this.setState({ selectedStorefront: e })
        this.fetchPersonaList(e.number);
    }

    onAllChecked = (allChecked) => {
        this.setState({ allChecked: !allChecked });
        if (this.state.personaList.length > 0) {
            const checkedList = this.state.personaList.map(o => {
                return {
                    ...o,
                    checked: !allChecked
                }
            });
            this.setState({ personaList: checkedList }, () => {
                this.checkedPersonaList(this.state.personaList);
            });
        }
    }

    async checkedPersonaList(personaList) {
        const personasList = personaList?.filter(o => o.checked === true).map(o => o.value);
        const allPersonas = personasList.length === 0 && this.state.allChecked ? "*" : personasList
        await UsersService.updateFilter("persona", allPersonas);
        await UsersService.updateFilter("storefrontNumber", this.state.selectedStorefront.number);
        this.props.userActions.GetFilteredUsers();
    }

    onPersonaChecked = (item) => {
        item.checked = !item.checked;
        if (this.state.personaList.length > 0) {
            const mapChecked = this.state.personaList.map(o => {
                if (item.id === o.id) {
                    return {
                        ...o,
                        checked: o.checked
                    }
                }
                return item;

            });
            this.setState({ personaList: mapChecked });
        }
        this.setState({ allChecked: false });
        this.checkedPersonaList(this.state.personaList);
    }

    render() {
        const localized = this.props.StorefrontConfig.labels;
        return (
            <div className="fele-user-filters">
                <div className="search">
                    <div className="input-wrapper">
                        <SearchIcon />
                        <input type="text" placeholder={localized.SearchPlaceholder} value={this.state.filterSearch} onChange={this.handleSearchChange}></input>
                    </div>
                </div>
                <div className="refine">
                    <div className="button-wrapper">
                        <button className="button menu-toggle" onClick={this.toggleMenu} ref={node => (this.nodeToggle = node)}>
                            {this.state.menuIsOpen ? localized.Close : localized.RefineLabel}
                        </button>
                    </div>
                    <div className={`menu ${this.state.menuIsOpen ? "open" : ""}`} ref={node => (this.nodeMenu = node)}>
                        {filterButton(localized.ShowActiveUsersLabel, this.state.filterActive, "active-toggle", this.toggleActiveFilter)}

                        <div className="item header">{localized.SortLabel}</div>
                        <button className={`item sort ${(this.state.sort === "firstName") ? "selected" : ""}`} value="firstName" onClick={() => this.selectSort("firstName")}>
                            <div className="label">{localized.FirstName}</div></button>
                        <button className={`item sort ${(this.state.sort === "lastName") ? "selected" : ""}`} value="lastName" onClick={() => this.selectSort("lastName")}>
                            <div className="label">{localized.LastName}</div></button>

                        <div className="item header">{localized.FilterLabel}</div>
                        {
                            !this.state.isCustomerAdmin &&
                            <div style={{ padding: "1rem" }}>
                                <div className="label">
                                    Storefront:
                                </div>
                                <Select options={this.getOptions()} onChange={this.storefrontOnChange} value={this.state.selectedStorefront} placeholder={'Select a storefront'} />
                            </div>
                        }
                        <div style={{ padding: "1rem" }} className="label">
                            Persona:
                        </div>
                        <div className="field-wrapper flex" style={{ paddingBottom: "1rem" }}>
                            <CheckBox checked={this.state.allChecked} onClick={() => this.onAllChecked(this.state.allChecked)} />
                            {'All'}
                        </div>
                        {
                            this.state.personaList && this.state.personaList.length > 0 &&
                            this.state.personaList.map((item, key) =>
                                <div style={{ paddingBottom: "1rem" }} className="customer flex space-between" key={key}>
                                    <div className="field-wrapper flex">
                                        <CheckBox id="checkbox" checked={item.checked} onClick={() => this.onPersonaChecked(item)} />
                                        {item.label}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div >
                <div className="new-user-placholder">

                </div>
            </div>
        )
    }
}

function filterButton(label, active, className, callback) {
    return (
        <button className={`item ${className}`} onClick={callback}>
            <div className="flex">
                <div className="x-box-wrapper">
                    <div className="x-box">
                        {
                            active &&
                            <span>X</span>
                        }
                    </div>
                </div>
                <div className="label">{label}</div>
            </div>
        </button>
    )
}

Filter.propTypes = {
    userActions: PropTypes.object,
    currentUser: PropTypes.object
};


function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        currentUser: state.user.currentUser
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStorefrontConfig(Filter));