import LocalizedStrings from 'react-localization';
import Localization from '../../services/Localization';

class CalendarStrings {
    localized = new LocalizedStrings({
        en: {
            lang: "en-US"
        },

        pt: {
            lang: "pt-BR"
        },

        es: {
            lang: "es"
        },

        it: {
            lang:"it-IT"
        },

        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new CalendarStrings();
