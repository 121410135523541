import React, { useState } from 'react';
import SearchIcon from './icons/Search';
import { withRouter } from 'react-router-dom';
import { useStorefrontSettings } from '../hooks/StorefrontSettingsContext';

export const Search = props => {
    const storefrontConfig = useStorefrontSettings();
    const history = props.history || useHistory();
    const localized = storefrontConfig.labels;
    const [search, setSearch] = useState('');
    const [visualSearch, setVisualSearch] = useState('');

    const querySku = () => {
        if (search !== "" && search.length > 2)
            history.push({
                pathname: "/products/" + search + "/page/1", state: {
                    selectedCustomer: props.selectedCustomer,
                    hideYourCost: props.hideYourCost
                }
            });
    }

    const regexForwardslash = new RegExp("/", "g");
    const regexOtherSpecialCharacters = new RegExp("[%+=]", "g");
    const catchEnter = (e) => {
        if (e.key === 'Enter')
            querySku();
    }

    return (
        <React.Fragment>
            <div className="input-wrapper">
                <span className="display-tablet">
                    <SearchIcon />
                </span>
                <input type="text"
                    data-cy="fele-search"
                    className="search"
                    name="search"
                    value={visualSearch}
                    onChange={e => {
                        setVisualSearch(e.target.value);
                        setSearch(e.target.value.replace(regexForwardslash, "|")
                                                .replace(regexOtherSpecialCharacters, ""))
                                               
                    }}
                    placeholder={localized.PlaceHolderText}
                    onKeyPress={catchEnter}
                />
            </div>
            <div className="button-wrapper small">
                <button className="button" onClick={querySku} >
                    <span className="display-mobile">
                        <SearchIcon />
                    </span>
                    <span className="display-tablet">
                        {localized.Search}
                    </span>
                </button>
            </div>
        </React.Fragment>

    );
}
export default withRouter(Search);