import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import OrdersReportFilter from './OrdersReportFilter';
import OrderService from '../../services/OrderService';
import SalesTerritoryOrders from './SalesTerritoryOrders';
import Paging from '../Paging';
import Order from '../orders/Order';
import { useCustomers } from '../../hooks/CustomerContext';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';

function OrdersReport(props) {
    const { currentCustomer: selectedCustomer } = useCustomers();
    const salesHistoryConfig = useSalesHistoryConfig();
    const [dateRange, setDateRange] = useState(30);
    const [page, setPage] = useState(1);
    const [number, setNumber] = useState("");
    const [status, setStatus] = useState("");
    const [fetching, setFetching] = useState(false);
    const [orders, setOrders] = useState(null);
    const [count, setCount] = useState(null);
    const [pageSize, setPageSize] = useState(100);
    const [filterCustomer, setCustomer] = useState(0);
    const [territory, setTerritory] = useState('');
    const [orderDetails, setOrderDetails] = useState({});
    const [showOrder, setShowOrder] = useState(false);
    const [creditHoldFilter, setCreditHoldFilter] = useState('');

    const store = props.store ? props.store : useSelector((state) => state);
    const currentUser = store.user.currentUser;

    const firstRender = useRef(true);
    useEffect(() => {
        let mounted = true;
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if (mounted) updateOrders();

        return () => { mounted = false; return null; }
    }, [territory, dateRange, page, status, filterCustomer]);

    const filterDateRange = (e) => {
        setDateRange(e.target.value);
        setPage(1);
    }

    const filterSetNumber = (e) => {
        setNumber(e.target.value);
        setPage(1);
    }

    const updateOrders = () => {
        fetchOrders();
    }

    const fetchOrders = () => {
        if (selectedCustomer) {
            setFetching(true);
            let state = {
                page,
                dateRange,
                number,
                status,
                filterCustomer,
                territory,
                creditHoldFilter
            };
            OrderService.fetchOrdersByTerritory(state, currentUser, false).then(loaded);
        }
    }

    const loaded = (data) => {
        if (data) {
            setOrders(data.orders);
            setCount(data.totalCount);
            setFetching(false);
        }
    }

    const filterStatus = (e) => {
        setStatus(e.target.value);
    }

    const pageCount = () => {
        return Math.ceil(count / pageSize);
    }

    const nextPage = () => {
        if (page < pageCount()) {
            setPage(page + 1);
        }
    }

    const previousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    const setFilterCustomer = (e) => {
        setCustomer(e.target.value);
    }

    const setFilterTerritory = (newTerritory) => {
        setTerritory(newTerritory);
    }

    const filterCreditHold = (e) => {
        setCreditHoldFilter(e.target.value);
    }

    const renderPaging = () => {
        return (
            <Paging activePage={page} pageCount={pageCount} nextPage={nextPage} previousPage={previousPage} />
        )
    }

    const OpenOrderDetails = (orderId, orderCompany, orderType) => {
        setOrderDetails({
            orderId,
            orderCompany,
            orderType
        });
        setShowOrder(true);
    }

    return (
        <>
            {
                !showOrder ? <>
                    <div className="display-2" style={{ marginBottom: '50px' }}><b>{salesHistoryConfig.labels.SalesTerritoryReport}</b></div>
                    <OrdersReportFilter
                        setDateRange={filterDateRange}
                        dateRange={dateRange}
                        setNumber={filterSetNumber}
                        numberFilter={number}
                        fetchOrders={fetchOrders}
                        setStatus={filterStatus}
                        statusFilter={status}
                        filterCustomer={filterCustomer}
                        setCustomer={setFilterCustomer}
                        setTerritory={setFilterTerritory}
                        territory={territory}
                        setCreditHoldFilter={filterCreditHold}
                        creditHoldFilter={creditHoldFilter}
                    />
                    <SalesTerritoryOrders
                        orders={orders}
                        fetching={fetching}
                        renderPaging={renderPaging}
                        openOrderDetails={OpenOrderDetails}
                    />
                </>
                    :
                    <>
                        <div className="fele-order-history-filter">
                            <div className="button-wrapper">
                                <button className="button search-button button-alt" onClick={() => { setShowOrder(false) }}>{salesHistoryConfig.labels.BackToReport}</button>
                            </div>

                            <div className="seperator"></div>
                        </div>
                        <Order
                            orderId={orderDetails.orderId}
                            orderCompany={orderDetails.orderCompany}
                            docType={orderDetails.orderType}
                        />
                    </>
            }
        </>
    );
}

export default OrdersReport;