import React from 'react';
import PropTypes from 'prop-types';
import MapPinIcon from './icons/MapPin';
import AngleDown from './icons/AngleDown';
import Search from './icons/Search';
import CustomerList from './CustomersList';
import LogoSelector from './LogoSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';

export class CustomerSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectIsOpen: false,
            search: "",
            openParent: -1,
            filteredCustomers: props.customers,
            fetching: true,
            openAllParents: false,
        };
    }

    componentDidMount() {
        this.mounted = true;
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        this.mounted = false;
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.customers && prevProps.customers !== this.props.customers && this.props.customers.length > 0) {
            if (this.state.filteredCustomers.length === 0) {
                this.setMountedState({ filteredCustomers: this.props.customers }, this.fetchedCustomers);
            }
            else if (this.state.fetching) {
                this.fetchedCustomers();
            }
            else {
                this.setState({ filteredCustomers: this.props.customers });
            }
        }
        else if (this.props.customers && prevProps.customers === this.props.customers && this.props.customers.length > 0) {
            if (this.state.fetching) {
                this.fetchedCustomers();
            }
        }
    }

    fetchedCustomers = () => {
        this.setState({ fetching: false });
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    handleClick = (e) => {
        if (this.node && this.node.contains(e.target))
            return;
        this.setMountedState({ selectIsOpen: false, openAllParents: false });
    }

    toggleSelect = () => {
        this.setMountedState({ selectIsOpen: !this.state.selectIsOpen });
        if (this.state.selectIsOpen) {
            this.setMountedState({ openAllParents: false });
        }
    }

    setOpenFromList = (openState) => {
        this.setMountedState({ selectIsOpen: openState });
        if (!openState) {
            this.setMountedState({ openAllParents: false });
        }
    }

    handleSearchChange = (e) => {
        this.setMountedState({ search: e.target.value }, () => {
            if (this.state.search.length >= 2) {
                const custlist = JSON.parse(JSON.stringify(this.props.customers));
                let filtered = [...new Set(custlist.filter(this.matchesSearch))];
                filtered = [...new Set(this.filterChildren(filtered.slice(0)))];
                this.setState({ filteredCustomers: filtered });
            } else {
                this.setState({ filteredCustomers: this.props.customers });
            }
        });
    }

    matchesSearch = (cust) => {
        const filter = this.state.search.toLowerCase();
        const match = cust.name.toLowerCase().includes(filter) || cust.address.city.toLowerCase().includes(filter) || cust.externalId.toLowerCase().includes(filter) || cust.taxId.toLowerCase().includes(filter);
        const childMatch = cust.children && cust.children.filter(c => c.name.toLowerCase().includes(filter) || c.address.city.toLowerCase().includes(filter) || c.taxId.toLowerCase().includes(filter) || c.externalId.toLowerCase().includes(filter)).length > 0;
        if (!this.props.addCustomer) {
            if (this.props.currentUser.storefrontNumber === "8700") {
                this.setMountedState({ openAllParents: true });
            }
        }
        return match || childMatch;

    }

    findCustomer = (id) => {
        const find = this.props.customers.find(c => c.externalId.toLowerCase() === id);
        return find;
    }

    filterChildren = (array) => {

        for (var i = 0; i < array.length; i++) {
            if (array[i].children) {
                const node = this.findCustomer(array[i].externalId.toLowerCase());
                array[i].children = node.children.filter(this.matchesSearch);
            }
        }

        return array;
    }

    empty = (data) => {
        return !data || data.length === 0;
    }

    renderLoading = () => {
        return (
            <div data-cy="customer-selector" className="fele-shipto-select">
                <div className="fele-loading">Loading...</div>
            </div>
        );
    }

    renderSearch = () => {
        const { search } = this.state;
        const localized = this.props.StorefrontConfig.labels;
        return (
            <div className="input-wrapper">
                <Search />
                <input
                    value={search}
                    onChange={this.handleSearchChange}
                    className="search-customer"
                    type="text" placeholder={localized.Search + ' ' + localized.CustomerSearchLabel} />
            </div>
        )
    }

    render() {
        const { selectIsOpen } = this.state;
        return (
            <div data-cy="customer-selector" className={`fele-shipto-select ${selectIsOpen ? "open" : ""}`} ref={node => this.node = node}>
                {
                    this.props.showLabel &&
                    <div className="label">
                        <MapPinIcon />
                        {
                            this.props.selectedCustomer &&
                            <React.Fragment>
                                <div className="text">
                                    {this.props.selectedCustomer.isActive === false ? <><FontAwesomeIcon icon={faUserSlash} size="xs" />{" "}</> : ""}{this.props.StorefrontConfig.renderAddressType(this.props.selectedCustomer.addressType)}
                                </div>
                                <LogoSelector BrandCode={this.props.selectedCustomer.brandCode} />
                            </React.Fragment>
                        }
                    </div>
                }
                <div className="selector">
                    <div className="selected">
                        <div className="button-wrapper">
                            <button className="name" onClick={this.toggleSelect} >
                                <span>
                                    {this.props.selectedCustomer && this.props.selectedCustomer.externalId} – {this.props.selectedCustomer && this.props.selectedCustomer.name}
                                    {this.state.fetching && this.props.customers.length === 0 && !this.props.selectedCustomer && this.renderLoading()}
                                </span>
                                <AngleDown />
                            </button>
                        </div>
                    </div>
                    <div className="shipto-list-wrapper">
                        {this.renderSearch()}
                        <div className="shipto-options">
                            {!this.state.fetching && this.props.customers.length > 0 &&
                                <CustomerList updateCustomer={this.props.updateSelectedCustomer} currentUser={this.props.currentUser} customers={this.state.filteredCustomers} search={this.state.search} selectedCustomer={this.props.selectedCustomer} setOpenFromList={this.setOpenFromList} openAllParents={this.state.openAllParents} />
                            }
                            {
                                !this.state.fetching && this.props.customers.length === 0 &&
                                <div className="no-customers">No customers found.</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CustomerSelector.propTypes = {
    userActions: PropTypes.object,
    customers: PropTypes.array,
    selectedCustomer: PropTypes.object,
    showLabel: PropTypes.bool,
}

CustomerSelector.defaultProps = {
    showLabel: true,
}

export default (withStorefrontConfig(CustomerSelector))