import React from 'react';

export default class Checkmark extends React.Component {
    render() {
        return (
            <div className="icon checkmark">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32" xmlSpace="preserve">
                    <path d="M10,27.2l-8.5-8.6c-0.5-0.5-0.5-1.4,0-2l1.9-1.9c0.5-0.5,1.4-0.5,2,0l5.7,5.7L26.8,5c0.5-0.5,1.4-0.5,2,0l1.9,1.9 c0.5,0.5,0.5,1.4,0,2L12,27.2C11.5,27.8,10.6,27.8,10,27.2z"></path>
                </svg>
            </div>
        );
    }
}
