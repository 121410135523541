import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../form-controls/CheckBox';
import initialState from '../../reducers/initialState';

export default class FreightCollectOption extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            freightHandlingCode: props.freightHandlingCode,
            preferredStringNeedsValidation: false,
            accountStringNeedsValidation: false,
            preferredString: props.preferredString || "",
            accountString: props.accountString || "",
            isFreightCollect: props.freightHandlingCode === "COL" || false,
        };
        this.initialValidate = (props.freightHandlingCode === "COL" && props.preferredString.trim().length > 0 && props.accountString.trim().length > 0) || props.freightHandlingCode !=="COL"
        this.props.freightCollectOptionUpdated(props.preferredString, props.accountString, props.freightHandlingCode, this.initialValidate);
        this.handleCheck = this.handleCheck.bind(this);
        this.preferredStringLimit = 19;
        this.accountStringLimit = 10;
    }
    
    handleChangePreferred= async (e) => {
        let preferred = e.target.value;        
        if (preferred.length < this.preferredStringLimit + 1) {
            await this.setState({ preferredString: preferred });            
        }
        await this.updateFreightCollection('preferred');   
            
    }

    handleChangeAccount = async (e) => {
        let account = e.target.value;        
        if (account.length < this.accountStringLimit + 1) {
            await this.setState({ accountString: account });
        }        
        await this.updateFreightCollection('account');
        
    }

    updateFreightCollection = async (field) => {
        let isValid;
        if (field === "check") {
            if (this.state.freightHandlingCode ==="COL") {
                isValid = false;
            }
            else {
                isValid = true;
            }
        }
        else {
            isValid = this.validate(field);
        }
        this.props.freightCollectOptionUpdated(this.state.preferredString, this.state.accountString, this.state.freightHandlingCode, isValid);
    }

    handleCheck = async () => {
        await this.setState({
            freightHandlingCode: this.state.freightHandlingCode === "COL" ? "" : "COL", isFreightCollect:!this.state.isFreightCollect
                    
       });
        this.setState({
            preferredStringNeedsValidation: false,
            accountStringNeedsValidation: false
        });
        this.setState({ preferredString: "" });
        this.setState({ accountString: "" });
        this.updateFreightCollection("check");
    }

    validate = (field) => {   
        if (this.state.preferredString.trim().length > 0 && this.state.accountString.trim().length > 0) {
            this.setState({
                preferredStringNeedsValidation: false,
                accountStringNeedsValidation: false
            });
            return true;
        }
        else {
            if (this.state.preferredString.trim().length === 0 && field ==="preferred") {
                this.setState({preferredStringNeedsValidation: true});
            }
            if (this.state.accountString.trim().length === 0 && field ==="account") {
                this.setState({ accountStringNeedsValidation: true });
            }
            return false
        }            
    }

    render() {
        const { preferredString, accountString } = this.state;
        return (
            <div id="freightCollectOption" className="fele-content">
                <div className="fele-cart-delivery-instructions">
                    <div className="fele-content">
                        <div className="flex-parent">
                            <CheckBox checked={this.state.isFreightCollect} onClick={this.handleCheck} />
                            <div className="label flex-label checkbox-offset">{this.props.useLabel}</div>                            
                        </div>
                        {this.state.isFreightCollect &&
                            <div className="input-wrapper flex-wrapper">
                                <div className="justify-right">
                                    <div className="flex-parent validation-input-width">
                                        <div className="label flex-label freight-carrier-padding" >{this.props.preferredLabel}</div>
                                        <input className={`form-control ${(this.state.preferredStringNeedsValidation) ? (preferredString.trim().length < 1 ? "is-invalid" : "is-valid") :""}`}
                                            type="text" value={preferredString} onChange={this.handleChangePreferred} onBlur={this.handleChangePreferred} />                                    
                                    </div>
                                    <div className="textarea-note">
                                        {`${preferredString ? this.preferredStringLimit - preferredString.length : this.preferredStringLimit} ${this.props.charactersLeft}`}
                                    </div>                                 
                                    <div className="flex-parent validation-input-width">
                                        <div className="label flex-label freight-account-padding" >{this.props.carrierLabel}</div>
                                        <input className={`form-control ${(this.state.accountStringNeedsValidation) ? (accountString.trim().length < 1 ? "is-invalid" : "is-valid") : ""}`}
                                            type="text" value={accountString} onChange={this.handleChangeAccount} onBlur={this.handleChangeAccount} />
                                    </div>
                                    <div className="textarea-note">
                                        {`${accountString ? this.accountStringLimit - accountString.length : this.accountStringLimit} ${this.props.charactersLeft}`}
                                    </div>                                
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

FreightCollectOption.propTypes = {
    freightCollectOptionUpdated: PropTypes.func,
    preferredString: PropTypes.string,
    accountString: PropTypes.string,
    accountLabel: PropTypes.string,
    preferredLabel: PropTypes.string,
    useLabel: PropTypes.string
};