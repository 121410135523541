import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import SubmitModal from '../shared/SubmitModal';
import Comments from '../cart/Comments';

const CommentDetailsModal = (props) => {



    const { show, onHide, callback, localized, itemNumber, serialNumber, detailCommentUpdated, detailComment, showFooter,IsDetailOnly } = props;



    const divStyle = {
        paddingBottom: '2rem',
        fontweight: '500',
        fontstyle: 'normal',
        fontsize: '12px'
    };
    return (
        <div>
            <SubmitModal
                isOpen={show}
                onClose={onHide}
                onSubmit={callback}
                closeText={'Cancel'}
                submitText={'Submit'}
                showFooter={showFooter}
                HeaderText={IsDetailOnly ? localized.DetailedComments : localized.AddDetailedComments}
                size={"lg"}
            >
                <Container>                  

                    <Row  style={{ paddingBottom: '1rem' }}>
                        <Col xl={3} style={{ textAlign: 'right'}}>
                            <div className="heading4">{localized.ItemNumber}:</div>
                        </Col>
                        <Col xl={2}>
                            <div>{itemNumber}</div>
                        </Col>
                        <Col xl={3} style={{ textAlign: 'right' }}>
                            <div className="heading4">{localized.SerialNumber}:</div>
                        </Col>
                        <Col xl={2}>
                            <div>{serialNumber}</div>
                        </Col>
                    </Row>
                    
                    {
                        <>
                            <Row style={{ paddingBottom: '2rem' }}>

                                <Col xs={12} md={11}>
                                    <Comments commentsUpdated={detailCommentUpdated} comments={detailComment || ""} editable={IsDetailOnly } />
                                </Col>
                            </Row>
                        </>
                    }
                </Container>
            </SubmitModal>
        </div>
    )
};

export default CommentDetailsModal;
