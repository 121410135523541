import React from 'react';

export default class SchneiderLogo extends React.Component {
    render() {
        return (
            <div className="brand-logo schneider-logo">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 370 134" enableBackground="new 0 0 370 134">
                    <g>
                        <path fill="#FFFFFF" d="M354.4,40c-1.2-6.4-1.3-5.5-8.5-6.5c-34.1-3.5-69.1-5.8-104.4-7.1c-18.2-0.7-36.4-1-54.7-1
                            c-30.1-0.1-60.2,0.7-90,2.1c-3.8,0.2-7.6,0.3-11.6,0.6c-8.3,0.3-16.5,0.9-24.7,1.5h-0.2h-0.2c-4.4,0.3-8.8,0.6-13,0.9
                            c-2.5,0.2-4.9,0.4-7.4,0.7c-3.9,0.3-7.8,0.7-11.6,1.1c-2.7,0.2-4.9,0.4-7.6,0.7c-7.2,1-7.3,0.2-8.5,6.5c-1.5,8.6-2.2,17.1-2.4,24.7
                            c0,0.1,0,0.2,0,0.3v0.2c0,0,0,0.1,0,0.2c0,7.9,0.8,16.7,2.4,25.6c1.1,6.4,1.2,5.6,8.4,6.6c53,5.5,108,8.2,163,8.2
                            c54.9,0,110-2.7,163-8.2c7.1-1,7.2-0.2,8.3-6.6c0.7-3.8,1.1-7.5,1.5-11.2c0.3-2.4,0.4-4.6,0.6-7c0.1-2.2,0.2-4.5,0.2-6.7
                            c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.2C356.8,57.5,356.1,48.7,354.4,40z"/>
                        <g>
                            <path fill="#EC2130" d="M89.2,79.8c-0.2,0-0.3,0-0.4,0c-1.5,0.1-2.6,0.8-3.5,2.2c-0.9,1.5-1.3,3.4-1.2,5.6
                                c0.1,1.3,0.4,2.5,1.2,3.3c0.7,0.8,1.7,1.2,2.9,1.2c0.2,0,0.3-0.1,0.6-0.1c1.3-0.1,2.5-0.9,3.4-2.2c1-1.5,1.5-3.4,1.3-5.6
                                c-0.1-1.5-0.6-2.5-1.2-3.3C91.5,80.2,90.5,79.8,89.2,79.8z"/>
                            <path fill="#EC2130" d="M201.1,80.8c-0.7-0.7-1.7-1.1-3-1.1c-0.1,0-0.2,0-0.4,0c-1.3,0.1-2.6,0.8-3.4,2.2c-1,1.5-1.3,3.4-1.2,5.6
                                c0.1,1.3,0.4,2.5,1.2,3.3c0.7,0.8,1.7,1.2,2.9,1.2c0.2,0,0.3-0.1,0.4-0.1c1.3-0.1,2.5-0.9,3.4-2.2c0.9-1.5,1.5-3.4,1.2-5.6
                                C202.2,82.6,201.9,81.6,201.1,80.8z"/>
                            <path fill="#EC2130" d="M255.3,45h-5.7l-2.2,14.8h5.7c0.7,0,1.3-0.1,2-0.1c2-0.2,3.6-0.7,4.7-1.6c1.5-1.2,2.4-3,2.8-5.7
                                c0.3-2.6,0-4.5-1.1-5.6C260.3,45.6,258.3,45,255.3,45z"/>
                            <path fill="#EC2130" d="M261.3,80.2c-0.3-0.2-0.9-0.3-1.7-0.3h-2.9l-0.6,3.7h2.1c1.3,0,2.4-0.2,2.9-0.6s0.8-0.9,0.7-1.7
                                C261.8,80.8,261.7,80.4,261.3,80.2z"/>
                            <path fill="#EC2130" d="M173.4,80.2c-0.3-0.2-0.9-0.3-1.7-0.3h-2.8l-0.6,3.7h2.1c1.3,0,2.4-0.2,2.9-0.6c0.6-0.3,0.8-0.9,0.7-1.7
                                C173.9,80.8,173.7,80.4,173.4,80.2z"/>
                            <path fill="#EC2130" d="M146.2,80.8c-0.7-0.7-1.7-1.1-3-1.1c-0.1,0-0.2,0-0.4,0c-1.3,0.1-2.6,0.8-3.4,2.2c-1,1.5-1.3,3.4-1.2,5.6
                                c0.1,1.3,0.4,2.5,1.2,3.3c0.7,0.8,1.7,1.2,2.9,1.2c0.2,0,0.3-0.1,0.4-0.1c1.5-0.1,2.5-0.9,3.4-2.2c1-1.5,1.5-3.4,1.2-5.6
                                C147.3,82.6,147,81.6,146.2,80.8z"/>
                            <path fill="#EC2130" d="M172.7,87.6c-0.4-0.3-1-0.6-1.8-0.6h-3.1l-0.7,4.6h2.1c0.3,0,0.7-0.1,1-0.1c1.1-0.1,1.8-0.2,2.4-0.6
                                c0.6-0.4,0.9-1.1,0.8-2C173.3,88.4,173,87.8,172.7,87.6z"/>
                            <path fill="#EC2130" d="M260.5,87.6c-0.4-0.3-1-0.6-1.7-0.6h-3.3l-0.7,4.6h2.1c0.4,0,0.7-0.1,1-0.1c1.1-0.1,1.8-0.2,2.4-0.6
                                c0.6-0.4,0.9-1.1,0.8-2C261.2,88.4,261,87.8,260.5,87.6z"/>
                            <polygon fill="#EC2130" points="286.2,82.4 284,88.1 286.2,88.1 288.4,88.1 286.8,80.7 		"/>
                            <path fill="#EC2130" d="M338.8,46.1c-0.7-0.3-2-0.6-4-0.6h-6.4l-0.8,5.4h6.7c1.7,0,3-0.2,3.7-0.7c0.8-0.4,1.2-1.1,1.5-2.1
                                C339.6,47.1,339.4,46.6,338.8,46.1z"/>
                            <path fill="#EC2130" d="M363.7,35.6c-1.2-7.2-1.3-6.2-9-7.3c-35.9-3.9-72.7-6.5-109.8-8c-19.1-0.7-38.3-1.1-57.5-1.1
                                c-31.7-0.1-63.4,0.7-94.7,2.4c-4.2,0.2-8.2,0.4-12.2,0.7c-8.8,0.3-17.4,1-26.1,1.6h-0.2H54c-4.6,0.3-9.2,0.7-13.8,1
                                c-2.6,0.2-5.3,0.4-7.9,0.8c-4,0.3-8,0.8-12,1.2c-2.8,0.3-5.2,0.4-8,0.8c-7.5,1.1-7.6,0.2-9,7.3C1.7,44.7,0.9,54,0.8,62.8
                                c0,0.1,0,0.2,0,0.4v0.2c0,0.1,0,0.1,0,0.2c0,8.9,0.8,18.9,2.5,28.9c1.2,7.2,1.3,6.3,8.9,7.4c55.8,6.2,113.7,9.2,171.5,9.2
                                s115.7-3,171.5-9.2c7.4-1.1,7.5-0.2,8.8-7.4c0.7-4.3,1.2-8.4,1.6-12.6c0.3-2.6,0.4-5.2,0.7-7.9c0.1-2.6,0.2-5.1,0.2-7.5
                                c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2C366.3,55.2,365.4,45.5,363.7,35.6z M281.7,37.8h31.7l-1.1,7.4h-20.1l-0.6,3.6h16.2l-1.1,7.4
                                h-16.2l-0.6,3.9h20.2l-1.1,7.8h-31.7L281.7,37.8z M222.9,37.5h11.6l-4.6,30.2h-11.6L222.9,37.5z M18.1,52.6
                                c-1.6-1.5-2.1-3.5-1.7-6.1c0.4-3.4,2.2-5.8,5.2-7.4c2.9-1.6,7.4-2.4,13.4-2.4c6.2,0,10.7,0.8,13.5,2.4c2.8,1.6,4,4,3.8,7.3H38.9
                                c-0.1-1-0.4-1.7-1.3-2.1c-0.9-0.6-2.2-0.8-4-0.8c-1.3,0-2.4,0.2-3.1,0.6c-0.8,0.3-1.2,0.8-1.3,1.5c-0.1,0.6,0.1,1,0.7,1.3
                                c0.6,0.3,1.7,0.6,3.4,0.8l10.2,1.5c3.3,0.4,5.5,1.5,7,2.9c1.3,1.5,1.9,3.5,1.6,6c-0.6,3.7-2.6,6.3-6.1,8.1
                                c-3.5,1.7-8.8,2.6-15.7,2.6c-5.5,0-9.7-0.9-12.4-2.6c-2.7-1.7-3.8-4.2-3.4-7.4h13.9c0,0.8,0.4,1.5,1.2,1.9s2,0.7,3.5,0.7
                                c1.6,0,2.7-0.2,3.6-0.6c0.8-0.3,1.3-0.9,1.5-1.7c0.1-0.6-0.2-1.1-0.8-1.6c-0.6-0.3-1.6-0.7-3-0.8 M66.8,95.3h-4.4L64.6,81l-6,14.4
                                h-4.4L52.8,81l-2.1,14.4h-4.5l2.9-19.1h6.5l1.5,13.6l6-13.6h6.6L66.8,95.3z M60,64.6c-3-2.6-4-6.6-3.3-11.9
                                c0.8-5.3,3.1-9.2,6.9-11.9c3.7-2.7,9-4,15.6-4c6.2,0,10.8,1.1,13.7,3.5c3,2.4,4.4,5.6,3.9,10H83.6c0.1-1.8-0.3-3.3-1.3-4.3
                                c-0.9-0.9-2.4-1.5-4.3-1.5c-2.5,0-4.5,0.7-6,2.1c-1.5,1.5-2.5,3.5-2.9,6.1c-0.4,2.6-0.1,4.6,1,6.1c1.1,1.5,2.9,2.1,5.4,2.1
                                c1.8,0,3.3-0.4,4.5-1.2c1.2-0.8,2.1-2.1,2.7-3.7H96c-1.2,4.2-3.7,7.3-7.3,9.4c-3.6,2.1-8.4,3.3-14.4,3.3
                                C67.7,68.6,62.9,67.2,60,64.6z M95.9,92.6c-1.8,2.1-4.2,3.1-7.1,3.3c-0.1,0-0.2,0-0.3,0c-2.7,0-4.8-0.8-6.5-2.4
                                c-1.6-1.6-2.6-3.7-2.7-6.4c-0.2-3.3,0.6-6.1,2.5-8.2c1.8-2,4.2-3.1,7.1-3.3c0.1,0,0.2,0,0.3,0c2.7,0,4.9,0.8,6.5,2.4
                                c1.6,1.6,2.5,3.6,2.7,6.3C98.4,87.8,97.7,90.5,95.9,92.6z M123.2,80.2H118l-2.4,15.2h-4.9l2.4-15.2h-5.3l0.6-3.9h15.4L123.2,80.2z
                                M112.8,55.6L111,67.8H99.5l4.5-30.2h11.6l-1.6,10.6h13.1l1.6-10.6h11.6l-4.6,30.2h-11.6l1.8-12.2H112.8z M149.8,92.6
                                c-1.8,2.1-4.2,3.1-7.1,3.3c-0.1,0-0.2,0-0.3,0c-2.7,0-4.9-0.8-6.5-2.4c-1.7-1.6-2.6-3.7-2.7-6.4c-0.2-3.3,0.6-6.1,2.5-8.2
                                c1.8-2,4.2-3.1,7.1-3.3c0.1,0,0.2,0,0.3,0c2.7,0,4.8,0.8,6.5,2.4c1.6,1.6,2.5,3.6,2.7,6.3C152.5,87.8,151.7,90.5,149.8,92.6z
                                M177.3,87.1c0.4,0.7,0.7,1.5,0.8,2.4c0.1,0.9-0.1,1.7-0.4,2.5c-0.3,0.8-0.8,1.5-1.5,1.9c-0.6,0.6-1.3,1-2.4,1.1
                                c-0.9,0.2-2.1,0.3-3.7,0.3c-0.1,0-0.3,0-0.4,0h-8l2.9-19.1h7.6c2,0,3.5,0.4,4.6,1.2c1.1,0.8,1.7,2,1.7,3.6
                                c0.1,1.1-0.2,2.1-0.7,2.9c-0.6,0.8-1.3,1.3-2.5,1.7C176.3,86,177,86.5,177.3,87.1z M176.6,67.8h-12.9l-9.8-17.7l-2.7,17.6h-10.9
                                l4.6-30.2h13.6l8.9,17.5l2.6-17.5h11.1L176.6,67.8z M204.7,92.6c-1.8,2.1-4.2,3.1-7.1,3.3c-0.1,0-0.2,0-0.3,0
                                c-2.7,0-4.9-0.8-6.5-2.4c-1.7-1.6-2.6-3.7-2.7-6.4c-0.2-3.3,0.7-6.1,2.5-8.2c1.8-2,4.2-3.1,7.1-3.3c0.1,0,0.2,0,0.3,0
                                c2.7,0,4.8,0.8,6.5,2.4c1.6,1.6,2.5,3.6,2.7,6.3C207.4,87.8,206.6,90.5,204.7,92.6z M213.5,67.8h-32.1l4.5-30.2H218l-1.1,7.4
                                h-20.7l-0.6,3.6h16.2l-1.1,7.4h-16.2l-0.6,4h20.8L213.5,67.8z M237.3,95.3h-4.4l2.2-14.4l-6,14.4h-4.4L223.4,81l-2.2,14.4h-4.4
                                l2.9-19.1h6.6l1.5,13.6l6-13.6h6.6L237.3,95.3z M265.3,87.1c0.4,0.7,0.7,1.5,0.8,2.4c0.1,0.9-0.1,1.7-0.4,2.5s-0.8,1.5-1.5,1.9
                                c-0.6,0.6-1.3,1-2.4,1.1c-0.9,0.2-2.1,0.3-3.7,0.3c-0.1,0-0.3,0-0.4,0h-8l2.9-19.1h7.6c2,0,3.5,0.4,4.6,1.2c1.1,0.8,1.7,2,1.7,3.6
                                c0.1,1.1-0.2,2.1-0.7,2.9c-0.6,0.8-1.3,1.3-2.5,1.7C264.3,86,264.8,86.5,265.3,87.1z M275.1,49.9c0,0.9-0.1,1.8-0.2,2.7
                                c-0.7,4.9-2.9,8.8-6.5,11.2c-3.4,2.4-7.8,3.6-13.4,3.7c-0.4,0.1-0.9,0.1-1.3,0.1h-19.1l4.6-30.3h19.1c6.2,0,10.7,1.3,13.5,3.9
                                c2.2,2,3.3,4.8,3.4,8.2V49.9L275.1,49.9z M289.9,95.3l-0.7-3.4h-6.7l-1.2,3.4H276l8.4-19.1h1.7h3.6l4.9,19.1H289.9z M313.2,83.5
                                c0.8,0.3,1.3,0.6,1.7,0.8c1.7,0.8,2.9,1.6,3.6,2.5c0.7,0.8,1.1,1.8,1.2,3.1c0.1,1.8-0.4,3.4-1.7,4.5c-1.3,1.1-3.1,1.6-5.4,1.6
                                c-2.5,0-4.4-0.6-5.8-1.7s-2.2-2.7-2.5-4.9h4.5c0.1,1,0.4,1.7,1,2.2c0.6,0.4,1.5,0.7,2.6,0.7c0.9,0,1.7-0.2,2.2-0.6
                                c0.6-0.3,0.8-0.8,0.8-1.5c-0.1-0.7-1.1-1.6-3.1-2.4c-0.7-0.3-1.2-0.6-1.7-0.8c-1.6-0.7-2.7-1.6-3.5-2.4c-0.7-0.9-1.1-1.9-1.2-3.1
                                c-0.1-1.7,0.4-3.1,1.7-4.2c1.2-1,2.9-1.6,5.1-1.6c2.5,0,4.3,0.6,5.6,1.6c1.3,1.1,2.1,2.6,2.4,4.6h-4.4l-0.1-0.1
                                c0-0.8-0.3-1.5-0.8-1.9c-0.6-0.4-1.3-0.7-2.4-0.7c-0.8,0-1.5,0.2-1.9,0.6c-0.4,0.3-0.7,0.8-0.7,1.3
                                C310.3,81.9,311.3,82.6,313.2,83.5z M352.5,46c-0.2,1.8-0.9,3.4-2,4.7c-1.1,1.3-2.4,2.4-4,3c1.2,0.6,2,1.2,2.5,2
                                c0.3,0.8,0.4,2.4,0.2,4.4c0,0.2-0.1,0.6-0.1,0.9c-0.4,3.9-0.2,6.1,0.6,6.5l-0.1,0.4h-12.8c-0.3-0.7-0.3-1.8-0.1-3.7
                                c0.2-1,0.2-1.8,0.2-2.5c0.1-1.1-0.2-1.9-0.7-2.4c-0.6-0.4-1.3-0.7-2.7-0.7c-0.1,0-0.2,0-0.3,0h-6.9l-1.3,9.2h-11.7l4.6-30.2h22.7
                                c2.6,0,4.6,0.1,6.2,0.3c1.5,0.2,2.6,0.7,3.5,1.2c1,0.7,1.7,1.6,2.1,2.7C352.7,43.2,352.8,44.4,352.5,46z"/>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}
