import React from 'react';
import Money from '../../Money';

export default class ShipmentSummary extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const { shipment, selectedCustomer, localized } = this.props;

        return (
            (!isEmpty(shipment)) &&
            <div className="shipment-summary">
                <div className="shipping-method">
                    <div className="label">{localized.ShippingMethodsLabel}</div>
                    <div className="fele-content">
                        <div> &nbsp;</div>
                    </div>
                </div>
                <div className="shipping-instructions">
                    <div className="label">{localized.ShippingInstructionsLabel}</div>
                    <div className="fele-content">
                        <div> &nbsp;</div>
                    </div>
                </div>
                <div className="shipping-comments">
                    <div className="label">{localized.Comments}</div>
                    <div className="fele-content">
                        <div> &nbsp;</div>
                    </div>
                </div>
                <div className="shipping-totals">
                    <div className="sub-total">
                        <div className="label">{localized.ProductSubtotalLabel}:</div>
                        <div className="fele-content">
                            <Money value={shipment.productSubtotal} currency={shipment.currency} country={selectedCustomer.address.country} />
                        </div>
                    </div>
                    <div className="freight">
                        <div className="label">{localized.ShipmentFreightLabel}</div>
                        <div className="fele-content">
                            <Money value={shipment.freight} currency={shipment.currency} country={selectedCustomer.address.country} />
                        </div>
                    </div>
                    <div className="tax">
                        <div className="label">{localized.ShipmentTaxLabel}:</div>
                        <div className="fele-content">
                            <Money value={shipment.tax} currency={shipment.currency} country={selectedCustomer.address.country} />
                        </div>
                    </div>

                    <div className="total">
                        <div className="label">{localized.Total}:</div>
                        <div className="fele-content">
                            <Money value={shipment.total} currency={shipment.currency} country={selectedCustomer.address.country} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
