import React from 'react';

export default class FeedbackSpeechBubble extends React.Component {
    render() {
        return (
            <div className="icon feedback-speech-bubble">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.316 25.998">
                    <path id="Union_1" data-name="Union 1" d="M3.53,25.835a.861.861,0,0,1-.354-.871l.886-5.036a10.2,10.2,0,0,1-3-3.253A7.913,7.913,0,0,1,0,12.736c0-5.35,5.558-9.7,12.391-9.7a15.508,15.508,0,0,1,3.859.482l-1.468,1.5a14.071,14.071,0,0,0-2.406-.206,13.654,13.654,0,0,0-4.127.624,11.344,11.344,0,0,0-3.376,1.7A8.261,8.261,0,0,0,2.594,9.653a6.1,6.1,0,0,0,.14,6.395,8.709,8.709,0,0,0,2.76,2.714.881.881,0,0,1,.4.9l-.664,3.8L10.4,20.682a.887.887,0,0,1,.43-.107c.028,0,.057,0,.086,0,.563.05,1.046.074,1.477.074a13.67,13.67,0,0,0,4.124-.622,11.31,11.31,0,0,0,3.371-1.7,8.227,8.227,0,0,0,2.274-2.516,6.182,6.182,0,0,0,.835-3.081A6.063,6.063,0,0,0,22.6,10.6l1.344-1.363a7.823,7.823,0,0,1,.836,3.5c0,5.35-5.558,9.7-12.39,9.7-.517,0-.959-.019-1.388-.059L4.46,25.894a.781.781,0,0,1-.414.1A.826.826,0,0,1,3.53,25.835ZM7.154,16.9a.737.737,0,1,1,0-1.474h9.337a.737.737,0,1,1,0,1.474Zm-.123-3.563a.737.737,0,1,1,0-1.474H9.856a.737.737,0,1,1,0,1.474Zm7.056-.247a.813.813,0,0,1-.533-.254.767.767,0,0,1-.229-.559l.051-3.9a.819.819,0,0,1,.229-.533l7.63-7.63a.79.79,0,0,1,1.092,0L26.1,3.99a.774.774,0,0,1,0,1.079L18.4,12.763a.788.788,0,0,1-.533.254l-3.771.076Zm.812-4.38-.038,2.793,2.666-.038,3.936-3.923L18.784,4.828Zm4.964-4.977,2.679,2.717,1.917-1.93L21.768,1.831ZM7.031,9.777a.737.737,0,0,1,0-1.474H9.856a.737.737,0,0,1,0,1.474Z" />
                </svg>
            </div>
        );
    }
}