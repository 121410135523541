import React from "react";
import PropTypes from "prop-types";
import Calendar from "../../../form-controls/Calendar";

const CalendarInput = ({
    date,
    dateError,
    minDate,
    needsValidated,
    callBackFunction,
    dateFormat,
    showIcon,
    pioneerBrand,
    datacy
}) => {
    const handleCallback = (value) => {
        callBackFunction(value);

        if (datacy === "dateInstalled-submit") {
            focusElement('[data-cy="dateFailed-submit"]');
        } else if (datacy === "dateFailed-submit") {
            focusElement(".claim-replacement-option .form-select");
        }
    };

    const focusElement = (selector) => {
        const element = document.querySelector(selector);
        const inputField = element?.querySelector("input") || element;
        inputField?.focus();
    };

    const validationNeeded =
        pioneerBrand && pioneerBrand.shown === true ? "" : needsValidated;

    return (
        <Calendar
            date={date}
            dateError={dateError}
            minDate={minDate}
            needsValidated={validationNeeded}
            callBackFunction={handleCallback}
            dateFormat={dateFormat}
            showIcon={showIcon}
        />
    );
};

CalendarInput.propTypes = {
    dateError: PropTypes.bool,
    needsValidated: PropTypes.bool,
    callBackFunction: PropTypes.func,
    dateFormat: PropTypes.string,
    showIcon: PropTypes.bool,
};

export default CalendarInput;
