import LocalizedStrings from 'react-localization';
import Localization from '../services/Localization';

class PagerStrings {
    localized = new LocalizedStrings({

        en: {
            OfPage_Label:"of",
            Page_Label: "Page"
        },

        pt: {
            OfPage_Label: "of",
            Page_Label: "Page"
        },

        es: {
            OfPage_Label: "de",
            Page_Label: "Pagina"
        },

        it: {
            OfPage_Label: "di",
            Page_Label: "Pagina"
        },

        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new PagerStrings();
