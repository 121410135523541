import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrderIconLarge from "../icons/OrderIconLarge";
import { Link } from "react-router-dom";

export const ActiveOrders = (props) => {
    const orderData = props.orderData;
    const locale = props.locale == "en_US"
    const classChoiceTop = locale ? "font-size-20" : "font-size-28";
    const classChoiceBottom = !locale ? "font-size-20" : "font-size-28";
    const top3 = (orderData && orderData.headersOrdersResponse && orderData.headersOrdersResponse.length > 0) ? orderData.headersOrdersResponse.reduce((x, y) => {
        if (!x.find((z) => z.orderNumber === y.orderNumber)) {
            x.push(y);
        }
        return x;
    }, []).slice(0, 3) : [];

    return (
        <>
            {props.storeSettings.settingsList.UseDashboardActiveOrders == "1" &&
                <div className="gray-box">
                    <Link className="link-style" to={{ pathname: "/orders", query: { statuses: props.isFueling ? ["BeingProcessed", "Shipped"] : props.availableStatuses.filter(x => x.isQuoteStatus === false && x.shown === true && x.isActiveStatus ===true).map(x => x.description), dateRange: 729 } } }>
                        <div className="flex">
                            <div><OrderIconLarge /></div>
                        <div className="big-number">
                            <div className="font-size-64">
                                {orderData.totalActiveOrders}
                            </div>

                                <div className="label-wrapper">
                                    <div className={classChoiceTop}>
                                    {props.dashboardLabels.ACTIVE}
                                </div>
                                    <div className={classChoiceBottom }>
                                    {props.dashboardLabels.ORDERS}
                                </div>
                            </div>
                        </div>
                        
                        </div>
                    </Link>
                </div>
            }
        </>
    );
}

ActiveOrders.propTypes = {
    orderData: PropTypes.object
};
function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
    };
}

export default connect(mapStateToProps, null)(ActiveOrders);