import React from 'react';
import PropTypes from 'prop-types';
import Money from '../Money';

export default class FullCartSummary extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const localized = this.props.localized;
        return (
            <React.Fragment>
                <div className="section">
                    <div className="fele-cart-full-summary">
                        <div className="heading3">{localized.SummaryTitle}</div>
                        <div className="summary-header">
                            <div className="item-number">
                                <div className="label">{localized.ItemNumber}</div>
                            </div>
                            <div className="item-description">
                                <div className="label">{localized.ItemDescription}</div>
                            </div>
                            <div className="item-quantity">
                                <div className="label">{localized.ItemQuantity}</div>
                            </div>
                            <div className="unit-price">
                                <div className="label">{localized.UnitPrice}</div>
                            </div>
                        </div>
                        {
                            this.props.items.map((item, key) => (
                                <div key={key}>
                                    <div className="summary-details">
                                        <div className="item-number">
                                            {item.product.sku}
                                        </div>
                                        <div className="item-description">
                                            {item.product.description}
                                        </div>
                                        <div className="item-quantity">
                                            <div className="label">{localized.ItemQuantity}:</div> {item.quantity}
                                        </div>
                                        <div className="unit-price">
                                            <div className="label">{localized.UnitPrice}</div>
                                            <Money value={item.netPrice} currency={this.props.currency} country={this.props.selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                </div>
                            ))}

                        <div className="fele-summary-summary">
                            <div className="fele-summary-request-date">
                                <div className="label">{localized.RequestedDate}:&nbsp;</div>
                                <div>{this.props.requestDate}</div>
                            </div>
                            <div className="fele-summary-total">
                                <div className="label">{localized.ProductTotal}:&nbsp;</div>
                                <div><Money value={this.props.subTotal} currency={this.props.currency} country={this.props.selectedCustomer.address.country} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


FullCartSummary.propTypes = {
    items: PropTypes.array,
    requestDate: PropTypes.string,
    subTotal: PropTypes.number,
    currency: PropTypes.string,
    selectedCustomer: PropTypes.object,
};
