import React from 'react';
import Users from '../../services/UserService';
import FeedbackSpeechBubble from '../icons/FeedbackSpeechBubble';
import FeedbackRightArrow from '../icons/FeedbackRightArrow';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import { Link } from 'react-router-dom';

export class FeedbackButton extends React.Component {

    constructor(props) {
        super(props);        
        this.state = {
            expanded: true,
        };
        window.setTimeout(this.collapse, props.collapseInMs);
    }

    componentDidMount() {
        this.mounted = true;
        Users.subscribe(this.setUser, "user");
        this.setUser();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    collapse = () => {
        if (this.mounted)
            this.setState({ expanded: false });
    }

    setUser = () => {
        if (this.mounted)
            this.setState({ user: Users.current });
    }

    render() {
        const localized = this.props.StorefrontConfig.labels
        return (
            <div className="fele-feedback">
                <Link to="../../feedback">
                    <div className="button-wrapper">
                        <div className={'feedback' + (!this.state.expanded ? ' collapsed' : '')}>
                            <button>
                                <FeedbackSpeechBubble />
                                <span>{localized.FeedbackMessage}</span>
                                <FeedbackRightArrow />
                            </button>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }

}
export default (withStorefrontConfig(FeedbackButton))