import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuoteLarge from "../icons/QuoteLarge";
import { Link } from "react-router-dom";
import StatusHelper from '../../helpers/StatusHelper';
import Authorize from '../Authorize';
import * as Roles from '../../resources/UserRole';

export const ActiveQuotes = (props) => {
    const quoteData = props.quoteData;
    const top3 = (quoteData && quoteData.headerResponse && quoteData.headerResponse.length > 0) ? quoteData.headerResponse.reduce((x, y) => {
        if (!x.find((z) => z.orderNumber === y.orderNumber)) {
            x.push(y);
        }
        return x;
    }, []).slice(0, 3) : [];
   
    return (
        <>
            <Authorize hasRoles={[Roles.VIEW_QUOTE_HISTORY]} displayMessage={false}>
                <div className="green-box">
                    <Link className="link-style" to={{ pathname: "/quotes", query: { statuses: props.availableStatuses.filter(x => x.isQuoteStatus === true && x.shown === true && x.isActiveStatus===true).map(x => x.description), dateRange: props.QuoteDayRange } }}>
                        <div >
                            <div><QuoteLarge /></div>
                            <div className="big-number" style={{padding: "10px", overflow: "hidden"} }>
                        <div className="font-size-64">
                          {quoteData.totalOpenQuotes}
                        </div>
                        <div className="label-wrapper">
                            <div className="font-size-28">
                                {props.dashboardLabels.QUOTES}
                            </div>
                            <div className="font-size-20">
                                {props.dashboardLabels.INPROGRESS}
                            </div>
                        </div>
                    </div>
                    
                    </div>
                </Link>
            </div>
            </Authorize>
        </>
    );
}

ActiveQuotes.propTypes = {
    quoteData: PropTypes.object,
    QuoteDayRange: PropTypes.string
};
function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
    };
}

export default connect(mapStateToProps, null)(ActiveQuotes);