import Api from './Api';

class LoggingService {
    
    fetchLoggedItems = (customerId = null, territoryCode = null, companyCode = null, item = null, page, pageSize,startDate, endDate) => {
        let filteredItems = {
            customerId: (customerId === 0 || customerId ==="") ? null : customerId,
            salesTerritoryCode: (territoryCode === 0 || territoryCode === "") ? null : territoryCode,
            companyCode: (companyCode === 0 || companyCode === "") ? null: companyCode,
            itemNumber: item==="" ? null : item,
            page: page,
            pageSize: pageSize,
            startDate: startDate,
            endDate: endDate
        };

        return Api.fetch(`/api/logging/GetLoggedItems`, { method: 'POST', body: JSON.stringify(filteredItems) })
            .then((res) => {
                if (res) {
                    return res.json();
                }
            });
    }         
    
}

export default new LoggingService();