import React, { useContext } from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';
import { useCustomers } from '../hooks/CustomerContext';
import Notifications from '../services/Notifications';
import { useStorefrontSettings } from '../hooks/StorefrontSettingsContext';
import { useStateStorageConfig } from '../hooks/StateStorageConfigContext';
import { useHistory } from 'react-router-dom';

export const NavLink = props => {
   
    const { currentCustomer } = useCustomers();
    const { labels } = useStorefrontSettings(); 
    const {  resetHistoryFilters } = useStateStorageConfig();
    const hasCustomer = () => {
        return (currentCustomer !== null && currentCustomer !== undefined && currentCustomer && currentCustomer !== {});
    }

    const handleClick = (e) => {
        Notifications.error(labels.SelectInitialCustomerMessage);
    }

    const handleNavAnchorClick = (event) => {
     
        // 👇️ use event.preventDefault() if you want to
        // 👇️ refers to the link element
        //console.log(event.currentTarget);
        const anchor = event?.target?.closest("a"); // Use optional chaining
        // console.log(anchor);
        if (anchor && anchor.getAttribute("href") !== '/orders') {
            resetHistoryFilters();
            // console.log('Other Anchor element clicked on /orders route');
        }
        else {
            //console.log('Anchor element clicked on /orders route');
        }
    };

    // Get the history object
    const history = useHistory();

    return (
        <li>
            {hasCustomer() &&
                <Link to={props.url} activeClassName="active" onClick={(event) => {
                    handleNavAnchorClick(event);
                }}>
                    {props.icon}
                    <div className="caption">{props.caption}</div>
                </Link>
            }
            {!hasCustomer() &&
                <a href="#" onClick={handleClick}>
                    {props.icon}
                    <div className="caption">{props.caption}</div>
                </a>
            }
        </li>
    );
}

export default NavLink;

