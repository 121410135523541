import React from 'react';
import PropTypes from 'prop-types';
import LgLogo from './logos/LgLogo';
import LeaoLogo from './logos/LeaoLogo';
import SchneiderLogo from './logos/SchneiderLogo';

export default class LogoSelector extends React.Component {

    render() {
        return (
            <div>
                {this.props.BrandCode === 'SCH' &&
                    <SchneiderLogo />
                }
                {this.props.BrandCode === 'LG' &&
                    <LgLogo />
                }
                {this.props.BrandCode === 'LEA' &&
                    <LeaoLogo />
                }
                {this.props.BrandCode === 'PIO' &&
                    <span>&nbsp;</span>
                }
            </div>
        );
    }
}

LogoSelector.propTypes = {
    BrandCode: PropTypes.string,
};