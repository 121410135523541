import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Calendar from '../form-controls/Calendar';
import StatusHelper from '../../helpers/StatusHelper';

const QuotesFilter = (props) => {
    const lineStatusFilters = props.availableStatuses.filter(x => x.isQuoteStatus === true && x.shown === true).map(x => x.description);
    const [sortSelectValue, setSortSelectValue] = useState(null);    
    const handleEnter = (e) => {
        if (e.key === 'Enter') props.fetchOrders();
    };
    const maxFromDate = props.maxFromDate;

    const lineStatusOptions = lineStatusFilters.map((item, index) => ({
            value: item,
            label: StatusHelper.formatQuoteStatus(item, props.localized)
        }))

        
    const defaults = () => {
        if (props.quoteStatus) {
            return props.quoteStatus.map((item, index) => ({
                value: item,
                label: StatusHelper.formatQuoteStatus(item, props.localized)
            }))
        }
    };


    const customSelectStyles = {
        option: (provided) => ({
            ...provided,
            fontSize: '1.5rem',
        }),
        control: (base) => ({
            ...base,
            minHeight: 35,
            fontSize: '1.4rem',
        }),
    };
    useEffect(() => {      
        if (sortSelectValue == null) {
            const obj = { label: props.localized.NewestFirst, value: 0 };
            setSortSelectValue(obj);
        }
    }, []);

    const handleSelectChange = (option) => {
        const obj = { value: option.value, label: option.label };
        setSortSelectValue(obj);
        props.setSort(option);
    };

    const handleStatusChange = (statusOptions) => {
        props.setStatus(statusOptions);
    };

    const sortOptions = [
        { value: '0', label: props.localized.NewestFirst },
        { value: '1', label: props.localized.OldestFirst },
    ];

    const {
        fetchOrders,
        sort,
        numberFilter,
        setNumber,
        setSort,
        setFromDate,
        fromFullDate,
        quoteStatusFilter,
        setStatus,
        dateFormat,
        toFullDate,
        setToDate,
    } = props;

    return (
        <div className="fele-order-history-filter">
            <div className="flex">
                <div data-cy="sort-by" className="quotes-sort-by">
                    <div className="label">{props.localized.SortByLabel}</div>
                    <div className="fele-content">
                        <div className="select-wrapper">
                            <Select                      
                                id={'ddlSetSort'}
                                styles={customSelectStyles}
                                options={sortOptions}
                                defaultValue={{ label: props.localized.NewestFirst, value: 0 }}
                                onChange={(selectedOption) => handleSelectChange(selectedOption)}
                                value={sortSelectValue}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="quotes-order-status">
                    <div className="label">{props.localized.Status}</div>
                    <div className="fele-content">
                        <div className="select-wrapper">
                            <Select
                                id={'ddlSetStatus' }
                                styles={customSelectStyles}                                
                                isMulti
                                options={lineStatusOptions}
                                onChange={(selectedOption) => handleStatusChange(selectedOption)}
                                placeholder={props.localized.SelectStatus}
                                defaultValue={defaults}
                            />
                        </div>
                    </div>
                </div>
                <div className="from-to-dates" data-cy="order-from-date">
                    <div className="label">{props.localized.FromDate}</div>
                    <div className="fele-content">
                        <div className="input-wrapper quoteHistoryCalendar">
                            <Calendar
                                date={fromFullDate}
                                minDate={new Date().setDate(new Date().getDate() - maxFromDate)}
                                callBackFunction={setFromDate}
                                showIcon={true}
                                dateFormat={dateFormat}
                                localized={props.localized}
                            />
                        </div>
                    </div>
                </div>
                <div className="from-to-dates" data-cy="order-to-date">
                    <div className="label">{props.localized.ToDate}</div>
                    <div className="fele-content">
                        <div className="input-wrapper quoteHistoryCalendar">
                            <Calendar
                                date={toFullDate}
                                minDate={new Date().setDate(new Date().getDate() - maxFromDate)}
                                callBackFunction={setToDate}
                                dateFormat={dateFormat}
                                showIcon={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="search">
                    <div className="label">{props.localized.Search}</div>
                    <div className="fele-content">
                        <div className="input-wrapper">
                            <input
                                data-cy="order-search"
                                type="text"
                                placeholder={props.localized.Search}
                                onChange={setNumber}
                                onKeyPress={handleEnter}
                                value={numberFilter}
                            />
                        </div>
                        <div className="button-wrapper small">
                            <button
                                data-cy="order-submit-search"
                                className="button search-button button-alt"
                                onClick={fetchOrders}
                            >
                                {props.localized.Search}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="seperator"></div>
        </div>
    );
};

export default QuotesFilter;
