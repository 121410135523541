import React from 'react';
import Logo from './icons/Logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';
import { isChrome, isFirefox, isSafari, isEdge, isElectron } from 'react-device-detect';

export class BrowserCheck extends React.Component {
    constructor(props) {
        super(props);
    }

    isSupported() {
        var supported = false;

        // Our list of supported browsers
        if (isFirefox || isSafari || isEdge || isChrome || isElectron) {
            supported = true;
        }

        return supported;
    }

    render() {
        const localized = this.props.StorefrontConfig.labels;
        if (this.isSupported()) {
            return null;
        }
        else {
            return (
                <div className="fele_browser_overlay">
                    <div className="smodal">
                        <div className="exclimation">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="8x" />
                        </div>
                        <div className="warning">
                            <Logo size="small" className="logo-backing" />
                            <div className="heading1">
                                {localized.BrowserWarningTitle}
                            </div>
                            <div>
                                {localized.BrowserWarningMessage}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }

}

export default (withStorefrontConfig(BrowserCheck))
