import React from 'react';
import PropTypes from 'prop-types';

export default class Paging extends React.Component {
  
    render() {
        const { activePage, previousPage, pageCount, nextPage} = this.props;
        return (
           pageCount() > 1 &&
            <div className="fele-pager">
                <div className="page-navigation">
                    <button
                        disabled={activePage === 1 ? "disabled" : ""}
                        onClick={previousPage}
                        className="previous-page page-link"><span>{"<"}</span></button>
                    <div className="current-page">
                        <span>Page </span>
                        <span className="active-page">{activePage}</span>
                        <span className="page-count"> of {pageCount()}</span>
                    </div>
                    <button
                        disabled={activePage === pageCount() ? "disabled" : ""}
                        onClick={nextPage}
                        className="next-page page-link">
                        <span>{">"}</span>
                    </button>
                </div>
            </div>
        );
    }
}

Paging.propTypes = {
    activePage: PropTypes.number,
    pageCount: PropTypes.func,
    nextPage: PropTypes.func,
    previousPage: PropTypes.func,
}