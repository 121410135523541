import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "../Loading";
import InvoiceDetail from "./InvoiceDetail";
import { withSelectedCustomer } from "../../hooks/withSelectedCustomer";
import { withInvoiceConfig } from "../../hooks/InvoiceConfigContext";
import { withStorefrontConfig } from "../../hooks/StorefrontSettingsContext";

const ZERO = 0;
const ONE = 1;

export function Invoices(props) {
  const localized = props?.InvoiceConfig?.labels;
    const showDiscountDueDate = props.InvoiceConfig.settings.ShowDiscountDueDate;
  const renderInvoiceHeader = () => {
    return (
      <div className="invoice-header">
        <div className="invoice-date">
          <div className="label">{localized.InvoiceDateLabel}</div>
        </div>

        <div className="due-date">
          <div className="label">{localized.DueDateLabel}</div>
        </div>

        <div className="number">
          <div className="label">{localized.InvoiceNumber}</div>
        </div>

        <div className="open-amount">
          <div className="label">{localized.OpenAmountLabel}</div>
        </div>

        <div className="gross-amount">
          <div className="label">{localized.GrossAmountLabel}</div>
        </div>
            {showDiscountDueDate &&
                <div className="discount-date">
                    <div className="label">{localized.DiscountDateLabel}</div>
                </div>
            }

        <div className="pdf">
          <div className="label">{localized.PDF}</div>
        </div>

        {props.InvoiceConfig.settings.UsePastDue ? (
          <div className="past-due">
            <div className="label">{localized.PastDue}</div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderLoader = () => {
    return props.fetching && <Loading type="brand" />;
  };

  const renderNoInvoicesMessage = () => {
    return (
      !props.fetching &&
      props.invoices &&
      props.invoices.length < ONE && (
        <div className="details no-results">{localized.NoInvoicesMessage}</div>
      )
    );
  };

  const renderInvoiceDetail = () => {
    return (
      !props.fetching &&
      props.invoices &&
      props.invoices.length > ZERO &&
      props.invoices.map((item, key) => (
        <InvoiceDetail
              invoice={item}
              key={key}
              selectedCustomer={props.selectedCustomer}
              invoicePDFLink={
                  props.InvoiceConfig.settings.ShowInvoicePDFLink === true
              }
              dateDisplayFormat={
                  props.StorefrontConfig.settingsList.DateDisplayFormat
              }
              shareViaWhatsAppLink={
                  props.InvoiceConfig.settings.ShareViaWhatsApp === true
              }
              showDiscountDueDate={
                  props.InvoiceConfig.settings.ShowDiscountDueDate === true
              }
        />
      ))
    );
  };

  return (
    <div className="fele-invoices">
      {renderInvoiceHeader()}
      {renderLoader()}
      {renderNoInvoicesMessage()}
      {renderInvoiceDetail()}
    </div>
  );
}

Invoices.propTypes = {
  selectedCustomer: PropTypes.object,
  fetching: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.currentUser,
  };
}

export default connect(
  mapStateToProps,
  null
)(withInvoiceConfig(withStorefrontConfig(withSelectedCustomer(Invoices))));
